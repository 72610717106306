import { gql } from '@apollo/client';

export const GET_LIVE_DATA_CHART = gql`
  query GetLiveDataChart($propertyId: String!) {
    getLiveData(propertyId: $propertyId) {
      stay_date
      available_rooms_live
      current_otb_live
      current_rate_live
      group_blocked_live
      group_booked_live
      ooo_live
      total_capacity_live
      transient_capacity_live
    }
  }
`;
