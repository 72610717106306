import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../HelpLayout/NewArticleFormStyles.scss';

import React, { useState } from 'react';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

interface TextEditorProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const TextEditor: React.FC<TextEditorProps> = ({ setFieldValue }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const htmlContent = stateToHTML(contentState);
    setFieldValue('body', htmlContent);
  };

  return (
    <div className='text-editor-container'>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorStateChange}
        toolbarClassName='toolbarClassName'
        wrapperClassName='wrapperClassName'
        editorClassName='editorClassName'
        placeholder='Enter your article here...'
      />
    </div>
  );
};

export default TextEditor;
