/* eslint-disable */
import * as Types from '../../../../graphql/gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRoundingRulesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetRoundingRulesQuery = { __typename?: 'Query', getRoundingRules?: Array<{ __typename?: 'RoundingRules', default_col?: boolean | undefined, description?: string | undefined, format?: number | undefined, format_above_bound?: number | undefined, method?: string | undefined, price_bound?: number | undefined, rule_id?: number | undefined, use_bound?: boolean | undefined } | undefined> | undefined };

export type GetPropertyRuleQueryVariables = Types.Exact<{
  propertyId: Types.Scalars['String'];
}>;


export type GetPropertyRuleQuery = { __typename?: 'Query', getPropertyByPropertyId?: { __typename?: 'Property', property_id?: string | undefined, property_name?: string | undefined, rule_id?: number | undefined } | undefined };

export type UpdateRoundingRuleMutationVariables = Types.Exact<{
  propertyId: Types.Scalars['String'];
  ruleId: Types.Scalars['Int'];
}>;


export type UpdateRoundingRuleMutation = { __typename?: 'Mutation', updatePropertyRoundingRule?: { __typename?: 'Property', property_id?: string | undefined, property_name?: string | undefined, rule_id?: number | undefined } | undefined };


export const GetRoundingRulesDocument = gql`
    query GetRoundingRules {
  getRoundingRules {
    default_col
    description
    format
    format_above_bound
    method
    price_bound
    rule_id
    use_bound
  }
}
    `;

/**
 * __useGetRoundingRulesQuery__
 *
 * To run a query within a React component, call `useGetRoundingRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoundingRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoundingRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRoundingRulesQuery(baseOptions?: Apollo.QueryHookOptions<GetRoundingRulesQuery, GetRoundingRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoundingRulesQuery, GetRoundingRulesQueryVariables>(GetRoundingRulesDocument, options);
      }
export function useGetRoundingRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoundingRulesQuery, GetRoundingRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoundingRulesQuery, GetRoundingRulesQueryVariables>(GetRoundingRulesDocument, options);
        }
export type GetRoundingRulesQueryHookResult = ReturnType<typeof useGetRoundingRulesQuery>;
export type GetRoundingRulesLazyQueryHookResult = ReturnType<typeof useGetRoundingRulesLazyQuery>;
export type GetRoundingRulesQueryResult = Apollo.QueryResult<GetRoundingRulesQuery, GetRoundingRulesQueryVariables>;
export const GetPropertyRuleDocument = gql`
    query GetPropertyRule($propertyId: String!) {
  getPropertyByPropertyId(propertyId: $propertyId) {
    property_id
    property_name
    rule_id
  }
}
    `;

/**
 * __useGetPropertyRuleQuery__
 *
 * To run a query within a React component, call `useGetPropertyRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyRuleQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetPropertyRuleQuery(baseOptions: Apollo.QueryHookOptions<GetPropertyRuleQuery, GetPropertyRuleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropertyRuleQuery, GetPropertyRuleQueryVariables>(GetPropertyRuleDocument, options);
      }
export function useGetPropertyRuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyRuleQuery, GetPropertyRuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropertyRuleQuery, GetPropertyRuleQueryVariables>(GetPropertyRuleDocument, options);
        }
export type GetPropertyRuleQueryHookResult = ReturnType<typeof useGetPropertyRuleQuery>;
export type GetPropertyRuleLazyQueryHookResult = ReturnType<typeof useGetPropertyRuleLazyQuery>;
export type GetPropertyRuleQueryResult = Apollo.QueryResult<GetPropertyRuleQuery, GetPropertyRuleQueryVariables>;
export const UpdateRoundingRuleDocument = gql`
    mutation UpdateRoundingRule($propertyId: String!, $ruleId: Int!) {
  updatePropertyRoundingRule(propertyId: $propertyId, ruleId: $ruleId) {
    property_id
    property_name
    rule_id
  }
}
    `;
export type UpdateRoundingRuleMutationFn = Apollo.MutationFunction<UpdateRoundingRuleMutation, UpdateRoundingRuleMutationVariables>;

/**
 * __useUpdateRoundingRuleMutation__
 *
 * To run a mutation, you first call `useUpdateRoundingRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoundingRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoundingRuleMutation, { data, loading, error }] = useUpdateRoundingRuleMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      ruleId: // value for 'ruleId'
 *   },
 * });
 */
export function useUpdateRoundingRuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoundingRuleMutation, UpdateRoundingRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoundingRuleMutation, UpdateRoundingRuleMutationVariables>(UpdateRoundingRuleDocument, options);
      }
export type UpdateRoundingRuleMutationHookResult = ReturnType<typeof useUpdateRoundingRuleMutation>;
export type UpdateRoundingRuleMutationResult = Apollo.MutationResult<UpdateRoundingRuleMutation>;
export type UpdateRoundingRuleMutationOptions = Apollo.BaseMutationOptions<UpdateRoundingRuleMutation, UpdateRoundingRuleMutationVariables>;
export const namedOperations = {
  Query: {
    GetRoundingRules: 'GetRoundingRules',
    GetPropertyRule: 'GetPropertyRule'
  },
  Mutation: {
    UpdateRoundingRule: 'UpdateRoundingRule'
  }
}