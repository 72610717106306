import './date-feed.scss';
import { Button, IconButton, Tooltip } from '@mui/material';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowUp,
  MdRefresh,
} from 'react-icons/md';
import {
  MetricsWithLiveData,
  usePropMetrics,
} from '../../hooks/use-prop-metrics';
import { addDays, dataDate, today } from 'features/dates/date-helpers';
import dayjs, { Dayjs } from 'dayjs';
import {
  selectPolling,
  selectUploading,
} from 'features/rate-upload/redux/rate-upload-selectors';
import { useEffect, useRef, useState } from 'react';
import CalendarIcon from '@mui/icons-material/Event';
import { CustomCard } from '../custom-card';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DayCard } from './day-card';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { METRICS_DURATION } from 'pages/home/helpers';
import { RateUploadButton } from 'features/rate-upload/components/rate-upload-button';
import { RecReviewStatus } from 'types/RecReviewTypes';
import { View } from '../inventory-rate-level-widget/inventory-rate-level-widget';
import { VirtualElement } from '@popperjs/core';
import { labels } from 'locales/en.label';
import { useAppSelector } from 'redux/hooks';
import useDeviceType from 'hooks/useDeviceType';
import { useRateUpload } from 'features/rate-upload/hooks/use-rate-upload';
import { useUser } from 'features/users/context/userContext';

interface DateFeedProps {
  selectedDate: string;
  setDate: (date: string) => void;
  setView: (view: View) => void;
  metricsFilter: { field: string; value: any } | null;
  handlePriorityChange: (level: number | null) => void;
}

type PaginationState = {
  totalRecords: number;
  recordsPerPage: number;
  startIndex: number;
  cardHeight: number;
};

const LoadingCards = ({ count }: { count: number }) => {
  const cards = [];
  for (let i = 0; i < count; i++) {
    cards.push(
      <CustomCard key={i} className='day-card' style={{ height: '141.5px' }}>
        <LoadingBar />
      </CustomCard>
    );
  }
  return <>{cards}</>;
};

export const DateFeed = ({
  selectedDate,
  setDate,
  setView,
  metricsFilter,
  handlePriorityChange,
}: DateFeedProps) => {
  const { isReadOnly } = useUser();
  const [recStatusFilter, setRecStatusFilter] = useState<string | null>(null);
  const [expanded, setExpanded] = useState(false);
  const [isDatePickerOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<VirtualElement | null>(null);
  const { isMobile } = useDeviceType();
  const numberOfDays = expanded ? 5 : 2;

  let filter = null;
  if (metricsFilter) {
    filter = metricsFilter;
  } else if (recStatusFilter) {
    filter = { field: 'rec_status', value: recStatusFilter };
  }
  const { data, loading } = usePropMetrics(
    numberOfDays,
    filter,
    handlePriorityChange
  );
  const [selectedDayCards, setSelectedDayCards] = useState<string[]>([]);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const { handleChangeSingleRate, handleTriggerUpload } = useRateUpload();

  const handleFilterChange = (field: string, value: string | number) => {
    if (field === 'priority' && typeof value === 'number') {
      setRecStatusFilter(null);
      handlePriorityChange(value);
    } else if (field === 'rec_status' && typeof value === 'string') {
      handlePriorityChange(null);
      setRecStatusFilter((filter) => (filter === value ? null : value));
    }
  };
  const polling = useAppSelector(selectPolling);
  const uploading = useAppSelector(selectUploading);
  const cardListRef = useRef<HTMLDivElement | null>(null);
  const [currentDate, setCurrentDate] = useState<Dayjs | null>(dayjs());

  const [paginationState, setPaginationState] = useState<PaginationState>({
    startIndex: 0,
    totalRecords: 0,
    recordsPerPage: 0,
    cardHeight: 0,
  });

  useEffect(() => {
    const selectedCards = (
      data?.map((d) =>
        d.rec_status === RecReviewStatus.PENDING ? d.stay_date : null
      ) ?? []
    ).filter((date): date is string => date !== null);
    setSelectedDayCards(selectedCards);
    const totalRecords = data?.length || 0;
    let cardHeight =
      cardListRef.current?.querySelector('.content')?.clientHeight || 0;
    cardHeight = cardHeight ? cardHeight + 8 : 0; // add 8px padding bottom according to the CSS specification
    setPaginationState((prevState) => ({
      ...prevState,
      totalRecords: totalRecords,
      cardHeight: cardHeight,
    }));
  }, [data]);

  useEffect(() => {
    // Function to set the number of cards to be visible based on different  screen sizes
    const handleResize = () => {
      const containerHeight = cardListRef.current?.clientHeight ?? 0;
      let cardHeight =
        cardListRef.current?.querySelector('.content')?.clientHeight || 0;
      cardHeight = cardHeight ? cardHeight + 8 : 0; // add paddingBottom
      const recordsPerPage = parseInt(
        (containerHeight / cardHeight).toString()
      );
      setPaginationState((prevState) => ({
        ...prevState,
        recordsPerPage: recordsPerPage,
        cardHeight: cardHeight,
      }));
    };

    // Attach ResizeObserver to cardListRef
    const resizeObserver = new ResizeObserver(handleResize);
    if (cardListRef.current) {
      resizeObserver.observe(cardListRef.current);
    }

    // Cleanup function
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    setExpanded(isMobile);
  }, [isMobile]);

  const selectOrDeselectCard = (
    isCardAlreadySelected: boolean,
    stay_date: string
  ) => {
    setSelectedDayCards((prevSelectedDayCards) => {
      if (isCardAlreadySelected) {
        return prevSelectedDayCards.filter((cardId) => cardId !== stay_date);
      } else {
        return [...prevSelectedDayCards, stay_date];
      }
    });
  };

  const handleDayCardClick = (cardData: MetricsWithLiveData | undefined) => {
    if (!cardData || isReadOnly) return;
    const { stay_date, rec_status, rec_rate, overriden_rate } = cardData;
    const isCardAlreadySelected = selectedDayCards.includes(stay_date);
    const sameRate = overriden_rate === rec_rate;
    if (rec_status === RecReviewStatus.SENT) {
      selectOrDeselectCard(isCardAlreadySelected, stay_date);
      return;
    }
    if (!isCardAlreadySelected && rec_status !== RecReviewStatus.PENDING) {
      handleChangeSingleRate({
        ...cardData,
        rec_status: RecReviewStatus.PENDING,
        overriden_rate: sameRate ? rec_rate : overriden_rate,
      });
      selectOrDeselectCard(isCardAlreadySelected, stay_date);
    } else if (isCardAlreadySelected) {
      if (rec_status === RecReviewStatus.PENDING && sameRate) {
        handleChangeSingleRate({
          ...cardData,
          rec_status: RecReviewStatus.REVIEW,
          overriden_rate: sameRate ? rec_rate : overriden_rate,
        });
        selectOrDeselectCard(isCardAlreadySelected, stay_date);
      }
      if (!sameRate) {
        setIsResetModalOpen(true);
      }
    }
  };

  const scrollCardListToSpecifiedLocation = (position: number) => {
    cardListRef.current?.scrollTo(0, position);
  };

  const handleDateChange = (newDate: Dayjs | null) => {
    setCurrentDate(newDate);
    const index =
      data?.findIndex((card) => {
        return newDate?.isSame(dayjs(card.stay_date), 'day');
      }) || 0;
    if (index !== -1) {
      setPaginationState((prevState) => ({ ...prevState, startIndex: index }));
      let scrollPosY = paginationState.cardHeight * index;
      scrollCardListToSpecifiedLocation(scrollPosY); // Scroll to the specified day in the list of cards when a new date is selected
    }
  };

  useEffect(() => {
    if (expanded) {
      handleDateChange(currentDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, currentDate]);

  const handleNextClick = () => {
    if (!expanded) {
      setCurrentDate((currentDate) => currentDate!.add(2, 'day'));
    }
    const nextStartIndex = paginationState.startIndex + numberOfDays;
    setPaginationState((prevState) => ({
      ...prevState,
      startIndex: nextStartIndex,
    }));
    let scrollPosY = paginationState.cardHeight * nextStartIndex;
    scrollCardListToSpecifiedLocation(scrollPosY);
  };

  const handlePreviousClick = () => {
    if (!expanded) {
      setCurrentDate((currentDate) => currentDate!.subtract(2, 'day'));
    }

    let updatedStartIndex = paginationState.startIndex - numberOfDays;
    // If the user is trying to go beyond the total records then just show from first record
    if (updatedStartIndex < 0) {
      updatedStartIndex = 0;
    }
    setPaginationState((prevState) => ({
      ...prevState,
      startIndex: updatedStartIndex,
    }));
    let scrollPosY = paginationState.cardHeight * updatedStartIndex;
    scrollCardListToSpecifiedLocation(scrollPosY);
  };

  return (
    <>
      <div className={`date-feed ${expanded ? 'expanded' : ''}`}>
        <div className='controls'>
          <Button
            variant='outlined'
            className='btn'
            disabled={dataDate(currentDate?.toISOString()) === today()}
            onClick={() => setCurrentDate(dayjs())}
          >
            Today
          </Button>

          <div>
            <RateUploadButton
              disabled={
                polling || uploading || !selectedDayCards.length || isReadOnly
              }
              handleTriggerUpload={handleTriggerUpload}
              polling={polling}
              uploading={uploading}
              className='icon-btn'
            />
            <Tooltip title={labels.home.tooltip.reset} arrow placement='top'>
              <span>
                <IconButton
                  disableFocusRipple
                  disableRipple
                  aria-label='reset'
                  className='icon-btn'
                  onClick={() => setIsResetModalOpen(true)}
                  disabled={!selectedDayCards.length || isReadOnly}
                >
                  <MdRefresh />
                </IconButton>
              </span>
            </Tooltip>
            <IconButton
              aria-label='previous'
              className='icon-btn'
              disabled={paginationState.startIndex === 0}
              onClick={handlePreviousClick}
            >
              <MdKeyboardArrowLeft />
            </IconButton>
            <DatePicker
              open={isDatePickerOpen}
              onClose={() => setIsOpen(false)}
              value={currentDate}
              onChange={handleDateChange}
              disablePast
              maxDate={addDays(METRICS_DURATION - 1)}
              PopperProps={{
                placement: 'top',
                anchorEl: anchorEl,
              }}
              renderInput={() => (
                <IconButton
                  aria-label='pick a date'
                  className='icon-btn'
                  disableFocusRipple
                  disableRipple
                  disabled={!data}
                  onClick={(e) => {
                    setIsOpen((isOpen) => !isOpen);
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  <CalendarIcon />
                </IconButton>
              )}
            />

            <IconButton
              aria-label='next'
              className='icon-btn'
              disabled={
                paginationState.startIndex >= paginationState.totalRecords
              }
              onClick={handleNextClick}
            >
              <MdKeyboardArrowRight />
            </IconButton>

            <IconButton
              aria-label='expand/collapse'
              className='icon-btn expand-btn'
              disabled={!data}
              onClick={() => setExpanded((expanded) => !expanded)}
            >
              <MdKeyboardArrowUp />
            </IconButton>
          </div>
        </div>

        {loading && <LoadingCards count={numberOfDays} />}
        <div className={`${expanded ? 'card-list' : ''}`} ref={cardListRef}>
          {data?.map((metrics) => {
            if (
              !expanded &&
              dataDate(metrics.stay_date) < currentDate!.format('YYYY-MM-DD')
            )
              return null;
            return (
              <DayCard
                key={metrics.stay_date}
                selected={selectedDate === metrics.stay_date}
                metrics={metrics}
                setDate={setDate}
                setView={setView}
                recStatusFilter={recStatusFilter}
                onFilterChange={handleFilterChange}
                handleCardClick={() => {
                  handleDayCardClick(metrics);
                }}
                isCardSelected={
                  selectedDayCards.includes(metrics.stay_date) ||
                  metrics.rec_status === RecReviewStatus.PENDING
                }
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
