import './EventList.scss';
import { AlertMessage } from 'types/EventsTypes';
import { BiSort } from 'react-icons/bi';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { MenuButton } from 'components/MenuButton/MenuButton';
import { ToggleButton } from 'components/ToggleButton/ToggleButton';
import { formatFormDate } from 'features/dates/date-helpers';
import { useEventListController } from './useEventListController';
import { useUser } from 'features/users/context/userContext';
import { labels } from 'locales/en.label';
import { FaPlus } from 'react-icons/fa';

type EventListProps = {
  onError: ({ message, messageType }: AlertMessage) => void;
  //onEventCopy: (events: any, event: EventResponse) => void;
  onFormReset: () => void;
};

export const EventList = ({
  onError,
  //onEventCopy,
  onFormReset,
}: EventListProps) => {
  const {
    isPastEvent,
    sortByOrder,
    setSelectedIds,
    groupedEvents,
    eventListSortOptions,
    onSortByOptionChange,
    onToggle,
    onRowClick,
    loading,
    view,
    currentEvent,
  } = useEventListController({
    onError,
    //onEventCopy,
    onFormReset,
  });

  const { isReadOnly } = useUser();
  return (
    <>
      <div className='event-list'>
        <div className='event-list__header'>
          <div className='event-list__toggleBtn'>
            <ToggleButton
              id='event-type'
              selected={isPastEvent}
              toggleSelected={onToggle}
              labelOptions={[labels.events.active, labels.events.past]}
            />
          </div>
          <div className='event-list__actionBtns'>
            {/* {currentEvent?.length === 1 && !isReadOnly && (
              <IconButton
                disableFocusRipple
                disableRipple
                aria-label='copy'
                color='primary'
                onClick={onCopy}
                title='Copy Events'
                disabled={isReadOnly}
              >
                <MdContentCopy className='event-list__actionIcon' size={14} />
              </IconButton>
            )} */}
            <MenuButton
              title='Sort By'
              options={eventListSortOptions}
              selectedOption={sortByOrder}
              onSelectedOptionChange={onSortByOptionChange}
            >
              <BiSort className='event-list__actionIcon' size={14} />
            </MenuButton>
          </div>
        </div>
        <LoadingBar className='event-list__body' loading={loading}>
          <ul className='event-list__items'>
            {groupedEvents[view]?.map((evnt) => {
              return (
                <li className='event-list__item' key={evnt?.id}>
                  <input
                    className='hidden'
                    type='checkbox'
                    id={evnt?.id}
                    name={evnt?.id}
                    checked={currentEvent?.includes(evnt)}
                    onChange={(event) => onRowClick(event.target.checked, evnt)}
                  />
                  <label htmlFor={evnt?.id} className='event-label'>
                    <div className='event-label__name event-list__text'>
                      {evnt.event?.name}
                    </div>

                    <div className='event-label__date event-list__text'>
                      {formatFormDate(evnt?.start_date, 'DD MMM YYYY')}
                    </div>
                  </label>
                </li>
              );
            })}
            {!isPastEvent && !isReadOnly && (
              <li className='event-list__item'>
                <input
                  className='hidden'
                  type='checkbox'
                  id='newEvent'
                  name='newEvent'
                  checked={!currentEvent?.length}
                  onChange={() => {
                    setSelectedIds([]);
                  }}
                />
                <label htmlFor='newEvent' className='event-label'>
                  <div className='name event-list__text'>
                    {currentEvent?.length! > 0 && (
                      <FaPlus className='add-icon' />
                    )}
                    {labels.events.new_event}
                  </div>
                </label>
              </li>
            )}
          </ul>
        </LoadingBar>
      </div>
    </>
  );
};
