import './DayPicker.scss';

import { DayOption, weekDays } from './config';
import { Field, useField } from 'formik';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { capitalize } from 'lodash';
import { labels } from 'locales/en.label';
import { styled } from '@mui/material/styles';
import styles from 'common/_variables.module.scss';
import { useEffect } from 'react';

interface DayPickerProps {
  name: string;
  onValueChange?: (selectedDays: []) => void;
  validDays?: string[];
  isTooltipVisible?: boolean;
  isDisabled?: boolean;
}

const DaypickerTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: styles.colorGreen,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: styles.colorGreen,
  },
}));

export const DayPicker = ({
  name,
  onValueChange,
  isTooltipVisible = false,
  validDays = [],
  isDisabled = false,
}: DayPickerProps) => {
  const [field, meta] = useField(name);
  useEffect(() => {
    if (onValueChange) {
      onValueChange(field.value);
    }
  }, [field.value, onValueChange]);
  return (
    <>
      <div className='day-picker' role='group'>
        {weekDays.map((day: DayOption, index) => {
          return (
            <DaypickerTooltip
              key={index}
              title={
                isTooltipVisible && !validDays.includes(day.value)
                  ? `${labels.daypicker.tooltip_msg} ${capitalize(day.value)}s`
                  : ''
              }
            >
              <div key={index}>
                <Field
                  className='day-picker__checkbox'
                  id={day.value.toLowerCase()}
                  type='checkbox'
                  name={name}
                  value={day.value}
                  disabled={
                    isDisabled ||
                    (isTooltipVisible && !validDays.includes(day.value))
                  }
                />
                <label
                  className='day-picker__label'
                  htmlFor={day.value.toLowerCase()}
                  tabIndex={0}
                >
                  {day.label}
                </label>
              </div>
            </DaypickerTooltip>
          );
        })}
      </div>
      {meta.error && meta.touched && <p className='error'>{meta.error}</p>}
    </>
  );
};
