import { ApolloError } from '@apollo/client';
import { apolloErrorAdded } from '../redux/alerts-slice';
import { useAppDispatch } from 'redux/hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';

/** Accepts an array of possible Apollo errors and renders them as alerts */
const useApolloErrorAlerts = (errors: (ApolloError | undefined)[]) => {
  const dispatch = useAppDispatch();

  useDeepCompareEffect(() => {
    errors.forEach((error) => {
      if (error) dispatch(apolloErrorAdded(error));
    });
  }, [dispatch, errors]);
};

export default useApolloErrorAlerts;
