import { RootState } from 'redux/store';
import { createSelector } from 'reselect';

const selectMyView = (state: RootState) => state.myView;

export const selectMyMetrics = createSelector(
  selectMyView,
  (myView) => myView.myMetrics
);

export const selectTabs = createSelector(selectMyView, (myView) => myView.tabs);

export const selectQueryInput = createSelector(
  selectMyView,
  (myView) => myView.queryInput
);

export const selectSearchId = createSelector(
  selectMyView,
  (myView) => myView.searchId
);

export const selectShowSearchPanel = createSelector(
  selectMyView,
  (myView) => myView.showSearchPanel
);

export const selectShowDataProcessingMessage = createSelector(
  selectMyView,
  (myView) => myView.showDataProcessingMessage
);

export const selectTableSort = createSelector(
  selectMyView,
  (myView) => myView.tableSort
);

export const selectRecReviewTab = createSelector(
  selectMyView,
  (myView) => myView.recReviewTab
);

export const selectRecReviewChartDays = createSelector(
  selectMyView,
  (myView) => myView.recReviewChartDays
);

export const selectRecReviewChartDayTypes = createSelector(
  selectMyView,
  (myView) => myView.recReviewChartDayTypes
);

export const selectRecPriceChangeSearchId = createSelector(
  selectMyView,
  (myView) => myView.recPriceChangeSearchId
);
