import {
  GetSupportArticlesByCategoryDocument,
  useCreateArticleMutation,
} from '../gql/_gen_/help.gql';

interface Article {
  id: string;
  title: string;
  category: string;
  body: string;
  createdAt: string;
  updatedAt: string;
  slug: string;
}

const useCreateArticle = () => {
  const [createArticleMutation, { data, loading, error }] =
    useCreateArticleMutation({
      update(cache, { data }) {
        if (!data || !data.createArticle) return;

        const newArticle = data.createArticle;

        const existingData = cache.readQuery<{
          getCategoryArticles: Article[];
        }>({
          query: GetSupportArticlesByCategoryDocument,
          variables: { category: 'general' },
        });

        if (existingData) {
          cache.writeQuery({
            query: GetSupportArticlesByCategoryDocument,
            variables: { category: 'general' },
            data: {
              getCategoryArticles: [
                ...existingData.getCategoryArticles,
                newArticle,
              ],
            },
          });
        }
      },
    });

  const createArticle = async (
    title: string,
    body: string,
    category: string,
    slug: string
  ) => {
    try {
      const result = await createArticleMutation({
        variables: {
          data: {
            title,
            body,
            category,
            slug,
          },
        },
      });
      return result;
    } catch (error) {
      console.error('Failed to create article', error);
      throw error;
    }
  };
  return { createArticle, data, loading, error };
};

export default useCreateArticle;
