import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts-grouped-categories/grouped-categories')(Highcharts);

type ChartProps = {
  options: Object;
};

Highcharts.setOptions({
  chart: {
    style: { fontFamily: '"Roboto", sans-serif' },
  },
  credits: {
    enabled: false,
  },
  lang: {
    decimalPoint: '.',
    thousandsSep: ',',
  },
});

export const Chart = ({ options }: ChartProps) => {
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
