import { Prompt, useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { Location } from 'history';
import { Modal } from 'components/Modal/Modal';
import { labels } from 'locales/en.label';

type RouterPromptProps = {
  isBlocked: boolean;
  onConfirm?: () => void;
};
const RouterPromptModal = ({ isBlocked, onConfirm }: RouterPromptProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [lastLocation, setLastLocation] = useState(location);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const content = labels.router_prompt_modal.message;
  const closeModal = () => {
    setIsModalOpen(false);
    setShouldUnload(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const showModal = (nextLocation: React.SetStateAction<Location<unknown>>) => {
    openModal();
    setLastLocation(nextLocation);
  };

  const handleBlockedRoute = (
    nextLocation: React.SetStateAction<Location<unknown>>
  ) => {
    if (!confirmedNavigation && isBlocked) {
      showModal(nextLocation);
      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal();
    setConfirmedNavigation(true);
    onConfirm?.();
  };

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setShouldUnload(true);
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Block non-react routes
  useEffect(() => {
    const unload = (event: { returnValue: string }) => {
      if (isBlocked && !shouldUnload) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = content;
      }
      if (shouldUnload) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', unload);

    return () => window.removeEventListener('beforeunload', unload);
  }, [isBlocked, content, shouldUnload]);

  return (
    <>
      <Prompt when message={handleBlockedRoute} />
      {isModalOpen && (
        <div className='ModalWrapper'>
          <Modal
            headerText={labels.router_prompt_modal.title}
            isShown={isModalOpen}
            hide={closeModal}
            onConfirm={handleConfirmNavigationClick}
            onCancel={closeModal}
            isConfirmationModal={true}
            confirmBtnText={labels.router_prompt_modal.btn_confirm_text}
            messageBody={content}
            hasIcon={false}
          />
        </div>
      )}
    </>
  );
};
export default RouterPromptModal;
