import { addDaysStr, todaySubtract } from 'features/dates/date-helpers';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { selectQueryInput } from 'features/my-view/redux/selectors';
import { setRecRates } from 'features/rate-upload/redux/rate-upload-slice';
import { useGetPropertyRecRatesQuery } from 'features/rec-rates/gql/_gen_/property-rec-rates.gql';

type HookProps = {
  propertyId?: string;
};

export const useGetPropertyRecRates = ({ propertyId }: HookProps) => {
  const dispatch = useAppDispatch();
  const queryInput = useAppSelector(selectQueryInput);

  const startDate = todaySubtract(1, 'week');
  const endDate =
    startDate && queryInput?.duration
      ? addDaysStr(queryInput.duration, startDate)
      : undefined;

  return useGetPropertyRecRatesQuery({
    skip: !propertyId,
    notifyOnNetworkStatusChange: true,
    variables: {
      propertyId,
      ...(startDate &&
        endDate && {
          dateRange: {
            startDate,
            endDate,
          },
        }),
    },
    onCompleted: (data) => {
      dispatch(setRecRates(data.getPropertyRecRates));
    },
  });
};
