import './settings.scss';

import { DropdownMenu, menuType } from 'components/DropdownMenu/DropdownMenu';
import { useEffect, useState } from 'react';
import {
  useGetPropertyRuleQuery,
  useGetRoundingRulesQuery,
  useUpdateRoundingRuleMutation,
} from 'features/rate-rounding/gql/_gen_/rate-rounding.gql';

import { AgGridReact } from 'ag-grid-react';
import { labels } from '../../locales/en.label';
import { twoDecimalRateFormatter } from '../MyView/tableCustomCellRender';
import { useApolloError } from 'hooks/useApolloError';
import { useAppTracking } from 'hooks/useTracking';
import { usePropertyContext } from 'context/propertyContext';
import { useUser } from 'features/users/context/userContext';

export const Settings = () => {
  const [roundingRule, setRoundingRule] = useState(0);
  const { property, currencySymbol } = usePropertyContext();
  const propertyId = property?.propertyId;
  const { handleApolloError } = useApolloError();
  const { isReadOnly } = useUser();
  const { track } = useAppTracking();

  const { data: dataPropertyRule } = useGetPropertyRuleQuery({
    skip: !propertyId,
    variables: {
      propertyId: propertyId || '',
    },
  });

  const propertyRule = dataPropertyRule?.getPropertyByPropertyId?.rule_id;

  useEffect(() => {
    if (propertyRule && propertyRule !== roundingRule) {
      setRoundingRule(propertyRule);
    } else if (!propertyRule) {
      setRoundingRule(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property, propertyRule]);

  const columnDefs = [
    {
      headerName: labels.settings.rounding_rules.column_headers.rule_id,
      field: 'rule_id',
      width: 100,
    },
    {
      headerName: labels.settings.rounding_rules.column_headers.price_bound,
      field: 'price_bound',
      width: 100,
      valueFormatter: (params: { value: number }) => {
        if (!params.value) return;
        const rate = twoDecimalRateFormatter(params?.value?.toString());
        return `${currencySymbol}${rate}`;
      },
    },
    {
      headerName: labels.settings.rounding_rules.column_headers.description,
      field: 'description',
      width: 250,
      tooltipField: 'description',
    },
    {
      headerName: labels.settings.rounding_rules.column_headers.format,
      field: 'format',
      width: 120,
    },
    {
      headerName: labels.settings.rounding_rules.column_headers.format_above,
      field: 'format_above_bound',
      width: 130,
    },
    {
      headerName: labels.settings.rounding_rules.column_headers.method,
      field: 'method',
      width: 120,
    },
    {
      headerName: labels.settings.rounding_rules.column_headers.use_bound,
      field: 'use_bound',
      width: 120,
    },
  ];

  const { data: dataRoundingRules } = useGetRoundingRulesQuery();
  const filteredRules = dataRoundingRules?.getRoundingRules?.filter(
    (rule) => rule?.rule_id !== 0
  );

  const [updateRoundingRule] = useUpdateRoundingRuleMutation();

  const getOptions = () => {
    if (!dataRoundingRules?.getRoundingRules) return [];

    const options = dataRoundingRules?.getRoundingRules?.map((rule) => {
      if (rule?.rule_id === 0) {
        return {
          label: labels.common.none,
          value: 0,
        };
      } else {
        return {
          label: rule?.rule_id?.toString(),
          value: rule?.rule_id,
        };
      }
    }) as menuType[];

    return options.sort((a, b) => {
      if (typeof a.value === 'number' && typeof b.value === 'number')
        return a.value - b.value;
      return 0;
    }) as menuType[];
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const ruleId = parseInt(e.target.value);
    setRoundingRule(ruleId);

    if (propertyId) {
      updateRoundingRule({
        variables: {
          propertyId,
          ruleId: ruleId,
        },
        refetchQueries: ['GetPropertyRule'],
        onCompleted: () => {
          track({
            action: 'Update Rate Rounding Rule',
            propertyId,
            page: 'Settings',
            value: ruleId.toString(),
          });
        },
        onError: (error) => {
          handleApolloError(error);
        },
      });
    }
  };

  return (
    <div className='settings'>
      <div className='rounding-rules'>
        <form className='rounding-form'>
          <label>Rate Rounding Rule</label>
          <DropdownMenu
            options={getOptions()}
            preSelectValue={roundingRule}
            handleChange={handleChange}
            className='rule-select'
            disabled={isReadOnly}
          />
        </form>
      </div>
      <div className='ag-theme-alpine rules-table'>
        <AgGridReact columnDefs={columnDefs as any} rowData={filteredRules} />
      </div>
    </div>
  );
};
