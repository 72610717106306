import './property-search.scss';

import { MdClose, MdSearch } from 'react-icons/md';
import {
  OktaProfileType,
  useUser,
} from '../../features/users/context/userContext';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect, useRef, useState } from 'react';

import { Modal } from 'components/Modal/Modal';
import { UserRole } from 'graphql/gql-types';
import { alertAdded } from 'features/alerts/redux/alerts-slice';
import { labels } from 'locales/en.label';
import { useProperty } from 'hooks/useProperty';
import { usePropertyContext } from 'context/propertyContext';

interface Suggestion {
  property_id: string;
  search_name: string;
  currency_code: string;
}

export const PropertySearch = () => {
  const dispatch = useAppDispatch();
  const { user, oktaUser } = useUser();
  const { property, setProperty } = usePropertyContext();
  const unsavedRulesData = useAppSelector((state) => state.rules.unsavedData);
  const [isUnsavedFormModalOpen, setIsUnsavedFormModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [selProperty, setSelProperty] = useState<Suggestion | null>();

  const searchRef = useRef<HTMLInputElement>(null);

  const { data, loading } = useProperty({
    searchTerm,
  });

  const filterProperties = (suggestedProperty: Suggestion) => {
    if (oktaUser?.profileType === OktaProfileType.CORPORATE) return true;
    return user?.prop_id?.includes(suggestedProperty.property_id);
  };

  useEffect(() => {
    if (data && data.getProperties) {
      setSuggestions(data.getProperties.filter(filterProperties));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function handleBlur() {
    if (searchRef.current) {
      searchRef.current.placeholder = placeHolderText();
    }
  }

  function handleSetProperty(property: Suggestion) {
    const isValidProperty =
      user?.role !== UserRole.hotel_full ||
      user?.prop_id?.includes(property.property_id);

    if (isValidProperty) {
      setProperty({
        propertyId: property.property_id,
        propertyName: property.search_name,
        currencyCode: property.currency_code,
      });
    } else {
      dispatch(alertAdded('error', labels.alerts.propertyAccess));
    }
  }

  function handleChange(selectedProperty: Suggestion) {
    if (unsavedRulesData) {
      setIsUnsavedFormModalOpen(true);
      selectedProperty && setSelProperty(selectedProperty);
    } else if (selectedProperty) {
      handleSetProperty(selectedProperty);
      setSuggestions([]);
      setSearchTerm('');
    }
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value);
  }

  const placeHolderText = () => {
    if (user) {
      if (property?.propertyName) {
        return property?.propertyName;
      } else {
        return labels.autocomplete.no_property_selected;
      }
    } else {
      // There is no user so display no property
      return labels.autocomplete.placeholder;
    }
  };

  const resetTextVal = () => {
    setSuggestions([]);
    setSearchTerm('');

    if (searchRef.current) {
      searchRef.current.value = '';
      searchRef.current.placeholder = '';
      searchRef.current?.focus();
    }
  };

  const onUnsavedFormModalClose = () => {
    setIsUnsavedFormModalOpen(false);
  };

  const onUnsavedFormModalConfirm = () => {
    if (selProperty) {
      setProperty({
        propertyId: selProperty.property_id,
        propertyName: selProperty.search_name,
        currencyCode: selProperty.currency_code,
      });
      setSuggestions([]);
      setSearchTerm('');
    }
    setIsUnsavedFormModalOpen(false);
  };

  return (
    <>
      <Modal
        headerText={labels.router_prompt_modal.title}
        isShown={isUnsavedFormModalOpen}
        hide={onUnsavedFormModalClose}
        onConfirm={onUnsavedFormModalConfirm}
        onCancel={onUnsavedFormModalClose}
        isConfirmationModal={true}
        confirmBtnText={labels.router_prompt_modal.btn_confirm_text}
        messageBody={labels.router_prompt_modal.message}
        hasIcon={false}
      />
      <div className='autoSearch'>
        <div className='autoSearch__search-icon'>
          <MdSearch size={20} />
        </div>
        <input
          id='input'
          autoComplete='off'
          onBlur={handleBlur}
          onChange={handleInputChange}
          placeholder={placeHolderText()}
          ref={searchRef}
          spellCheck={false}
          type={'text'}
          value={searchTerm}
        />
        <div className='autoSearch__close-icon' onClick={resetTextVal}>
          <MdClose size={16} />
        </div>
        {suggestions.length > 0 && (
          <ul className='autoSearch__list'>
            {suggestions.map((item: Suggestion, index) => {
              let className;
              return (
                <li
                  key={index}
                  className={`autoSearch__list-item ${className}`}
                  onClick={() => handleChange(item)}
                >
                  {item.search_name}
                </li>
              );
            })}
          </ul>
        )}
        {searchTerm.length > 2 && suggestions.length < 1 && (
          <ul className='autoSearch__list'>
            <li className={`autoSearch__list-item`}>
              {loading
                ? labels.autocomplete.searching
                : labels.autocomplete.no_property_found}
            </li>
          </ul>
        )}
      </div>
    </>
  );
};
