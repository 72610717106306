import './dropdown-list.scss';

import { Button, Menu, MenuItem } from '@mui/material';

import ComingSoonTooltip from 'pages/MyView/ComingSoonTooltip';
import { MenuOptionType } from 'pages/MyView/config';
import React from 'react';

type DropdownProps = {
  content: React.ReactElement;
  menu: Array<MenuOptionType>;
};

export const DropdownList = ({ content, menu }: DropdownProps) => {
  const menuIdsComingSoon = [1, 2, 3, 4, 5]; // These are ids from more options to show coming soon tooltip
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='dropdownList__container'>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className='dropdownList__button'
      >
        {content}
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menu.map((menuItem) => (
          <MenuItem
            key={menuItem.id}
            onClick={() => {
              menuItem.onClick && menuItem.onClick();
              handleClose();
            }}
            disabled={menuItem.disabled}
          >
            {/* Remove id:6 from menuIdsComingSoon array once Price Decision is ready: */}
            {menuIdsComingSoon.indexOf(menuItem.id) >= 0 ? (
              <ComingSoonTooltip>
                <span>{menuItem.title}</span>
              </ComingSoonTooltip>
            ) : (
              menuItem.title
            )}
            {/* replace with: {menuItem.title} */}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
