import { dateFormatHomeDisplay, formatDate } from 'features/dates/date-helpers';

import dayjs from 'dayjs';
import styles from 'common/_variables.module.scss';

const buildCategories = () => {
  const categories: string[] = [];

  const day = dayjs();
  for (let i = 0; i < 7; i++) {
    categories.unshift(day.subtract(i, 'days').format());
  }

  return categories;
};

export const trendChartConfig: Record<string, any> = {
  chart: {
    height: 384,
    type: 'spline',
    spacing: [24, 16, 16, 16],
  },
  title: {
    text: 'Last 7 days trend',
    align: 'left',
    margin: 20,
    style: {
      fontSize: '1.25rem',
      color: styles.colorGrey60,
    },
    x: 25,
  },
  subtitle: {
    text: 'Rates vs Pickup',
    align: 'right',
    style: { color: styles.mainGrey },
    x: -25,
    y: 15,
  },
  accessibility: {
    enabled: false,
  },
  credits: { enabled: false },
  legend: {
    itemStyle: {
      fontWeight: '400',
      color: styles.colorGrey70,
    },
    itemDistance: 0,
    itemWidth: 100,
    width: 400,
    maxHeight: 42,
    navigation: {
      activeColor: '#59B3C7',
    },
  },
  tooltip: {
    formatter: () => '',
  },
  yAxis: [],
  xAxis: {
    labels: {
      style: { color: styles.mainGrey },
      formatter: function (this: any) {
        if (dayjs().isSame(dayjs(this.value), 'day')) {
          return `<span style="color: ${styles.colorGrey};">Today</span>`;
        }
        return formatDate(this.value, 'MM/DD');
      },
    },
    categories: buildCategories(),
    lineWidth: 0,
  },
  plotOptions: {
    spline: {
      marker: { enabled: false },
    },
    column: {
      pointWidth: 10,
      borderRadius: 5,
      borderWidth: 0,
    },
  },
  colors: [
    styles.graphLightGrey,
    styles.graphBrown,
    styles.graphLightBlue,
    styles.graphOrange,
    styles.graphPurple,
    styles.graphYellow,
    styles.graphGreen,
    styles.graphDarkBlue,
    styles.graphRed,
    styles.graphDarkPurple,
    styles.graphPink,
    styles.graphLighterBlue,
  ],
  series: [],
};

export const buildTooltip = (currencySymbol: string) => {
  return {
    shared: true,
    useHTML: true,
    borderColor: '#ccc',
    backgroundColor: styles.whiteColor,
    borderRadius: 10,
    distance: 32,
    formatter: function (this: Highcharts.TooltipFormatterContextObject) {
      let date = formatDate(this.x as string, dateFormatHomeDisplay);

      let result = `<table class='tooltip'><tr><th colspan="2">${date}</th></tr>`;

      this.points?.forEach(({ series: { name, color }, y }) => {
        const circle = `<div class='circle'style="background-color:${color}"></div>`;

        if (name.length >= 15) name = `${name.slice(0, 12)}...`;
        const nameCell = `<td class='series-name'>${circle}${name}:</td>`;

        const isPickup = name === 'Pickup';

        const prefix = isPickup ? '' : `${currencySymbol}`;
        const valueCell = `<td class='series-value'>${prefix}${
          isPickup ? y : y?.toFixed(2)
        }</td>`;

        result += `<tr>${nameCell}${valueCell}</tr>`;
      });
      result += '</table>';

      return result;
    },
  };
};
