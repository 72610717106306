import './home.scss';

import {
  InventoryData,
  InventoryRateLevelWidget,
  View,
} from './components/inventory-rate-level-widget/inventory-rate-level-widget';
import {
  OccupancyData,
  OccupancyWidget,
} from './components/occupancy-widget/occupancy-widget';

import { HomeDashboardControls } from './components/home-dashboard-controls/home-dashboard-controls';
import { OtbWidget } from './components/otb-widget/otb-widget';
import { OverviewPane } from './components/overview-pane/overview-pane';
import { RecRateWidget } from './components/rec-rate-widget/rec-rate-widget';
import { RecReviewData } from 'pages/MyView/useDashboardController';
import { TrendWidget } from './components/trend-widget/trend-widget';
import { calcTotalForecast } from 'pages/MyView/tableCustomCellRender';
import { today } from 'features/dates/date-helpers';
import { useMetricsForDate } from './hooks/use-metrics-for-date';
import { useRateLevelsForDate } from './hooks/use-rate-levels-for-date';
import { useState } from 'react';
import { useTrendDataForDate } from './hooks/use-trend-data-for-date';
import { useUser } from 'features/users/context/userContext';

export const Home = () => {
  const { user } = useUser();
  const [date, setDate] = useState(today());
  const { metrics, loading: loadingMetrics } = useMetricsForDate(date);
  const {
    rateLevels,
    total,
    loading: loadingRateLevels,
  } = useRateLevelsForDate(date);
  const trendData = useTrendDataForDate(date);

  const [invRateLevelView, setView] = useState<View>('inventory');
  const calcForecast: Partial<RecReviewData> | undefined = metrics && {
    group_booked: metrics.group_booked,
    group_booked_live: metrics.group_booked_live!,
    total_fcst: metrics.total_fcst,
    total_capacity: metrics.total_capacity,
    stay_date: metrics.stay_date,
  };

  const finalForecastValue = calcTotalForecast(calcForecast);

  const occupancyData: OccupancyData | undefined = metrics && {
    lastYearFinalPct: metrics.occ_pct_lyf,
    forecastPct:
      finalForecastValue &&
      (finalForecastValue.fcst_occ_pct_live ?? finalForecastValue.fcst_occ_pct),
    occupancyPct: metrics.occ_pct_live ?? metrics.occ_pct,
  };

  const inventoryData: InventoryData | undefined = metrics && {
    groupBlocked: metrics.group_blocked,
    groupBlockedLive: metrics.group_blocked_live,
    groupBooked: metrics.group_booked,
    groupBookedLive: metrics.group_booked_live,
    outOfOrder: metrics.out_of_order,
    outOfOrderLive: metrics.ooo_live,
    transientBooked: metrics.transient_booked,
    transientBookedLive: metrics.transient_booked_live,
    physicalCap: metrics.total_capacity,
  };

  return (
    <div className='home'>
      <OverviewPane
        name={user?.first_name}
        selectedDate={date}
        setDate={setDate}
        setView={setView}
        lastUpdated={metrics?.last_updated_date}
      />
      <div className='details-pane'>
        <HomeDashboardControls
          selectedDate={date}
          setDate={setDate}
          setView={setView}
          eventId={metrics?.event_id}
          groupBlocked={metrics?.group_blocked_live ?? metrics?.group_blocked}
          groupBooked={metrics?.group_booked_live ?? metrics?.group_booked}
        />
        <RecRateWidget metrics={metrics} loading={loadingMetrics} />
        <OtbWidget
          rateLevels={rateLevels}
          total={total}
          loading={loadingRateLevels}
        />
        <OccupancyWidget
          occupancyData={occupancyData}
          loading={loadingMetrics}
        />
        <TrendWidget trendData={trendData.data} loading={trendData.loading} />
        <InventoryRateLevelWidget
          view={invRateLevelView}
          setView={setView}
          inventoryData={inventoryData}
          rateLevels={rateLevels}
          loadingMetrics={loadingMetrics}
          loadingRateLevels={loadingRateLevels}
        />
      </div>
    </div>
  );
};
