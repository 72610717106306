import { createContext, useContext, useEffect } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { useCannedSearches } from 'hooks/useUserSearches';
import { setDefaultSearchId } from 'features/header/redux/header-slice';
import useApolloErrorAlerts from 'features/alerts/hooks/use-apollo-error-alerts';
import { getSearchIdFromList } from '../default-helper';
import { labels } from 'locales/en.label';

type defaultContextProps = {};
type DefaultProviderProps = { children: React.ReactNode };

const DefaultContext = createContext<defaultContextProps | undefined>(
  undefined
);

const DefaultProvider = ({ children }: DefaultProviderProps) => {
  const dispatch = useAppDispatch();
  const { data: cannedSearchList, error } = useCannedSearches();

  useApolloErrorAlerts([error]);

  useEffect(() => {
    if (cannedSearchList?.getCannedSearchIds?.length > 0) {
      const defaultId = getSearchIdFromList(
        cannedSearchList?.getCannedSearchIds,
        labels.rec_review.search.search_names.default
      );
      dispatch(setDefaultSearchId(defaultId));
    }
  }, [cannedSearchList?.getCannedSearchIds, dispatch]);
  const value = {};

  return (
    <DefaultContext.Provider value={value}>{children}</DefaultContext.Provider>
  );
};

const useDefaultContext = () => {
  const context = useContext(DefaultContext);
  if (context === undefined) {
    throw new Error(`useDefault must be used within a DefaultProvider`);
  }
  return context;
};

export { DefaultProvider, useDefaultContext };
