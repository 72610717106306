import './competitor-info.scss';

import { CompetitorChart } from './competitor-chart';
import { IconButton } from '@mui/material';
import { useState } from 'react';

export const CompetitorInfo = () => {
  const [isChartVisible, setIsChartVisible] = useState(false);

  return (
    <div className='competitor-info'>
      <IconButton
        className='toggle-btn'
        onClick={() => setIsChartVisible((prev) => !prev)}
        disableFocusRipple
        disableRipple
      >
        {/* Hidden from display until fully implemented per B20V-2305: */}
        {/* {!isChartVisible ? (
          <HiChartBarSquare size={22} title='Graph View' />
        ) : (
          <BsCardText size={16} title='Text View' />
        )} */}
      </IconButton>
      {isChartVisible ? (
        <CompetitorChart />
      ) : (
        <div className='info'>
          <h3 className='header'>Who are my true competitors?</h3>
          <p className='text'>
            True competitors are those that your customer would go to as their
            next choice when your property is not available.
          </p>
          <h3 className='header'>Factors to consider to build a comp-set</h3>
          <ul className='text'>
            <li>Quality of similar product / room type</li>
            <li>Price</li>
            <li>Level of Service</li>
            <li>Customer perception of the competition</li>
            <li>Physical location / Proximity</li>
            <li>Amenities</li>
            <li>Customer Segment (Leisure vs Business)</li>
          </ul>
        </div>
      )}
    </div>
  );
};
