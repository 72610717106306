import '../../HelpLayout/SuccessModalStyles.scss';

import React, { useEffect } from 'react';

interface EditSuccessModalProps {
  show: boolean;
  onClose: () => void;
  message: string;
}

const EditSuccessModal: React.FC<EditSuccessModalProps> = ({
  show,
  onClose,
  message,
}) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(onClose, 2500);
      return () => clearTimeout(timer);
    }
  }, [show, onClose]);

  if (!show) {
    return null;
  }

  return (
    <div className='modal-backdrop'>
      <div className='modal-content'>{message}</div>
    </div>
  );
};

export default EditSuccessModal;
