import './trend-widget.scss';

import { Chart } from 'components/Chart/Chart';
import { CustomCard } from '../custom-card';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { TrendData } from 'pages/home/hooks/use-trend-data-for-date';
//import { buildTrendChartConfig } from './config';
import styles from 'common/_variables.module.scss';
import { usePropertyContext } from 'context/propertyContext';
import { buildTooltip, trendChartConfig } from './config';

type TrendWidgetProps = {
  trendData: TrendData | null;
  loading: boolean;
};

const buildChartOptions = (
  { pickupData, bwRates, marketRates, recRates, competitorRates }: TrendData,
  currencySymbol: string
) => {
  //const trendChartConfig = buildTrendChartConfig(currencySymbol);
  const config = { ...trendChartConfig };

  const buildDummyEntries = (count: number) => {
    const entries = [];
    for (let i = 0; i < count; i++) {
      entries.push({
        name: '',
        color: 'transparent',
      });
    }
    return entries;
  };

  const competitorSeriesData = competitorRates.map(({ name, rates }, i) => ({
    name,
    zIndex: 2,
    data: rates,
    visible: i === 0,
  }));

  config.series = [
    {
      name: 'Pickup',
      type: 'column',
      yAxis: 1,
      zIndex: 1,
      showInLegend: false,
      data: pickupData,
    },
    {
      name: 'BWH Rate',
      zIndex: 2,
      data: bwRates,
    },
    {
      name: 'Market Rate',
      zIndex: 2,
      data: marketRates,
    },
    {
      name: 'Rec Rate',
      zIndex: 2,
      data: recRates,
    },
    ...competitorSeriesData,
    // Need at least 6 entries to reach 3 rows and enable navigation
    ...buildDummyEntries(6 - competitorSeriesData.length),
  ];

  config.yAxis = [
    {
      title: { text: 'Rates', style: { color: styles.mainGrey } },
      // eslint-disable-next-line no-template-curly-in-string
      labels: {
        style: { color: styles.mainGrey },
        format: `${currencySymbol}{value}`,
      },
      gridLineWidth: 0,
    },
    {
      title: { text: 'Pickup', style: { color: styles.mainGrey } },
      labels: { style: { color: styles.mainGrey } },
      opposite: true,
      gridLineColor: 'rgba(196, 196, 196, 0.2)',
    },
  ];

  config.tooltip = buildTooltip(currencySymbol);

  return config;
};

export const TrendWidget = ({ trendData, loading }: TrendWidgetProps) => {
  const { currencySymbol } = usePropertyContext();
  const chartOptions =
    trendData && buildChartOptions(trendData, currencySymbol);
  return (
    <CustomCard className='trend-widget'>
      <LoadingBar loading={loading}>
        {chartOptions && <Chart options={chartOptions} />}
      </LoadingBar>
    </CustomCard>
  );
};
