import { AlertMessage } from 'types/EventsTypes';
import {
  initialFormState,
  setCurrentEvent,
  setEventsFormRef,
  setIsEventNameReadonly,
  setIsEventsFormDirty,
  updateEventsFormValues,
} from 'features/events/redux/events-form-slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { EventFormData } from 'graphql/gql-types';
import { FormikProps } from 'formik';
import { alertAdded } from 'features/alerts/redux/alerts-slice';
import { labels } from 'locales/en.label';
import {
  useDeleteEventRecord,
  useDeleteEvents,
} from 'features/events/hooks/event-hooks';
import { useModal } from 'components/Modal/useModal';

export const useEventsController = () => {
  const dispatch = useAppDispatch();
  const isEventNameReadonly = useAppSelector(
    (state) => state.eventsForm.isEventNameReadonly
  );
  const isEventsFormDirty = useAppSelector(
    (state) => state?.eventsForm.isEventsFormDirty
  );

  const { deleteEvents, deleteEventsLoading } = useDeleteEvents();
  const { deleteEventRecord, deleteEventRecordLoading } =
    useDeleteEventRecord();
  const [isAlertMessageVisible, setIsAlertMessageVisible] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const { isShown, toggle } = useModal();
  const eventsFormRef = useRef<FormikProps<EventFormData>>(null);

  useEffect(() => {
    dispatch(setEventsFormRef(eventsFormRef.current));
    dispatch(setIsEventsFormDirty(eventsFormRef.current?.dirty || false));
  }, [eventsFormRef, dispatch]);

  const onError = useCallback(
    ({ message, messageType }: AlertMessage) => {
      dispatch(alertAdded(messageType, message));
    },
    [dispatch]
  );

  const onAlertMessageClose = () => {
    setIsAlertMessageVisible(false);
  };

  const onConfirmtionWindowClose = useCallback(
    () => setIsConfirmationModalOpen(false),
    []
  );

  /* const onEventCopy = useCallback(
    (eventObj: EventResponse) => {
      if (isEventNameReadonly) dispatch(setIsEventNameReadonly(false));
      const copyEvent: any = {
        ...eventObj,
        event_id: '',
        name: labels.rules.copy + eventObj.event?.name,
      };

      dispatch(updateEventsFormValues({ ...copyEvent }));
      eventsFormRef.current?.resetForm({ values: copyEvent });
      setEventsFormRef({ values: copyEvent });
    },
    [isEventNameReadonly, dispatch]
  ); */

  const onDelete = () => {
    const selectedEvent: any = eventsFormRef?.current?.values;
    deleteEvents(selectedEvent, () => {
      toggle();
      const alertMessage = `${selectedEvent.name} ${labels.events.delete_single_success_msg}`;
      dispatch(alertAdded('success', alertMessage));
      eventsFormRef.current?.resetForm({ values: initialFormState.formValues });
      dispatch(setCurrentEvent([]));
    });
  };

  const onRecordDelete = (record: any) => {
    deleteEventRecord(record, () => {
      const alertMessage = `${labels.events.delete_event_record_success_msg}`;
      dispatch(alertAdded('success', alertMessage));
    });
  };

  const onFormReset = useCallback(async () => {
    dispatch(setIsEventNameReadonly(false));
    eventsFormRef.current?.resetForm({ values: initialFormState.formValues });
    dispatch(updateEventsFormValues({ ...initialFormState.formValues }));
    setIsConfirmationModalOpen(false);
  }, [dispatch]);

  const openConfirmationModal = useCallback(() => {
    if (eventsFormRef.current?.dirty) setIsConfirmationModalOpen(true);
    else onFormReset();
  }, [onFormReset]);

  return {
    loading: deleteEventsLoading || deleteEventRecordLoading || false,
    isConfirmationModalOpen,
    isEventNameReadonly,
    isAlertMessageVisible,
    isEventsFormDirty,
    eventsFormRef,
    openConfirmationModal,
    onConfirmtionWindowClose,
    onFormReset,
    //onEventCopy,
    onError,
    onAlertMessageClose,
    isShown,
    toggle,
    onDelete,
    deleteEventsLoading,
    deleteEvent: eventsFormRef?.current?.values,
    onRecordDelete,
  };
};
