import { RecReviewStatus } from 'graphql/gql-types';

export const REC_STATUS_ALL = [
  RecReviewStatus.N,
  RecReviewStatus.E,
  RecReviewStatus.P,
  RecReviewStatus.R,
  RecReviewStatus.S,
];
export const REC_STATUS_ALL_NOERR = [
  RecReviewStatus.N,
  RecReviewStatus.P,
  RecReviewStatus.R,
  RecReviewStatus.S,
];
