import { HtmlTooltip } from './html-tooltip';
import { formattedCurrency } from 'pages/MyView/tableCustomCellRender';
import { selectProperty } from 'features/property/redux/selectors';
import { useAppSelector } from 'redux/hooks';
import { useUser } from 'features/users/context/userContext';

type LiveDataFieldProps = {
  liveVal: unknown;
  oldVal: unknown;
  enableCurrencyFormatter: boolean;
  removeDecimals?: boolean;
};

/**
 * Show tooltip if there is live data and live_data !== old_data
 */
export const LiveDataField = ({
  liveVal,
  oldVal,
  enableCurrencyFormatter,
  removeDecimals,
}: LiveDataFieldProps) => {
  const { locale } = useUser();
  const property = useAppSelector(selectProperty);
  const { currencyCode } = property;
  const oldRate = enableCurrencyFormatter
    ? formattedCurrency(
        oldVal as string,
        locale,
        currencyCode,
        enableCurrencyFormatter,
        undefined,
        removeDecimals
      )
    : oldVal;
  const liveRate = enableCurrencyFormatter
    ? formattedCurrency(
        liveVal as string,
        locale,
        currencyCode,
        enableCurrencyFormatter,
        undefined,
        removeDecimals
      )
    : liveVal;

  if (
    liveVal !== undefined &&
    !Number.isNaN(liveVal) &&
    oldVal !== liveVal &&
    liveVal !== null &&
    Number(liveVal) !== 0
  ) {
    return (
      <HtmlTooltip placement='right' title={oldVal === null ? '-' : oldRate}>
        <div className='liveCell'>
          <span className='liveCell__value'>{liveRate}</span>
          <span className='liveCell__star'>*</span>
        </div>
      </HtmlTooltip>
    );
  } else {
    return <span>{oldVal ? oldRate : '-'}</span>;
  }
};
