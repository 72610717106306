import './two-pane-layout.scss';

import { LoadingBar } from 'components/loading-bar/loading-bar';
import { ReactNode } from 'react';

type TwoPaneLayoutProps = {
  LeftSideComponent: ReactNode;
  RightSideComponent: ReactNode;
  loading?: boolean;
};

export const TwoPaneLayout = ({
  LeftSideComponent,
  RightSideComponent,
  loading = false,
}: TwoPaneLayoutProps) => {
  return (
    <LoadingBar
      loading={loading}
      overlayStyle={{
        borderRadius: '10px 20px 20px 10px',
        backgroundColor: ' rgba(0, 0, 0, 0.15)',
      }}
    >
      <div className='two-pane-layout'>
        <div className='left'>{LeftSideComponent}</div>
        <div className='right'>{RightSideComponent}</div>
      </div>
    </LoadingBar>
  );
};
