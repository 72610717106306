import { gql } from '@apollo/client';

export const GET_PROPERTY_COMPETITORS = gql`
  query GetPropertyCompetitors($propertyId: String) {
    getPropertyCompetitors(propertyId: $propertyId) {
      comp_id
      comp_name
      rating
      user_count
      comp_weight
      comp_address
      comp_city
      comp_state
      comp_zip
      last_updated_by
      last_updated_date
    }
  }
`;

export const GET_PROPERTY_COMPETITOR_SET = gql`
  query GetPropertyCompetitorSet($propertyId: String, $seasonId: String) {
    getPropertyCompetitorsSet(propertyId: $propertyId, seasonId: $seasonId) {
      comp_id
      comp_name
      rating
      user_count
      comp_weight
      comp_address
      comp_city
      comp_state
      comp_zip
      last_updated_by
      last_updated_date
    }
  }
`;

export const CREATE_COMP_SEASONS = gql`
  mutation CreateCompSeasons($newCompSeasons: [UpdateCompSeasonsInput!]!) {
    createCompSeasons(newCompSeasons: $newCompSeasons) {
      message
      success
    }
  }
`;

export const UPDATE_COMP_SEASONS = gql`
  mutation UpdateCompSeasons($modifiedCompSeasons: [UpdateCompSeasonsInput!]!) {
    updateCompSeasons(modifiedCompSeasons: $modifiedCompSeasons) {
      message
      numUpdatedRows
      success
    }
  }
`;

export const DELETE_COMP_SEASONS = gql`
  mutation DeleteCompSeasons($selectedCompSeasons: [DeleteCompSeasonInput!]!) {
    deleteCompSeasons(selectedCompSeasons: $selectedCompSeasons) {
      message
      numDeletedRows
      success
    }
  }
`;

export const GET_PROP_RATES = gql`
  query getPropertyRates($propertyId: String, $input: SearchInfo) {
    PropCompetitorSet(propertyId: $propertyId, input: $input) {
      result_rows
      results {
        comp_id_1
        comp_id_2
        comp_id_3
        comp_id_4
        comp_id_5
        comp_id_6
        comp_id_7
        comp_id_8
        comp_id_9
        comp_id_10
        comp_name_1
        comp_name_2
        comp_name_3
        comp_name_4
        comp_name_5
        comp_name_6
        comp_name_7
        comp_name_8
        comp_name_9
        comp_name_10
        comp_rate_1
        comp_rate_2
        comp_rate_3
        comp_rate_4
        comp_rate_5
        comp_rate_6
        comp_rate_7
        comp_rate_8
        comp_rate_9
        comp_rate_10
        stay_date
        property_id
        market_rate
        current_rate
      }
    }
  }
`;
