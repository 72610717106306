export const propertyInfo = {
  propertyId: '03121',
  propertyName: '03121: B/W Plus Inn of Williams',
  currencyCode: 'USD',
};

export const defaultProperty = {
  propertyId: '03121',
  propertyName: '03121: B/W Plus Inn of Williams',
  currencyCode: 'USD',
};
