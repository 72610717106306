import { Field, useField } from 'formik';
import { status, StatusOption } from './config';
import './StatusPicker.scss';

interface StatusPickerProps {
  name: string;
}

export const StatusPicker = ({ name }: StatusPickerProps) => {
  const [, meta] = useField(name);

  return (
    <>
      <div className='status-picker' role='group'>
        {status.map((status: StatusOption) => {
          return (
            <div key={status.label}>
              <Field
                className='status-picker__checkbox'
                id={status.value.toLowerCase()}
                type='checkbox'
                name={name}
                value={status.value}
              />
              <label
                className='status-picker__label'
                htmlFor={status.value.toLowerCase()}
                tabIndex={0}
              >
                {status.label}
              </label>
            </div>
          );
        })}
      </div>
      {meta.error && meta.touched && <p className='error'>{meta.error}</p>}
    </>
  );
};
