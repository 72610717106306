import './otb-widget.scss';

import { Chart } from 'components/Chart/Chart';
import { CustomCard } from '../custom-card';
import { FormattedRateLevels } from 'pages/home/hooks/use-rate-levels-for-date';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { otbChartConfig } from './config';

type OtbWidgetProps = {
  rateLevels: FormattedRateLevels | undefined;
  total: number | undefined;
  loading: boolean;
};

export const OtbWidget = ({ rateLevels, total, loading }: OtbWidgetProps) => {
  const chartOptions = { ...otbChartConfig };
  const data: { name: string; y: number }[] = [];
  rateLevels?.forEach(({ level, otb, liveOtb }) => {
    if (!liveOtb && !otb) return;
    data.push({
      name: level,
      y: liveOtb ?? otb,
    });
  });
  chartOptions.series[0].data = data;

  return (
    <CustomCard className='otb-widget'>
      <LoadingBar loading={loading}>
        {rateLevels && total !== undefined ? (
          <div className='content'>
            <div className='chart-container'>
              <Chart options={chartOptions} />
            </div>
            <div className='total'>
              <h1>{total}</h1>
              <span>OTB</span>
            </div>
          </div>
        ) : null}
      </LoadingBar>
    </CustomCard>
  );
};
