import { Form, Formik } from 'formik';
import { OccupancyType, PricingStrategy, ProxyType } from 'graphql/gql-types';
import {
  seasonChanged,
  selectCurrentSeason,
} from 'features/rules/redux/rules-slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { FormButtons } from '../form-buttons/form-buttons';
import { RatesForm } from './rates-form';
import { RatesInfo } from './rates-info';
import RouterPromptModal from 'components/RouterPromptModal/RouterPromptModal';
import { RuleTabs } from '../rule-tabs/rule-tabs';
import { TwoPaneLayout } from 'components/two-pane-layout/two-pane-layout';
import { alertAdded } from 'features/alerts/redux/alerts-slice';
import { useApolloError } from 'hooks/useApolloError';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useUpdatePropSeasonRateMutation } from 'features/rules/rates/gql/_gen_/prop-rates.gql';
import { useUser } from 'features/users/context/userContext';

export interface RatesFormValues {
  pricingStrategy: PricingStrategy;
  minRate: string;
  maxRate: string;
  derivedRateType: OccupancyType;
  derivedRateOffsetSign: '+' | '-';
  derivedRateOffset: string;
  proxy_type: ProxyType;
  proxy_comp_id: number;
  proxy_offset_amt: string;
}

export const Rates = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { handleApolloError } = useApolloError();
  const { user } = useUser();
  const selectedSeason = useAppSelector(selectCurrentSeason);
  const [updateSeasonRates, { loading: updateRatesLoading }] =
    useUpdatePropSeasonRateMutation();

  const [continueClicked, setContinueClicked] = useState(false);

  const initialValues: RatesFormValues = {
    pricingStrategy:
      selectedSeason?.pricing_strategy ?? PricingStrategy.neutral,
    minRate: selectedSeason?.min_rate?.toFixed(2) ?? '0.00',
    maxRate: selectedSeason?.max_rate?.toFixed(2) ?? '0.00',
    derivedRateType: selectedSeason?.derived_rate_type ?? OccupancyType.single,
    derivedRateOffsetSign:
      (selectedSeason?.derived_rate_offset ?? 0) < 0 ? '-' : '+',
    derivedRateOffset: Math.abs(
      selectedSeason?.derived_rate_offset ?? 0
    )?.toFixed(2),
    proxy_type: selectedSeason?.proxy_type ?? ProxyType.value,
    proxy_comp_id: selectedSeason?.proxy_comp_id ?? 0,
    proxy_offset_amt:
      selectedSeason?.proxy_type === ProxyType.percent
        ? (selectedSeason?.proxy_offset_amt! * 100).toFixed(2)
        : selectedSeason?.proxy_offset_amt?.toFixed(2) ?? '0.00',
  };

  const handleSubmit = async (values: RatesFormValues) => {
    if (!selectedSeason) return false;

    const updatedFields = {
      property_id: selectedSeason.property_id!,
      season_id: selectedSeason.season_id!,
      pricing_strategy: values.pricingStrategy,
      min_rate: parseFloat(values.minRate),
      max_rate: parseFloat(values.maxRate),
      derived_rate_type: values.derivedRateType,
      derived_rate_offset:
        (values.derivedRateOffsetSign === '+' ? 1 : -1) *
        parseFloat(values.derivedRateOffset),
      last_updated_by: user?.login_id,
      proxy_type: values.proxy_type,
      proxy_comp_id: values.proxy_comp_id || undefined,
      proxy_offset_amt:
        values.proxy_type === ProxyType.value
          ? parseFloat(values.proxy_offset_amt)
          : parseFloat(values.proxy_offset_amt) / 100,
    };

    const { data } = await updateSeasonRates({
      variables: {
        updatedPropSeasonRates: updatedFields,
      },
      refetchQueries: ['GetPropSeasons'],
      onCompleted: () => {
        dispatch(seasonChanged({ ...selectedSeason, ...updatedFields }));
      },
      onError: (error) => {
        handleApolloError(error);
      },
    });

    if (data?.updatePropertySeasonRates?.success) {
      dispatch(alertAdded('success', data.updatePropertySeasonRates.message!));
      if (continueClicked) history.push('/rules/autopilot');
    } else {
      dispatch(alertAdded('error', 'Rate changes not saved'));
      setContinueClicked(false);
    }
  };

  return (
    <div>
      <RuleTabs loading={updateRatesLoading} />
      <Formik<RatesFormValues>
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ dirty, validateForm, submitForm }) => (
          <>
            <RouterPromptModal isBlocked={dirty && !continueClicked} />
            <Form>
              <TwoPaneLayout
                loading={updateRatesLoading}
                LeftSideComponent={
                  <RatesForm useProxy={selectedSeason?.use_proxy} />
                }
                RightSideComponent={<RatesInfo />}
              />
              <FormButtons
                loading={updateRatesLoading}
                dirty={dirty}
                onContinueClick={async () => {
                  if (!dirty) {
                    history.push('/rules/autopilot');
                    return;
                  }
                  const isValid =
                    Object.keys(await validateForm()).length === 0;
                  if (isValid) setContinueClicked(true);
                  submitForm();
                }}
              />
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};
