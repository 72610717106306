import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { MdClose, MdOutlineRecordVoiceOver } from 'react-icons/md';
import styles from 'common/_variables.module.scss';
import { labels } from 'locales/en.label';
import './AskRev.scss';
import { data } from './config';
import { useAppDispatch } from 'redux/hooks';
import { setShowAskRev } from 'features/header/redux/header-slice';

type AskRevProps = {
  handleChange: Function;
};
export const AskRev = ({ handleChange }: AskRevProps) => {
  const dispatch = useAppDispatch();
  return (
    <div className='askrev'>
      <div className='askrev__close'>
        <MdClose
          size={20}
          onClick={(e) => {
            dispatch(setShowAskRev(false));
            handleChange(e);
          }}
        />
      </div>
      <div className='askrev__left'>
        <MdOutlineRecordVoiceOver
          size={40}
          color={styles.colorGreen}
          className='askrev__icon'
        />
        <div className='askrev__title'>{labels.ask_best_rev.title}</div>
      </div>
      <div className='askrev__right'>
        <FormControl
          component='fieldset'
          name='askrev-list'
          className='askrev__search-list'
        >
          <RadioGroup onChange={(e) => handleChange(e)}>
            {data.map((ele, index) => {
              return (
                <FormControlLabel
                  value={ele.key}
                  key={index}
                  control={
                    <Radio
                      disableFocusRipple
                      disableRipple
                      color={'success'}
                      size='small'
                    />
                  }
                  label={ele.value}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};
