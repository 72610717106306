/* eslint-disable no-template-curly-in-string */
import Highcharts from 'highcharts';
import { labels } from 'locales/en.label';
import styles from 'common/_variables.module.scss';

export const daysOptions = [
  {
    label: labels.rec_review.days.day30,
    value: 30,
    type: 'day',
  },
  {
    label: labels.rec_review.days.day60,
    value: 60,
    type: 'day',
  },
  {
    label: labels.rec_review.days.day90,
    value: 90,
    type: 'day',
  },
  {
    label: labels.rec_review.days.day120,
    value: 120,
    type: 'day',
  },
  {
    label: labels.rec_review.days.day365,
    value: 365,
    type: 'day',
  },
];

export const weekOptions = [
  {
    label: labels.rec_review.weeks.all,
    value: 'all',
    type: 'week',
  },
  {
    label: labels.rec_review.weeks.weekdays,
    value: 'weekdays',
    type: 'week',
  },
  {
    label: labels.rec_review.weeks.weekends,
    value: 'weekends',
    type: 'week',
  },
];

export const graphOptions = [
  {
    label: labels.rec_review.chart.graph.demand,
    value: 'demand',
  },
  {
    label: labels.rec_review.chart.graph.inventory,
    value: 'inventory',
  },
];

export const rateOptions = [
  {
    key: 'bw_rates',
    name: 'BWH Rate',
  },
  {
    key: 'market_rates',
    name: 'Market Rate',
  },
  {
    key: 'rec_rates',
    name: 'Rec Rate',
  },
  {
    key: 'yoy_rate_lyst',
    name: 'YOY Rate',
  },
];

export const demamdChartSeries: any = {
  bw_rates: {
    name: labels.rec_review.dashboard.bw_rate,
    color: styles.graphBrown,
  },
  market_rates: {
    name: labels.rec_review.dashboard.market_rate,
    color: styles.graphLightBlue,
  },
  rec_rates: {
    name: labels.rec_review.dashboard.rec_rate,
    color: styles.graphOrange,
  },
  yoy_rate_lyst: {
    name: labels.rec_review.dashboard.yoy_rate,
    color: styles.graphDarkBlue,
  },
};
export const inventoryChartSeries: any = {
  group_booked: {
    name: labels.rec_review.dashboard.group_booked,
    color: styles.graphPink,
    type: 'column',
  },
  group_blocked: {
    name: labels.rec_review.dashboard.group_blocked,
    color: styles.graphPurple,
    type: 'column',
  },
  transient_booked: {
    name: labels.rec_review.dashboard.transient_booked,
    color: styles.graphLightBlue,
    type: 'column',
  },
  available_rooms: {
    name: labels.rec_review.dashboard.available_rooms,
    color: styles.graphLightGrey,
    type: 'column',
  },
  out_of_order: {
    name: labels.rec_review.dashboard.out_of_order,
    color: styles.graphDarkerGrey,
    type: 'column',
  },
  overbooking: {
    name: labels.rec_review.dashboard.overbooking,
    color: styles.graphRed,
    type: 'column',
  },
};

const tooltipConfig = (symbol: string) => {
  return {
    shared: true,
    useHTML: true,
    borderRadius: 15,
    borderColor: '#ccc',
    backgroundColor: styles.whiteColor,
    distance: 32,
    formatter: function (this: Highcharts.TooltipFormatterContextObject) {
      const { points, x } = this;
      const title = x?.toString().substring(0, x?.toString().lastIndexOf(','));
      let result = `<table class="tooltip"><tr><th colspan="2">${title}</th></tr>`;

      points?.forEach(({ series: { name, color }, y }) => {
        const circle = `<div class='circle'style="background-color:${color}"></div>`;
        const nameCell = `<td class='series-name'>${circle}${name}:</td>`;

        const prefix = name.includes('Rate') ? `${symbol}` : '';
        const valueCell = `<td class='series-value'>${prefix}${y}</td>`;

        result += `<tr>${nameCell}${valueCell}</tr>`;
      });
      result += '</table>';

      return result;
    },
  };
};

export const buildDemandChartOptions = (currencySymbol: string) => {
  return {
    chart: {
      zoomType: 'xy',
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    accessibility: {
      enabled: false,
    },
    yAxis: [
      {
        // Primary yAxis
        labels: {
          format: '{value}',
          style: {
            color: 'rgba(72, 72, 72, 0.5)',
          },
        },
        title: {
          text: labels.rec_review.chart.room,
          style: {
            color: 'rgba(72, 72, 72, 0.5)',
          },
        },
      },
      {
        // Secondary yAxis
        title: {
          text: labels.rec_review.chart.rates,
          style: {
            color: 'rgba(72, 72, 72, 0.5)',
          },
        },
        labels: {
          format: `${currencySymbol}{value}`,
          style: {
            color: 'rgba(72, 72, 72, 0.5)',
          },
        },
        opposite: true,
      },
    ],
    tooltip: tooltipConfig(currencySymbol),
    plotOptions: {
      spline: {
        marker: { enabled: false },
      },
      column: {
        grouping: false,
        shadow: false,
        borderWidth: 0,
        borderRadius: 7,
      },
    },
  };
};

export const buildInventoryChartOptions = (currencySymbol: string) => {
  return {
    chart: {
      zoomType: 'xy',
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    accessibility: {
      enabled: false,
    },
    yAxis: [
      {
        labels: {
          format: '{value}',
          style: {
            color: 'rgba(72, 72, 72, 0.5)',
          },
        },
        title: {
          text: labels.rec_review.chart.room,
          style: {
            color: 'rgba(72, 72, 72, 0.5)',
          },
        },
      },
      {
        // Secondary yAxis
        title: {
          text: 'Rates',
          style: {
            color: 'rgba(72, 72, 72, 0.5)',
          },
        },
        labels: {
          format: `${currencySymbol}{value}`,
          style: {
            color: 'rgba(72, 72, 72, 0.5)',
          },
        },
        opposite: true,
      },
    ],
    tooltip: tooltipConfig(currencySymbol),
    plotOptions: {
      column: {
        grouping: false,
        shadow: false,
        stacking: 'normal',
      },
      line: {
        dataLabels: {
          enabled: true,
        },
      },
      spline: {
        marker: { enabled: false },
        dataLabels: {
          enabled: true,
          color: styles.colorBlack,
        },
      },
      series: { stacking: 'normal' },
    },
  };
};
