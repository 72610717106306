import { SelectedDays } from '../../types/SearchTypes';

export const DOW_ALL = [
  SelectedDays.SUNDAY,
  SelectedDays.MONDAY,
  SelectedDays.TUESDAY,
  SelectedDays.WEDNESDAY,
  SelectedDays.THURSDAY,
  SelectedDays.FRIDAY,
  SelectedDays.SATURDAY,
];
export const DOW_WEEKEND = [SelectedDays.SATURDAY, SelectedDays.SUNDAY];
export const DOW_WEEKDAY = [
  SelectedDays.MONDAY,
  SelectedDays.TUESDAY,
  SelectedDays.WEDNESDAY,
  SelectedDays.THURSDAY,
  SelectedDays.FRIDAY,
];
