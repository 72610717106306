import './competitor-table.scss';

import {
  competitorTypes,
  getRowStyle,
  gridOptions,
  tableComponents,
} from './config';

import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { PropCompetitorsType } from 'types/RulesTypes';
import { SeasonSelect } from '../season-select/season-select';
import { ToggleSwitch } from 'components/toggle-switch/toggle-switch';

interface CompetitorTableProps {
  isAllCompetitors: boolean;
  onToggle: () => void;
  rowData: PropCompetitorsType[];
  columnDefs: ColDef[];
  pinnedBottomData: Record<string, any>[];
}

export const CompetitorTable = ({
  isAllCompetitors,
  onToggle,
  rowData,
  columnDefs,
  pinnedBottomData,
}: CompetitorTableProps) => {
  return (
    <div className='competitor-table'>
      <div className='header'>
        <SeasonSelect />
        <ToggleSwitch
          id='competitor-type'
          checked={isAllCompetitors}
          options={competitorTypes}
          onToggle={onToggle}
        />
      </div>
      <div className='ag-theme-alpine table'>
        <AgGridReact
          components={tableComponents}
          rowData={rowData}
          columnDefs={columnDefs}
          getRowStyle={getRowStyle}
          gridOptions={gridOptions}
          pinnedBottomRowData={isAllCompetitors ? undefined : pinnedBottomData}
        />
      </div>
    </div>
  );
};
