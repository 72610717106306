import { MenuItem, TextField } from '@mui/material';

import { LoadingBar } from 'components/loading-bar/loading-bar';

export type menuType = {
  label: string;
  value: string | number;
  icon?: string | JSX.Element;
  to?: string;
};
type DropdownProps = {
  options: Array<menuType>;
  preSelectValue: string | number;
  handleChange: Function;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
};

export const DropdownMenu = ({
  options,
  preSelectValue,
  handleChange,
  className,
  loading = false,
  disabled = false,
}: DropdownProps) => {
  return (
    <div className='dropdownMenu__container'>
      <LoadingBar loading={loading} overlayStyle={{ borderRadius: '10px' }}>
        <TextField
          id='outlined-select'
          select
          disabled={disabled}
          className={className ? className : ''}
          value={options.length ? preSelectValue : ''}
          onChange={(e) => handleChange(e)}
          variant='outlined'
          SelectProps={{
            MenuProps: { PaperProps: { sx: { maxHeight: '20rem' } } },
          }}
        >
          {options.map((option) => (
            <MenuItem
              className={`dropdownMenu__list-item ${className}-list-item`}
              key={option.value}
              value={option.value}
              about={option.label}
            >
              <span className='label'>{option.label}</span>
              {option.icon && <span className='icon'>{option.icon}</span>}
            </MenuItem>
          ))}
        </TextField>
      </LoadingBar>
    </div>
  );
};
