import { METRICS_DURATION } from '../helpers';
import dayjs from 'dayjs';
import { labels } from 'locales/en.label';
import { uniqBy } from 'lodash';
import { useMemo } from 'react';
import { usePropMetrics } from './use-prop-metrics';

export type PriorityTotals = {
  priority: number;
  label: string;
  count: number;
}[];
export type RevAlert = {
  type: 'warning' | 'insight';
  nights: string[];
  message: string;
};
export type RevAlerts = { [type: string]: RevAlert };
export type RevChartData = { current: number[]; recommended: number[] };

type UsePriceRecsResponse = {
  totals: PriorityTotals;
  alerts: RevAlerts;
  revChartData: RevChartData | null;
  loading: boolean;
};

const recLevelLabels = labels.common.rec_levels;

export const usePriceRecs = (): UsePriceRecsResponse => {
  const { data, loading } = usePropMetrics(METRICS_DURATION);

  const priceRecs = useMemo(() => {
    const priorityTotals = [0, 0, 0];
    const alerts: RevAlerts = {};
    const revChartData = data
      ? { current: [0, 0, 0, 0], recommended: [0, 0, 0, 0] }
      : null;

    // Get array of month indexes for the first 4 months (ex. January = 0, February = 1, etc.)
    const months = uniqBy(data, (night) => dayjs(night.stay_date).month())
      .map((night) => dayjs(night.stay_date).month())
      .slice(0, 4);

    data?.forEach((night) => {
      // Capture priority totals
      if (typeof night.priority === 'number') {
        priorityTotals[night.priority ?? 0] += 1;
      }
      // Create warning alerts
      if (
        (night.current_rate_live ?? night.current_rate) < night.market_rate &&
        night.fcst_occ_pct > 90
      ) {
        alerts.warning = alerts.warning || {
          type: 'warning',
          nights: [],
          message: labels.home.alert_messages.warning,
        };
        alerts.warning.nights.push(night.stay_date);
      }
      // Calculate monthly revenue totals
      const index = months.indexOf(dayjs(night.stay_date).month());
      if (index > 3 || index < 0 || !revChartData) return;
      revChartData.current[index] += night.fcst_rev;
      revChartData.recommended[index] += night.fcst_rev_rec;
    });

    const totals = [
      {
        priority: 2,
        label: recLevelLabels[2],
        count: priorityTotals[2],
      },
      { priority: 1, label: recLevelLabels[1], count: priorityTotals[1] },
      { priority: 0, label: recLevelLabels[0], count: priorityTotals[0] },
    ];

    return {
      totals,
      alerts,
      revChartData,
      loading,
    };
  }, [data, loading]);

  return priceRecs;
};
