import DataTable, { TableProps } from 'react-data-table-component';

import { FaCaretDown } from 'react-icons/fa';

function DataTableBase<T>(props: TableProps<T>): JSX.Element {
  return (
    <DataTable
      dense
      fixedHeader={true}
      highlightOnHover
      pagination
      sortIcon={<FaCaretDown />}
      striped
      {...props}
    />
  );
}

export default DataTableBase;
