import { FormControl, MenuItem, OutlinedInput } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { dateFormatData, formatDate } from 'features/dates/date-helpers';
import { useMemo, useRef, useState } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { competitorChartSeries } from './config';
import dayjs from 'dayjs';
import { selectCurrentSeason } from 'features/rules/redux/rules-slice';
import styles from 'common/_variables.module.scss';
import { useAppSelector } from 'redux/hooks';
import { useCompRatesChartData } from 'features/rules/competitors/hooks/use-prop-competitors';

type CompRate = {
  comp_id: number | null;
  comp_name: string;
  comp_rate: number[];
};

type StyledSelectProps = {
  value: CompRate | null;
  options: CompRate[];
  onChange: (val: string) => void;
};

const chartConfig: Record<string, any> = {
  type: 'spline',
  title: {
    text: 'Price Profile (Last Year 2022)',
    style: {
      color: styles.mainGrey,
      fontFamily: styles.primaryFontFamily,
    },
  },
  accessibility: {
    enabled: false,
  },
  xAxis: [
    {
      categories: [],
      crosshair: true,
      labels: {
        style: {
          color: styles.mainGrey,
          fontFamily: styles.primaryFontFamily,
        },
      },
    },
  ],
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      formatter: function (this: any): string {
        return '$' + this.value;
      },
      style: {
        color: styles.mainGrey,
        fontFamily: styles.primaryFontFamily,
      },
    },
    lineWidth: 2,
  },
  tooltip: {
    shared: true,
  },
  plotOptions: {
    spline: {
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
  },
};

const StyledSelect = ({ value, options, onChange }: StyledSelectProps) => {
  const [val, setVal] = useState(value?.comp_name);
  const handleChange = (event: SelectChangeEvent) => {
    setVal(event.target.value);
    onChange(event.target.value);
  };
  return (
    <FormControl sx={{ minWidth: 120 }} className='select-competitor'>
      <Select
        id='competitors'
        value={val}
        title={val}
        onChange={handleChange}
        displayEmpty
        input={<OutlinedInput />}
        renderValue={(selected: any) => {
          if (!selected) {
            return <span>Select Competitor</span>;
          }
          return <span>{selected}</span>;
        }}
      >
        {options.map((competitor: CompRate) => {
          return (
            <MenuItem key={competitor.comp_name} value={competitor.comp_name}>
              {competitor.comp_name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const CompetitorChart = () => {
  const selectedSeason = useAppSelector(selectCurrentSeason);

  const { chartData, compRatesLoading } = useCompRatesChartData({
    duration:
      dayjs(selectedSeason?.season_end).diff(
        dayjs(selectedSeason?.season_start),
        'day'
      ) + 1,
    selectedDays: selectedSeason?.selected_days,
    startDate: formatDate(
      dayjs(selectedSeason?.season_start).subtract(364, 'days').toString(),
      dateFormatData
    ),
  });

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [selectedCompetitor, setSelectedCompetitor] = useState<CompRate | null>(
    null
  );

  const chartOptions = useMemo(() => {
    const config = { ...chartConfig };

    if (chartData) {
      config.xAxis[0].categories = chartData.stay_dates;
      config.series = [
        {
          type: 'spline',
          name: competitorChartSeries.bw_rates.name,
          data: chartData?.current_rates,
          color: competitorChartSeries.bw_rates.color,
        },
        {
          type: 'spline',
          name: competitorChartSeries.market_rates.name,
          data: chartData?.market_rates,
          color: competitorChartSeries.market_rates.color,
        },
        {
          type: 'spline',
          name: '', // selectedCompetitor?.comp_name || '',
          data: undefined, // selectedCompetitor?.comp_rate,
          color: 'white',
          // selectedCompetitor
          //   ? competitorChartSeries.competitor_rate.color
          //   : 'white',
        },
      ];
    }
    return config;
  }, [chartData]);

  const onCompetitorNameChange = (competitorName: string) => {
    const option =
      chartData?.comp_rate?.find(
        (option) => option.comp_name === competitorName
      ) || null;

    const chart = chartComponentRef?.current?.chart;
    chart?.series.forEach((series: any) => {
      if (series.name === selectedCompetitor?.comp_name) {
        series.remove();
      }
    });
    chart?.addSeries({
      type: 'spline',
      name: option?.comp_name || '',
      data: option?.comp_rate || [],
      color: competitorChartSeries.competitor_rate.color,
    });
    setSelectedCompetitor(option);
  };

  return (
    <LoadingBar loading={compRatesLoading}>
      <div className='chart'>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          containerProps={{ style: { height: '21rem' } }}
        />
        <StyledSelect
          value={selectedCompetitor}
          options={chartData.comp_rate}
          onChange={onCompetitorNameChange}
        />
      </div>
    </LoadingBar>
  );
};
