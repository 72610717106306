/* eslint-disable */
import * as Types from '../../../../../graphql/gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePropSeasonRateMutationVariables = Types.Exact<{
  updatedPropSeasonRates?: Types.InputMaybe<Types.UpdatePropSeasonRatesInput>;
}>;


export type UpdatePropSeasonRateMutation = { __typename?: 'Mutation', updatePropertySeasonRates?: { __typename?: 'UpdatePropSeasonRatesResult', success?: boolean | undefined, message?: string | undefined } | undefined };


export const UpdatePropSeasonRateDocument = gql`
    mutation UpdatePropSeasonRate($updatedPropSeasonRates: UpdatePropSeasonRatesInput) {
  updatePropertySeasonRates(updatedPropSeasonRates: $updatedPropSeasonRates) {
    success
    message
  }
}
    `;
export type UpdatePropSeasonRateMutationFn = Apollo.MutationFunction<UpdatePropSeasonRateMutation, UpdatePropSeasonRateMutationVariables>;

/**
 * __useUpdatePropSeasonRateMutation__
 *
 * To run a mutation, you first call `useUpdatePropSeasonRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropSeasonRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropSeasonRateMutation, { data, loading, error }] = useUpdatePropSeasonRateMutation({
 *   variables: {
 *      updatedPropSeasonRates: // value for 'updatedPropSeasonRates'
 *   },
 * });
 */
export function useUpdatePropSeasonRateMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePropSeasonRateMutation, UpdatePropSeasonRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePropSeasonRateMutation, UpdatePropSeasonRateMutationVariables>(UpdatePropSeasonRateDocument, options);
      }
export type UpdatePropSeasonRateMutationHookResult = ReturnType<typeof useUpdatePropSeasonRateMutation>;
export type UpdatePropSeasonRateMutationResult = Apollo.MutationResult<UpdatePropSeasonRateMutation>;
export type UpdatePropSeasonRateMutationOptions = Apollo.BaseMutationOptions<UpdatePropSeasonRateMutation, UpdatePropSeasonRateMutationVariables>;
export const namedOperations = {
  Mutation: {
    UpdatePropSeasonRate: 'UpdatePropSeasonRate'
  }
}