import { useEffect, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { GroupPriceToolInput } from './group-price-tool';
import { Tooltip } from '@mui/material';
import { formatDate } from 'features/dates/date-helpers';

const defaultColOptions: ColDef = {
  width: 100,
  resizable: true,
  suppressSizeToFit: true,
};

const FieldColumnCell = (props: any) => {
  return (
    <Tooltip title={props.data.description} arrow placement='right'>
      <div>{props.value}</div>
    </Tooltip>
  );
};

type InputData = {
  capacity: number;
  current_otb: number;
  expected_transient_adr: number;
  transient_fcst: number;
  transient_and_group_fcst: number;
  stay_date: string;
};

type TransformedData = {
  field: string;
  [stayDate: string]: number | string;
};

interface NetBenefitTableProps {
  data: InputData[];
  dates: string[];
  formValues: GroupPriceToolInput;
}

export const NetBenefitTable = ({
  data,
  dates,
  formValues,
}: NetBenefitTableProps) => {
  const gridRef: React.RefObject<AgGridReact> = useRef(null);

  useEffect(() => {
    if (gridRef.current) {
      gridRef?.current?.api?.setDomLayout('autoHeight');
    }
  }, []);

  const fieldMapping: { [key: string]: string } = {
    capacity: 'Capacity',
    current_otb: 'On the Books',
    expected_transient_adr: 'Expected Transient ADR',
    transient_fcst: 'Transient Forecast',
    transient_and_group_fcst: 'Transient and Group Forecast',
    new_group_rooms_requested: 'New Group Rooms Requested',
    net_rooms_added: 'Net Rooms Added',
    rooms_displaced: 'Rooms Displaced',
    guests_walked: 'Guests Walked',
    shoulder_rooms_displaced: 'Shoulder Rooms Displaced',
    incremental_room_revenue: 'Incremental Room Revenue',
    cost_of_guests_walked: 'Cost of Guests Walked',
    incremental_direct_variable_opex: 'Incremental Direct Variable OPEX',
    displacement_cost: 'Displacement Cost',
    shoulder_displacement: 'Shoulder Displacement',
    combined_f_and_b_meeting_space_benefit:
      'Combined F&B, Meeting Space Benefit',
    planners_advantage_points_cost: 'Planners Advantage Points Cost',
    net_benefit: 'Net Benefit',
    required_profit: 'Required Profit',
    take_group: 'Take Group?',
  };

  const fieldDescriptions: { [key: string]: string } = {
    capacity:
      'This is the total number of rooms you have in your hotel, less any rooms that are out of order. In other words, it is the total number of rooms you have available to sell, which may vary by date.',
    current_otb:
      'This is the number of rooms that you currently have booked for each night.',
    expected_transient_adr:
      'The expected transient ADR forecast for the selected stay date, based upon historical known data for that same stay date for previous years and recent ADR trends.',
    transient_fcst:
      "This is the total number of non-Group rooms you should expect to sell. The transient forecast is based on how many transient guests you've had in house over the equivalent dates in previous years, as well as how the current number of transient guests on the books compares to the equivalent point in time in previous years",
    transient_and_group_fcst:
      'This is the forecast of how many transient rooms you should expect, with the addition of any group you have already on the books.',
    new_group_rooms_requested:
      'This is the number you enter at the top of the page. I.E. the total number of rooms requested by the new group.',
    net_rooms_added:
      "Whenever you are expected to have enough rooms available to accommodate the new Group, the 'net rooms added' will equal the number of rooms requested. But if taking the group will cause you to displace a transient guest, the net rooms added will equal the number of Group rooms requested - the number of transient rooms displaced.",
    rooms_displaced: 'Number of requested rooms minus net rooms added.',
    guests_walked:
      'When the new group rooms requested exceeds the capacity available today and transient room nights on the books currently exists.',
    shoulder_rooms_displaced:
      'Using the hotels average length of stay to gauge the probability that rooms will be displaced on the night before and night after the group date requested.',
    incremental_room_revenue:
      'Net rooms added minus wash percentage multiplied by the group rate request as entered in the controls above. The model assumes that in the absence of the group, none of the would-be group guests would book your hotel.',
    // cost_of_guests_walked: 'The cost of guests walked.',
    // incremental_direct_variable_opex:
    //   'The incremental direct variable operating expense.',
    displacement_cost:
      'In the event the group is expected to displace transient rooms, this value is calculated as the number of displaced transient guests multiplied by the differential between the group rate and the transient rate.',
    shoulder_displacement:
      'In the event shoulder rooms are displaced, this calculation is the loss of the transient guest revenue that cannot be realized because the group block prevents the booking. If the loss occurs prior to the groups arrival, the calculation is added to the first night of the groups stay. If the loss occurs after the groups arrival, the calculation is added to the last night of the groups stay. It is possible for shoulder displacement to occur both before and after the group is in house.',
    combined_f_and_b_meeting_space_benefit:
      'The value a user input in the control “F&B profit per group room night”. This is the incremental net benefit you would expect to receive, expressed on a per room night basis.',
    planners_advantage_points_cost:
      'The cost of points issued to a meeting planner through the Planners Advantage Program. 1 Point is equal to $0.0055 USD.',
    net_benefit:
      'The sum of incremental revenue, expected meeting space and F&B benefit, minus the cost of displacement (including shoulder displacement), incremental direct variable costs, commission, rebates and comp rooms.',
    // required_profit: 'The required profit.',
    take_group:
      'This field will display as a green check mark if the net benefit exceeds the required profit. If not, the field will display as a red X if the net benefit does not exceed the required profit.',
  };

  const calculatedData = data.map((dataEntry) => {
    const new_group_rooms_requested = formValues.roomsRequested;
    const rooms_displaced = Math.max(
      0,
      new_group_rooms_requested + dataEntry.current_otb - dataEntry.capacity
    );
    const net_rooms_added =
      dataEntry.capacity > new_group_rooms_requested + dataEntry.current_otb
        ? new_group_rooms_requested
        : new_group_rooms_requested - rooms_displaced;

    return {
      ...dataEntry,
      new_group_rooms_requested,
      net_rooms_added,
      rooms_displaced,
    };
  });

  const transformData = (inputData: InputData[]): TransformedData[] => {
    const fields = Object.keys(fieldMapping);

    const transformedData: TransformedData[] = fields.map((field) => {
      const row: TransformedData = { field: fieldMapping[field] };
      row.description = fieldDescriptions[field];

      inputData.forEach((dataEntry) => {
        if (field === 'expected_transient_adr') {
          row[dataEntry.stay_date] = Number(
            dataEntry[field as keyof InputData]
          ).toFixed(2);
          return;
        }
        row[dataEntry.stay_date] = dataEntry[field as keyof InputData];
      });

      return row;
    });

    return transformedData;
  };

  const transformedData = transformData(calculatedData);

  const totalValueFormatter = (params: any) => {
    if (params.data.field === 'Expected Transient ADR') {
      const sum = dates.reduce((acc, date) => {
        const totalForDate = parseFloat(params.data[date]);
        return acc + (typeof totalForDate === 'number' ? totalForDate : 0);
      }, 0);

      return (sum / dates.length).toFixed(2);
    }
    const total = dates.reduce((acc, date) => {
      const totalForDate = params.data[date];
      return acc + (typeof totalForDate === 'number' ? totalForDate : 0);
    }, 0);

    return total;
  };

  const columnDefs: ColDef[] = [
    {
      headerName: 'Detailed Net Benefit Estimate',
      field: 'field',
      pinned: 'left',
      width: 250,
      cellRenderer: FieldColumnCell,
    },
    {
      headerName: 'Total',
      valueGetter: totalValueFormatter,
      flex: 1,
    },
    ...data.map((dataEntry) => ({
      headerName: formatDate(dataEntry.stay_date),
      field: dataEntry.stay_date,
      flex: 1,
    })),
  ];

  return (
    <div className='group-price-tool__grid'>
      <div className='ag-theme-alpine'>
        <AgGridReact
          ref={gridRef}
          rowData={transformedData}
          columnDefs={columnDefs}
          domLayout='autoHeight'
          defaultColDef={defaultColOptions}
          rowHeight={35}
        />
      </div>
    </div>
  );
};
