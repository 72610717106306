import styles from 'common/_variables.module.scss';

export const otbChartConfig: Record<string, any> = {
  chart: {
    type: 'pie',
    height: 175,
    spacingTop: 0,
    spacingBottom: 0,
    backgroundColor: 'transparent',
  },
  title: {
    text: undefined,
  },
  accessibility: {
    enabled: false,
  },
  credits: { enabled: false },
  colors: [
    styles.graphGreen,
    styles.graphYellow,
    styles.graphOrange,
    styles.graphRed,
    styles.graphLighterBlue,
    styles.graphLightBlue,
    styles.graphPurple,
    styles.graphDarkPurple,
  ],
  plotOptions: {
    pie: {
      showInLegend: true,
    },
  },
  legend: {
    align: 'right',
    verticalAlign: 'middle',
    layout: 'vertical',
    x: -13,
    itemMarginBottom: 5,
    symbolRadius: 0,
    symbolHeight: 8,
    useHTML: true,
    itemStyle: {
      fontWeight: '400',
      lineHeight: '18px',
      color: styles.colorGrey70,
    },
  },
  tooltip: {
    headerFormat: null,
    pointFormat: 'Rate Level {point.name}: <b>{point.y}</b>',
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  series: [
    {
      size: 150,
      innerSize: '75%',
      dataLabels: {
        enabled: false,
      },
    },
  ],
};
