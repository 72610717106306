import * as Yup from 'yup';

import { Form, Formik } from 'formik';
import {
  seasonChanged,
  selectCurrentSeason,
} from 'features/rules/redux/rules-slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { AutopilotForm } from './autopilot-form';
import { AutopilotInfo } from './autopilot-info';
import { AutopilotOption } from 'graphql/gql-types';
import { FormButtons } from '../form-buttons/form-buttons';
import RouterPromptModal from 'components/RouterPromptModal/RouterPromptModal';
import { RuleTabs } from '../rule-tabs/rule-tabs';
import { TwoPaneLayout } from 'components/two-pane-layout/two-pane-layout';
import { alertAdded } from 'features/alerts/redux/alerts-slice';
import { labels } from 'locales/en.label';
import { useApolloError } from 'hooks/useApolloError';
import { useHistory } from 'react-router-dom';
import { usePropertyContext } from 'context/propertyContext';
import { useState } from 'react';
import { useUpdateSeasonMutation } from 'features/rules/seasons/gql/_gen_/prop-seasons.gql';
import { useUser } from 'features/users/context/userContext';

export interface AutopilotFormValues {
  priorityThresholdHigh: string;
  priorityThresholdMed: string;
  priorityThresholdLow: string;
  autopilotHigh: AutopilotOption;
  autopilotMed: AutopilotOption;
  autopilotLow: AutopilotOption;
}

const autopilotLabels = labels.rules.autopilot_tab;

const autopilotSchema = Yup.object().shape({
  priorityThresholdHigh: Yup.number().moreThan(
    Yup.ref('priorityThresholdMed'),
    autopilotLabels.threshold_high_err
  ),
});

export const Autopilot = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { user } = useUser();
  const {
    property: { propertyId },
  } = usePropertyContext();
  const { handleApolloError } = useApolloError();
  const selectedSeason = useAppSelector(selectCurrentSeason);

  const [updateSeason, { loading }] = useUpdateSeasonMutation({
    variables: {
      updatedBy: user?.login_id,
    },
    refetchQueries: ['GetPropSeasons'],
    onError: (error) => handleApolloError(error),
  });

  const [continueClicked, setContinueClicked] = useState(false);

  const initialValues: AutopilotFormValues = {
    priorityThresholdHigh: `${selectedSeason?.priority_threshold_high ?? 0}`,
    priorityThresholdMed: `${selectedSeason?.priority_threshold_med ?? 0}`,
    priorityThresholdLow: `${selectedSeason?.priority_threshold_low ?? 0}`,
    autopilotHigh: selectedSeason?.autopilot_high ?? AutopilotOption.N,
    autopilotMed: selectedSeason?.autopilot_med ?? AutopilotOption.N,
    autopilotLow: selectedSeason?.autopilot_low ?? AutopilotOption.N,
  };

  const handleSubmit = async (values: AutopilotFormValues) => {
    const {
      priorityThresholdHigh,
      priorityThresholdMed,
      priorityThresholdLow,
      ...otherValues
    } = values;

    let success = false;

    await updateSeason({
      variables: {
        updatedSeason: {
          propertyId,
          seasonId: selectedSeason?.season_id,
          seasonStartDate: selectedSeason?.season_start!,
          seasonEndDate: selectedSeason?.season_end!,
          selectedDays: selectedSeason?.selected_days!,
          priorityThresholdHigh: parseFloat(priorityThresholdHigh),
          priorityThresholdMed: parseFloat(priorityThresholdMed),
          priorityThresholdLow: parseFloat(priorityThresholdLow),
          derivedRateOffset: selectedSeason?.derived_rate_offset,
          derivedRateType: selectedSeason?.derived_rate_type,
          pricingStrategy: selectedSeason?.pricing_strategy,
          ...otherValues,
        },
      },
      onCompleted: () => {
        success = true;
        dispatch(alertAdded('success', autopilotLabels.save_success_msg));
        dispatch(
          seasonChanged({
            ...selectedSeason!,
            priority_threshold_high: parseFloat(priorityThresholdHigh),
            priority_threshold_med: parseFloat(priorityThresholdMed),
            priority_threshold_low: parseFloat(priorityThresholdLow),
            autopilot_high: values.autopilotHigh,
            autopilot_med: values.autopilotMed,
            autopilot_low: values.autopilotLow,
          })
        );
        if (continueClicked) history.push('/rules/seasons');
      },
      onError: (error) => {
        dispatch(alertAdded('error', error.message));
        setContinueClicked(false);
      },
    });

    return success;
  };

  return (
    <div>
      <RuleTabs loading={loading} />
      <Formik<AutopilotFormValues>
        initialValues={initialValues}
        validationSchema={autopilotSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ dirty, validateForm, submitForm }) => (
          <>
            <RouterPromptModal isBlocked={dirty && !continueClicked} />
            <Form>
              <TwoPaneLayout
                loading={loading}
                LeftSideComponent={<AutopilotForm />}
                RightSideComponent={<AutopilotInfo />}
              />
              <FormButtons
                loading={loading}
                dirty={dirty}
                onContinueClick={async () => {
                  if (!dirty) {
                    history.push('/rules/seasons');
                    return;
                  }
                  const isValid =
                    Object.keys(await validateForm()).length === 0;
                  if (isValid) setContinueClicked(true);
                  submitForm();
                }}
              />
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};
