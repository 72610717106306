import { RootState } from 'redux/store';
import { createSelector } from 'reselect';

const selectRateUpload = (state: RootState) => state.rateUpload;

export const selectPendingRecs = createSelector(
  selectRateUpload,
  (rateUpload) => rateUpload.pendingRecs
);
export const selectPolling = createSelector(
  selectRateUpload,
  (rateUpload) => rateUpload.polling
);
export const selectUploading = createSelector(
  selectRateUpload,
  (rateUpload) => rateUpload.uploading
);

export const selectAllRowsSelected = createSelector(
  selectRateUpload,
  (rateUpload) => rateUpload.allRowsSelected
);
