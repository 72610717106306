import { gql } from '@apollo/client';

export const GET_PROPERTY_STAY_VIEW_CHART = gql`
  query GetPropertyStayViewChart(
    $selectedDays: [String]
    $duration: Int
    $propertyId: String
  ) {
    getPropertyStayView(
      selectedDays: $selectedDays
      duration: $duration
      propertyId: $propertyId
    ) {
      stay_date
      available_cap
      available_rooms
      current_otb
      current_rate
      group_blocked
      group_booked
      market_rate
      out_of_order
      overbooking
      rec_rate
      total_capacity
      total_fcst
      transient_cap
      yoy_rate_lyst
    }
  }
`;
