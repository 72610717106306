import {
  useAcceptAllPropertyRecsMutation,
  useResetPropertyRecsMutation,
  useUpdateRecMutation,
} from '../gql/_gen_/property-rec-rates.gql';

export const useAcceptAllRecRates = () => {
  return useAcceptAllPropertyRecsMutation();
};

export const useResetRecRates = () => {
  return useResetPropertyRecsMutation();
};

export const useUpdateRecRate = () => {
  return useUpdateRecMutation();
};
