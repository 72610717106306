import { PayloadAction, createSlice, nanoid } from '@reduxjs/toolkit';

import { AlertColor } from '@mui/material';
import { ApolloError } from '@apollo/client';

export interface AlertObj {
  id: string;
  severity: AlertColor;
  message: string;
  linkTo?: string;
}

type AlertsState = AlertObj[];

const initialState: AlertsState = [];

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    alertAdded: {
      reducer(state, action: PayloadAction<AlertObj>) {
        state.push(action.payload);
      },
      prepare(severity: AlertColor, message: string, linkTo?: string) {
        return {
          payload: {
            id: nanoid(),
            severity,
            message,
            linkTo,
          },
        };
      },
    },
    apolloErrorAdded: {
      reducer(state, action: PayloadAction<AlertObj[]> | null) {
        if (action) state.push(...action.payload);
      },
      prepare(error: ApolloError) {
        return {
          payload: error.graphQLErrors.map(({ path, message }) => {
            console.error(`${path}: ${message}`);
            const queryName = path?.[0];

            if (!queryName) return null;
            return {
              id: nanoid(),
              severity: 'error' as AlertColor,
              message: `Error calling "${path}"`,
            };
          }),
        };
      },
    },
    alertClosed(state, action: PayloadAction<string>) {
      return state.filter((alert) => alert.id !== action.payload);
    },
  },
});

export const { alertAdded, apolloErrorAdded, alertClosed } =
  alertsSlice.actions;

export default alertsSlice.reducer;
