import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { PropSeason } from 'graphql/gql-types';
import { RootState } from 'redux/store';

interface RulesState {
  currentSeason: PropSeason | null;
  unsavedData: boolean;
}

const initialState: RulesState = {
  currentSeason: null,
  unsavedData: false,
};

export const rulesSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    seasonChanged(state, action: PayloadAction<PropSeason | null>) {
      state.currentSeason = action.payload;
    },
    unsavedDataChanged(state, action: PayloadAction<boolean>) {
      state.unsavedData = action.payload;
    },
  },
});

export const selectCurrentSeason = createSelector(
  (state: RootState) => state.rules,
  (rules) => rules.currentSeason
);

export const { seasonChanged, unsavedDataChanged } = rulesSlice.actions;

export default rulesSlice.reducer;
