import { RootState } from 'redux/store';
import { createSelector } from 'reselect';

const selectHeader = (state: RootState) => state.header;

export const selectDefaultSearchId = createSelector(
  selectHeader,
  (header) => header.defaultSearchId
);

export const selectSelloutCount = createSelector(
  selectHeader,
  (header) => header.selloutcount
);

export const selectShowAskRev = createSelector(
  selectHeader,
  (header) => header.showAskRev
);

export const selectEnableSellOutFlag = createSelector(
  selectHeader,
  (header) => header.enableSellOutFlag
);

export const selectShowRateChangeLogs = createSelector(
  selectHeader,
  (header) => header.showRateChangeLogs
);

export const selectShowRateOffsetLogs = createSelector(
  selectHeader,
  (header) => header.showRateOffsetLogs
);

