import { useEffect, useState } from 'react';

import { CompetitorInfo } from './competitor-info';
import { CompetitorTable } from './competitor-table';
import { FormButtons } from '../form-buttons/form-buttons';
import RouterPromptModal from 'components/RouterPromptModal/RouterPromptModal';
import { RuleTabs } from '../rule-tabs/rule-tabs';
import { TwoPaneLayout } from 'components/two-pane-layout/two-pane-layout';
import { unsavedDataChanged } from 'features/rules/redux/rules-slice';
import { useAppDispatch } from 'redux/hooks';
import { useCompetitorsController } from './use-competitors-controller';
import { useHistory } from 'react-router-dom';

export const Competitors = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isDataModified, loading, saveCompetitorSet, ...tableProps } =
    useCompetitorsController();
  const [continueClicked, setContinueClicked] = useState(false);

  useEffect(() => {
    dispatch(unsavedDataChanged(isDataModified));
  }, [dispatch, isDataModified]);

  return (
    <div>
      <RouterPromptModal isBlocked={isDataModified && !continueClicked} />
      <RuleTabs loading={loading} />
      <TwoPaneLayout
        loading={loading}
        LeftSideComponent={<CompetitorTable {...tableProps} />}
        RightSideComponent={<CompetitorInfo />}
      />
      <FormButtons
        loading={loading}
        dirty={isDataModified}
        onSaveClick={saveCompetitorSet}
        onContinueClick={async () => {
          setContinueClicked(true);
          const success = await saveCompetitorSet();
          if (success) {
            history.push('/rules/rates');
          } else {
            setContinueClicked(false);
          }
        }}
      />
    </div>
  );
};
