import { Link, useLocation } from 'react-router-dom';

import { IconType } from 'react-icons/lib';
import { selectRecReviewTab } from 'features/my-view/redux/selectors';
import { useAppSelector } from 'redux/hooks';

type NavButtonProps = {
  icon: IconType;
  to: string;
  title: string;
  color: string;
  enabled?: boolean;
};

export const NavButton = ({
  to = '',
  icon: Icon,
  title = '',
  color = '',
  enabled = true,
}: NavButtonProps) => {
  const { pathname } = useLocation();
  const currentPage = pathname.split('/')[1];
  const thisPage = to.split('/')[1];

  const defaultRecReviewTab = useAppSelector(selectRecReviewTab);

  return (
    <li className={enabled ? 'navButton' : 'disabledNavButton'}>
      <div>
        {enabled ? (
          <Link
            to={to === '/my-view/' ? `${to}${defaultRecReviewTab}` : to}
            className={`navButton__link ${
              currentPage === thisPage ? 'navButton__active-link' : ''
            }`}
          >
            <div className='navButton__icon'>
              <Icon size='20' color={color} className='navButton__icon' />
            </div>
            <div className='navButton__text'>{title}</div>
          </Link>
        ) : (
          <div>
            <div className='navButton__icon'>
              <Icon size='20' color={color} className='navButton__icon' />
            </div>
            <div className='navButton__text'>{title}</div>
          </div>
        )}
      </div>
    </li>
  );
};
