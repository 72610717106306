import {
  GetPropSeasonsDocument,
  useCreatePropSeasonMutation,
  useDeleteSeasonsMutation,
  useUpdateSeasonMutation,
} from './gql/_gen_/prop-seasons.gql';
import {
  dataDate,
  dateFormatData,
  formatFormDate,
} from 'features/dates/date-helpers';

import { PropSeason } from 'graphql/gql-types';
import { SeasonFormValues } from 'pages/rules/components/seasons/seasons';
import { alertAdded } from 'features/alerts/redux/alerts-slice';
import dayjs from 'dayjs';
import { labels } from 'locales/en.label';
import { useApolloError } from 'hooks/useApolloError';
import { useAppDispatch } from 'redux/hooks';
import { useEffect } from 'react';
import usePrevious from 'hooks/use-previous';
import { usePropertyContext } from 'context/propertyContext';
import { useSeasons } from '../rules-hooks';
import { useUser } from 'features/users/context/userContext';

export const useOverlappedSeasons = () => {
  const { seasons } = useSeasons();

  const getOverlappedSeasons = (values: SeasonFormValues) => {
    const startDate = formatFormDate(values.startDate, dateFormatData);

    const endDate = formatFormDate(values.endDate, dateFormatData);

    const overlappedSeasons = seasons?.filter((season) => {
      const seasonStartDate = dataDate(season.season_start);
      const seasonEndDate = dataDate(season.season_end);

      const startIsBetween =
        dayjs(startDate).isSameOrAfter(seasonStartDate) &&
        dayjs(startDate).isSameOrBefore(seasonEndDate);
      const endIsBetween =
        dayjs(endDate).isSameOrAfter(seasonStartDate) &&
        dayjs(endDate).isSameOrBefore(seasonEndDate);
      const startBeforeEndAfter =
        dayjs(startDate).isSameOrBefore(seasonStartDate) &&
        dayjs(endDate).isSameOrAfter(seasonEndDate);

      return (
        season.season_id !== values.id &&
        season.season_priority === Number(values.priority) &&
        (startIsBetween || endIsBetween || startBeforeEndAfter)
      );
    }) as PropSeason[];

    return overlappedSeasons.length ? overlappedSeasons : undefined;
  };

  return getOverlappedSeasons;
};

export const useDuplicateSeasons = () => {
  const { seasons } = useSeasons();

  const getDuplicateSeasons = (values: SeasonFormValues) => {
    const startDate = formatFormDate(values.startDate, dateFormatData);

    const duplicateSeasons = seasons?.filter((season) => {
      const seasonEndDate = dataDate(season.season_end);
      return (
        startDate < seasonEndDate && season.season_name === values.seasonName
      );
    });
    return duplicateSeasons?.length ? duplicateSeasons : undefined;
  };

  return getDuplicateSeasons;
};

export const useCreateSeason = () => {
  const dispatch = useAppDispatch();
  const { handleApolloError } = useApolloError();
  const {
    property: { propertyId },
  } = usePropertyContext();
  const { user } = useUser();

  const [createNewSeason, { loading: createSeasonLoading }] =
    useCreatePropSeasonMutation();

  const createSeason = async (
    values: SeasonFormValues
  ): Promise<PropSeason | undefined> => {
    if (!values.startDate || !values.endDate)
      throw new Error('Start and end dates must be defined');

    const { data } = await createNewSeason({
      variables: {
        newPropSeason: {
          property_id: propertyId,
          season_name: values.seasonName,
          season_start: values.startDate,
          season_end: values.endDate,
          selected_days: values.selectedDays,
          season_priority: values.priority,
          highlight_myview: values.highlightInMyView,
        },
        updatedBy: user?.login_id,
      },
      update: (cache, { data }) => {
        const seasonsCache = cache.readQuery<{ getPropSeasons: PropSeason[] }>({
          query: GetPropSeasonsDocument,
          variables: { propertyId },
        });
        cache.writeQuery({
          query: GetPropSeasonsDocument,
          variables: { propertyId },
          data: {
            getPropSeasons: [
              ...(seasonsCache?.getPropSeasons ?? []),
              data?.createPropSeason,
            ],
          },
        });
      },
      onCompleted: ({ createPropSeason }) => {
        const message = labels.rules.create_confirmation_msg;
        dispatch(
          alertAdded('success', `${createPropSeason?.season_name} ${message}`)
        );
      },
      onError: (error) => {
        if (error.message === labels.alerts.insufficientRole) {
          handleApolloError(error);
        } else {
          dispatch(alertAdded('error', labels.rules.create_error_msg));
        }
      },
    });

    return data?.createPropSeason;
  };

  return { createSeason, createSeasonLoading };
};

export const useUpdateSeason = () => {
  const dispatch = useAppDispatch();
  const { handleApolloError } = useApolloError();
  const {
    property: { propertyId },
  } = usePropertyContext();
  const { user } = useUser();

  const [updateExistingSeason, { loading: updateSeasonLoading }] =
    useUpdateSeasonMutation({
      refetchQueries: ['GetPropSeasons'],
    });

  const updateSeason = async (values: SeasonFormValues) => {
    if (!values.startDate || !values.endDate)
      throw new Error('Start and end dates must be defined');

    const { data } = await updateExistingSeason({
      variables: {
        updatedSeason: {
          propertyId,
          seasonId: values.id!,
          seasonStartDate: values.startDate,
          seasonEndDate: values.endDate,
          selectedDays: values.selectedDays,
          seasonPriority: values.priority,
          highlightInMyView: values.highlightInMyView,
        },
        updatedBy: user?.login_id,
      },
      onCompleted: () => {
        dispatch(
          alertAdded(
            'success',
            `${values.seasonName} ${labels.rules.modify_confirmation_msg}`
          )
        );
      },
      onError: (error) => {
        if (error.message === labels.alerts.insufficientRole) {
          handleApolloError(error);
        } else {
          dispatch(alertAdded('error', labels.rules.modify_error_msg));
        }
      },
    });

    return data?.updateSeason;
  };

  return { updateSeason, updateSeasonLoading };
};

export const useDeleteSeasons = () => {
  const {
    property: { propertyId },
  } = usePropertyContext();
  const [deleteSeasonsMutation, { loading: deleteSeasonsLoading }] =
    useDeleteSeasonsMutation();
  const { handleApolloError } = useApolloError();

  const deleteSeasons = async (
    checkedSeasons: PropSeason[],
    onCompleted?: () => void
  ) => {
    const selectedIds = checkedSeasons.map((s) => s.season_id);
    await deleteSeasonsMutation({
      variables: {
        selectedIds,
        propertyId,
      },
      update: (cache) => {
        selectedIds.forEach((id) => {
          const normalizedId = cache.identify({
            season_id: id,
            __typename: 'PropSeason',
          });
          cache.evict({ id: normalizedId });
        });
        cache.gc();
      },
      onCompleted,
      onError: (error) => {
        handleApolloError(error);
      },
    });
  };

  return { deleteSeasons, deleteSeasonsLoading };
};

export const useOnPropertyChange = (callback: () => void) => {
  const {
    property: { propertyId },
  } = usePropertyContext();
  const previousId = usePrevious(propertyId);

  useEffect(() => {
    if (previousId !== propertyId) {
      callback();
    }
  }, [callback, previousId, propertyId]);
};
