export const windowDataExtractor = (window: Window) => {
  const { navigator, location, screen } = window;
  const { languages, maxTouchPoints, userAgent } = navigator;
  const {
    hash,
    host,
    hostname,
    href,
    origin,
    pathname,
    port,
    search,
    protocol,
  } = location;
  const {
    availHeight,
    availWidth,
    colorDepth,
    height,
    orientation: { angle: orientationAngle, type: orientationType },
    pixelDepth,
    width,
  } = screen;

  const plugins =
    navigator?.plugins &&
    Object.entries(navigator?.plugins).map(([key, value]) => ({
      name: value.name,
      description: value.description,
    }));

  return {
    userAgent,
    plugins: plugins,
    performance: window?.performance,
    screen: {
      height,
      width,
      colorDepth,
      orientation: {
        angle: orientationAngle,
        type: orientationType,
      },
      pixelDepth,
      availHeight,
      availWidth,
    },
    userAgentData: {
      // @ts-expect-error userAgentData is available
      os: navigator?.userAgentData?.platform,
      // @ts-expect-error userAgentData is available
      mobile: navigator?.userAgentData?.mobile,
    },
    languages: languages,
    hardwareConcurrency: window?.navigator?.hardwareConcurrency,
    // @ts-expect-error deviceMemory does exist
    deviceMemory: navigator?.deviceMemory || 'NA',
    maxTouchPoints: maxTouchPoints,
    location: {
      hash,
      host,
      hostname,
      href,
      origin,
      pathname,
      port,
      protocol,
      search,
    },
  };
};
