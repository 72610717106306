import './form-buttons.scss';

import { useHistory, useParams } from 'react-router-dom';

import { labels } from 'locales/en.label';
import { useUser } from 'features/users/context/userContext';

interface FormButtonsProps {
  loading?: boolean;
  dirty: boolean;
  onSaveClick?: () => void;
  onContinueClick: () => void;
}

const tabs = ['seasons', 'competitors', 'rates', 'autopilot'];

export const FormButtons = ({
  loading = false,
  dirty,
  onSaveClick,
  onContinueClick,
}: FormButtonsProps) => {
  const { isReadOnly } = useUser();
  const history = useHistory();
  const { selectedTab } = useParams<{ selectedTab: string }>();

  const goBack = () => {
    const index = tabs.findIndex((tab) => tab === selectedTab) - 1;
    history.push(`/rules/${tabs[index]}`);
  };

  return (
    <div className='form-buttons'>
      <div className='left'>
        {selectedTab !== 'seasons' && (
          <button
            type='button'
            disabled={loading}
            className='back'
            onClick={goBack}
          >
            {labels.rules.back}
          </button>
        )}
        <button
          type='submit'
          disabled={loading || !dirty || isReadOnly}
          onClick={onSaveClick}
        >
          {labels.common.save}
        </button>
      </div>
      <div className='right'>
        <button
          type='button'
          disabled={loading}
          className='continue'
          onClick={onContinueClick}
        >
          {selectedTab === 'autopilot'
            ? labels.rules.finish
            : labels.rules.continue}
        </button>
      </div>
    </div>
  );
};
