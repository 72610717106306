import './rates-chart.scss';

import dayjs, { Dayjs } from 'dayjs';
import { useRef, useState } from 'react';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import TextField from '@mui/material/TextField';
import { chartOptions } from './config';

export const RatesChart = () => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  function handleDateChange(value: Dayjs | null) {
    setSelectedDate(value);
  }
  return (
    <>
      <div className='rates__chart-header-text'>
        <span>Price profile for </span>
        <DatePicker
          value={selectedDate}
          onChange={handleDateChange}
          renderInput={(params) => <TextField margin='normal' {...params} />}
        />
      </div>

      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{ style: { height: '335px', width: '100%' } }}
        options={chartOptions}
        ref={chartComponentRef}
      />
    </>
  );
};
