import './forecast-info-tooltip.scss';

import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';
import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

import { IconType } from 'react-icons/lib';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { Maybe } from 'types/maybe-type';
import dayjs from 'dayjs';
import { labels as globalLabels } from 'locales/en.label';
import styles from 'common/_variables.module.scss';
import { usePropertyContext } from 'context/propertyContext';

const labels = globalLabels.rec_review.dashboard.forecast_info;

interface RecRateExplainer {
  newRecRate: Maybe<number>;
  prevRecRate: Maybe<number>;
  changeInRecRate: Maybe<number>;
  changeInMarketRate: Maybe<number>;
  reasons: Maybe<string>;
  lastHourPickup: Maybe<number>;
  expectedPickup: Maybe<number>;
  actualPickup: Maybe<number>;
  expectedAllDayPickup: Maybe<number>;
  expectedAllDayPickupUnconstrained: Maybe<number>;
  snapshotHour: number;
}

interface ForecastInfoTooltipProps {
  data: Maybe<RecRateExplainer>;
  loading?: boolean;
  children: React.ReactElement<any, any> & React.ReactNode;
}

type ReasonCodes = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      color: styles.whiteColor,
      border: `1px solid #ccc`,
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: styles.whiteColor,
    border: `1px solid #ccc`,
    padding: 0,
    maxWidth: 'none',
  },
}));

export const ForecastInfoTooltip = ({
  data,
  loading = false,
  children,
}: ForecastInfoTooltipProps) => {
  const { currencySymbol } = usePropertyContext();
  const changeInRecRate = data?.changeInRecRate ?? 0;
  let changeType: '' | 'inc' | 'dec' = '';
  let Icon: IconType | undefined;

  if (changeInRecRate > 0) {
    changeType = 'inc';
    Icon = FaLongArrowAltUp;
  } else if (changeInRecRate < 0) {
    changeType = 'dec';
    Icon = FaLongArrowAltDown;
  }

  const reasons = (data?.reasons?.split(',') ?? []) as ReasonCodes[];

  const updatedAt = dayjs()
    .startOf('day')
    .add(data?.snapshotHour ?? 0, 'hours')
    .format('M/D hh:mma');

  return (
    <StyledTooltip
      title={
        <LoadingBar className='forecast-info-tooltip' loading={loading}>
          <div className='content'>
            <h1 className={`header ${changeType}`}>
              {labels.header(changeType, changeInRecRate)}
            </h1>
            <div className='body'>
              <div className='section left'>
                <div className='row new-rec-rate bottom-margin'>
                  <div>
                    <span>
                      <strong>
                        {labels.new_rec_rate}: {currencySymbol}
                        {data?.newRecRate}
                      </strong>
                    </span>
                    <span>
                      ({labels.as_of} {updatedAt})
                    </span>
                  </div>
                  {Icon && <Icon className={`arrow ${changeType}`} />}
                </div>

                <div className='bottom-margin'>
                  <div className='row'>
                    <span>{labels.prev_rec_rate}</span>
                    <span>
                      {currencySymbol}
                      {data?.prevRecRate}
                    </span>
                  </div>
                  <div className='row'>
                    <span>{labels.market_rate_change}</span>
                    {data?.changeInMarketRate && (
                      <span>
                        {data.changeInMarketRate >= 0 ? '+' : '-'}{' '}
                        {currencySymbol}
                        {Math.abs(data.changeInMarketRate)}
                      </span>
                    )}
                  </div>
                </div>

                <div className='row'>
                  <span>
                    <strong>{labels.reasons}:</strong>
                  </span>
                  <div className='reasons'>
                    {reasons?.map((code) => (
                      <span key={code}>{labels.reason_codes[code]}</span>
                    ))}
                  </div>
                </div>
              </div>

              <div className='section right'>
                <div className='row'>
                  <span>{labels.last_hour_pickup}</span>
                  <span>{data?.lastHourPickup}</span>
                </div>

                <div>
                  <span>
                    <strong>{labels.pickup_since_12}</strong>
                  </span>
                  <div className='row'>
                    <span>{labels.expected_pickup}</span>
                    <span>{data?.expectedPickup}</span>
                  </div>
                  <div className='row'>
                    <span>{labels.actual_pickup}</span>
                    <span>{data?.actualPickup}</span>
                  </div>
                  <div className='row'>
                    <span>{labels.expected_all_day_pickup}</span>
                    <span>{data?.expectedAllDayPickup}</span>
                  </div>
                  <div className='row'>
                    <div>
                      <span>{labels.expected_all_day_pickup}</span>
                      <span>({labels.unconstrained})</span>
                    </div>
                    <span>{data?.expectedAllDayPickupUnconstrained}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LoadingBar>
      }
    >
      {children}
    </StyledTooltip>
  );
};

export default ForecastInfoTooltip;
