import styles from 'common/_variables.module.scss';

export const ratesChartSeries = {
  neutral: {
    name: 'Neutral',
    color: styles.graphDarkBlue,
  },
  conservative: {
    name: 'Conservative',
    color: styles.graphLightBlue,
  },
  aggressive: {
    name: 'Aggressive',
    color: styles.graphYellow,
  },
};

export const chartOptions = {
  type: 'spline',
  title: {
    text: '',
    style: {
      color: styles.mainGrey,
      fontFamily: styles.primaryFontFamily,
    },
  },
  xAxis: [
    {
      title: {
        text: 'Days left for arrival',
      },
      categories: ['120', '75', '21', '14', '7', '5', '3', '0'],
      crosshair: true,
      labels: {
        style: {
          color: styles.mainGrey,
          fontFamily: styles.primaryFontFamily,
        },
      },
    },
  ],
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      formatter: function (this: any): string {
        return '$' + this.value;
      },
      style: {
        color: styles.mainGrey,
        fontFamily: styles.primaryFontFamily,
      },
    },
    lineWidth: 2,
  },
  tooltip: {
    shared: true,
    useHTML: true,
    borderRadius: 15,
    borderColor: styles.mainGrey,
    borderWidth: 1,
    backgroundColor: styles.whiteColor,
    formatter: function (this: Highcharts.TooltipFormatterContextObject) {
      var points = this.points;
      let result = `<table class="tooltip__container">`;
      points?.forEach((p) => {
        const isDollarSymbol = p.series.name.includes('Rate');
        result += `<tr><td class='tooltip__container-row'><div class='tooltip__container-circle'style="background-color:${
          p.series.color
        }"></div><div class='tooltip__container-text'><span class='tooltip-text-label'>${
          p.series.name
        }:</span> <span class='tooltip-text-value'>${
          isDollarSymbol ? '$' : ''
        }${p.y}</span><div></td></tr>`;
      });
      result += '</table>';
      return result;
    },
  },
  plotOptions: {
    spline: {
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
  },
  series: [
    {
      type: 'spline',
      name: ratesChartSeries.neutral.name,
      data: [112, 114, 100, 130, 104, 102],
      color: ratesChartSeries.neutral.color,
    },
    {
      type: 'spline',
      name: ratesChartSeries.conservative.name,
      data: [100, 119, 108, 116, 120, 130],
      color: ratesChartSeries.conservative.color,
    },
    {
      type: 'spline',
      name: ratesChartSeries.aggressive.name,
      data: [101, 111, 108, 116, 105, 109],
      color: ratesChartSeries.aggressive.color,
    },
  ],
};
