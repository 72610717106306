import { PropSeason } from 'graphql/gql-types';
import { SelectedDays } from './SearchTypes';

export type PropertyType = {
  propertyId: string;
  propertyName: string;
  rule_id?: number;
  currencyCode: string;
};
export interface MetricsColumns {
  property_id: string;
  stay_date: string;
  dow: string;
  sellout_ind: boolean;
  is_favorite: boolean;
  event_id: string;
  current_rate: number;
  rec_rate: number;
  overriden_rate: number;
  rec_status: string;
  market_rate: number;
  available_rooms: number;
  transient_cap: number;
  total_fcst: number;
  current_otb: number;
  adr: number;
  revpar: number;
  occ_pct: number;
  fcst_occ_pct: number;
}

export interface ForecastColumns {
  property_id: string;
  stay_date: string;
  dow: string;
  sellout_ind: boolean;
  is_favorite: boolean;
  event_id: string;
  current_rate: number;
  rec_rate: number;
  overriden_rate: number;
  rec_status: string;
  market_rate: number;
  unc_dmd_curr_rate: number;
  con_dmd_curr_rate: number;
  con_dmd_rec_rate: number;
}

export interface PickupColumns {
  property_id: string;
  stay_date: string;
  dow: string;
  sellout_ind: boolean;
  is_favorite: boolean;
  event_id: string;
  current_rate: number;
  rec_rate: number;
  overriden_rate: number;
  rec_status: string;
  market_rate: number;
  pkp_day_1: number;
  pkp_day_3: number;
  pkp_day_7: number;
  pkp_doa: number;
  pkp_day_1_ly: number;
  pkp_day_3_ly: number;
  pkp_day_7_ly: number;
  pkp_doa_ly: number;
}

export interface InventoryColumns {
  property_id: string;
  stay_date: string;
  dow: string;
  sellout_ind: boolean;
  is_favorite: boolean;
  event_id: string;
  rec_rate: number;
  rec_status: string;
  current_rate: number;
  market_rate: number;
  current_otb: number;
  current_otb_lyst: number;
  current_otb_lyf: number;
  group_blocked: number;
  group_booked: number;
  out_of_order: number;
  transient_cap: number;
  total_capacity: number;
}

export interface KpiColumns {
  property_id: string;
  stay_date: string;
  dow: string;
  sellout_ind: boolean;
  is_favorite: boolean;
  event_id: string;
  current_rate: number;
  rec_rate: number;
  overriden_rate: number;
  rec_status: string;
  market_rate: number;
  adr: number;
  adr_lyst: number;
  adr_lyf: number;
  revpar: number;
  revpar_lyst: number;
  revpar_lyf: number;
  occ_pct: number;
  occ_pct_lyst: number;
  occ_pct_lyf: number;
  fcst_occ_pct: number;
  fcst_rev: number;
}

export interface RateColumns {
  property_id: string;
  stay_date: string;
  dow: string;
  sellout_ind: boolean;
  is_favorite: boolean;
  event_id: string;
  current_rate: number;
  rec_rate: number;
  overriden_rate: number;
  rec_status: string;
  market_rate: number;
  last_current_rate: number;
  prev_rec_rate: number;
  rate_variance: number;
  yoy_rate_lyst: number;
  yoy_rate_lyf: number;
  final_rec_rate: number;
  derived_rate: number;
}

export type Search = {
  duration: number;
  isEditable: boolean;
  recStatuses: string[] | [];
  searchCriteria: SearchMetricsInput[];
  searchId: number | null;
  searchName: string;
  searchType: string;
  selectedDays: string[] | [];
  startDate: Date | null;
  statuses: string[] | [];
  userId: number;
};

export type SearchInput = {
  duration?: number | string;
  searchId: number | null;
  searchName?: string;
  selectedDays?: string[] | [];
  startDate?: Date | null;
  userId: number;
  statuses?: string[] | [];
  searchCriteria?: Array<SearchMetricsInput>;
};

export type Operator_Rhs = {
  code?: string;
  value?: string;
};
export type SearchMetrics = {
  lhsCode?: string;
  lhsName?: string;
  operator?: Array<Operator_Rhs>;
  rhs?: Array<Operator_Rhs>;
};

export type SearchMetricsInput = {
  lhsCode: string;
  operatorCode: string;
  rhsCode: string;
  rhsCustomValue: number | null;
};

export type RecReviewChartArgs = {
  dayType: 'all' | 'weekdays' | 'weekends';
  propertyId?: string;
};

export type RecReviewChartDataType = {
  dayType: 'all' | 'weekdays' | 'weekends';
  chartData: ChartData;
  loading: boolean;
};

export type ChartData = {
  available_rooms: number[];
  bw_rates: number[];
  current_otb: number[];
  group_blocked: number[];
  group_booked: number[];
  market_rates: number[];
  out_of_order: number[];
  overbooking: number[] | string[];
  rec_rates: number[];
  stay_dates: string[];
  total_capacity: number[];
  total_fcst: number[];
  transient_booked: number[];
  transient_cap: number[];
  yoy_rate_lyst: number[];
  available_cap: number[];
  total_fcst_incl_group: number[];
};

export enum RecReviewStatus {
  NO_CHANGE = 'N',
  REVIEW = 'R',
  PENDING = 'P',
  SENT = 'S',
  ERROR = 'E',
}

export interface RecReviewType {
  current_rate: number;
  current_rate_live?: number;
  derived_rate: number;
  final_rec_rate: number;
  last_current_rate: number;
  last_updated_by: string;
  last_updated_date: string;
  market_rate: number;
  market_rate_lyf: number;
  market_rate_lyst: number;
  overriden_rate: number;
  prev_rec_rate: number;
  property_id: string;
  rate_variance: number;
  rec_rate: number;
  rec_status: RecReviewStatus;
  stay_date: string;
  yoy_rate_lyf: number;
  yoy_rate_lyst: number;
}

export interface UploadRatesInput {
  propertyId: string;
  rateToOverride: number | null;
  recStatus: RecReviewStatus;
  stayDate: string;
}

export interface UploadRatesType extends UploadRatesInput {
  oldRate: number | null;
  oldStatus: string;
}
export type UploadRates = UploadRatesType[];

export type GetPropertyRecRatesArgs = {
  propertyId: string;
  statuses: RecReviewStatus[];
};

export type ResetPropertyRecsArgs = {
  dates?: string[];
  propertyId: string;
  statusToUpdate?: RecReviewStatus;
  lastUpdatedBy?: string;
};

export type UpdatePendingRecsArgs = {
  dates?: string[];
  propertyId: string;
  status: RecReviewStatus;
  lastUpdatedBy: string;
};

export type UpdateRecArgs = {
  date: string;
  propertyId: string;
  newRate: number;
  status: RecReviewStatus;
  lastUpdatedBy: string;
};

export type SeasonType = {
  date: string;
  property_id: string;
  season_id: string;
  season_name: string;
  season_start: string | null;
  season_end: string | null;
  selected_days: SelectedDays[];
  season_priority: number;
  highlight_myview: boolean;
};

export type SeasonDictionaryType = { season: PropSeason };
