import './MenuButton.scss';

import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import React, { MouseEvent, useState } from 'react';

import { SortButton } from '../SortByButton/SortByButton';

const ITEM_HEIGHT = 48;

type SortOption = {
  label: string;
};

type SortFilter = {
  sortByIndex: number;
  sortOrder: string;
};

type MenuButtonProps = {
  options: SortOption[];
  selectedOption: SortFilter;
  children?: JSX.Element;
  title: string;
  onSelectedOptionChange: (newOption: SortFilter) => void;
};

export const MenuButton = ({
  options,
  selectedOption,
  onSelectedOptionChange,
  title,
  children,
}: MenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setAnchorEl(null);
    onSelectedOptionChange({
      sortByIndex: index,
      sortOrder: selectedOption.sortOrder,
    });
  };

  const onOrderChange = (index: number, order: string) => {
    onSelectedOptionChange({ sortByIndex: index, sortOrder: order });
  };

  return (
    <>
      <IconButton
        aria-label={title}
        id='menu-button'
        aria-controls={open ? 'menu-button' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={openMenu}
        title={title}
      >
        {children}
      </IconButton>
      <Menu
        className='menu-button'
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4,
            width: '20ch',
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            onClick={(event) => onMenuItemClick(event, index)}
            selected={index === selectedOption?.sortByIndex}
          >
            <div className='menu-button__container'>
              <ListItemText className='menu-button__text'>
                {option.label}
              </ListItemText>
              <ListItemIcon className='menu-button__icon'>
                <SortButton
                  value={
                    index === selectedOption.sortByIndex
                      ? selectedOption.sortOrder
                      : ''
                  }
                  onOrderChange={(order: string) => onOrderChange(index, order)}
                />
              </ListItemIcon>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
