import axios from 'axios';
import { windowDataExtractor } from './trackingHelper';

export const datalakeIngestion = async (window: Window, tracking: object) => {
  const clientData = windowDataExtractor(window);
  return await axios.post(
    process.env.REACT_APP_REST_API_URL! + '/data-stream',
    { ...tracking, ...clientData }
  );
};
