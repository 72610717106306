import { Card } from '@mui/material';
import styles from 'common/_variables.module.scss';
interface CustomCardProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  handleCardClick?: () => void;
  isSelected?: boolean;
}

export const CustomCard = ({
  children,
  style,
  className,
  isSelected,
  handleCardClick,
}: CustomCardProps) => {
  return (
    <Card
      variant='outlined'
      style={{
        borderRadius: '10px',
        backgroundColor: isSelected ? styles.lightGrey : 'white',
        height: '100%',
        ...style,
      }}
      onClick={handleCardClick}
      className={className}
    >
      {children}
    </Card>
  );
};
