import { Chart } from 'components/Chart/Chart';
import { CustomCard } from '../custom-card';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { occupancyChartConfig } from './config';

export type OccupancyData = {
  lastYearFinalPct: number;
  forecastPct: number | undefined;
  occupancyPct: number;
};

type OccupancyWidgetProps = {
  occupancyData: OccupancyData | undefined;
  loading: boolean;
};

export const OccupancyWidget = ({
  occupancyData,
  loading,
}: OccupancyWidgetProps) => {
  const chartOptions = { ...occupancyChartConfig };

  if (occupancyData) {
    const { lastYearFinalPct, forecastPct, occupancyPct } = occupancyData;
    chartOptions.series[0].data = [Math.round(lastYearFinalPct)];
    chartOptions.series[1].data = forecastPct && [Math.round(forecastPct)];
    chartOptions.series[2].data = [Math.round(occupancyPct)];
  }

  return (
    <CustomCard className='occupancy-widget'>
      <LoadingBar loading={loading}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          {occupancyData && <Chart options={chartOptions} />}
        </div>
      </LoadingBar>
    </CustomCard>
  );
};
