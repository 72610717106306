import './overview-pane.scss';

import {
  Collapse,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import { CustomCard } from '../custom-card';
import { DateFeed } from '../date-feed/date-feed';
import { ExpandMore } from '@mui/icons-material';
import { METRICS_DURATION } from 'pages/home/helpers';
import { SwipeableWidget } from '../swipeable-widget/swipeable-widget';
import { View } from '../inventory-rate-level-widget/inventory-rate-level-widget';
import _ from 'lodash';
import { addDays } from 'features/dates/date-helpers';
import dayjs from 'dayjs';
import { labels } from 'locales/en.label';
import useDeviceType from 'hooks/useDeviceType';
import { usePriceRecs } from 'pages/home/hooks/usePriceRecs';
import { useState } from 'react';

interface OverviewPaneProps {
  name: string | undefined;
  selectedDate: string;
  setDate: (date: string) => void;
  setView: (view: View) => void;
  lastUpdated: string | undefined;
}

const priorityClassNames = ['low', 'med', 'high'];

export const OverviewPane = ({
  name,
  selectedDate,
  setDate,
  setView,
  lastUpdated,
}: OverviewPaneProps) => {
  const [detailsExpanded, setDetailsExpanded] = useState(false);
  const [selectedPriority, setPriority] = useState<number | null>(null);
  const [filteredDates, setFilteredDates] = useState<string[] | null>(null);
  const { totals, alerts, revChartData, loading } = usePriceRecs();
  const { isMobile } = useDeviceType();

  let filter = null;
  if (selectedPriority !== null) {
    filter = { field: 'priority', value: selectedPriority };
  } else if (filteredDates) {
    filter = { field: 'stay_date', value: filteredDates };
  }

  const handlePriorityChange = (selection: number | null) => {
    setFilteredDates(null);
    setPriority((oldPriority) =>
      oldPriority === selection ? null : selection
    );
  };

  const handleFilteredDatesChange = (dates: string[]) => {
    setPriority(null);
    setFilteredDates((prevDates) => {
      if (_.isEqual(prevDates, dates)) return null;
      return dates;
    });
  };

  const upToDate = dayjs.utc().isSame(lastUpdated, 'day');

  return (
    <CustomCard className='overview' style={isMobile ? { border: 'none' } : {}}>
      <h2 className='name'>
        {labels.home.salutation}, {name}
      </h2>
      <div className={`${isMobile ? 'mobile-view-warning' : ''}`}>
        {lastUpdated && (
          <p className={`text ${!upToDate ? 'warning' : ''}`}>
            {labels.home.recs_message(upToDate)}
          </p>
        )}

        <IconButton
          aria-label='show details'
          aria-expanded={detailsExpanded}
          className={`expand-btn ${detailsExpanded ? 'expanded' : ''} ${
            isMobile ? 'mobile-collapse-btn' : ''
          }`}
          disabled={!lastUpdated}
          onMouseEnter={() => setDetailsExpanded((expanded) => !expanded)}
          onMouseLeave={() => setDetailsExpanded(false)}
          onClick={() => setDetailsExpanded((expanded) => !expanded)}
        >
          <ExpandMore />
        </IconButton>

        <Collapse in={detailsExpanded} className='details'>
          <p className={`text ${!upToDate ? 'warning' : ''}`}>
            {labels.home.generated_dates_message(
              dayjs().format('D MMM'),
              addDays(METRICS_DURATION - 1).format('D MMM')
            )}
            <br />
            {labels.home.last_updated_message(
              dayjs(lastUpdated).format('D MMM YYYY h:mm A')
            )}
          </p>
        </Collapse>
      </div>
      <ToggleButtonGroup
        value={selectedPriority}
        exclusive
        onChange={(e, value) => handlePriorityChange(value)}
        className={`rec-levels ${detailsExpanded ? 'hide-priorities' : ''}`}
      >
        {totals?.map(({ priority, label, count }) => (
          <ToggleButton
            key={priority}
            value={priority}
            className='priority-btn'
          >
            <div className={`count ${priorityClassNames[priority]}`}>
              {count}
            </div>
            <span className='priority'>{label}</span>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      <SwipeableWidget
        revAlerts={alerts}
        revChartData={revChartData}
        onReviewClick={handleFilteredDatesChange}
        loading={loading}
      />
      <DateFeed
        selectedDate={selectedDate}
        setDate={setDate}
        setView={setView}
        metricsFilter={filter}
        handlePriorityChange={handlePriorityChange}
      />
    </CustomCard>
  );
};
