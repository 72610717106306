import './numericCellEditor.scss';

import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { ICellEditorParams } from 'ag-grid-community';
import { alertAdded } from 'features/alerts/redux/alerts-slice';
import { useAppDispatch } from 'redux/hooks';

const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_F2 = 'F2';
const KEY_CNTRL = 'Control';
const KEY_CMD = 'Meta';

const toFixedNoRound = (value: number) => {
  return Number(value.toString().match(/^\d+(?:\.\d{0,2})?/));
};

export const getFormattedValue = (
  value: number | string,
  initialValue: number
) => {
  if (value === '' || value === '.') {
    return toFixedNoRound(initialValue);
  }
  return toFixedNoRound(Number(value));
};

export const NumericCellEditor = memo(
  forwardRef((props: ICellEditorParams, ref) => {
    const createInitialState = () => {
      let startValue;
      let highlightAllOnFocus = true;

      if (props.eventKey === KEY_BACKSPACE || props.eventKey === KEY_DELETE) {
        // if backspace or delete pressed, we clear the cell
        startValue = '';
      } else if (props.charPress) {
        // if a letter was pressed, we start with the letter
        startValue = props.charPress;
        highlightAllOnFocus = false;
      } else {
        // otherwise we start with the current value
        startValue = props.value;
        if (props.eventKey === KEY_F2) {
          highlightAllOnFocus = false;
        }
      }

      return {
        value: startValue,
        highlightAllOnFocus,
      };
    };

    const initialState = createInitialState();
    const [value, setValue] = useState(initialState.value);
    const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(
      initialState.highlightAllOnFocus
    );
    const [controlPressed, setControlPressed] = useState<Boolean>(false);
    const refInput = useRef<HTMLInputElement>(null);

    const dispatch = useAppDispatch();

    // focus on the input
    useEffect(() => {
      // get ref from React component
      const eInput = refInput.current!;
      eInput.focus();
      if (highlightAllOnFocus) {
        eInput.select();

        setHighlightAllOnFocus(false);
      } else {
        // when we started editing, we want the caret at the end, not the start.
        // this comes into play in two scenarios:
        //   a) when user hits F2
        //   b) when user hits a printable character
        const length = eInput.value ? eInput.value.length : 0;
        if (length > 0) {
          eInput.setSelectionRange(length, length);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* Utility Methods */
    const cancelBeforeStart =
      props.charPress && parseFloat(props.charPress) < 0;

    const isLeftOrRight = (key: string) => {
      return ['ArrowLeft', 'ArrowRight'].includes(key);
    };

    const isCharNumeric = (charStr: string) => {
      const isValHasPeriod = charStr === '.' ? true : false;
      return (
        !isNaN(parseFloat(charStr)) ||
        !isNaN(parseInt(charStr)) ||
        isValHasPeriod
      );
    };

    const deleteOrBackspace = (key: string) => {
      return [KEY_DELETE, KEY_BACKSPACE].includes(key);
    };

    const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
      const { key } = event;

      if (key === KEY_CNTRL || key === KEY_CMD) {
        setControlPressed(true);
      }

      if (key === 'v' && controlPressed) {
        if (navigator.clipboard) {
          // Use the Clipboard API to paste from clipboard
          navigator.clipboard
            .readText()
            .then((clipText) => {
              if (!isCharNumeric(clipText)) {
                event.preventDefault();
                throw new Error('Invalid input');
              }
              setValue(clipText);
            })
            .catch((err) => {
              dispatch(alertAdded('error', err.message));
            });
        } else {
          // Fallback for browsers that do not support the Clipboard API
          dispatch(
            alertAdded('error', 'Copy and paste not supported in this browser')
          );
        }
      }

      if (isLeftOrRight(key) || deleteOrBackspace(key)) {
        event.stopPropagation();
        return;
      }
      if (!isCharNumeric(key)) {
        event.preventDefault();
      }
    };

    const onKeyUp: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
      const { key } = event;

      if (key === KEY_CNTRL || key === KEY_CMD) {
        setControlPressed(false);
      }
    };

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          return getFormattedValue(value, initialState.value);
        },

        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart() {
          return cancelBeforeStart;
        },

        // Gets called once when editing is finished (eg if Enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        isCancelAfterEnd() {
          // will reject the number if it greater than 900
          // not very practical, but demonstrates the method.
          // return value > 900;
          return false; // Not 100% sure how this works, so to remove the max rate limit, I'm just returning false
          // Daniel Wise, 2024-02-02 for JIRA B20V-2627 Remove rate change safe max limit of 1000 in the client app
        },
      };
    });

    return (
      <input
        ref={refInput}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        className='dashboard__rate-upload-input'
      />
    );
  })
);
