import {
  GET_PROPERTIES,
  GET_PROPERTY_BY_PROPERTY_ID,
  GET_RECREVIEW_DATA,
} from 'queries/gqlRecReview';
import { useLazyQuery, useQuery } from '@apollo/client';
import { SearchInfo } from 'types/SearchTypes';

type UsePropertyArgs = {
  searchTerm: string;
};

type UsePropertySelloutArgs = {
  propertyId?: string;
  input?: SearchInfo;
  userId?: string;
};

export const useProperty = ({ searchTerm }: UsePropertyArgs) => {
  return useQuery(GET_PROPERTIES, {
    skip: searchTerm === '' || searchTerm.length < 3,
    variables: { searchTerm },
  });
};

export const useGetPropertyById = () => {
  return useLazyQuery(GET_PROPERTY_BY_PROPERTY_ID);
};

export const useLazyGetProperties = ({ searchTerm }: UsePropertyArgs) => {
  return useLazyQuery(GET_PROPERTIES, {
    variables: { searchTerm },
  });
};

export const useGetpropertySelloutCount = ({
  propertyId,
  input,
  userId,
}: UsePropertySelloutArgs) => {
  const { data, loading } = useQuery(GET_RECREVIEW_DATA, {
    skip: !propertyId || !userId || !input,
    variables: { propertyId, input, userId },
  });

  const selloutCount = data?.PropMetrics?.results?.filter(
    (r: { sellout_ind: boolean }) => r.sellout_ind === true
  ).length;

  return {
    propertySelloutCount: selloutCount || 0,
    loading,
  };
};
