import './Header.scss';
import { Link, useLocation } from 'react-router-dom';
import { MdFlag, MdOutlinedFlag } from 'react-icons/md';
import {
  selectEnableSellOutFlag,
  selectSelloutCount,
} from 'features/header/redux/selectors';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import IconButton from '@mui/material/IconButton';
import { setEnableSellOutFlag } from 'features/header/redux/header-slice';
import { useEffect } from 'react';

export const SelloutCount = () => {
  const tabs = [
    'metrics',
    'forecast',
    'inventory',
    'pickup',
    'rates',
    'competitors',
    'kpi',
  ];

  const path = useLocation().pathname;
  const isPageMyView = tabs.some((tab) => path.includes(tab));
  const dispatch = useAppDispatch();
  const enableSellOutFlag = useAppSelector(selectEnableSellOutFlag);

  const propertySelloutCount = useAppSelector(selectSelloutCount);
  const FlagIcon = isPageMyView && enableSellOutFlag ? MdFlag : MdOutlinedFlag;

  useEffect(() => {
    if (!isPageMyView) {
      dispatch(setEnableSellOutFlag(false));
    }
  }, [dispatch, isPageMyView]);
  const FlagEle = () => {
    return (
      <IconButton
        disableFocusRipple
        disableRipple
        aria-label='warning'
        onClick={() => {
          dispatch(setEnableSellOutFlag(!enableSellOutFlag));
        }}
      >
        <FlagIcon className='header__flag-icon' />
        <span className='header__icon-sellout-cnt'>{propertySelloutCount}</span>
      </IconButton>
    );
  };

  return (
    <>
      {
        //If there is sellout counts
        propertySelloutCount! > 0 && enableSellOutFlag ? (
          <FlagEle />
        ) : propertySelloutCount === 0 ? (
          <IconButton disableFocusRipple disableRipple aria-label='warning'>
            <MdFlag className='header__icon header__icon-disable' />
          </IconButton>
        ) : isPageMyView ? (
          <FlagEle />
        ) : (
          <Link
            to={{
              pathname: '/my-view/metrics',
            }}
            style={{ color: '#484848' }}
          >
            <FlagEle />
          </Link>
        )
      }
    </>
  );
};
