import './availability-table.scss';

import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';

import { dateFormatDisplay } from 'features/dates/date-helpers';
import dayjs from 'dayjs';

interface AvailabilityTableProps {
  data: AvailabilityData[];
  dates: string[];
}

interface AvailabilityData {
  bed_type: string;
  room_type_description: string;
  stay_date: string;
  remaining: number;
}

interface RoomType {
  room: string;
  subRows: RoomType[];
  [date: string]: number | string | RoomType[];
}

export const AvailabilityTable = ({ data, dates }: AvailabilityTableProps) => {
  const columnHelper = createColumnHelper<RoomType>();

  const generateColumns = useCallback(
    (dates: string[]) => {
      return [
        columnHelper.accessor('room', {
          header: 'Room Type Remaining',
          cell: ({ row, getValue }) => (
            <div className={`room-cell-${row.depth}`}>
              <div>
                {row.depth === 0 ? (
                  <>
                    <button
                      {...{
                        onClick: row.getToggleExpandedHandler(),
                        style: { cursor: 'pointer' },
                      }}
                      className='expand-button'
                    >
                      {row.getIsExpanded() ? (
                        <IoIosArrowDown />
                      ) : (
                        <IoIosArrowForward />
                      )}
                    </button>{' '}
                  </>
                ) : null}
                {getValue()}
              </div>
            </div>
          ),
          enableResizing: true,
          size: 300,
        }),
        ...dates.map((date) =>
          columnHelper.accessor(date, {
            header: dayjs(date).format(dateFormatDisplay),
            cell: ({ row, getValue }) => (
              <div className='remaining-cell'>{getValue()}</div>
            ),
          })
        ),
      ];
    },
    [columnHelper]
  );

  const formatData = (data: AvailabilityData[]) => {
    const roomsMap = new Map();
    data.forEach((room) => {
      const { bed_type, stay_date, remaining, room_type_description } = room;
      //check if roomsMap has bed type
      if (!roomsMap.has(bed_type)) {
        roomsMap.set(bed_type, {
          [stay_date]: remaining,
          subRows: [{ room: room_type_description, [stay_date]: remaining }],
        });
      } else {
        //if bed type does not have stay date
        const currentValue = roomsMap.get(bed_type);
        //check if stay_date exists in currentValue
        if (currentValue[stay_date]) {
          //add remaining to stay date
          currentValue[stay_date] += remaining;
        } else {
          //if stay_date does not exist, add it
          currentValue[stay_date] = remaining;
        }

        const subRow = currentValue.subRows.find(
          (subRow: any) => subRow.room === room_type_description
        );
        if (subRow) {
          subRow[stay_date] = remaining;
        } else {
          currentValue.subRows.push({
            room: room_type_description,
            [stay_date]: remaining,
          });
        }

        //update the bed_type with the updated currentValue
        roomsMap.set(bed_type, currentValue);
      }
    });

    return Array.from(roomsMap, ([bed_type, value]) => ({
      room: bed_type,
      ...value,
    }));
  };

  const rows = useMemo(() => formatData(data), [data]);
  const columns = useMemo(
    () => generateColumns(dates),
    [dates, generateColumns]
  );

  const table = useReactTable({
    columns,
    data: rows,
    initialState: {
      expanded: true,
    },
    enableColumnPinning: true,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <div className='availability-table-container'>
      <table className='availability-table'>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={`row-${row.depth}`}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
