import './RecReviewCharts.scss';
import { buildDemandChartOptions, demamdChartSeries } from './config';
import { getCategories } from 'hooks/useRecReviewCharts';
import { Chart } from 'components/Chart/Chart';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { RecReviewChartDataType } from 'types/RecReviewTypes';
import { labels } from 'locales/en.label';
import styles from 'common/_variables.module.scss';
import { usePropertyContext } from 'context/propertyContext';

export const DemandChart = ({
  dayType,
  chartData,
  loading,
}: RecReviewChartDataType) => {
  const xAxisCategory = getCategories(chartData?.stay_dates);
  const { currencySymbol } = usePropertyContext();
  const demandChartOptions = buildDemandChartOptions(currencySymbol);
  let chartOptions = {
    xAxis: [
      {
        categories: xAxisCategory,
        crosshair: true,
        lineWidth: 0,
        tickWidth: 0,
        labels: {
          rotation: 0,
          formatter: function (this: any) {
            const text = this.value;
            if (typeof text === 'string') {
              const day = `${text.substring(0, 3)}<br/>${text.substring(8, 9)}`;
              if (
                (text.indexOf('Fri') > -1 || text.indexOf('Sat') > -1) &&
                dayType === 'all'
              ) {
                return '<span class="weekend">' + day + '</span>';
              }
              return '<span class="weekday">' + day + '</span>';
            }
            return text;
          },
        },
      },
      {
        title: {
          text: 'Stay Date',
          style: {
            color: styles.graphGrey,
          },
        },
      },
    ],

    series: [
      {
        name: labels.rec_review.chart.total_fcst_incl_group,
        type: 'column',
        data: chartData?.total_fcst_incl_group,
        pointPadding: 0.2,
        pointPlacement: -0.2,
        color: styles.graphLightGrey,
      },
      {
        name: labels.rec_review.chart.current_otb,
        type: 'column',
        data: chartData?.current_otb,
        pointPadding: 0.2,
        pointPlacement: -0.05,
        color: styles.graphDarkGrey,
      },
      {
        name: demamdChartSeries.bw_rates.name,
        type: 'spline',
        yAxis: 1,
        data: chartData?.bw_rates,
        color: demamdChartSeries.bw_rates.color,
      },
      {
        name: demamdChartSeries.market_rates.name,
        type: 'spline',
        yAxis: 1,
        data: chartData?.market_rates,
        color: demamdChartSeries.market_rates.color,
      },
      {
        name: demamdChartSeries.rec_rates.name,
        type: 'spline',
        yAxis: 1,
        data: chartData?.rec_rates,
        color: demamdChartSeries.rec_rates.color,
      },
      {
        name: demamdChartSeries.yoy_rate_lyst.name,
        type: 'spline',
        yAxis: 1,
        data: chartData?.yoy_rate_lyst,
        color: demamdChartSeries.yoy_rate_lyst.color,
        showInLegend: true,
        visible: false,
      },
    ],
  };

  chartOptions = { ...demandChartOptions, ...chartOptions };

  return (
    <div className='demand-chart'>
      <LoadingBar loading={loading}>
        <Chart options={chartOptions} />
      </LoadingBar>
    </div>
  );
};
