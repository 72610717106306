import { labels } from 'locales/en.label';

export const tabItems = [
  {
    class: 'tab-my-metrics',
    to: '/my-view/metrics',
    title: 'My Metrics',
    key: 'metrics',
  },
  {
    class: 'tab-forecast',
    to: '/my-view/forecast',
    title: 'Forecast',
    key: 'forecast',
  },
  {
    class: 'tab-inventory',
    to: '/my-view/inventory',
    title: 'Inventory',
    key: 'inventory',
  },
  {
    class: 'tab-pickup',
    to: '/my-view/pickup',
    title: 'Pickup',
    key: 'pickup',
  },
  {
    class: 'tab-rates',
    to: '/my-view/rates',
    title: 'Rates',
    key: 'rates',
  },
  {
    class: 'tab-competitors',
    to: '/my-view/competitors',
    title: 'Competitors',
    key: 'competitors',
  },
  {
    class: 'tab-kpi',
    to: '/my-view/kpi',
    title: 'KPI',
    key: 'kpi',
  },
];

export interface MenuOptionType {
  id: number;
  title: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const moreOptionItems: MenuOptionType[] = [
  {
    id: 1,
    title: labels.rec_review.dashboard.more_action.create_season,
  },
  {
    id: 2,
    title: labels.rec_review.dashboard.more_action.show_season,
  },
  {
    id: 3,
    title: labels.rec_review.dashboard.more_action.create_event,
  },
  {
    id: 4,
    title: labels.rec_review.dashboard.more_action.create_mask,
  },
  {
    id: 5,
    title: labels.rec_review.dashboard.more_action.do_not_autopilot,
  },
];

export const days = [
  {
    label: '30',
    value: '30',
  },
  {
    label: '60',
    value: '60',
  },
  {
    label: '90',
    value: '90',
  },
  {
    label: '120',
    value: '120',
  },
  {
    label: '365',
    value: '365',
  },
];

export const lhsMetricOrder = [
  'rec_rate',
  'market_rate',
  'current_rate',
  'current_otb',
  'total_fcst',
  'available_cap',
  'adr',
  'occ_pct',
  'fcst_occ_pct',
];
