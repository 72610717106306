import { Maybe } from 'types/maybe-type';
import { dataDate } from 'features/dates/date-helpers';
import { dayRange } from 'features/dates/date-helpers';
import dayjs from 'dayjs';
import { uniqBy } from 'lodash';
import { useGetRateTrendBySnapshotQuery } from 'features/home/gql/_gen_/get-rate-trend.gql';
import { useMemo } from 'react';
import { usePropCompetitors } from 'features/rules/competitors/hooks/use-prop-competitors';
import { usePropertyContext } from 'context/propertyContext';

export type TrendData = {
  pickupData: Maybe<number>[];
  bwRates: Maybe<number>[];
  marketRates: Maybe<number>[];
  recRates: Maybe<number>[];
  competitorRates: {
    name: string;
    rates: Maybe<number>[];
  }[];
};

const hasValue = (value: unknown) => value !== undefined && value !== null;

export const useTrendDataForDate = (
  stayDate: string
): { data: TrendData | null; loading: boolean } => {
  const { property } = usePropertyContext();
  const propertyId = property?.propertyId;

  const { data: rateTrendData, loading: loadingRates } =
    useGetRateTrendBySnapshotQuery({
      variables: { propertyId, stayDate },
      skip: !propertyId,
    });
  const { data: competitorData, loading: loadingComps } = usePropCompetitors({
    propertyId,
  });

  const compNames = useMemo(
    () =>
      competitorData?.getPropertyCompetitors.reduce<Record<string, string>>(
        (compsById, current) => {
          compsById[current.comp_id] = current.comp_name;
          return compsById;
        },
        {}
      ),
    [competitorData?.getPropertyCompetitors]
  );

  const data = useMemo(() => {
    if (!rateTrendData?.getRateTrendBySnapshot || !compNames) return null;

    const trendData: TrendData = {
      pickupData: [],
      bwRates: [],
      marketRates: [],
      recRates: [],
      competitorRates: [],
    };

    let prevOtb: number | null | undefined;

    const uniqueDates = uniqBy(
      rateTrendData.getRateTrendBySnapshot,
      'last_updated_date'
    );

    const dateRange = dayRange(
      dayjs().subtract(7, 'days').format(), // One more day than necessary in order to properly calculate pickup
      dayjs().format()
    );

    dateRange.forEach((date, i) => {
      const found = uniqueDates.find(
        (snapshot) => dataDate(snapshot?.last_updated_date) === date
      );

      // We don't need the first snapshot for the chart, but we do need it to calculate the pickup for the following day
      if (i === 0) {
        prevOtb = found?.current_otb;
        return;
      }

      //Build Out Competitor Data
      competitorData?.getPropertyCompetitors.forEach((comp) => {
        if (!trendData.competitorRates.find((c) => c.name === comp.comp_name)) {
          trendData.competitorRates.push({
            name: comp.comp_name,
            rates: [],
          });
        }
      });

      //If no data for date, push nulls
      if (!found) {
        trendData.pickupData.push(null);
        trendData.bwRates.push(null);
        trendData.marketRates.push(null);
        trendData.recRates.push(null);
        trendData.competitorRates.forEach((comp) => comp.rates.push(null));

        return;
      }

      const { current_rate, market_rate, rec_rate, current_otb } = found;

      trendData.bwRates.push(current_rate);
      trendData.marketRates.push(market_rate);
      trendData.recRates.push(rec_rate);
      trendData.pickupData.push(
        hasValue(prevOtb) && hasValue(current_otb)
          ? current_otb! - prevOtb!
          : null
      );

      prevOtb = current_otb;

      // Add Competitor Rates
      for (let i = 1; i <= 10; i++) {
        const id = (found as Record<string, number>)[`comp_id_${i}`];
        const rate = (found as Record<string, number>)[`comp_rate_${i}`];
        const compName = compNames[id];

        if (compName) {
          const compIndex = trendData.competitorRates.findIndex(
            (comp) => comp.name === compName
          );

          trendData.competitorRates[compIndex].rates.push(rate);
        }
      }
    });

    return trendData;
  }, [
    compNames,
    competitorData?.getPropertyCompetitors,
    rateTrendData?.getRateTrendBySnapshot,
  ]);

  return { data, loading: loadingRates || loadingComps };
};
