import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RecReview, RecReviewStatus } from 'graphql/gql-types';

import { GetPropertyRecRatesQuery } from 'features/rec-rates/gql/_gen_/property-rec-rates.gql';
import { dataDate } from 'features/dates/date-helpers';

type RecRates = GetPropertyRecRatesQuery['getPropertyRecRates'];

export interface RateUploadState {
  pendingRecs: RecRates;
  polling: boolean;
  recRates: RecRates;
  uploading: boolean;
  allRowsSelected?: boolean;
}

const initialState: RateUploadState = {
  pendingRecs: [],
  polling: false,
  recRates: [],
  uploading: false,
  allRowsSelected: false,
};

export const filterRecs = (recs?: RecRates, status = RecReviewStatus.P) => {
  return recs?.filter((rec) => rec?.rec_status === status) ?? [];
};

export const removeRec = (recs?: RecRates, recDate?: string) => {
  return (
    recs?.filter(
      (rec) => rec?.stay_date && dataDate(rec.stay_date) !== dataDate(recDate)
    ) ?? []
  );
};

export const rateUploadSlice = createSlice({
  name: 'rateUpload',
  initialState,
  reducers: {
    setPendingRecs(state, action: PayloadAction<RecRates>) {
      state.pendingRecs = filterRecs(action.payload);
    },
    setRecRates(state, action: PayloadAction<RecRates>) {
      // Update the state of all rec rates
      state.recRates = action.payload;

      // Update the state of pending rec rates
      state.pendingRecs = filterRecs(action.payload);
    },
    setPolling(state, action: PayloadAction<boolean>) {
      state.polling = action.payload;
    },
    setUploading(state, action: PayloadAction<boolean>) {
      state.uploading = action.payload;
    },
    setAllRowsSelected(state, action: PayloadAction<boolean>) {
      state.allRowsSelected = action.payload;
    },
    togglePolling(state) {
      state.polling = !state.polling;
    },
    toggleUploading(state) {
      state.uploading = !state.uploading;
    },
    updatePending(state, action: PayloadAction<RecReview>) {
      const baseRecs = removeRec(state.pendingRecs, action.payload.stay_date);

      if (action.payload.rec_status === RecReviewStatus.P) {
        // If the rec is pending, add it to the list
        baseRecs.push(action.payload);
      }
      state.pendingRecs = baseRecs;
    },
  },
});

export const {
  setPendingRecs,
  setPolling,
  setRecRates,
  setUploading,
  setAllRowsSelected,
  togglePolling,
  toggleUploading,
  updatePending,
} = rateUploadSlice.actions;

export default rateUploadSlice.reducer;
