import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';
import styles from 'common/_variables.module.scss';

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      color: styles.whiteColor,
      border: `1px solid ${styles.graphOrange}`,
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: styles.whiteColor,
    color: styles.graphOrange,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${styles.graphOrange}`,
  },
}));
