import './InputDate.scss';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { Field } from 'formik';
import { FixMeLater } from 'types/FixMeLaterType';
import TextField from '@mui/material/TextField';
import { dateFormatData } from 'features/dates/date-helpers';

interface InputDateProps {
  name: string;
  label: string;
  minDate?: Dayjs;
  disabled?: boolean;
  disablePast?: boolean;
  onValueChange?: (date: string | null) => void;
}
const getValueFromPath = (data: any, path: string) => {
  path = path.trim();
  const keys = path.split('.');

  for (let key of keys) {
    if (key.includes('[') && key.includes(']')) {
      // If the key contains brackets, extract the list index
      const [listKey, index] = key.split('[');
      data = data[listKey][parseInt(index)];
    } else {
      data = data[key];
    }
  }

  return data;
};
export const InputDate = ({
  name,
  disablePast,
  disabled = false,
  minDate,
  onValueChange,
  ...props
}: InputDateProps) => {
  return (
    <div className='date-container form-row__item'>
      <Field name={name}>
        {({ form, field, meta }: FixMeLater) => {
          const { setFieldValue, values } = form;
          const currentValue = getValueFromPath(values, name);
          return (
            <div>
              <DatePicker
                {...field}
                {...props}
                name={name}
                value={
                  currentValue
                    ? dayjs(currentValue).format(dateFormatData)
                    : null
                }
                disabled={disabled}
                disablePast={disablePast}
                onChange={(val: Dayjs) => {
                  const formattedVal = val?.format(dateFormatData) || null;
                  setFieldValue(name, formattedVal);
                  onValueChange?.(formattedVal);
                }}
                minDate={minDate}
                renderInput={(params) => (
                  <TextField
                    helperText={meta.touched && meta.error}
                    margin='normal'
                    {...params}
                    {...props}
                    name={name}
                    error={meta.touched && !!meta.error}
                  />
                )}
              />
            </div>
          );
        }}
      </Field>
    </div>
  );
};
