import { dateFormatData, today } from 'features/dates/date-helpers';
import {
  useCreatePropSeasonMutation,
  useGetPropSeasonsQuery,
} from './seasons/gql/_gen_/prop-seasons.gql';
import { useEffect, useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { DOW_ALL } from 'features/search/search-helpers';
import { PropSeason } from 'graphql/gql-types';
import dayjs from 'dayjs';
import { labels } from 'locales/en.label';
import { usePropertyContext } from 'context/propertyContext';

export const useSeasons = (): {
  seasons: PropSeason[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const {
    property: { propertyId },
  } = usePropertyContext();
  const { data, loading, error } = useGetPropSeasonsQuery({
    skip: !propertyId,
    variables: { propertyId },
  });

  return { seasons: data?.getPropSeasons, loading, error };
};

// Create default season if it doesn't already exist for property
export const useDefaultSeason = () => {
  const {
    property: { propertyId },
  } = usePropertyContext();
  const { seasons } = useSeasons();
  const [createNewSeason] = useCreatePropSeasonMutation();
  const createSeasonCalled = useRef(false);

  useEffect(() => {
    const defaultSeason = seasons?.find(
      (s) => s?.season_name === labels.rules.default_season_name
    );
    if (!seasons || defaultSeason || createSeasonCalled.current) {
      return;
    }
    createNewSeason({
      variables: {
        newPropSeason: {
          property_id: propertyId,
          season_name: labels.rules.default_season_name,
          season_start: today(),
          season_end: dayjs().add(10, 'years').format(dateFormatData),
          selected_days: DOW_ALL,
          season_priority: 1,
          highlight_myview: false,
        },
      },
    });
    createSeasonCalled.current = true;
  }, [createNewSeason, propertyId, seasons]);
};
