import { ICellRendererParams } from 'ag-grid-community';
import { formattedCurrency } from './tableCustomCellRender';
import { selectProperty } from 'features/property/redux/selectors';
import { useAppSelector } from 'redux/hooks';
import { useUser } from 'features/users/context/userContext';

type RateCellProps = {
  params: ICellRendererParams;
  digitFormatter: (value: number | string) => string;
};

export const RateCellRender = ({ params, digitFormatter }: RateCellProps) => {
  const property = useAppSelector(selectProperty);
  const { currencyCode } = property;
  const { locale } = useUser();

  if (params.value) {
    return (
      <span>
        {formattedCurrency(
          params.value,
          locale,
          currencyCode,
          true,
          digitFormatter
        )}
      </span>
    );
  } else {
    return <span>{params.value}</span>;
  }
};
