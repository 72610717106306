import { useGetHotelRateOffsetLogsQuery } from '../gql/_gen_/rate-offset-log.gql';
import { usePropertyContext } from 'context/propertyContext';

export const useRateOffsetLogs = (page: number) => {
  const FETCH_LIMIT = 300;

  const { property } = usePropertyContext();
  const { data, loading } = useGetHotelRateOffsetLogsQuery({
    skip: !property?.propertyId,
    variables: {
      propertyId: property?.propertyId,
      limit: FETCH_LIMIT,
      page: page
    },
  });

  return {
    data,
    loading,
    propertyId: property?.propertyId,
  };
};
