import { RateChangeTable, useRateChangeLogs } from 'features/rate-change-log';

import { RateChangeLog } from 'graphql/gql-types';

export const Main = () => {
  const { data, loading } = useRateChangeLogs();

  return (
    <div>
      <h1>Rate Change Log</h1>
      {loading && <div>Loading...</div>}
      {data && !loading && (
        <RateChangeTable
          data={data?.getHotelRateChangeLogs as RateChangeLog[]}
        />
      )}
      {!data && !loading && <div>No data found</div>}
    </div>
  );
};
