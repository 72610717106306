import './modal.scss';

import React, { FunctionComponent, ReactNode, useEffect } from 'react';

import { LoadingBar } from 'components/loading-bar/loading-bar';
import { MdClose } from 'react-icons/md';
import ReactDOM from 'react-dom';
import { labels } from 'locales/en.label';

export interface ModalProps {
  isShown: boolean;
  hide: (event?: React.MouseEvent<HTMLDivElement>) => void;
  headerText?: string;
  messageBody?: ReactNode | null;
  isConfirmationModal?: boolean;
  onConfirm?: (event?: React.MouseEvent<HTMLDivElement>) => void;
  onCancel?: (event?: React.MouseEvent<HTMLDivElement>) => void;
  confirmBtnText?: string;
  cancelBtnText?: string;
  btnText?: string;
  hasIcon?: boolean;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  loading?: boolean;
  className?: string;
}

export const Modal: FunctionComponent<ModalProps> = ({
  isShown,
  hide,
  headerText,
  messageBody,
  hasIcon,
  isConfirmationModal,
  confirmBtnText = labels.common.confirm,
  cancelBtnText = labels.common.cancel,
  btnText,
  onConfirm,
  onCancel,
  icon,
  children,
  loading = false,
  className,
}) => {
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === 27 && isShown) {
        hide();
      }
    };
    isShown
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [hide, isShown]);

  const modal = (
    <div
      aria-modal
      aria-labelledby={headerText}
      tabIndex={-1}
      role='dialog'
      className='modal'
    >
      <div className='modal__overlay' onClick={hide}></div>
      <LoadingBar
        loading={loading}
        className={
          className ? `modal__container ${className}` : 'modal__container'
        }
      >
        <>
          <div className='modal__close' onClick={hide}>
            <MdClose size={20} />
          </div>
          {
            <div className='modal__content'>
              {headerText && (
                <div className='modal__header'>
                  {hasIcon && <div className='modal__icon'>{icon}</div>}
                  <span className='modal__header--text'>{headerText}</span>
                </div>
              )}

              <div className='modal__body'>
                {messageBody && messageBody}
                {children}
              </div>
              {isConfirmationModal && (
                <div className='modal__confirmation-btns'>
                  <div
                    className='button modal__confirmation-btns--cancel'
                    onClick={onCancel}
                  >
                    {cancelBtnText}
                  </div>
                  <div
                    className='button modal__confirmation-btns--confirm'
                    onClick={onConfirm}
                  >
                    {confirmBtnText}
                  </div>
                </div>
              )}
              {btnText && (
                <div className='modal__button modal__confirmation-btns--other'>
                  {btnText}
                </div>
              )}
            </div>
          }
        </>
      </LoadingBar>
    </div>
  );
  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};
