import './generic-fallback.scss';

import { Button } from '@mui/material';
import HelpButton from 'components/HelpButton/help-button';

export const GenericFallback = () => (
  <div className='generic-fallback'>
    <div className='message'>
      <h1>Sorry!</h1>
      <p>
        It looks like something went wrong. If reloading your browser doesn't
        fix the problem, you may need to{' '}
        <Button
          variant='text'
          className='reset-btn'
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
        >
          reset your local BestRev Data
        </Button>
        .
      </p>
    </div>
    <HelpButton />
  </div>
);
