import { getYear, today } from './features/dates/date-helpers';
import { useEffect, useState } from 'react';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const Changelog = () => {
  const changelogPath = require('./CHANGELOG.md');
  const [changelog, setChangelog] = useState('');

  useEffect(() => {
    fetch(changelogPath)
      .then((res) => {
        return res.text();
      })
      .then((text) => {
        setChangelog(text);
      });
  }, [changelogPath]);

  return (
    <div
      style={{
        overflow: 'auto',
        height: 'calc(100vh - 150px)',
        marginTop: '1.5rem',
      }}
    >
      <div>
        <h1>Changelog</h1>
        <div>
          <ReactMarkdown children={changelog} remarkPlugins={[remarkGfm]} />
        </div>
      </div>
      <footer>
        <div>
          <div>
            <span>&copy; {getYear(today())} Best Western Hotels.</span>{' '}
            <span>All rights reserved.</span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Changelog;
