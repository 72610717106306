import '../../HelpLayout/HelpStyles.scss';

import React from 'react';

interface SubmitArticleBtnProps {
  onClick: () => void;
}
const SubmitArticleBtn: React.FC<SubmitArticleBtnProps> = ({ onClick }) => {
  return (
    <button className='articleButton' onClick={onClick}>
      Submit an Article
    </button>
  );
};

export default SubmitArticleBtn;
