import {
  PropRateLevels,
  PropRateLevelsData,
} from 'features/home/types/prop-rate-levels';

import { GET_RATE_LEVELS } from 'queries/gqlRecReview';
import { MetricsWithLiveData } from './use-prop-metrics';
import { useMemo } from 'react';
import { useMetricsForDate } from './use-metrics-for-date';
import { usePropertyContext } from 'context/propertyContext';
import { useQuery } from '@apollo/client';
import { useUser } from 'features/users/context/userContext';

export type FormattedRateLevels = {
  level: string;
  otb: number;
  liveOtb: number | undefined;
  fcstSell: number;
  status: string;
}[];

type UseRateLevelsForDateResponse = {
  rateLevels: FormattedRateLevels | undefined;
  total: number | undefined;
  loading: boolean;
};

const sortFn = (a: [string, any], b: [string, any]) => (a[0] > b[0] ? 1 : -1);

export const formatRateLevels = (
  rateLevels: PropRateLevels,
  metrics: MetricsWithLiveData | undefined
) => {
  const otbs: [string, number][] = [];
  const liveOtbs: [string, number][] = [];
  const forecasts: [string, number][] = [];
  const statuses: [string, string][] = [];

  if (rateLevels) {
    Object.entries(rateLevels).forEach(([key, value]) => {
      if (key.includes('current_otb')) otbs.push([key, value as number]);
      if (key.includes('fcst_sell')) forecasts.push([key, value as number]);
      if (key.includes('status')) statuses.push([key, value as string]);
    });
  }

  if (metrics) {
    Object.entries(metrics).forEach(([key, value]) => {
      if (key === 'current_otb_live') return;
      if (key.includes('otb_live')) liveOtbs.push([key, value as number]);
    });
  }

  otbs.sort(sortFn);
  liveOtbs.sort(sortFn);
  forecasts.sort(sortFn);
  statuses.sort(sortFn);

  const levels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

  return levels.map((level, index) => ({
    level,
    otb: otbs[index][1],
    liveOtb: liveOtbs[index]?.[1],
    fcstSell: forecasts[index][1],
    status: statuses[index][1],
  }));
};

export const useRateLevelsForDate = (
  date: string
): UseRateLevelsForDateResponse => {
  const { property } = usePropertyContext();
  const { user } = useUser();
  const propertyId = property?.propertyId;
  const userId = user?.id;

  const { data, loading } = useQuery<PropRateLevelsData>(GET_RATE_LEVELS, {
    skip: !propertyId || !userId,
    variables: {
      propertyId,
      input: {
        userId,
        startDate: date,
        duration: 1,
      },
    },
  });
  const { metrics, loading: loadingMetrics } = useMetricsForDate(date);

  const formattedLevels: FormattedRateLevels | undefined = useMemo(() => {
    if (!data?.getPropRateLevels.length) return;
    return formatRateLevels(data.getPropRateLevels[0], metrics);
  }, [data, metrics]);

  return {
    rateLevels: formattedLevels,
    total: metrics?.current_otb_live ?? metrics?.current_otb,
    loading: loading || loadingMetrics,
  };
};
