import { MAKE_STAY_DATE_FAV_UNFAV } from 'queries/gqlRecReview';
import { useMutation } from '@apollo/client';
import { usePropertyContext } from 'context/propertyContext';
import { useUser } from 'features/users/context/userContext';

export const useFavStayDate = (stayDate?: string) => {
  const { property } = usePropertyContext();
  const { user } = useUser();
  const propertyId = property?.propertyId;
  const userId = user?.id;

  return useMutation<any, Record<string, any>>(MAKE_STAY_DATE_FAV_UNFAV, {
    variables: { propertyId, userId, stayDate },
  });
};
