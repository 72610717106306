import './NavBar.scss';

import ComingSoonTooltip from 'pages/MyView/ComingSoonTooltip';
import { NavButton } from './NavButton';
import { navMenuItems } from './config';
import { useUser } from 'features/users/context/userContext';

export const NavBar = () => {
  const { user } = useUser();

  return (
    <nav className='navContainer'>
      <ul className='navItems'>
        {navMenuItems.map((item) =>
          //TODO: Remove tooltip and enable Insights and Events when ready
          item.to === '/insights' ? (
            <ComingSoonTooltip key={item.to + '-coming-soon'}>
              <span>
                <NavButton key={item.to} enabled={false} {...item} />
              </span>
            </ComingSoonTooltip>
          ) : item.to === '/admin' && !user?.super_admin ? null : (
            <NavButton key={item.to} {...item} />
          )
        )}
      </ul>
    </nav>
  );
};
