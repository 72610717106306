import { ColDef, ICellRendererParams } from 'ag-grid-community';
import {
  ModifyCompSetCellRenderer,
  NumericCellEditor,
  OverrideCell,
} from './custom-table-cell-renderers';
import { allCompetitorColumnDefs, competitorSetColumnDefs } from './config';
import { isEqual, keyBy } from 'lodash';
import {
  useAddPropCompetitorSet,
  useDeletePropCompetitorSet,
  usePropCompetitorSet,
  usePropCompetitors,
  useUpdatePropCompetitorSet,
} from 'features/rules/competitors/hooks/use-prop-competitors';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect, useMemo, useState } from 'react';

import { PropCompetitorsType } from 'types/RulesTypes';
import { alertAdded } from 'features/alerts/redux/alerts-slice';
import { labels } from 'locales/en.label';
import { selectCurrentSeason } from 'features/rules/redux/rules-slice';
import { useApolloError } from 'hooks/useApolloError';
import { usePropertyContext } from 'context/propertyContext';
import { useUser } from 'features/users/context/userContext';

const competitorLabels = labels.rules.competitor_table;

export const useCompetitorsController = () => {
  const dispatch = useAppDispatch();
  const { handleApolloError } = useApolloError();
  const { user } = useUser();
  const {
    property: { propertyId },
  } = usePropertyContext();
  const selectedSeason = useAppSelector(selectCurrentSeason);

  const { data: compSetData, loading: compSetLoading } = usePropCompetitorSet({
    propertyId,
    seasonId: selectedSeason?.season_id,
  });
  const { data: propertyCompetitors, loading: propCompsLoading } =
    usePropCompetitors({ propertyId });

  const [competitorSet, setCompetitorSet] = useState<PropCompetitorsType[]>([]);

  useEffect(() => {
    if (compSetData?.getPropertyCompetitorsSet)
      setCompetitorSet(compSetData.getPropertyCompetitorsSet);
  }, [compSetData]);

  const [isAllCompetitors, setIsAllCompetitors] = useState(false);
  useEffect(() => {
    if (
      compSetData?.getPropertyCompetitorsSet &&
      !compSetData.getPropertyCompetitorsSet.length
    ) {
      setIsAllCompetitors(true);
    }
  }, [compSetData?.getPropertyCompetitorsSet]);

  const [pinnedBottomData, setPinnedBottomData] = useState([
    {
      comp_name: 'Total',
      comp_weight: 0,
    },
  ]);
  useEffect(() => {
    let totalWeight = 0;
    competitorSet.forEach((row) => {
      totalWeight += +row.comp_weight || 0;
    });
    const pinnedRowObj = {
      comp_name: 'Total',
      comp_weight: totalWeight,
    };
    setPinnedBottomData([pinnedRowObj]);
  }, [competitorSet]);

  const [addPropCompSet, { loading: addPropCompSetLoading }] =
    useAddPropCompetitorSet();
  const [updateCompetitorSet, { loading: updatePropCompetitorSetLoading }] =
    useUpdatePropCompetitorSet();
  const [deleteCompSet, { loading: deleteCompetitorSetLoading }] =
    useDeletePropCompetitorSet();

  const weightageColumn: ColDef = useMemo(
    () => ({
      headerName: competitorLabels.weighting_percentage,
      field: 'comp_weight',
      cellEditor: NumericCellEditor,
      cellEditorParams: {
        min: 0,
        max: 100,
      },
      cellRenderer: OverrideCell,
      valueSetter: (params) => {
        const { oldValue, data, api } = params;
        const newValue = +params.newValue;
        if (oldValue === newValue) return false;

        setCompetitorSet((prev) =>
          prev.map((comp) =>
            comp.comp_id === data.comp_id
              ? {
                  ...comp,
                  comp_weight: newValue,
                }
              : comp
          )
        );

        let totalWeight = 0;
        api.forEachNode((node) => {
          const weight = node.data.comp_weight;
          if (weight && !isNaN(weight)) {
            totalWeight += parseInt(weight);
          }
        });
        api.setPinnedBottomRowData([
          {
            comp_name: 'Total',
            comp_weight: totalWeight,
          },
        ]);

        return true;
      },
      editable: true,
      singleClickEdit: true,
      sortable: true,
      sortIndex: 3,
      sort: 'desc',
      width: 140,
    }),
    []
  );

  const modifyCompSetColumn: ColDef = {
    headerName: '',
    width: 50,
    cellRenderer: ModifyCompSetCellRenderer,
    cellRendererParams: (params: ICellRendererParams) => ({
      isInCompetitorSet: competitorSet?.some(
        (c) => c.comp_id === params.data.comp_id
      ),
      onClick: () => {
        const competitor = params.data;
        const compIndex = competitorSet?.findIndex(
          (c) => c.comp_id === competitor.comp_id
        );

        const modifiedCompSet =
          compIndex === -1
            ? [...competitorSet, { ...competitor, comp_weight: 0 }]
            : competitorSet
                .slice(0, compIndex)
                .concat(competitorSet.slice(compIndex + 1));

        setCompetitorSet(modifiedCompSet);
      },
    }),
  };

  const formatForMutations = (
    comps: PropCompetitorsType[],
    isDelete: boolean = false
  ) =>
    comps.map(({ comp_id, comp_weight }) => ({
      property_id: propertyId,
      season_id: selectedSeason?.season_id,
      comp_id,
      ...(!isDelete && { comp_weight, updatedBy: user?.login_id }),
    }));

  const getModifiedCompetitorSet = () => {
    const initialCompSet = keyBy(
      compSetData?.getPropertyCompetitorsSet,
      'comp_id'
    );
    const currentCompSet = keyBy(competitorSet, 'comp_id');

    const addedCompetitors: PropCompetitorsType[] = [];
    const updatedCompetitors: PropCompetitorsType[] = [];

    competitorSet.forEach((comp) => {
      const initialComp = initialCompSet[comp.comp_id];
      if (initialComp) {
        if (!isEqual(initialComp, comp) && comp.comp_weight)
          updatedCompetitors.push(comp);
      } else {
        addedCompetitors.push(comp);
      }
    });

    const deletedCompetitors =
      compSetData?.getPropertyCompetitorsSet.filter((comp) => {
        const currentComp = currentCompSet[comp.comp_id];
        return !currentComp || currentComp.comp_weight === 0;
      }) || [];

    return {
      added: formatForMutations(addedCompetitors),
      updated: formatForMutations(updatedCompetitors),
      deleted: formatForMutations(deletedCompetitors, true),
    };
  };

  const saveCompetitorSet = async (): Promise<boolean> => {
    if (!competitorSet.length) {
      dispatch(alertAdded('error', competitorLabels.no_competitors_error));
      return false;
    }
    if (pinnedBottomData[0].comp_weight !== 100) {
      dispatch(alertAdded('error', competitorLabels.total_weighting_error));
      return false;
    }
    if (!isDataModified) {
      return true;
    }
    if (!selectedSeason) {
      dispatch(alertAdded('error', competitorLabels.season_not_selected_err));
      return false;
    }

    const modifiedCompSet = getModifiedCompetitorSet();
    const apiResponses = [];

    if (modifiedCompSet.added.length) {
      const addCompSetRequest = addPropCompSet({
        variables: { newCompSeasons: modifiedCompSet.added },
        onError: (error) => handleApolloError(error),
      });
      apiResponses.push(addCompSetRequest);
    }
    if (modifiedCompSet.updated.length) {
      const updateCompSetRequest = updateCompetitorSet({
        variables: { modifiedCompSeasons: modifiedCompSet.updated },
        onError: (error) => handleApolloError(error),
      });
      apiResponses.push(updateCompSetRequest);
    }
    if (modifiedCompSet.deleted.length) {
      const deleteCompSetRequest = deleteCompSet({
        variables: { selectedCompSeasons: modifiedCompSet.deleted },
        onError: (error) => handleApolloError(error),
      });
      apiResponses.push(deleteCompSetRequest);
    }

    try {
      const responses = await Promise.all(apiResponses);
      const allResponsesOk = responses.every((response) => response.data);
      if (responses.length && allResponsesOk) {
        dispatch(alertAdded('success', competitorLabels.save_success_msg));
      }
    } catch (error) {
      console.error(error);
      dispatch(alertAdded('error', competitorLabels.save_failed_err));
      return false;
    }
    return true;
  };

  const onToggle = () => setIsAllCompetitors((prev) => !prev);

  const rowData =
    (isAllCompetitors
      ? propertyCompetitors?.getPropertyCompetitors
      : competitorSet) ?? [];

  const columnDefs = isAllCompetitors
    ? [modifyCompSetColumn, ...allCompetitorColumnDefs]
    : [...competitorSetColumnDefs, weightageColumn];

  const isDataModified = !isEqual(
    compSetData?.getPropertyCompetitorsSet,
    competitorSet
  );

  const loading =
    compSetLoading ||
    propCompsLoading ||
    addPropCompSetLoading ||
    updatePropCompetitorSetLoading ||
    deleteCompetitorSetLoading;

  return {
    saveCompetitorSet,
    isAllCompetitors,
    onToggle,
    rowData,
    columnDefs,
    pinnedBottomData,
    isDataModified,
    loading,
    propertyCompetitors: propertyCompetitors?.getPropertyCompetitors,
  };
};
