import './ToggleButton.scss';

import { ChangeEvent } from 'react';

type ToggleButtonProps = {
  id: string;
  selected: boolean;
  labelOptions: string[];
  toggleSelected: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const ToggleButton = ({
  id,
  selected,
  toggleSelected,
  labelOptions,
}: ToggleButtonProps) => {
  return (
    <div className='toggle-button-switch'>
      <input
        type='checkbox'
        id={id}
        onChange={(event) => toggleSelected(event)}
        checked={selected}
      />
      <div className='content'>
        <label className='toggle-btn-label' htmlFor={id}>
          <div className='toggle'></div>
          <div className='names'>
            <p className={!selected ? 'on ' : 'off'}>{labelOptions[0]}</p>
            <p className={selected ? 'on' : 'off'}>{labelOptions[1]}</p>
          </div>
        </label>
      </div>
    </div>
  );
};
