import './HelpLayout/HelpStyles.scss';

import {
  GetArticleBySlugDocument,
  GetSupportArticlesByCategoryDocument,
} from 'features/help/gql/_gen_/help.gql';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

interface Article {
  id: string;
  title: string;
  category: string;
  body: string;
  createdAt: string;
  updatedAt: string;
  slug: string;
}

interface GetArticlesData {
  getCategoryArticles: Article[];
}

export const Help = () => {
  const { slug } = useParams<{ slug?: string }>();
  const history = useHistory();
  const [selectedCategory, setSelectedCategory] = useState<string | null>(
    'general'
  );
  const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);
  const [activeButton, setActiveButton] = useState<string | null>(null);

  const { data, loading, error } = useQuery<GetArticlesData>(
    GetSupportArticlesByCategoryDocument,
    {
      variables: {
        category: selectedCategory || '',
      },
      skip: !selectedCategory, // Skip the query if selectedCategory is null
    }
  );

  const { data: articleData } = useQuery(GetArticleBySlugDocument, {
    variables: { slug },
    skip: !slug,
  });

  // Sort the list of articles alphabetically
  const sortedArticles = useMemo(() => {
    return (
      data?.getCategoryArticles
        .slice()
        .sort((a, b) => a.title.localeCompare(b.title)) || []
    );
  }, [data]);

  // Set the selected article based on the slug in the URL
  useEffect(() => {
    if (selectedCategory === 'help' && !slug) {
      setSelectedArticle(null);
    } else if (articleData && articleData.getArticleBySlug) {
      setSelectedArticle(articleData.getArticleBySlug);
    } else if (sortedArticles.length > 0) {
      setSelectedArticle(sortedArticles[0]);
    }
  }, [articleData, sortedArticles, selectedCategory, slug]);

  // Set the active button based on the selected category
  const handleCategoryClick = (category: string) => {
    // If the selected category is already active, do nothing
    if (category === selectedCategory) {
      return;
    }
    setSelectedCategory(category);
    setSelectedArticle(null); // Reset selected article when category is changed
    setActiveButton(category); // Set the active button to the selected category
    // If the category is 'help', navigate to the glossary page
    if (category === 'help') {
      history.push('/help/help');
    } else {
      history.push(`/help/${category}`);
    }
  };

  const handleArticleClick = (article: Article) => {
    setSelectedArticle(article);
    history.push(`/help/${article.slug}`);
  };

  const handleEditClick = () => {
    if (selectedArticle) {
      history.push(`/help/edit/${selectedArticle.slug}`);
    }
  };

  return (
    <div className='help-container'>
      <div className='btns-container'>
        <button
          className={`articleButton ${
            activeButton === 'general' ? 'active' : ''
          }`}
          onClick={() => handleCategoryClick('general')}
        >
          General
        </button>
        <button
          className={`articleButton ${activeButton === 'help' ? 'active' : ''}`}
          onClick={() => handleCategoryClick('help')}
        >
          Glossary
        </button>
      </div>

      <div className='help-content-container'>
        {/* Conditionally render left column for any category except 'HELP' aka Glossary */}
        {selectedCategory !== 'help' && (
          <div className='leftColumn'>
            {/* Left side: List of alphabetized article titles */}
            <ul>
              {sortedArticles.map((article) => (
                <li
                  key={article.id}
                  onClick={() => handleArticleClick(article)}
                  className={selectedArticle === article ? 'selected' : ''}
                >
                  {article.title}
                </li>
              ))}
            </ul>
          </div>
        )}

        <div
          className={`rightColumn ${
            selectedCategory === 'help' ? 'glossary-mode' : ''
          }`}
        >
          {loading && <div>Loading...</div>}
          {error && <div>Error! {error.message}</div>}
          {selectedArticle && (
            <div>
              <h2>{selectedArticle.title}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: selectedArticle.body,
                }}
              />
              <button className='editButton' onClick={handleEditClick}>
                Edit
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
