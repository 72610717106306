import './rule-tabs.scss';

import { FaDollarSign, FaPlaneDeparture, FaUsers } from 'react-icons/fa';

import { MdSegment } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { labels } from 'locales/en.label';

export type Tabs = 'seasons' | 'competitors' | 'rates' | 'autopilot';

interface RuleTabsProps {
  tabsEnabled?: boolean;
  loading?: boolean;
}

export const RuleTabs = ({
  tabsEnabled = true,
  loading = false,
}: RuleTabsProps) => {
  return (
    <div className='rule-tabs'>
      <NavLink to='seasons'>
        <button tabIndex={-1} disabled={loading}>
          <div className='icon'>
            <MdSegment size={24} />
          </div>
          <div className='label'>{labels.rules.seasons}</div>
        </button>
      </NavLink>
      <NavLink to='competitors'>
        <button tabIndex={-1} disabled={loading || !tabsEnabled}>
          <div className='icon'>
            <FaUsers size={24} />
          </div>
          <div className='label'>{labels.rules.competitors}</div>
        </button>
      </NavLink>
      <NavLink to='rates'>
        <button tabIndex={-1} disabled={loading || !tabsEnabled}>
          <div className='icon'>
            <FaDollarSign size={24} />
          </div>
          <div className='label'>{labels.rules.rates}</div>
        </button>
      </NavLink>
      <NavLink to='autopilot'>
        <button tabIndex={-1} disabled={loading || !tabsEnabled}>
          <div className='icon'>
            <FaPlaneDeparture size={24} />
          </div>
          <div className='label'>{labels.rules.autopilot}</div>
        </button>
      </NavLink>
    </div>
  );
};
