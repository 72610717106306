import { AlertMessage } from 'types/EventsTypes';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { today } from 'features/dates/date-helpers';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { labels } from 'locales/en.label';
import { useEvents } from 'features/events/hooks/event-hooks';
import { EventResponse } from 'graphql/gql-types';
import { orderBy, sortBy, trim } from 'lodash';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  initialFormState,
  setCurrentEvent,
  updateEventsFormValues,
} from 'features/events/redux/events-form-slice';
dayjs.extend(isSameOrAfter);

type EventInput = {
  onError: ({ message, messageType }: AlertMessage) => void;
  //onEventCopy: (event: EventResponse) => void;
  onFormReset: () => void;
};
type EventType = 'active' | 'past';
type EventListSortOptionType = {
  label: string;
};

const eventListSortOptions: EventListSortOptionType[] = [
  { label: labels.events.name },
  { label: labels.events.start_date },
];

const sortFields = {
  [labels.events.name]: 'name',
  [labels.events.name]: 'start_date',
};

type SortFilter = {
  sortByIndex: number;
  sortOrder: string;
};

export const formatDataForEventForm = (events: any, evnt: any) => {
  const temp = events?.filter((e: { event_id: any }) => {
    return e.event_id === evnt.event_id;
  });

  const eventDetails = temp && {
    ...temp[0],
  };

  // Creating the result structure to support Event Form
  const result = {
    event_id: eventDetails.event_id,
    name: eventDetails.event.name,
    created_at: eventDetails.event.created_at,
    created_by_id: eventDetails.event.created_by_id,
    updated_at: eventDetails.event.updated_at,
    updated_by_id: eventDetails.event.updated_by_id,
    recurs: eventDetails.event.recurs,
    occ_impact: eventDetails.event.occ_impact,
    category: eventDetails.event.category,
    property_id: eventDetails.event.property_id,
    records: temp?.map((item: any) => ({
      id: item.id,
      start_date: item.start_date,
      end_date: item.end_date,
      event_id: eventDetails.event_id,
    })),
  };
  return result;
};

export const useEventListController = ({
  onError,
  //onEventCopy,
  onFormReset,
}: EventInput) => {
  const [isPastEvent, setIsPastEvent] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const { eventsList, loading } = useEvents();
  const [view, setView] = useState<EventType>('active');
  const [events, setEvents] = useState(eventsList);
  const [sortByOrder, setSortByOrder] = useState<SortFilter>({
    sortByIndex: 1,
    sortOrder: labels.events.ascending,
  });
  const currentEvent = useAppSelector(
    (state) => state?.eventsForm.currentEvent
  );
  const groupedEvents = useMemo(() => {
    const groupedEvents: Record<EventType, EventResponse[]> = {
      active: [],
      past: [],
    };

    if (!events) {
      setSelectedIds([]);
      return groupedEvents;
    }

    let allEvents = [...events];
    const sortField =
      sortFields[eventListSortOptions[sortByOrder.sortByIndex].label];
    allEvents = sortBy(allEvents, [sortField]);
    if (sortByOrder.sortOrder === labels.events.descending)
      allEvents = allEvents.reverse();

    allEvents?.forEach((event) => {
      if (
        dayjs(event.end_date).isSameOrAfter(today()) ||
        event?.end_date === null
      ) {
        groupedEvents.active.push(event!);
      } else {
        groupedEvents.past.push(event!);
      }
    });

    return groupedEvents;
  }, [events, sortByOrder.sortByIndex, sortByOrder.sortOrder]);

  // select event if it's the only one checked
  useEffect(() => {
    if (eventsList) {
      setEvents(eventsList);
    }
    if (currentEvent && currentEvent.length === 1) {
      const checkedEvent = formatDataForEventForm(
        groupedEvents.active,
        currentEvent[0]
      );
      dispatch(updateEventsFormValues({ ...checkedEvent }));
    } else {
      onFormReset();
      dispatch(updateEventsFormValues(initialFormState.formValues));
    }
  }, [
    currentEvent,
    dispatch,
    onFormReset,
    eventsList,
    events,
    groupedEvents.active,
  ]);

  const onToggle = (event: ChangeEvent<HTMLInputElement>) => {
    setIsPastEvent(event.target.checked);
    getFilteredEvents(sortByOrder);
    setSelectedIds([]);
    setView(event.target.checked ? 'past' : 'active');
  };

  /* const onCopy = () => {
    if (currentEvent.length > 1) {
      onError({
        message: labels.events.mul_copy_err,
        messageType: 'error',
      });
      return;
    } else if (currentEvent.length === 0) {
      onError({
        message: labels.events.noselect_copy_err,
        messageType: 'error',
      });
      return;
    }
    if (currentEvent.length !== 0) {
      if (currentEvent) onEventCopy(currentEvent[0]);
    }
  }; */

  const sortEventsByName = (
    data: (EventResponse | undefined)[],
    sortOrder: 'asc' | 'desc'
  ) => {
    return orderBy(
      data,
      (item: any) => trim(item?.event?.name),
      [sortOrder] // This is now correctly typed
    );
  };

  const sortEventsByStartDate = (
    data: (EventResponse | undefined)[],
    sortOrder: string
  ) => {
    data.sort((a, b) =>
      dayjs(a?.start_date).isAfter(dayjs(b?.start_date)) ? 1 : -1
    );
    return sortOrder === labels.events.ascending ? data : data.reverse();
  };

  const getFilteredEvents = (filterQuery: SortFilter) => {
    let copyEvent: (EventResponse | undefined)[] = eventsList
      ? [...events!]
      : [];
    switch (eventListSortOptions[filterQuery.sortByIndex].label) {
      case labels.events.name: {
        copyEvent = filterQuery.sortOrder
          ? sortEventsByName(copyEvent, filterQuery.sortOrder as 'asc' | 'desc')
          : copyEvent;
        break;
      }
      case labels.events.start_date: {
        copyEvent = filterQuery.sortOrder
          ? sortEventsByStartDate(copyEvent, filterQuery.sortOrder)
          : copyEvent;
        break;
      }
    }
    setEvents([...(copyEvent as [EventResponse])]);
  };

  const onSortByOptionChange = (newOption: SortFilter) => {
    getFilteredEvents(newOption);
    setSortByOrder(newOption);
  };

  const isIdSelected = (id: string) => {
    let index = selectedIds.findIndex((currentId) => currentId === id);
    return index;
  };

  const onRowClick = (checked: boolean, evnt: EventResponse) => {
    if (isPastEvent) {
      onError({
        message: labels.events.past_event_edit_err,
        messageType: 'error',
      });
      return;
    } else {
      if (checked) {
        currentEvent && dispatch(setCurrentEvent([...currentEvent!, evnt]));
      } else {
        const index = currentEvent?.findIndex(
          ({ event_id }) => event_id === evnt?.event_id
        );

        if (currentEvent) {
          const test = [
            ...currentEvent.slice(0, index),
            ...currentEvent.slice(index! + 1),
          ];
          dispatch(setCurrentEvent(test));
        }
      }
    }
  };

  return {
    loading,
    isIdSelected,
    sortByOrder,
    selectedIds,
    setSelectedIds,
    currentEvent,
    isPastEvent,
    onToggle,
    //onCopy,
    onRowClick,
    onSortByOptionChange,
    eventListSortOptions,
    events,
    groupedEvents,
    view,
  };
};
