import { Modal } from 'components/Modal/Modal';
import { labels } from 'locales/en.label';

const modalLabels = labels.rec_review.reset_rates_modal;

interface ResetRateModalProps {
  /** Text for the cancel button */
  cancelBtnText?: string;
  /** Text for the confirm button */
  confirmBtnText?: string;
  /** Whether the modal has an icon */
  hasIcon?: boolean;
  /** Text for the header */
  headerText?: string;
  /** Function that hides the modal */
  hide: (event?: React.MouseEvent<HTMLDivElement>) => void;
  /** Whether the modal is a confirmation modal */
  isConfirmationModal?: boolean;
  /** Whether the modal is shown */
  isShown: boolean;
  /** Text for the body */
  messageBody?: string;
  /** Function that runs when the cancel button is clicked */
  onCancel?: (event?: React.MouseEvent<HTMLDivElement>) => void;
  /** Function that runs when the confirm button is clicked */
  onConfirm?: (event?: React.MouseEvent<HTMLDivElement>) => void;
}

export const ResetRateModal = ({
  cancelBtnText,
  confirmBtnText,
  hasIcon = true,
  headerText = modalLabels.header,
  hide,
  isConfirmationModal = true,
  isShown,
  messageBody = modalLabels.message,
  onCancel,
  onConfirm,
}: ResetRateModalProps) => {
  return (
    <Modal
      cancelBtnText={cancelBtnText}
      confirmBtnText={confirmBtnText}
      hasIcon={hasIcon}
      headerText={headerText}
      hide={hide}
      isConfirmationModal={isConfirmationModal}
      isShown={isShown}
      messageBody={messageBody}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};
