import '../../HelpLayout/SuccessModalStyles.scss';

import React from 'react';

interface SubmissionSuccessModalProps {
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const SubmissionSuccessModal: React.FC<SubmissionSuccessModalProps> = ({
  show,
  onClose,
  children,
}) => {
  if (!show) {
    return null;
  }

  return (
    <div className='modal-backdrop'>
      <div className='modal-content'>{children}</div>
    </div>
  );
};

export default SubmissionSuccessModal;
