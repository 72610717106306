import { selectShowDataProcessingMessage } from 'features/my-view/redux/selectors';
import { useAppSelector } from 'redux/hooks';
import { useLocation } from 'react-router-dom';
import { useUser } from 'features/users/context/userContext';

export const DataProcessingMessage = () => {
  const showDataProcessingMessage = useAppSelector(
    selectShowDataProcessingMessage
  );

  // If on Home page and logged in, the header needs to be reduced in width
  const location = useLocation();
  const onHomePage = location.pathname.includes('home');
  const { isAuthenticated } = useUser();
  const altWidth = onHomePage && isAuthenticated;

  const dataProcessingMessageStyle: React.CSSProperties = {
    backgroundColor: '#ffd700', // yellow background
    color: '#000000', // black text color
    padding: '10px',
    marginTop: '10px',
    borderRadius: '5px',
    border: '1px solid #000000', // black border color
  };

  if (showDataProcessingMessage) {
    return (
      <div className={`header ${altWidth ? 'alt-width' : ''}`}>
        {altWidth && <div className='filler-el' />}
        <div className='header__content'>
          <div style={dataProcessingMessageStyle} role='alert'>
            Data processing generally runs from 4am to 6am local hotel time.
            There may be blank data during this time.
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
