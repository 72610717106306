import './season-select.scss';

import { MenuItem, Select } from '@mui/material';
import {
  seasonChanged,
  selectCurrentSeason,
} from 'features/rules/redux/rules-slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useCallback, useEffect, useMemo } from 'react';

import { LoadingBar } from 'components/loading-bar/loading-bar';
import { PropSeason } from 'graphql/gql-types';
import dayjs from 'dayjs';
import { labels } from 'locales/en.label';
import { today } from 'features/dates/date-helpers';
import { useOnPropertyChange } from 'features/rules/seasons/seasons-hooks';
import { useSeasons } from 'features/rules/rules-hooks';

export const SeasonSelect = () => {
  const dispatch = useAppDispatch();
  const selectedSeason = useAppSelector(selectCurrentSeason);
  const { seasons, loading, error } = useSeasons();

  const resetCallback = useCallback(() => {
    dispatch(seasonChanged(null));
  }, [dispatch]);
  useOnPropertyChange(resetCallback);

  const activeSeasons = useMemo(() => {
    const activeSeasons: PropSeason[] = [];

    seasons?.forEach((season) => {
      if (
        dayjs(season?.season_end).isSameOrAfter(today()) ||
        season?.season_name === labels.rules.default_season_name
      ) {
        activeSeasons.push(season);
      }
    });
    return activeSeasons;
  }, [seasons]);

  useEffect(() => {
    // Select the default season if nothing is selected
    if (selectedSeason) return;
    for (let season of activeSeasons || []) {
      if (season?.season_name === labels.rules.default_season_name) {
        dispatch(seasonChanged(season));
        break;
      }
    }
  }, [dispatch, activeSeasons, selectedSeason]);

  const onSeasonChange = (id: string) => {
    const season = activeSeasons?.find(({ season_id }) => season_id === id);
    // isDataModified?
    season && dispatch(seasonChanged(season));
  };

  return (
    <LoadingBar
      className='season-select-container'
      loading={loading}
      overlayStyle={{ borderRadius: '10px' }}
    >
      <Select
        className='season-select'
        value={
          selectedSeason?.season_id ?? (activeSeasons?.length ? '' : 'none')
        }
        onChange={(e) => onSeasonChange(e.target.value)}
      >
        {activeSeasons?.length ? (
          activeSeasons?.map(({ season_id, season_name }) => (
            <MenuItem key={season_id} value={season_id}>
              {season_name}
            </MenuItem>
          ))
        ) : (
          <MenuItem value='none'>{error && 'Unable to load Seasons'}</MenuItem>
        )}
      </Select>
    </LoadingBar>
  );
};
