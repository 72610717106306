import './swipeable-widget.scss';

import {
  RevAlert,
  RevAlerts,
  RevChartData,
} from 'pages/home/hooks/usePriceRecs';

import { Button } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { Chart } from 'components/Chart/Chart';
import { CustomCard } from '../custom-card';
import { IoTriangle } from 'react-icons/io5';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import dayjs from 'dayjs';
import { labels } from 'locales/en.label';
import { buildTooltip, revChartConfig } from './config';
import { usePropertyContext } from 'context/propertyContext';

type CircleButtonProps = {
  nights: string[];
  onClick: (value: string[]) => void;
};
type AlertCardProps = {
  alert: RevAlert;
  onReviewClick: (value: string[]) => void;
};
type RevenueChartProps = { data: RevChartData | null; loading: boolean };
type SwipeableWidgetProps = {
  revAlerts: RevAlerts;
  revChartData: RevChartData | null;
  onReviewClick: (value: string[]) => void;
  loading: boolean;
};

const CircleButton = ({ nights, onClick }: CircleButtonProps) => (
  <Button
    variant='outlined'
    className='circle-btn'
    onClick={() => onClick(nights)}
  >
    <div className='nights-count'>{nights.length}</div>
    <span>{nights.length === 1 ? labels.home.night : labels.home.nights}</span>
  </Button>
);

export const AlertCard = ({
  alert: { type, nights, message },
  onReviewClick,
}: AlertCardProps) => (
  <div className={`alert-card ${type}`}>
    <CustomCard
      className='alert-content'
      style={{ borderRadius: '10px 10px 0 0', borderBottom: 'none' }}
    >
      <CircleButton nights={nights} onClick={onReviewClick} />
      <p className='message'>{message}</p>
    </CustomCard>
    <Button
      variant='contained'
      className='review-btn'
      onClick={() => onReviewClick(nights)}
    >
      {labels.home.review}
    </Button>
  </div>
);

const buildChartOptions = (
  { current, recommended }: RevChartData,
  currencySymbol: string
) => {
  const config = { ...revChartConfig };
  const currentMonth = dayjs().month();
  const months = labels.home.months.slice(currentMonth, currentMonth + 4);
  const additionalMonthsCount = 4 - months.length;
  const additionalMonths = labels.home.months.slice(0, additionalMonthsCount);

  if (additionalMonths.length) months.push(...additionalMonths);

  config.xAxis.categories = months;
  config.series = [
    { name: 'Current', data: current },
    { name: 'Recommended', data: recommended },
  ];
  config.tooltip = buildTooltip(currencySymbol);
  return config;
};

const sumOf = (numbers: number[]) =>
  numbers.reduce((total, val) => total + val, 0);

export const RevenueChart = ({ data, loading }: RevenueChartProps) => {
  const { currencySymbol } = usePropertyContext();
  if (!data)
    return (
      <CustomCard className='chart-content'>
        <LoadingBar loading={loading} />
      </CustomCard>
    );
  const chartOptions = buildChartOptions(data, currencySymbol);
  const currentTotal = sumOf(data.current);
  const recommendedTotal = sumOf(data.recommended);
  const difference = Math.abs(currentTotal - recommendedTotal);
  const increase = currentTotal <= recommendedTotal;

  return (
    <CustomCard className='chart-content'>
      <div className='rev-total-container'>
        {difference !== 0 && (
          <IoTriangle className={`arrow ${increase ? 'up' : 'down'}`} />
        )}
        <div className='rev-total'>
          <span className='amount'>
            {currencySymbol}
            {difference}
          </span>
          <span className='text'>
            {increase ? labels.home.incr : labels.home.decr}{' '}
            {labels.home.revenue}
          </span>
        </div>
      </div>
      <Chart options={chartOptions} />
    </CustomCard>
  );
};

export const SwipeableWidget = ({
  revAlerts,
  revChartData,
  onReviewClick,
  loading,
}: SwipeableWidgetProps) => {
  const carouselItems = [];
  if (revAlerts.warning)
    carouselItems.push(
      <AlertCard
        key='warning'
        alert={revAlerts.warning}
        onReviewClick={onReviewClick}
      />
    );
  if (revAlerts.insight)
    carouselItems.push(
      <AlertCard
        key='insight'
        alert={revAlerts.insight}
        onReviewClick={onReviewClick}
      />
    );
  carouselItems.push(
    <RevenueChart key='chart' data={revChartData} loading={loading} />
  );

  return (
    <Carousel
      autoPlay={false}
      className='carousel'
      height='242px'
      fullHeightHover={false}
      indicatorContainerProps={{ style: { marginTop: '4px' } }}
      indicators={carouselItems.length > 1}
      navButtonsAlwaysInvisible={carouselItems.length === 1}
    >
      {carouselItems}
    </Carousel>
  );
};
