import { MetricsWithLiveData, usePropMetrics } from './use-prop-metrics';

type useMetricsForDateResponse = {
  metrics: MetricsWithLiveData | undefined;
  loading: boolean;
};

export const useMetricsForDate = (date: string): useMetricsForDateResponse => {
  const { data, loading } = usePropMetrics(1, {
    field: 'stay_date',
    value: date,
  });

  return {
    metrics: data?.[0],
    loading,
  };
};
