import './overlapped-seasons.scss';

import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { PropSeason } from 'graphql/gql-types';
import { SeasonPriority } from 'types/SeasonTypes';
import { labels } from 'locales/en.label';

type OverlappedSeasonsProps = {
  overlappedSeasons: PropSeason[];
  onSeasonPriorityChange: (
    id: string,
    event: SelectChangeEvent<number>
  ) => void;
};
export const OverlappedSeasons = ({
  overlappedSeasons,
  onSeasonPriorityChange,
}: OverlappedSeasonsProps) => {
  return (
    <>
      <div className='overlapping-modal'>
        <p className='overlapping-modal__message'>
          {labels.rules.overlap_confirmation_message}
        </p>
        <div className='overlapping-modal__container'>
          <div className='overlapping-modal__emptytext'></div>
          <div className='overlapping-modal__text'>
            <div className='overlapping-modal__text__priority'>
              {labels.rules.priority}
            </div>
            <div className='overlapping-modal__text__container'>
              <div className='overlapping-modal__subheader'>
                {labels.rules.current}
              </div>
              <div className='overlapping-modal__subheader'>
                {labels.rules.new}
              </div>
            </div>
          </div>
        </div>

        {overlappedSeasons.map((season) => {
          return (
            <div
              key={season.season_id}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div className='overlapping-modal__emptytext'>
                {season.season_name}
              </div>
              <FormControl
                sx={{ m: 1, minWidth: 100 }}
                disabled
                className='overlapping-modal__primarytext overlapping-modal__disabledSelect'
              >
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={season.season_priority}
                >
                  {Object.entries(SeasonPriority).map(
                    ([key, value]) =>
                      !isNaN(Number(value)) && (
                        <MenuItem key={key} value={value}>
                          {key}
                        </MenuItem>
                      )
                  )}
                </Select>
              </FormControl>
              <FormControl
                sx={{ m: 1, minWidth: 100 }}
                className='overlapping-modal__primarytext'
              >
                <Select
                  value={season.season_priority}
                  onChange={(e) => onSeasonPriorityChange(season.season_id!, e)}
                  autoWidth
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  {Object.entries(SeasonPriority).map(
                    ([key, value]) =>
                      !isNaN(Number(value)) && (
                        <MenuItem
                          key={key}
                          value={value}
                          className='overlapping-modal__primarytext'
                        >
                          {key}
                        </MenuItem>
                      )
                  )}
                </Select>
              </FormControl>
            </div>
          );
        })}
      </div>
    </>
  );
};
