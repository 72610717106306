/* eslint-disable */
import * as Types from '../../../../graphql/gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetHotelRateOffsetLogsQueryVariables = Types.Exact<{
  propertyId: Types.Scalars['String'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetHotelRateOffsetLogsQuery = { __typename?: 'Query', getHotelRateOffsetLogs?: Array<{ __typename?: 'RateOffsetLog', date: string, updatedAt?: string | undefined, ratePlan?: string | undefined, userId?: string | undefined, rates: Array<{ __typename?: 'Rate', category: string, offsets: { __typename?: 'OffsetRates', Per_1?: string | undefined, Per_2?: string | undefined, Per_3?: string | undefined, Per_4?: string | undefined, Per_5?: string | undefined, Per_6?: string | undefined, Per_7_Plus?: string | undefined } } | undefined> } | undefined> | undefined };


export const GetHotelRateOffsetLogsDocument = gql`
    query GetHotelRateOffsetLogs($propertyId: String!, $limit: Int, $page: Int) {
  getHotelRateOffsetLogs(propertyId: $propertyId, limit: $limit, page: $page) {
    date
    updatedAt
    ratePlan
    userId
    rates {
      category
      offsets {
        Per_1
        Per_2
        Per_3
        Per_4
        Per_5
        Per_6
        Per_7_Plus
      }
    }
  }
}
    `;

/**
 * __useGetHotelRateOffsetLogsQuery__
 *
 * To run a query within a React component, call `useGetHotelRateOffsetLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelRateOffsetLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelRateOffsetLogsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetHotelRateOffsetLogsQuery(baseOptions: Apollo.QueryHookOptions<GetHotelRateOffsetLogsQuery, GetHotelRateOffsetLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHotelRateOffsetLogsQuery, GetHotelRateOffsetLogsQueryVariables>(GetHotelRateOffsetLogsDocument, options);
      }
export function useGetHotelRateOffsetLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHotelRateOffsetLogsQuery, GetHotelRateOffsetLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHotelRateOffsetLogsQuery, GetHotelRateOffsetLogsQueryVariables>(GetHotelRateOffsetLogsDocument, options);
        }
export type GetHotelRateOffsetLogsQueryHookResult = ReturnType<typeof useGetHotelRateOffsetLogsQuery>;
export type GetHotelRateOffsetLogsLazyQueryHookResult = ReturnType<typeof useGetHotelRateOffsetLogsLazyQuery>;
export type GetHotelRateOffsetLogsQueryResult = Apollo.QueryResult<GetHotelRateOffsetLogsQuery, GetHotelRateOffsetLogsQueryVariables>;
export const namedOperations = {
  Query: {
    GetHotelRateOffsetLogs: 'GetHotelRateOffsetLogs'
  }
}