import {
  AddPropCompetitorSetData,
  DeletePropertyCompetitorSetData,
  PropCompRatesData,
  PropCompetitorSetData,
  PropCompetitorsData,
  UpdatePropertyCompetitorData,
} from '../types/prop-competitors-data';
import {
  CREATE_COMP_SEASONS,
  GET_PROPERTY_COMPETITORS,
  GET_PROPERTY_COMPETITOR_SET,
  UPDATE_COMP_SEASONS,
} from '../gql/gql-prop-competitors';
import {
  DELETE_COMP_SEASONS,
  GET_PROP_RATES,
} from './../gql/gql-prop-competitors';
import { useMutation, useQuery } from '@apollo/client';

import { PropCompRates } from 'types/RulesTypes';
import { SearchInfo } from 'types/SearchTypes';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { usePropertyContext } from 'context/propertyContext';

interface UsePropCompetitorsArgs {
  propertyId?: string;
}

export const usePropCompetitors = ({ propertyId }: UsePropCompetitorsArgs) => {
  return useQuery<PropCompetitorsData>(GET_PROPERTY_COMPETITORS, {
    skip: !propertyId,
    variables: { propertyId },
    notifyOnNetworkStatusChange: true,
  });
};

interface UsePropCompetitorSetArgs {
  propertyId?: string;
  seasonId?: String;
}

export const usePropCompetitorSet = ({
  propertyId,
  seasonId,
}: UsePropCompetitorSetArgs) => {
  return useQuery<PropCompetitorSetData>(GET_PROPERTY_COMPETITOR_SET, {
    skip: !propertyId || !seasonId,
    variables: { propertyId, seasonId },
    notifyOnNetworkStatusChange: true,
  });
};

export const useAddPropCompetitorSet = () => {
  return useMutation<AddPropCompetitorSetData>(CREATE_COMP_SEASONS, {
    refetchQueries: ['GetPropertyCompetitorSet'],
  });
};

export const useUpdatePropCompetitorSet = () => {
  return useMutation<UpdatePropertyCompetitorData>(UPDATE_COMP_SEASONS, {
    refetchQueries: ['GetPropertyCompetitorSet'],
  });
};

export const useDeletePropCompetitorSet = () => {
  return useMutation<DeletePropertyCompetitorSetData>(DELETE_COMP_SEASONS, {
    refetchQueries: ['GetPropertyCompetitorSet'],
  });
};

type CompRate = {
  comp_id: number | null;
  comp_name: string;
  comp_rate: number[];
};

type PropCompRateType = {
  stay_dates: string[];
  market_rates: number[];
  current_rates: number[];
  comp_rate: CompRate[];
};

const getFormattedCompRates = (
  propCompRates: PropCompRates[]
): PropCompRateType => {
  let propCompRate: PropCompRateType = {
    stay_dates: [],
    market_rates: [],
    current_rates: [],
    comp_rate: [],
  };
  propCompRates.forEach((pcRate: PropCompRates) => {
    propCompRate.stay_dates.push(dayjs(pcRate.stay_date).format('M/D'));
    propCompRate.market_rates.push(pcRate.market_rate);
    propCompRate.current_rates.push(pcRate.current_rate);
    if (!propCompRate.comp_rate.length) {
      for (let i = 1; i <= 10; i++) {
        if (pcRate[('comp_id_' + i) as keyof typeof pcRate]) {
          const obj: CompRate = {
            comp_id: null,
            comp_name: '',
            comp_rate: [],
          };
          obj.comp_id = +pcRate[('comp_id_' + i) as keyof typeof pcRate];
          obj.comp_name = pcRate[
            ('comp_name_' + i) as keyof typeof pcRate
          ] as string;
          for (let j = 0; j < propCompRates.length; j++) {
            obj.comp_rate.push(
              propCompRates[j][
                ('comp_rate_' + i) as keyof PropCompRates
              ] as number
            );
          }
          propCompRate.comp_rate.push(obj);
        }
      }
    }
  });
  return propCompRate;
};

export const useCompRatesChartData = (input: SearchInfo) => {
  const {
    property: { propertyId },
  } = usePropertyContext();
  const { data, loading, error, refetch } = useQuery<PropCompRatesData>(
    GET_PROP_RATES,
    {
      skip: !propertyId,
      fetchPolicy: 'cache-and-network',
      variables: { propertyId, input },
    }
  );

  const chartData = useMemo(
    () => getFormattedCompRates(data?.PropCompetitorSet.results || []),
    [data?.PropCompetitorSet]
  );

  return {
    chartData,
    compRatesLoading: loading,
    compRatesError: error,
    refetchCompRates: refetch,
  };
};
