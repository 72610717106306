import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../HelpLayout/EditorStyles.scss';

import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import EditSuccessModal from './EditSuccessModal';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { useGetArticleBySlugQuery } from 'features/help/gql/_gen_/help.gql';
import useIsMounted from 'features/help/hooks/use-is-mounted';
import useUpdateArticle from 'features/help/hooks/use-update-article';

interface Article {
  id: string;
  title: string;
  category: string;
  body: string;
  slug: string;
}

const categoryDisplayNames: { [key: string]: string } = {
  general: 'General',
  help: 'Glossary',
};

const ArticleEditor = () => {
  const { slug } = useParams<{ slug: string }>();
  // const { articleId } = useParams<{ articleId: string }>();
  const [fetchedArticle, setFetchedArticle] = useState<Article | null>(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const isMounted = useIsMounted();

  const { data, loading, error } = useGetArticleBySlugQuery({
    variables: { slug },
  });

  const { updateArticle } = useUpdateArticle();

  useEffect(() => {
    if (data && data.getArticleBySlug) {
      const article = data.getArticleBySlug as Article;

      if (isMounted.current) {
        setFetchedArticle(article);
        const contentState = convertContentToEditorState(article.body);
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  }, [data, isMounted]);

  const convertContentToEditorState = (content: string) => {
    const blocksFromHTML = convertFromHTML(content);
    return ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
  };

  const handleEditorChange = (state: EditorState) => {
    if (isMounted.current) {
      setEditorState(state);
    }
  };

  const handleSave = async () => {
    if (fetchedArticle) {
      const body = stateToHTML(editorState.getCurrentContent());

      try {
        const updatedArticle = await updateArticle({
          id: fetchedArticle.id,
          title: fetchedArticle.title,
          body,
          category: fetchedArticle.category,
        });

        if (updatedArticle) {
          setShowModal(true);
        } else {
          console.error('Failed to save article: No data returned');
        }
      } catch (error) {
        console.error('Failed to save article:', error);
      }
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    setFetchedArticle((prev) => (prev ? { ...prev, [id]: value } : null));
  };

  const handleCloseModal = () => {
    setShowModal(false);
    history.push('/help');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!data || !data.getArticleBySlug) return <div>No data</div>;

  return (
    <div className='article-editor-container'>
      <div className='form-row-inline'>
        <div className='input-container'>
          <label htmlFor='title'>Title:</label>
          <input
            type='text'
            id='title'
            value={fetchedArticle?.title || ''}
            onChange={handleInputChange}
            size={100}
          />
        </div>
      </div>
      <div className='form-row-inline'>
        <div className='input-container'>
          <label htmlFor='category'>Category:</label>
          <select
            id='category'
            value={fetchedArticle?.category || ''}
            onChange={(e) =>
              setFetchedArticle((prev) =>
                prev ? { ...prev, title: e.target.value } : null
              )
            }
          >
            {Object.keys(categoryDisplayNames).map((key) => (
              <option key={key} value={key}>
                {categoryDisplayNames[key]}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='editor-wrapper'>
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          wrapperClassName='wrapper-class'
          editorClassName='editor-class'
          toolbarClassName='toolbar-class'
        />
      </div>
      <button className='submit-button' onClick={handleSave}>
        Save
      </button>
      <EditSuccessModal
        show={showModal}
        onClose={handleCloseModal}
        message='Article successfully saved!'
      />
    </div>
  );
};

export default ArticleEditor;
