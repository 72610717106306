import { datalakeIngestion } from 'helpers/datalakeHelper';
import { selectProperty } from '../features/property/redux/selectors';
import { useAppSelector } from 'redux/hooks';
import { useLogTrackingMutation } from 'features/tracking/gql/_gen_/tracking.gql';
import { useUser } from '../features/users/context/userContext';

export const useAppTracking = () => {
  const { user } = useUser();
  const property: { propertyId: string } = useAppSelector(selectProperty);
  const [logTracking] = useLogTrackingMutation();

  interface TrackingInput {
    action: string;
    page?: string;
    propertyId?: string;
    autopilotPriority?: string;
    autopilotEnabled?: boolean;
    autopilotOption?: string;
    seasonName?: string;
    seasonId?: string;
    stayDate?: string;
    value?: string;
  }

  const getUserFields = () => {
    if (!user?.email || !user?.id || !property?.propertyId) return null;
    return {
      oktaId: user?.okta_id,
      userName: user?.name,
      userEmail: user?.email,
      userRole: user?.role,
      userId: user.id,
    };
  };

  const track = (payload: TrackingInput) => {
    const userFields = getUserFields();
    if (userFields) {
      const tracking = {
        ...payload,
        ...userFields,
        propertyId: payload.propertyId || property?.propertyId,
      };

      logTracking({
        variables: { tracking },
      });

      // log tracking to datalake for ingestion
      datalakeIngestion(window, tracking)
        .then()
        .catch((error) => console.log(error));
    }
  };

  return {
    track,
  };
};
