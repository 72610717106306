import { useUpdateArticleMutation } from '../gql/_gen_/help.gql';

interface UpdateArticleInput {
  id: string;
  title: string;
  body: string;
  category: string;
}

interface Article {
  id: string;
  title: string;
  category: string;
  body: string;
  createdAt: string;
  updatedAt: string;
}

const useUpdateArticle = () => {
  const [updateArticleMutation, { data, loading, error }] =
    useUpdateArticleMutation();

  const updateArticle = async (article: UpdateArticleInput) => {
    try {
      const result = await updateArticleMutation({
        variables: {
          data: article,
        },
      });
      if (result.data && result.data.updateArticle) {
        return result.data.updateArticle as Article;
      } else {
        throw new Error('Failed to update article, response data is undefined');
      }
    } catch (error) {
      throw new Error('Failed to update article');
    }
  };
  return { updateArticle, data, loading, error };
};

export default useUpdateArticle;
