/* eslint-disable */
import * as Types from '../../../../graphql/gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetShowPastQueryVariables = Types.Exact<{
  propertyId: Types.Scalars['String'];
  userId: Types.Scalars['String'];
}>;


export type GetShowPastQuery = { __typename?: 'Query', getMyMetrics?: { __typename?: 'MyMetrics', show_past?: boolean | undefined } | undefined };

export type ToggleShowPastMutationVariables = Types.Exact<{
  propertyId: Types.Scalars['String'];
  userId: Types.Scalars['String'];
}>;


export type ToggleShowPastMutation = { __typename?: 'Mutation', toggleShowPast?: { __typename?: 'MyMetrics', show_past?: boolean | undefined } | undefined };


export const GetShowPastDocument = gql`
    query GetShowPast($propertyId: String!, $userId: String!) {
  getMyMetrics(propertyId: $propertyId, userId: $userId) {
    show_past
  }
}
    `;

/**
 * __useGetShowPastQuery__
 *
 * To run a query within a React component, call `useGetShowPastQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowPastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowPastQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetShowPastQuery(baseOptions: Apollo.QueryHookOptions<GetShowPastQuery, GetShowPastQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShowPastQuery, GetShowPastQueryVariables>(GetShowPastDocument, options);
      }
export function useGetShowPastLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShowPastQuery, GetShowPastQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShowPastQuery, GetShowPastQueryVariables>(GetShowPastDocument, options);
        }
export type GetShowPastQueryHookResult = ReturnType<typeof useGetShowPastQuery>;
export type GetShowPastLazyQueryHookResult = ReturnType<typeof useGetShowPastLazyQuery>;
export type GetShowPastQueryResult = Apollo.QueryResult<GetShowPastQuery, GetShowPastQueryVariables>;
export const ToggleShowPastDocument = gql`
    mutation ToggleShowPast($propertyId: String!, $userId: String!) {
  toggleShowPast(propertyId: $propertyId, userId: $userId) {
    show_past
  }
}
    `;
export type ToggleShowPastMutationFn = Apollo.MutationFunction<ToggleShowPastMutation, ToggleShowPastMutationVariables>;

/**
 * __useToggleShowPastMutation__
 *
 * To run a mutation, you first call `useToggleShowPastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleShowPastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleShowPastMutation, { data, loading, error }] = useToggleShowPastMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useToggleShowPastMutation(baseOptions?: Apollo.MutationHookOptions<ToggleShowPastMutation, ToggleShowPastMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleShowPastMutation, ToggleShowPastMutationVariables>(ToggleShowPastDocument, options);
      }
export type ToggleShowPastMutationHookResult = ReturnType<typeof useToggleShowPastMutation>;
export type ToggleShowPastMutationResult = Apollo.MutationResult<ToggleShowPastMutation>;
export type ToggleShowPastMutationOptions = Apollo.BaseMutationOptions<ToggleShowPastMutation, ToggleShowPastMutationVariables>;
export const namedOperations = {
  Query: {
    GetShowPast: 'GetShowPast'
  },
  Mutation: {
    ToggleShowPast: 'ToggleShowPast'
  }
}