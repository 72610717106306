import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface PropertyState {
  propertyId: string;
  propertyName: string;
  currencyCode: string;
}

const initialState: PropertyState = {
  propertyId: '',
  propertyName: '',
  currencyCode: '',
};

export const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    setProperty(state, action: PayloadAction<PropertyState>) {
      state.propertyId = action.payload.propertyId;
      state.propertyName = action.payload.propertyName;
      state.currencyCode = action.payload.currencyCode;
    },
  },
});

export const { setProperty } = propertySlice.actions;

export default propertySlice.reducer;
