import { PropSeason, SearchInfo } from 'graphql/gql-types';
import {
  dataDate,
  getLongDayOfWeek,
  seasonDayRange,
  todaySubtract,
} from 'features/dates/date-helpers';

import { GET_RECREVIEW_DATA } from 'queries/gqlRecReview';
import { SeasonPriority } from 'types/SeasonTypes';
import { SeasonType } from 'types/RecReviewTypes';
import { useGetRecReviewDataQuery } from 'features/my-view/gql/_gen_/rec-review.gql';
import { useLazyQuery } from '@apollo/client';

const EMPTY_SEASON = {
  date: '',
  property_id: '',
  season_id: '',
  season_name: '',
  season_start: null,
  season_end: null,
  selected_days: [],
  highlight_myview: true,
  season_priority: SeasonPriority.Highest,
};
export const useRecReview = (input?: SearchInfo, propertyId?: string) => {
  return useGetRecReviewDataQuery({
    skip: !propertyId || !input?.userId || !input,
    variables: {
      propertyId,
      input: {
        ...input,
        startDate: todaySubtract(1, 'week'),
      },
    },
  });
};

export const useLazyGetAskRevData = () => {
  return useLazyQuery(GET_RECREVIEW_DATA);
};

export const useSelloutRecReview = (
  input?: SearchInfo,
  propertyId?: string
) => {
  return useLazyQuery(GET_RECREVIEW_DATA, {
    variables: {
      propertyId,
      input,
      userId: input?.userId,
    },
  });
};

export const getSeasonFormatData = (seasonData?: PropSeason[]) => {
  const seasonListByDateRange: SeasonType[] = [];
  const results: { [date: string]: SeasonType } = {};

  seasonData &&
    [...seasonData].forEach((season: any) => {
      if (season.highlight_myview) {
        // Utilize seasonDayRange to get the list of dates for the season. This function
        // limits the number of dates to 400. If the season is longer than 400 days, we
        // only want to display the first 400 days starting with 1 week prior to today.
        const dates = seasonDayRange(season.season_start, season.season_end);
        dates.forEach((stay_date) => {
          // Only include the season on the stay date if it applies to the day of week.
          if (
            season.selected_days.length > 0 &&
            season.selected_days.includes(getLongDayOfWeek(stay_date))
          ) {
            let seasonObj: SeasonType = EMPTY_SEASON;
            seasonObj.date = stay_date;
            seasonListByDateRange.push({ ...seasonObj, ...season });
          }
        });
      }
    });

  seasonListByDateRange.forEach((season) => {
    if (season.date in results) {
      if (season.season_priority > results[season.date].season_priority) {
        results[season.date] = {
          ...season,
          season_start: dataDate(season.season_start),
          season_end: dataDate(season.season_end),
        };
      }
    } else {
      results[season.date] = {
        ...season,
        season_start: dataDate(season.season_start),
        season_end: dataDate(season.season_end),
      };
    }
  });
  return results;
};
