import './RecReviewCharts.scss';

import { IconButton, SelectChangeEvent } from '@mui/material';
import { daysOptions, graphOptions, weekOptions } from './config';
import {
  selectRecReviewChartDayTypes,
  selectRecReviewChartDays,
} from 'features/my-view/redux/selectors';
import {
  setRecReviewChartDayTypes,
  setRecReviewChartDays,
} from 'features/my-view/redux/my-view-slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { DemandChart } from './DemandChart';
import { DropdownMenu } from 'components/DropdownMenu/DropdownMenu';
import { FiChevronLeft } from 'react-icons/fi';
import { InventoryChart } from './InventoryChart';
import { RecReviewChartArgs } from '../../../types/RecReviewTypes';
import { labels } from 'locales/en.label';
import { useHistory } from 'react-router-dom';
import { usePropertyContext } from 'context/propertyContext';
import { useState } from 'react';
import { useRecReviewCharts } from 'hooks/useRecReviewCharts';

export const RecReviewCharts = () => {
  const dispatch = useAppDispatch();
  const days = useAppSelector(selectRecReviewChartDays);
  const dayType = useAppSelector(selectRecReviewChartDayTypes) || 'all';
  const [chartView, setChartType] = useState('demand');
  const { property } = usePropertyContext();
  const propertyId = property?.propertyId || '';
  const handleDayChange = (e: SelectChangeEvent) => {
    dispatch(setRecReviewChartDays(e.target.value));
  };
  const handleWeekChange = async (e: SelectChangeEvent) => {
    dispatch(setRecReviewChartDayTypes(e.target.value));
  };
  const handleTestChange = (e: any) => {
    setChartType(e.target.value);
  };
  const history = useHistory();
  const handleClick = () => history.push('/my-view/metrics');

  const { chartData, loading } = useRecReviewCharts(propertyId, dayType, days);

  return (
    <div className='chart__container'>
      <div className='chart__backBtn'>
        <IconButton
          disableFocusRipple
          disableRipple
          aria-label='return'
          onClick={handleClick}
        >
          <FiChevronLeft size={16} className='chart__backBtn-icon' />
          <span className='chart__backBtn-text'>
            {labels.rec_review.chart.return_to_grid}
          </span>
        </IconButton>
      </div>
      <div className='chart__header'>
        <div className='chart__dropdown-graphType'>
          <DropdownMenu
            options={graphOptions}
            preSelectValue={chartView}
            handleChange={handleTestChange}
          />
        </div>
        <div className='chart__dropdown-days'>
          <DropdownMenu
            options={daysOptions}
            preSelectValue={days || ''}
            handleChange={handleDayChange}
          />
        </div>
        <div className='chart__dropdown-weeks'>
          <DropdownMenu
            options={weekOptions}
            preSelectValue={dayType || ''}
            handleChange={handleWeekChange}
          />
        </div>
      </div>
      <div className='chart__content'>
        {chartView === 'demand'
          ? chartData && (
              <DemandChart
                dayType={dayType as RecReviewChartArgs['dayType']}
                chartData={chartData}
                loading={loading}
              />
            )
          : chartData && (
              <InventoryChart
                dayType={dayType as RecReviewChartArgs['dayType']}
                chartData={chartData}
                loading={loading}
              />
            )}
      </div>
    </div>
  );
};
