export const getPercentage = (dividend: number, divisor: number) => {
  if (dividend === 0 || divisor === 0) return 0;
  return Math.round((dividend / divisor) * 100);
};

/** Similar to Number.prototype.toFixed except that the return value is a number instead of a string
 * @example toFixedNumber(1.83551, 2) -> 1.84
 */
export const toFixedNumber = (num: number, fractionDigits: number) => {
  const pow = Math.pow(10, fractionDigits);
  return Math.round(num * pow) / pow;
};
