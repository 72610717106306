import './home-dashboard-controls.scss';

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  addDays,
  dateFormatData,
  dateFormatHomeDisplay,
  formatDate,
} from 'features/dates/date-helpers';
import dayjs, { Dayjs } from 'dayjs';

import CalendarIcon from '@mui/icons-material/Event';
import { DatePicker } from '@mui/x-date-pickers';
import { FaRegCalendarCheck } from 'react-icons/fa';
import { GroupIcon } from '../group-icon';
import { IconButton } from '@mui/material';
import { METRICS_DURATION } from 'pages/home/helpers';
import { View } from '../inventory-rate-level-widget/inventory-rate-level-widget';
import { VirtualElement } from '@popperjs/core';
import styles from 'common/_variables.module.scss';
import { useState } from 'react';

type HomeDashboardControlsProps = {
  selectedDate: string;
  setDate: (date: string) => void;
  setView: (view: View) => void;
  eventId?: string | null;
  groupBlocked?: number;
  groupBooked?: number;
};

export const HomeDashboardControls = ({
  selectedDate,
  setDate,
  setView,
  eventId,
  groupBlocked = 0,
  groupBooked = 0,
}: HomeDashboardControlsProps) => {
  const [isDatePickerOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<VirtualElement | null>(null);

  const isTodaySelected = dayjs().isSame(selectedDate, 'day');
  const isLastDaySelected = addDays(METRICS_DURATION - 1).isSame(
    selectedDate,
    'day'
  );

  const updateDate = (direction: 'prev' | 'next') => {
    const operation = direction === 'prev' ? 'subtract' : 'add';
    setDate(dayjs(selectedDate)[operation](1, 'day').format(dateFormatData));
  };

  return (
    <div className='dashboard-controls'>
      <div className='controls-container'>
        <IconButton
          aria-label='previous'
          className='icon-btn'
          disabled={isTodaySelected}
          onClick={() => updateDate('prev')}
          data-testid='prevButton'
        >
          <MdKeyboardArrowLeft />
        </IconButton>
        <span className='selected-date'>
          {formatDate(selectedDate, dateFormatHomeDisplay)}
        </span>
        <DatePicker
          open={isDatePickerOpen}
          onClose={() => setIsOpen(false)}
          value={selectedDate}
          onChange={(newDate: Dayjs | null) => {
            newDate && setDate(newDate.format(dateFormatData));
          }}
          disablePast
          maxDate={addDays(METRICS_DURATION)}
          PopperProps={{
            placement: 'top',
            anchorEl: anchorEl,
          }}
          renderInput={() => (
            <IconButton
              aria-label='pick a date'
              className='icon-btn'
              disableFocusRipple
              disableRipple
              onClick={(e) => {
                setIsOpen((isOpen) => !isOpen);
                setAnchorEl(e.currentTarget);
              }}
            >
              <CalendarIcon />
            </IconButton>
          )}
        />
        <IconButton
          aria-label='next'
          className='icon-btn'
          disabled={isLastDaySelected}
          onClick={() => updateDate('next')}
          data-testid='nextButton'
        >
          <MdKeyboardArrowRight />
        </IconButton>
        {eventId && (
          <IconButton
            aria-label='event'
            className='icon-btn event-icon'
            onClick={() => console.log('TODO: Add functionality')}
          >
            <FaRegCalendarCheck color={styles.colorOrange} size={18} />
          </IconButton>
        )}
        <GroupIcon
          groupBooked={groupBooked}
          groupBlocked={groupBlocked}
          setView={setView}
        />
      </div>
    </div>
  );
};
