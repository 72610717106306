/* eslint-disable */
import * as Types from '../../../../graphql/gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TrackingByHotelQueryVariables = Types.Exact<{
  propertyId: Types.Scalars['String'];
  startDate?: Types.InputMaybe<Types.Scalars['String']>;
  endDate?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type TrackingByHotelQuery = { __typename?: 'Query', trackingByHotel?: Array<{ __typename?: 'Tracking', id?: string | undefined, action?: string | undefined, autopilotPriority?: string | undefined, autopilotEnabled?: boolean | undefined, autopilotOption?: string | undefined, createdAt?: string | undefined, oktaId?: string | undefined, page?: string | undefined, propertyId?: string | undefined, seasonId?: string | undefined, seasonName?: string | undefined, stayDate?: string | undefined, updatedAt?: string | undefined, userName?: string | undefined, userEmail?: string | undefined, userRole?: string | undefined, userId?: string | undefined, value?: string | undefined } | undefined> | undefined };

export type TrackingByEmailQueryVariables = Types.Exact<{
  userEmail: Types.Scalars['String'];
  startDate?: Types.InputMaybe<Types.Scalars['String']>;
  endDate?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type TrackingByEmailQuery = { __typename?: 'Query', trackingByEmail?: Array<{ __typename?: 'Tracking', id?: string | undefined, action?: string | undefined, autopilotPriority?: string | undefined, autopilotEnabled?: boolean | undefined, autopilotOption?: string | undefined, createdAt?: string | undefined, oktaId?: string | undefined, page?: string | undefined, propertyId?: string | undefined, seasonId?: string | undefined, seasonName?: string | undefined, stayDate?: string | undefined, updatedAt?: string | undefined, userName?: string | undefined, userEmail?: string | undefined, userRole?: string | undefined, userId?: string | undefined, value?: string | undefined } | undefined> | undefined };

export type LogTrackingMutationVariables = Types.Exact<{
  tracking: Types.TrackingInput;
}>;


export type LogTrackingMutation = { __typename?: 'Mutation', logTracking?: { __typename?: 'Tracking', id?: string | undefined, action?: string | undefined, autopilotPriority?: string | undefined, autopilotEnabled?: boolean | undefined, autopilotOption?: string | undefined, createdAt?: string | undefined, oktaId?: string | undefined, page?: string | undefined, propertyId?: string | undefined, seasonId?: string | undefined, seasonName?: string | undefined, stayDate?: string | undefined, updatedAt?: string | undefined, userName?: string | undefined, userEmail?: string | undefined, userRole?: string | undefined, userId?: string | undefined, value?: string | undefined } | undefined };


export const TrackingByHotelDocument = gql`
    query TrackingByHotel($propertyId: String!, $startDate: String, $endDate: String, $limit: Int) {
  trackingByHotel(
    propertyId: $propertyId
    startDate: $startDate
    endDate: $endDate
    limit: $limit
  ) {
    id
    action
    autopilotPriority
    autopilotEnabled
    autopilotOption
    createdAt
    oktaId
    page
    propertyId
    seasonId
    seasonName
    stayDate
    updatedAt
    userName
    userEmail
    userRole
    userId
    value
  }
}
    `;

/**
 * __useTrackingByHotelQuery__
 *
 * To run a query within a React component, call `useTrackingByHotelQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackingByHotelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackingByHotelQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTrackingByHotelQuery(baseOptions: Apollo.QueryHookOptions<TrackingByHotelQuery, TrackingByHotelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrackingByHotelQuery, TrackingByHotelQueryVariables>(TrackingByHotelDocument, options);
      }
export function useTrackingByHotelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrackingByHotelQuery, TrackingByHotelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrackingByHotelQuery, TrackingByHotelQueryVariables>(TrackingByHotelDocument, options);
        }
export type TrackingByHotelQueryHookResult = ReturnType<typeof useTrackingByHotelQuery>;
export type TrackingByHotelLazyQueryHookResult = ReturnType<typeof useTrackingByHotelLazyQuery>;
export type TrackingByHotelQueryResult = Apollo.QueryResult<TrackingByHotelQuery, TrackingByHotelQueryVariables>;
export const TrackingByEmailDocument = gql`
    query TrackingByEmail($userEmail: String!, $startDate: String, $endDate: String, $limit: Int) {
  trackingByEmail(
    userEmail: $userEmail
    startDate: $startDate
    endDate: $endDate
    limit: $limit
  ) {
    id
    action
    autopilotPriority
    autopilotEnabled
    autopilotOption
    createdAt
    oktaId
    page
    propertyId
    seasonId
    seasonName
    stayDate
    updatedAt
    userName
    userEmail
    userRole
    userId
    value
  }
}
    `;

/**
 * __useTrackingByEmailQuery__
 *
 * To run a query within a React component, call `useTrackingByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackingByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackingByEmailQuery({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTrackingByEmailQuery(baseOptions: Apollo.QueryHookOptions<TrackingByEmailQuery, TrackingByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrackingByEmailQuery, TrackingByEmailQueryVariables>(TrackingByEmailDocument, options);
      }
export function useTrackingByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrackingByEmailQuery, TrackingByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrackingByEmailQuery, TrackingByEmailQueryVariables>(TrackingByEmailDocument, options);
        }
export type TrackingByEmailQueryHookResult = ReturnType<typeof useTrackingByEmailQuery>;
export type TrackingByEmailLazyQueryHookResult = ReturnType<typeof useTrackingByEmailLazyQuery>;
export type TrackingByEmailQueryResult = Apollo.QueryResult<TrackingByEmailQuery, TrackingByEmailQueryVariables>;
export const LogTrackingDocument = gql`
    mutation LogTracking($tracking: TrackingInput!) {
  logTracking(tracking: $tracking) {
    id
    action
    autopilotPriority
    autopilotEnabled
    autopilotOption
    createdAt
    oktaId
    page
    propertyId
    seasonId
    seasonName
    stayDate
    updatedAt
    userName
    userEmail
    userRole
    userId
    value
  }
}
    `;
export type LogTrackingMutationFn = Apollo.MutationFunction<LogTrackingMutation, LogTrackingMutationVariables>;

/**
 * __useLogTrackingMutation__
 *
 * To run a mutation, you first call `useLogTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logTrackingMutation, { data, loading, error }] = useLogTrackingMutation({
 *   variables: {
 *      tracking: // value for 'tracking'
 *   },
 * });
 */
export function useLogTrackingMutation(baseOptions?: Apollo.MutationHookOptions<LogTrackingMutation, LogTrackingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogTrackingMutation, LogTrackingMutationVariables>(LogTrackingDocument, options);
      }
export type LogTrackingMutationHookResult = ReturnType<typeof useLogTrackingMutation>;
export type LogTrackingMutationResult = Apollo.MutationResult<LogTrackingMutation>;
export type LogTrackingMutationOptions = Apollo.BaseMutationOptions<LogTrackingMutation, LogTrackingMutationVariables>;
export const namedOperations = {
  Query: {
    TrackingByHotel: 'TrackingByHotel',
    TrackingByEmail: 'TrackingByEmail'
  },
  Mutation: {
    LogTracking: 'LogTracking'
  }
}