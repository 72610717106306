import '../../HelpLayout/ArticleFormStyles.scss';

import * as Yup from 'yup';

import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';

import SubmissionSuccessModal from './SubmissionSuccessModal';
import TextEditor from './TextEditor';
import slugify from 'slugify';
import useCreateArticle from 'features/help/hooks/use-create-article';
import { useHistory } from 'react-router-dom';

function replaceYouTubeLinks(body: string): string {
  const youtubeRegex =
    /(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)/g;
  return body.replace(youtubeRegex, (_, videoId) => {
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return `<iframe width="560" height="315" src="${embedUrl}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
  });
}

const ArticleSchema = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .min(3, 'Title must be at least 3 characters'),
  category: Yup.string().required('Category is required'),
  body: Yup.string()
    .required('Article body cannot be empty')
    .min(10, 'Article body must be at least 10 characters'),
});
interface FormValues {
  title: string;
  body: string;
  category: string;
}

const ArticleForm: React.FC = () => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const initialValues: FormValues = { title: '', category: '', body: '' };
  const { createArticle, loading, error } = useCreateArticle();

  const history = useHistory();

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);
    try {
      const embeddedBody = replaceYouTubeLinks(values.body);
      const slug = slugify(values.title, { lower: true, strict: true });
      await createArticle(values.title, embeddedBody, values.category, slug);

      setShowSuccessModal(true);
      setTimeout(() => {
        history.push('/help');
      }, 2000);
    } catch (error) {
      console.error('Failed to create article', error);
      setShowSuccessModal(false);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <SubmissionSuccessModal
        show={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
      >
        Article submitted successfully!
      </SubmissionSuccessModal>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={ArticleSchema}
      >
        {(formikProps) => (
          <Form>
            <div className='form-row-inline'>
              <label htmlFor='title'>Title:</label>
              <Field
                name='title'
                placeholder='Enter the article title'
                className='form-control'
              />
              {formikProps.touched.title && formikProps.errors.title && (
                <div className='error'>{formikProps.errors.title}</div>
              )}

              <div className='form-row-inline'>
                <label htmlFor='category'>Category</label>
                <Field name='category' as='select' className='form-control'>
                  <option value=''>Select a category...</option>
                  <option value='general'>General</option>
                  {/* <option value='help'>Glossary</option> Leave disabled for now */}
                  {/* <option value='new'>Add New Category...</option> Leave disabled for now */}
                </Field>
                {formikProps.touched.category &&
                  formikProps.errors.category && (
                    <div className='error'>{formikProps.errors.category}</div>
                  )}
              </div>
            </div>
            <div className='form-group'>
              <label htmlFor='body'>Body:</label>
              <TextEditor setFieldValue={formikProps.setFieldValue} />
              {formikProps.touched.body && formikProps.errors.body && (
                <div className='error'>{formikProps.errors.body}</div>
              )}
            </div>
            <button
              type='submit'
              className='submit-button'
              disabled={formikProps.isSubmitting || loading}
            >
              Submit Article
            </button>
            {error && <p> Error submitting form. Please try again.</p>}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ArticleForm;
