import { GET_LIVE_DATA_CHART } from '../gql/gql-live-data-chart';
import { useQuery } from '@apollo/client';

type LiveDataArgs = {
  propertyId?: string;
};

export const useLiveDataChart = ({ propertyId }: LiveDataArgs) => {
  return useQuery(GET_LIVE_DATA_CHART, {
    skip: !propertyId,
    variables: {
      propertyId,
    },
  });
};
