import './rates-info.scss';

// import { BsCardText } from 'react-icons/bs';
// import { HiChartBarSquare } from 'react-icons/hi2';
// import { IconButton } from '@mui/material';
import { RatesChart } from './rates-chart';
import { useState } from 'react';

export const RatesInfo = () => {
  // const [showGraph, setShowGraph] = useState(false);
  const [showGraph] = useState(false);

  return (
    <div className='rates-info'>
      {/* Hidden from display until fully implemented per B20V-2305: */}
      {/* <IconButton
        className='toggle-btn'
        onClick={() => setShowGraph((prev) => !prev)}
        disableFocusRipple
        disableRipple
      >
        {!showGraph ? (
          <HiChartBarSquare
            size={22}
            className='graph-icon'
            title='Graph View'
          />
        ) : (
          <BsCardText className='text-icon' title='Text View' />
        )}
      </IconButton> */}
      {showGraph ? (
        <RatesChart />
      ) : (
        <div className='info'>
          {/* Hidden from display until fully implemented per B20V-2305: */}
          {/* <div>
            <h3 className='header'>Pricing Strategy</h3>
            <p className='text'>
              A pricing strategy that the hotelier wishes to apply for the
              season
            </p>
          </div> */}
          <div>
            <h3 className='header'>Rate Boundary</h3>
            <p className='text'>
              Minimum and maximum rate boundaries for rate recommendation for
              the season
            </p>
          </div>
          <div>
            <h3 className='header'>Derived Rate</h3>
            <p className='text'>
              Increment/decrement currency value for the secondary room type
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
