import { IconButton, Tooltip } from '@mui/material';

import { MdOutlineGroups } from 'react-icons/md';
import { View } from './inventory-rate-level-widget/inventory-rate-level-widget';
import styles from 'common/_variables.module.scss';

type GroupIconProps = {
  groupBooked: number;
  groupBlocked: number;
  setView: (view: View) => void;
};

const makeTooltip = (count: number, type: 'booked' | 'blocked') =>
  `${count} group room${count > 1 ? 's' : ''} ${type}`;

export const GroupIcon = ({
  groupBooked,
  groupBlocked,
  setView,
}: GroupIconProps) => {
  if (!groupBooked && !groupBlocked) return null;
  return (
    <Tooltip
      title={makeTooltip(
        groupBooked || groupBlocked,
        groupBooked ? 'booked' : 'blocked'
      )}
      arrow
      placement='top'
    >
      <IconButton
        aria-label='groups'
        className='icon-btn group-icon'
        disableRipple
        onClick={() => setView('inventory')}
        data-testid='groupIcon'
      >
        <MdOutlineGroups
          size={30}
          color={groupBooked ? styles.colorGreen : styles.colorGrey60}
        />
      </IconButton>
    </Tooltip>
  );
};
