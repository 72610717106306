/* eslint-disable */
import * as Types from '../../../../graphql/gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSupportMessageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSupportMessageQuery = { __typename?: 'Query', getSupportMessage?: { __typename?: 'ControlPanel', support_message?: string | undefined } | undefined };

export type GetAutopilotStatusQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAutopilotStatusQuery = { __typename?: 'Query', getAutopilotStatus?: { __typename?: 'ControlPanel', allow_autopilot?: boolean | undefined } | undefined };

export type GetControlPanelQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetControlPanelQuery = { __typename?: 'Query', getControlPanel?: { __typename?: 'ControlPanel', allow_autopilot?: boolean | undefined, support_message?: string | undefined } | undefined };

export type ToggleAutopilotMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ToggleAutopilotMutation = { __typename?: 'Mutation', toggleAutopilot?: { __typename?: 'ControlPanel', allow_autopilot?: boolean | undefined } | undefined };

export type SetSupportMessageMutationVariables = Types.Exact<{
  message?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SetSupportMessageMutation = { __typename?: 'Mutation', setSupportMessage?: { __typename?: 'ControlPanel', support_message?: string | undefined } | undefined };


export const GetSupportMessageDocument = gql`
    query GetSupportMessage {
  getSupportMessage {
    support_message
  }
}
    `;

/**
 * __useGetSupportMessageQuery__
 *
 * To run a query within a React component, call `useGetSupportMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportMessageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSupportMessageQuery(baseOptions?: Apollo.QueryHookOptions<GetSupportMessageQuery, GetSupportMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupportMessageQuery, GetSupportMessageQueryVariables>(GetSupportMessageDocument, options);
      }
export function useGetSupportMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupportMessageQuery, GetSupportMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupportMessageQuery, GetSupportMessageQueryVariables>(GetSupportMessageDocument, options);
        }
export type GetSupportMessageQueryHookResult = ReturnType<typeof useGetSupportMessageQuery>;
export type GetSupportMessageLazyQueryHookResult = ReturnType<typeof useGetSupportMessageLazyQuery>;
export type GetSupportMessageQueryResult = Apollo.QueryResult<GetSupportMessageQuery, GetSupportMessageQueryVariables>;
export const GetAutopilotStatusDocument = gql`
    query GetAutopilotStatus {
  getAutopilotStatus {
    allow_autopilot
  }
}
    `;

/**
 * __useGetAutopilotStatusQuery__
 *
 * To run a query within a React component, call `useGetAutopilotStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutopilotStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutopilotStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAutopilotStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetAutopilotStatusQuery, GetAutopilotStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutopilotStatusQuery, GetAutopilotStatusQueryVariables>(GetAutopilotStatusDocument, options);
      }
export function useGetAutopilotStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutopilotStatusQuery, GetAutopilotStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutopilotStatusQuery, GetAutopilotStatusQueryVariables>(GetAutopilotStatusDocument, options);
        }
export type GetAutopilotStatusQueryHookResult = ReturnType<typeof useGetAutopilotStatusQuery>;
export type GetAutopilotStatusLazyQueryHookResult = ReturnType<typeof useGetAutopilotStatusLazyQuery>;
export type GetAutopilotStatusQueryResult = Apollo.QueryResult<GetAutopilotStatusQuery, GetAutopilotStatusQueryVariables>;
export const GetControlPanelDocument = gql`
    query GetControlPanel {
  getControlPanel {
    allow_autopilot
    support_message
  }
}
    `;

/**
 * __useGetControlPanelQuery__
 *
 * To run a query within a React component, call `useGetControlPanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetControlPanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetControlPanelQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetControlPanelQuery(baseOptions?: Apollo.QueryHookOptions<GetControlPanelQuery, GetControlPanelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetControlPanelQuery, GetControlPanelQueryVariables>(GetControlPanelDocument, options);
      }
export function useGetControlPanelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetControlPanelQuery, GetControlPanelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetControlPanelQuery, GetControlPanelQueryVariables>(GetControlPanelDocument, options);
        }
export type GetControlPanelQueryHookResult = ReturnType<typeof useGetControlPanelQuery>;
export type GetControlPanelLazyQueryHookResult = ReturnType<typeof useGetControlPanelLazyQuery>;
export type GetControlPanelQueryResult = Apollo.QueryResult<GetControlPanelQuery, GetControlPanelQueryVariables>;
export const ToggleAutopilotDocument = gql`
    mutation ToggleAutopilot {
  toggleAutopilot {
    allow_autopilot
  }
}
    `;
export type ToggleAutopilotMutationFn = Apollo.MutationFunction<ToggleAutopilotMutation, ToggleAutopilotMutationVariables>;

/**
 * __useToggleAutopilotMutation__
 *
 * To run a mutation, you first call `useToggleAutopilotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleAutopilotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleAutopilotMutation, { data, loading, error }] = useToggleAutopilotMutation({
 *   variables: {
 *   },
 * });
 */
export function useToggleAutopilotMutation(baseOptions?: Apollo.MutationHookOptions<ToggleAutopilotMutation, ToggleAutopilotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleAutopilotMutation, ToggleAutopilotMutationVariables>(ToggleAutopilotDocument, options);
      }
export type ToggleAutopilotMutationHookResult = ReturnType<typeof useToggleAutopilotMutation>;
export type ToggleAutopilotMutationResult = Apollo.MutationResult<ToggleAutopilotMutation>;
export type ToggleAutopilotMutationOptions = Apollo.BaseMutationOptions<ToggleAutopilotMutation, ToggleAutopilotMutationVariables>;
export const SetSupportMessageDocument = gql`
    mutation SetSupportMessage($message: String) {
  setSupportMessage(message: $message) {
    support_message
  }
}
    `;
export type SetSupportMessageMutationFn = Apollo.MutationFunction<SetSupportMessageMutation, SetSupportMessageMutationVariables>;

/**
 * __useSetSupportMessageMutation__
 *
 * To run a mutation, you first call `useSetSupportMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSupportMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSupportMessageMutation, { data, loading, error }] = useSetSupportMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSetSupportMessageMutation(baseOptions?: Apollo.MutationHookOptions<SetSupportMessageMutation, SetSupportMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSupportMessageMutation, SetSupportMessageMutationVariables>(SetSupportMessageDocument, options);
      }
export type SetSupportMessageMutationHookResult = ReturnType<typeof useSetSupportMessageMutation>;
export type SetSupportMessageMutationResult = Apollo.MutationResult<SetSupportMessageMutation>;
export type SetSupportMessageMutationOptions = Apollo.BaseMutationOptions<SetSupportMessageMutation, SetSupportMessageMutationVariables>;
export const namedOperations = {
  Query: {
    GetSupportMessage: 'GetSupportMessage',
    GetAutopilotStatus: 'GetAutopilotStatus',
    GetControlPanel: 'GetControlPanel'
  },
  Mutation: {
    ToggleAutopilot: 'ToggleAutopilot',
    SetSupportMessage: 'SetSupportMessage'
  }
}