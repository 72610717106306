import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { DashboardWrapper } from './DashboardWrapper';
import { ExportProvider } from 'context/exportContext';

export const MyView = () => (
  <ExportProvider>
    <DashboardWrapper />
  </ExportProvider>
);
