import { useGetHotelRateChangeLogsQuery } from '../gql/_gen_/rate-change-log.gql';
import { usePropertyContext } from 'context/propertyContext';

export const useRateChangeLogs = () => {
  const FETCH_LIMIT = 300;

  const { property } = usePropertyContext();
  const { data, loading } = useGetHotelRateChangeLogsQuery({
    skip: !property?.propertyId,
    variables: {
      propertyId: property?.propertyId,
      limit: FETCH_LIMIT,
    },
  });

  return {
    data,
    loading,
    propertyId: property?.propertyId,
  };
};
