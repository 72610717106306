import { ExportDataType, ExportHeaderColumns } from 'types/ExportColumns';
import { createContext, useContext, useState } from 'react';

type ExportHeader = Array<ExportHeaderColumns>;
type ExportContextProps = {
  exportData: ExportDataType;
  exportHeader: ExportHeader;
  setExportData?: (data: ExportDataType) => void;
  setExportHeader?: (header: ExportHeader) => void;
};

interface ExportProviderProps {
  children: React.ReactNode;
}

const ExportContext = createContext<ExportContextProps | undefined>(undefined);
const ExportProvider = ({ children }: ExportProviderProps) => {
  const [exportData, setExportData] = useState<ExportDataType>([]);
  const [exportHeader, setExportHeader] = useState<ExportHeader>([]);

  const value = {
    exportData,
    exportHeader,
    setExportData,
    setExportHeader,
  };
  return (
    <ExportContext.Provider value={value}>{children}</ExportContext.Provider>
  );
};

const useExportContext = () => {
  const context = useContext(ExportContext);
  if (context === undefined) {
    throw new Error(`useExport must be used within a ExportProvider`);
  }
  return context;
};

export { ExportProvider, useExportContext };
