import { FavClickParams } from './useDashboardController';
import { GET_FAV_NIGHTS } from 'queries/gqlRecReview';
import { ICellRendererParams } from 'ag-grid-community';
import { IoStar } from 'react-icons/io5';
import { PropUserFavNight } from 'graphql/gql-types';
import styles from 'common/_variables.module.scss';
import { useApolloError } from 'hooks/useApolloError';
import { useFavStayDate } from 'hooks/useFavStayDate';
import { usePropertyContext } from 'context/propertyContext';
import { useUser } from 'features/users/context/userContext';

type RenderFavStayDateProps = {
  params: ICellRendererParams & FavClickParams;
};

export const FavStayDate = ({ params }: RenderFavStayDateProps) => {
  const [favStayDate] = useFavStayDate();
  const { value = false, setValue } = params;
  const { user, isReadOnly } = useUser();
  const { property } = usePropertyContext();
  const { handleApolloError } = useApolloError();

  const iconColor = params.value ? styles.colorOrange : styles.colorNeutral;

  return (
    <span
      ref={(ref: HTMLSpanElement) => {
        if (!ref) return;
        ref.onclick = (event: MouseEvent) => {
          // We have to intercept the click and stop the propagation
          // from the span ref in order to prevent the AgGrid from
          // selecting the row.
          event.stopPropagation();
          if (isReadOnly) return;
          setValue?.(!value);
          favStayDate({
            variables: {
              stayDate: params.data.stay_date,
            },
            onError: (error) => {
              handleApolloError(error);
              if (setValue) {
                setValue(value);
              }
            },
            update: (cache, { data }) => {
              const cachedData = cache.readQuery<{
                listUserFavNights: PropUserFavNight[];
              }>({
                query: GET_FAV_NIGHTS,
                variables: {
                  propertyId: property?.propertyId,
                  userId: user?.id,
                },
              });

              const existingFavNights = cachedData?.listUserFavNights;

              const toggledNight = data.favoriteStayNight;

              if (
                existingFavNights &&
                !existingFavNights.find(
                  (night) => night.stay_date === toggledNight.stay_date
                )
              ) {
                cache.writeQuery({
                  query: GET_FAV_NIGHTS,
                  variables: {
                    propertyId: property?.propertyId,
                    userId: user?.id,
                  },
                  data: {
                    listUserFavNights: [...existingFavNights, toggledNight],
                  },
                });
              }
            },
          });
        };
      }}
      style={{ cursor: 'pointer' }}
    >
      <IoStar color={iconColor} size={20} />
    </span>
  );
};
