/* eslint-disable */
import * as Types from '../../../../graphql/gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSupportArticlesByCategoryQueryVariables = Types.Exact<{
  category: Types.Scalars['String'];
}>;


export type GetSupportArticlesByCategoryQuery = { __typename?: 'Query', getCategoryArticles?: Array<{ __typename?: 'SupportArticle', id?: string | undefined, title?: string | undefined, category: string, body?: string | undefined, createdAt?: string | undefined, updatedAt?: string | undefined, slug: string } | undefined> | undefined };

export type CreateArticleMutationVariables = Types.Exact<{
  data: Types.CreateArticleInput;
}>;


export type CreateArticleMutation = { __typename?: 'Mutation', createArticle?: { __typename?: 'SupportArticle', id?: string | undefined, title?: string | undefined, category: string, body?: string | undefined, createdAt?: string | undefined, updatedAt?: string | undefined, slug: string } | undefined };

export type UpdateArticleMutationVariables = Types.Exact<{
  data: Types.UpdateArticleInput;
}>;


export type UpdateArticleMutation = { __typename?: 'Mutation', updateArticle?: { __typename?: 'SupportArticle', id?: string | undefined, title?: string | undefined, category: string, body?: string | undefined, createdAt?: string | undefined, updatedAt?: string | undefined, slug: string } | undefined };

export type GetArticleByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetArticleByIdQuery = { __typename?: 'Query', getArticleById?: { __typename?: 'SupportArticle', id?: string | undefined, title?: string | undefined, category: string, body?: string | undefined, createdAt?: string | undefined, updatedAt?: string | undefined, slug: string } | undefined };

export type GetArticleBySlugQueryVariables = Types.Exact<{
  slug: Types.Scalars['String'];
}>;


export type GetArticleBySlugQuery = { __typename?: 'Query', getArticleBySlug?: { __typename?: 'SupportArticle', id?: string | undefined, title?: string | undefined, category: string, body?: string | undefined, createdAt?: string | undefined, updatedAt?: string | undefined, slug: string } | undefined };


export const GetSupportArticlesByCategoryDocument = gql`
    query GetSupportArticlesByCategory($category: String!) {
  getCategoryArticles(category: $category) {
    id
    title
    category
    body
    createdAt
    updatedAt
    slug
  }
}
    `;

/**
 * __useGetSupportArticlesByCategoryQuery__
 *
 * To run a query within a React component, call `useGetSupportArticlesByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportArticlesByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportArticlesByCategoryQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetSupportArticlesByCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetSupportArticlesByCategoryQuery, GetSupportArticlesByCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupportArticlesByCategoryQuery, GetSupportArticlesByCategoryQueryVariables>(GetSupportArticlesByCategoryDocument, options);
      }
export function useGetSupportArticlesByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupportArticlesByCategoryQuery, GetSupportArticlesByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupportArticlesByCategoryQuery, GetSupportArticlesByCategoryQueryVariables>(GetSupportArticlesByCategoryDocument, options);
        }
export type GetSupportArticlesByCategoryQueryHookResult = ReturnType<typeof useGetSupportArticlesByCategoryQuery>;
export type GetSupportArticlesByCategoryLazyQueryHookResult = ReturnType<typeof useGetSupportArticlesByCategoryLazyQuery>;
export type GetSupportArticlesByCategoryQueryResult = Apollo.QueryResult<GetSupportArticlesByCategoryQuery, GetSupportArticlesByCategoryQueryVariables>;
export const CreateArticleDocument = gql`
    mutation CreateArticle($data: CreateArticleInput!) {
  createArticle(data: $data) {
    id
    title
    category
    body
    createdAt
    updatedAt
    slug
  }
}
    `;
export type CreateArticleMutationFn = Apollo.MutationFunction<CreateArticleMutation, CreateArticleMutationVariables>;

/**
 * __useCreateArticleMutation__
 *
 * To run a mutation, you first call `useCreateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleMutation, { data, loading, error }] = useCreateArticleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateArticleMutation(baseOptions?: Apollo.MutationHookOptions<CreateArticleMutation, CreateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateArticleMutation, CreateArticleMutationVariables>(CreateArticleDocument, options);
      }
export type CreateArticleMutationHookResult = ReturnType<typeof useCreateArticleMutation>;
export type CreateArticleMutationResult = Apollo.MutationResult<CreateArticleMutation>;
export type CreateArticleMutationOptions = Apollo.BaseMutationOptions<CreateArticleMutation, CreateArticleMutationVariables>;
export const UpdateArticleDocument = gql`
    mutation UpdateArticle($data: UpdateArticleInput!) {
  updateArticle(data: $data) {
    id
    title
    category
    body
    createdAt
    updatedAt
    slug
  }
}
    `;
export type UpdateArticleMutationFn = Apollo.MutationFunction<UpdateArticleMutation, UpdateArticleMutationVariables>;

/**
 * __useUpdateArticleMutation__
 *
 * To run a mutation, you first call `useUpdateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleMutation, { data, loading, error }] = useUpdateArticleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateArticleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateArticleMutation, UpdateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateArticleMutation, UpdateArticleMutationVariables>(UpdateArticleDocument, options);
      }
export type UpdateArticleMutationHookResult = ReturnType<typeof useUpdateArticleMutation>;
export type UpdateArticleMutationResult = Apollo.MutationResult<UpdateArticleMutation>;
export type UpdateArticleMutationOptions = Apollo.BaseMutationOptions<UpdateArticleMutation, UpdateArticleMutationVariables>;
export const GetArticleByIdDocument = gql`
    query GetArticleById($id: String!) {
  getArticleById(id: $id) {
    id
    title
    category
    body
    createdAt
    updatedAt
    slug
  }
}
    `;

/**
 * __useGetArticleByIdQuery__
 *
 * To run a query within a React component, call `useGetArticleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleByIdQuery(baseOptions: Apollo.QueryHookOptions<GetArticleByIdQuery, GetArticleByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArticleByIdQuery, GetArticleByIdQueryVariables>(GetArticleByIdDocument, options);
      }
export function useGetArticleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticleByIdQuery, GetArticleByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArticleByIdQuery, GetArticleByIdQueryVariables>(GetArticleByIdDocument, options);
        }
export type GetArticleByIdQueryHookResult = ReturnType<typeof useGetArticleByIdQuery>;
export type GetArticleByIdLazyQueryHookResult = ReturnType<typeof useGetArticleByIdLazyQuery>;
export type GetArticleByIdQueryResult = Apollo.QueryResult<GetArticleByIdQuery, GetArticleByIdQueryVariables>;
export const GetArticleBySlugDocument = gql`
    query GetArticleBySlug($slug: String!) {
  getArticleBySlug(slug: $slug) {
    id
    title
    category
    body
    createdAt
    updatedAt
    slug
  }
}
    `;

/**
 * __useGetArticleBySlugQuery__
 *
 * To run a query within a React component, call `useGetArticleBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetArticleBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetArticleBySlugQuery, GetArticleBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArticleBySlugQuery, GetArticleBySlugQueryVariables>(GetArticleBySlugDocument, options);
      }
export function useGetArticleBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticleBySlugQuery, GetArticleBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArticleBySlugQuery, GetArticleBySlugQueryVariables>(GetArticleBySlugDocument, options);
        }
export type GetArticleBySlugQueryHookResult = ReturnType<typeof useGetArticleBySlugQuery>;
export type GetArticleBySlugLazyQueryHookResult = ReturnType<typeof useGetArticleBySlugLazyQuery>;
export type GetArticleBySlugQueryResult = Apollo.QueryResult<GetArticleBySlugQuery, GetArticleBySlugQueryVariables>;
export const namedOperations = {
  Query: {
    GetSupportArticlesByCategory: 'GetSupportArticlesByCategory',
    GetArticleById: 'GetArticleById',
    GetArticleBySlug: 'GetArticleBySlug'
  },
  Mutation: {
    CreateArticle: 'CreateArticle',
    UpdateArticle: 'UpdateArticle'
  }
}