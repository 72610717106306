import './season-list.scss';

import { MdContentCopy, MdOutlineDelete } from 'react-icons/md';
import { SeasonFormValues, defaultValues } from './seasons';
import { formatFormDate, today } from 'features/dates/date-helpers';
import {
  seasonChanged,
  selectCurrentSeason,
} from 'features/rules/redux/rules-slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect, useMemo, useState } from 'react';

import { BiSort } from 'react-icons/bi';
import { FaPlus } from 'react-icons/fa';
import { IconButton } from '@mui/material';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { MenuButton } from 'components/MenuButton/MenuButton';
import { Modal } from 'components/Modal/Modal';
import { PropSeason } from 'graphql/gql-types';
import { SeasonPriority } from 'types/SeasonTypes';
import { SelectedDays } from 'types/SearchTypes';
import { ToggleSwitch } from 'components/toggle-switch/toggle-switch';
import _ from 'lodash';
import { alertAdded } from 'features/alerts/redux/alerts-slice';
import dayjs from 'dayjs';
import { labels } from 'locales/en.label';
import useApolloErrorAlerts from 'features/alerts/hooks/use-apollo-error-alerts';
import { useDeleteSeasons } from 'features/rules/seasons/seasons-hooks';
import { useFormikContext } from 'formik';
import { useModal } from 'components/Modal/useModal';
import { usePropertyContext } from 'context/propertyContext';
import { useSeasons } from 'features/rules/rules-hooks';
import { useUser } from 'features/users/context/userContext';

type SeasonType = 'active' | 'past';

const sortOptions = [
  { label: labels.season_list.name },
  { label: labels.season_list.start_date },
  { label: labels.common.priority },
];

const sortFields = {
  [labels.season_list.name]: 'season_name',
  [labels.season_list.start_date]: 'season_start',
  [labels.common.priority]: 'season_priority',
};

interface SeasonListProps {
  setTabsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SeasonList = ({ setTabsEnabled }: SeasonListProps) => {
  const { isReadOnly } = useUser();
  const dispatch = useAppDispatch();
  const {
    property: { propertyId },
  } = usePropertyContext();
  const selectedSeason = useAppSelector(selectCurrentSeason);
  const { isShown, toggle } = useModal();
  const { seasons, loading, error } = useSeasons();
  useApolloErrorAlerts([error]);
  const { deleteSeasons, deleteSeasonsLoading } = useDeleteSeasons();
  const { touched, resetForm } = useFormikContext<SeasonFormValues>();

  const [checkedSeasons, setCheckedSeasons] = useState<PropSeason[]>(
    selectedSeason ? [selectedSeason] : []
  );
  const [view, setView] = useState<SeasonType>('active');
  const [sortBy, setSortBy] = useState({
    sortByIndex: 1,
    sortOrder: labels.season_list.ascending,
  });
  const [defaultChecked, setDefaultChecked] = useState(false);
  const groupedSeasons = useMemo(() => {
    const groupedSeasons: Record<SeasonType, PropSeason[]> = {
      active: [],
      past: [],
    };

    // Reset when data reloads to avoid out-of-sync state
    if (!seasons) {
      setCheckedSeasons([]);
      return groupedSeasons;
    }

    let allSeasons = [...seasons];
    const sortField = sortFields[sortOptions[sortBy.sortByIndex].label];
    allSeasons = _.sortBy(allSeasons, [sortField]);
    if (sortBy.sortOrder === labels.season_list.descending)
      allSeasons = allSeasons.reverse();

    allSeasons?.forEach((season) => {
      if (
        dayjs(season?.season_end).isSameOrAfter(today()) ||
        season?.season_name === labels.rules.default_season_name
      ) {
        groupedSeasons.active.push(season!);
      } else {
        groupedSeasons.past.push(season!);
      }
    });

    return groupedSeasons;
  }, [seasons, sortBy.sortByIndex, sortBy.sortOrder]);

  useEffect(() => {
    setCheckedSeasons([]);
  }, [propertyId]);

  // select new season when created
  useEffect(() => {
    if (selectedSeason) {
      const newSeason = groupedSeasons.active.find(
        (s) => s.season_id === selectedSeason.season_id
      );
      if (newSeason) setCheckedSeasons([newSeason]);
    }
  }, [groupedSeasons.active, selectedSeason]);

  // clear checkedSeasons when user starts to create a new season
  useEffect(() => {
    if (
      Object.values(touched).length &&
      !selectedSeason &&
      checkedSeasons.length
    ) {
      setDefaultChecked(false);
      setCheckedSeasons([]);
    }
  }, [checkedSeasons.length, touched, selectedSeason]);

  // select season if it's the only one checked
  useEffect(() => {
    if (
      checkedSeasons.length === 1 &&
      groupedSeasons.active.some(
        (season) => season.season_id === checkedSeasons[0].season_id
      )
    ) {
      const checkedSeason = checkedSeasons[0];
      const isDefault =
        checkedSeason.season_name === labels.rules.default_season_name;
      setDefaultChecked(isDefault);
      dispatch(seasonChanged(checkedSeason));
      setTabsEnabled(true);
      resetForm({
        values: {
          id: checkedSeason.season_id!,
          seasonName: checkedSeason.season_name!,
          startDate: checkedSeason.season_start!,
          endDate: checkedSeason.season_end!,
          selectedDays: checkedSeason.selected_days as SelectedDays[],
          highlightInMyView: checkedSeason.highlight_myview || false,
          priority: checkedSeason.season_priority as SeasonPriority,
        },
      });
    } else {
      if (selectedSeason) resetForm({ values: defaultValues });
      dispatch(seasonChanged(null));
      setTabsEnabled(false);
    }
  }, [
    groupedSeasons.active,
    checkedSeasons,
    resetForm,
    selectedSeason,
    setTabsEnabled,
    dispatch,
  ]);

  const onRowClick = (checked: boolean, season: PropSeason) => {
    const isDefault = season.season_name === labels.rules.default_season_name;
    if (checked) {
      if (isDefault) setDefaultChecked(true);
      setCheckedSeasons([...checkedSeasons, season]);
    } else {
      const index = checkedSeasons.findIndex(
        ({ season_id }) => season_id === season.season_id
      );
      if (isDefault) setDefaultChecked(false);
      setCheckedSeasons((seasons) => [
        ...seasons.slice(0, index),
        ...seasons.slice(index + 1),
      ]);
    }
  };

  const handleCopy = () => {
    const checkedSeason = checkedSeasons[0];
    resetForm({
      values: {
        id: null,
        seasonName: labels.rules.copy + checkedSeason.season_name!,
        startDate: checkedSeason.season_start!,
        endDate: checkedSeason.season_end!,
        selectedDays: checkedSeason.selected_days as SelectedDays[],
        highlightInMyView: checkedSeason.highlight_myview || false,
        priority: checkedSeason.season_priority as SeasonPriority,
      },
    });
    if (view === 'past') setView('active');
    setDefaultChecked(false);
    setCheckedSeasons([]);
    dispatch(seasonChanged(null));
    setTabsEnabled(false);
  };

  const handleDelete = () => {
    deleteSeasons(checkedSeasons, () => {
      toggle();
      const alertMessage =
        checkedSeasons.length > 1
          ? `${checkedSeasons.length} ${labels.season_list.delete_multiple_success_msg}`
          : `${checkedSeasons[0].season_name} ${labels.season_list.delete_single_success_msg}`;
      dispatch(alertAdded('success', alertMessage));
      setCheckedSeasons([]);
    });
  };

  return (
    <div className='season-list'>
      <Modal
        isShown={isShown}
        hide={toggle}
        onConfirm={handleDelete}
        onCancel={toggle}
        loading={deleteSeasonsLoading}
        isConfirmationModal
        hasIcon
        headerText={labels.season_list.modal_header_text}
        messageBody={`${
          labels.season_list.confirmation_message
        }\n${checkedSeasons.map((s) => s.season_name).join('\n')}`}
      />
      <div className='header'>
        <ToggleSwitch
          id='season-type'
          checked={view === 'past'}
          options={[labels.season_list.active, labels.season_list.past]}
          onToggle={(checked) => {
            setCheckedSeasons([]);
            setView(checked ? 'past' : 'active');
          }}
        />
        <div className='actions'>
          {checkedSeasons.length === 1 && !isReadOnly && (
            <IconButton
              disableFocusRipple
              disableRipple
              aria-label='copy'
              color='primary'
              onClick={handleCopy}
              title='Copy Season'
            >
              <MdContentCopy size={14} />
            </IconButton>
          )}
          {checkedSeasons.length > 0 && !defaultChecked && !isReadOnly && (
            <IconButton
              disableFocusRipple
              disableRipple
              aria-label='delete'
              color='primary'
              onClick={toggle}
              title='Delete Season'
            >
              <MdOutlineDelete size={18} />
            </IconButton>
          )}
          <MenuButton
            title='Sort By'
            options={sortOptions}
            selectedOption={sortBy}
            onSelectedOptionChange={(newOption) => {
              setSortBy(newOption);
            }}
          >
            <BiSort className='season-list__actionIcon' size={14} />
          </MenuButton>
        </div>
      </div>
      <LoadingBar className='body' loading={loading}>
        <ul className='items'>
          {groupedSeasons[view].map((season) => {
            return (
              <li className='item' key={season.season_id}>
                <input
                  className='hidden'
                  type='checkbox'
                  id={season.season_id}
                  name={season.season_id}
                  checked={checkedSeasons.includes(season)}
                  onChange={(event) => onRowClick(event.target.checked, season)}
                />
                <label htmlFor={season.season_id} className='season-label'>
                  <div className='name'>{season.season_name}</div>

                  <div className='date'>
                    {formatFormDate(season.season_start, 'DD MMM YYYY')}
                  </div>
                  <div className='priority'>
                    {SeasonPriority[season.season_priority!]}
                  </div>
                </label>
              </li>
            );
          })}
          {view === 'active' && !isReadOnly && (
            <li className='item'>
              <input
                className='hidden'
                type='checkbox'
                id='newSeason'
                name='newSeason'
                checked={!checkedSeasons.length}
                onChange={() => {
                  setDefaultChecked(false);
                  setCheckedSeasons([]);
                }}
              />
              <label htmlFor='newSeason' className='season-label'>
                <div className='name'>
                  {checkedSeasons.length > 0 && <FaPlus className='add-icon' />}
                  {labels.season_list.new_season}
                </div>
              </label>
            </li>
          )}
        </ul>
      </LoadingBar>
    </div>
  );
};
