import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import alertsReducer from 'features/alerts/redux/alerts-slice';
import eventsFormReducer from 'features/events/redux/events-form-slice';
import headerReducer from 'features/header/redux/header-slice';
import myViewReducer from 'features/my-view/redux/my-view-slice';
import propertyReducer from 'features/property/redux/property-slice';
import rateUploadReducer from 'features/rate-upload/redux/rate-upload-slice';
import rulesReducer from 'features/rules/redux/rules-slice';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import usersReducer from 'features/users/redux/users-slice';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['property', 'users'],
  blacklist: ['rateUpload'],
};

const myViewPersistConfig = {
  key: 'myView',
  storage,
  blacklist: ['showSearchPanel', 'tabs'],
};

const rateUploadConfig = {
  key: 'rateUpload',
  storage,
  whitelist: ['allRowsSelected'],
};

const rootReducer = combineReducers({
  rules: rulesReducer,
  eventsForm: eventsFormReducer,
  header: headerReducer,
  myView: persistReducer(myViewPersistConfig, myViewReducer),
  property: propertyReducer,
  rateUpload: persistReducer(rateUploadConfig, rateUploadReducer),
  alerts: alertsReducer,
  users: usersReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'addForm/resetForm',
          'addForm/addSeasonFormRef',
          'addForm/setAddSeasonFormRef',
          'addForm/setFormDirty',
          'competitor-slice/setCompetitorsSet',
          'competitor-slice/setGridApi',
          'ratesForm/setEventsFormRef',
          'header/setDefaultSearchId',
          'ratesForm/setRatesFormRef',
          'eventsForm/setEventsFormRef',
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
        ],
        ignoredPaths: [
          'addForm.resetForm',
          'addForm.setAddSeasonFormRef',
          'addForm.addSeasonFormRef',
          'competitor.gridApi',
          'ratesForm.resetForm',
          'ratesForm.ratesFormRef',
          'eventsForm.eventsFormRef',
          'eventsForm/setEventsFormRef',
        ],
      },
    }).concat(thunk),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
