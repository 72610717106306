/* eslint-disable */
import * as Types from '../../../../graphql/gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TriggerRateUploadQueryVariables = Types.Exact<{
  propertyId: Types.Scalars['String'];
  userName: Types.Scalars['String'];
}>;


export type TriggerRateUploadQuery = { __typename?: 'Query', triggerUpload?: { __typename?: 'MessageResponse', MessageId: string } | undefined };


export const TriggerRateUploadDocument = gql`
    query TriggerRateUpload($propertyId: String!, $userName: String!) {
  triggerUpload(propertyId: $propertyId, userName: $userName) {
    MessageId
  }
}
    `;

/**
 * __useTriggerRateUploadQuery__
 *
 * To run a query within a React component, call `useTriggerRateUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useTriggerRateUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTriggerRateUploadQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useTriggerRateUploadQuery(baseOptions: Apollo.QueryHookOptions<TriggerRateUploadQuery, TriggerRateUploadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TriggerRateUploadQuery, TriggerRateUploadQueryVariables>(TriggerRateUploadDocument, options);
      }
export function useTriggerRateUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TriggerRateUploadQuery, TriggerRateUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TriggerRateUploadQuery, TriggerRateUploadQueryVariables>(TriggerRateUploadDocument, options);
        }
export type TriggerRateUploadQueryHookResult = ReturnType<typeof useTriggerRateUploadQuery>;
export type TriggerRateUploadLazyQueryHookResult = ReturnType<typeof useTriggerRateUploadLazyQuery>;
export type TriggerRateUploadQueryResult = Apollo.QueryResult<TriggerRateUploadQuery, TriggerRateUploadQueryVariables>;
export const namedOperations = {
  Query: {
    TriggerRateUpload: 'TriggerRateUpload'
  }
}