import './RecReviewSearch.scss';

import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikProps,
} from 'formik';
import {
  MdClose,
  MdDeleteOutline,
  MdOutlineAdd,
  MdOutlineIndeterminateCheckBox,
  MdRefresh,
} from 'react-icons/md';
import { MenuItem, Select, TextField } from '@mui/material';
import { SearchMetric, UserSearch } from 'graphql/gql-types';
import { useCallback, useState } from 'react';

import { DayPicker } from 'components/DayPicker/DayPicker';
import { DropdownMenu } from 'components/DropdownMenu/DropdownMenu';
import IconButton from '@mui/material/IconButton';
import { InputDate } from 'components/InputDate/InputDate';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { Modal } from 'components/Modal/Modal';
import { StatusPicker } from 'components/StatusPicker/StatusPicker';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import { days } from '../config';
import { labels } from 'locales/en.label';
import styles from 'common/_variables.module.scss';

interface RecReviewSearchProps {
  id: string;
  formRef: React.Ref<FormikProps<UserSearch>>;
  onSearch: () => void;
  onSaveAs: () => void;
  onDelete: () => void;
  onSave: () => void;
  onClose: () => void;
  onCreate: () => void;
  onReset: () => void;
  searchData: UserSearch;
  searchList: Array<UserSearch>;
  searchMetrics?: Array<SearchMetric>;
  setIsBlocked: (isBlocked: boolean) => void;
  loading?: boolean;
}

export const RecReviewSearch = ({
  id,
  formRef,
  onSearch,
  onSaveAs,
  onDelete,
  onSave,
  onReset,
  searchData,
  searchMetrics,
  searchList,
  onCreate,
  onClose,
  setIsBlocked,
  loading = false,
}: RecReviewSearchProps) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [saveAsBtnDisable, setSaveAsBtnDisable] = useState(true);
  const [searchBtnDisable, setSearchBtnDisable] = useState(true);
  /* This state is for maintaing confimration type for modal */
  const [confMessageType, setConfMessageType] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const [disableSearchInput, setDisableSearchInput] = useState<Boolean>(true);

  const validateSearchName = (value: string) => {
    const checkName =
      searchList && searchList.length > 0
        ? searchList.some((i) => i['searchName'] === value)
        : undefined;
    let error;
    if (!value) {
      error = labels.rec_review.search.form_error.required_search_name;
    } else if (checkName && searchData.searchType === 'new') {
      error = labels.rec_review.search.form_error.search_name_exist_err;
    } else if (checkName) {
      error = labels.rec_review.search.form_error.search_name_copy_err;
    } else if (value.length > 50) {
      error = labels.rec_review.search.form_error.max_search_name_err;
    } else {
      error = '';
    }
    return error;
  };

  const validateStatus = (value: string) => {
    let error;
    if (value.length <= 0) {
      error = labels.rec_review.search.form_error.required_status_err;
    } else {
      error = '';
    }
    return error;
  };

  const validateSelectedDays = (value: string) => {
    let error;
    if (value.length <= 0) {
      error = labels.rec_review.search.form_error.required_day_err;
    } else {
      error = '';
    }
    return error;
  };

  const onConfirmtionWindowClose = useCallback(
    () => setIsConfirmationModalOpen(false),
    []
  );

  return (
    <>
      <Modal
        headerText={labels.rec_review.search.confirmation}
        isShown={isConfirmationModalOpen}
        hide={() => setIsConfirmationModalOpen(false)}
        onConfirm={() => {
          if (confMessageType === 'delete') {
            onDelete();
          } else {
            onClose();
          }
          setIsConfirmationModalOpen(false);
          setIsBlocked(false);
        }}
        onCancel={onConfirmtionWindowClose}
        isConfirmationModal={true}
        confirmBtnText={labels.rec_review.search.yes}
        cancelBtnText={labels.rec_review.search.no}
        messageBody={
          confMessageType === 'delete'
            ? labels.rec_review.search.delete_search_msg
            : labels.rec_review.search.discard_search_msg
        }
        hasIcon={true}
      />
      <div className='search__container'>
        <div className='search__content'>
          <LoadingBar loading={loading} overlayStyle={{ borderRadius: '10px' }}>
            <Formik<UserSearch>
              enableReinitialize={true}
              initialValues={searchData}
              innerRef={formRef}
              onSubmit={(values) => {
                console.warn('');
              }}
            >
              {({ validateForm, values, errors, setFieldValue, dirty }) => {
                if (dirty) {
                  setIsBlocked(true);
                  if (searchData.searchType === 'new') {
                    setSaveAsBtnDisable(true);
                  } else {
                    setSaveAsBtnDisable(false);
                  }
                  if (searchData.isEditable) {
                    if ('statuses' in errors || 'selectedDays' in errors) {
                      setSearchBtnDisable(true);
                    } else {
                      setSearchBtnDisable(false);
                    }
                  }
                  setDisableSearchInput(false);
                } else {
                  setSaveAsBtnDisable(true);
                  setDisableSearchInput(true);
                  setSearchBtnDisable(true);
                  setShowErrors(false);
                  setIsBlocked(false);
                }

                return (
                  <Form id={id} className='search__content-form'>
                    <div className='search__content-close'>
                      <MdClose
                        size={20}
                        onClick={() => {
                          if (dirty) {
                            setConfMessageType('discard');
                            setIsConfirmationModalOpen(true);
                            setShowErrors(false);
                          } else {
                            onClose();
                          }
                        }}
                      />
                    </div>
                    <div className='search__content--left'>
                      <div className='form-group'>
                        <Field name='searchName' validate={validateSearchName}>
                          {({ field }: any) => (
                            <input
                              type='text'
                              placeholder='Search Name'
                              disabled={
                                !searchData.isEditable && disableSearchInput
                              }
                              onChange={() => {
                                field.onChange(field.name);
                              }}
                              className='search__search-name'
                              {...field}
                            />
                          )}
                        </Field>
                      </div>

                      <div className='search__buttons'>
                        <div className='search__button-search-options'>
                          <button
                            type='button'
                            className={
                              searchBtnDisable
                                ? 'search__button-search search__button--disable'
                                : 'search__button-search search__button--enable'
                            }
                            onClick={() => {
                              validateForm().then((errors) => {
                                if (
                                  Object.keys(errors).length === 0 ||
                                  'searchName' in errors
                                ) {
                                  onSearch();
                                } else {
                                  if ('searchName' in errors) {
                                    onSearch();
                                  }
                                }
                              });
                            }}
                          >
                            {labels.rec_review.search.btn_search}
                          </button>
                        </div>

                        <div className='search__button-save-options'>
                          <button
                            type='button'
                            onClick={() => {
                              validateForm().then((errors) => {
                                console.warn('saveAs error', errors);
                                if (Object.keys(errors).length === 0) {
                                  onSaveAs();
                                } else {
                                  setShowErrors(true);
                                }
                              });
                            }}
                            className={
                              saveAsBtnDisable &&
                              (searchData.searchType === 'new'
                                ? true
                                : !searchData.isEditable)
                                ? 'search__button-save-as search__button--disable'
                                : 'search__button-save-as search__button--enable'
                            }
                          >
                            {labels.rec_review.search.btn_save_as}
                          </button>
                          <button
                            type='button'
                            onClick={() => {
                              validateForm().then((errors) => {
                                if (
                                  errors.searchName ===
                                    labels.rec_review.search.form_error
                                      .search_name_copy_err ||
                                  Object.keys(errors).length === 0
                                ) {
                                  onSave();
                                } else {
                                  setShowErrors(true);
                                }
                              });
                            }}
                            className={
                              searchData.isEditable
                                ? 'search__button-save search__button--enable'
                                : 'search__button-save search__button--disable'
                            }
                          >
                            {labels.rec_review.search.btn_save}
                          </button>
                        </div>
                      </div>
                      <div className='search__errors'>
                        {errors.searchName && showErrors && (
                          <p>{errors.searchName}</p>
                        )}
                        {errors.selectedDays && <p>{errors.selectedDays}</p>}
                        {errors.statuses && <p>{errors.statuses}</p>}
                      </div>
                    </div>

                    <div className='search__content--right'>
                      <div className='search__date'>
                        <div className='search__content-date'>
                          <InputDate
                            name='startDate'
                            label={''}
                            minDate={dayjs(new Date())}
                            disablePast
                          />
                        </div>
                        <div className='search__content-days'>
                          <Field name='duration' className='search__dropdown'>
                            {({
                              field: { value },
                              form: { setFieldValue },
                            }: FieldProps) => {
                              if (searchData.duration && !value)
                                setFieldValue('duration', value);
                              return (
                                <DropdownMenu
                                  className='search__duration'
                                  options={days}
                                  preSelectValue={value}
                                  handleChange={(e: React.ChangeEvent<any>) =>
                                    setFieldValue('duration', e.target.value)
                                  }
                                />
                              );
                            }}
                          </Field>
                          <span>{labels.rec_review.search.label_days}</span>
                        </div>
                        <div className='search__content-weekday'>
                          <Field
                            name='selectedDays'
                            as={DayPicker}
                            validate={validateSelectedDays}
                          />
                        </div>
                      </div>

                      <div className='search__status__picker'>
                        <div className='search__status__picker-left'></div>
                        <div className='search__status__picker-right'>
                          <Field
                            name='statuses'
                            as={StatusPicker}
                            validate={validateStatus}
                          />
                        </div>
                      </div>
                      <FieldArray name='searchCriteria'>
                        {({ remove, push }) => (
                          <div className='search__additional-search'>
                            {values.searchCriteria &&
                              values.searchCriteria.map((value, index) => {
                                return (
                                  <div
                                    key={index}
                                    className='search__additional-search-row'
                                  >
                                    <div className='search__additional-search-row-left'>
                                      <Field
                                        name={`searchCriteria[${index}].lhsCode`}
                                        key={`lhs_${index}`}
                                      >
                                        {({
                                          field,
                                          form: { touched, errors },
                                          meta,
                                        }: any) => (
                                          <Select
                                            displayEmpty
                                            renderValue={
                                              value?.lhsCode !== ''
                                                ? undefined
                                                : () => {
                                                    return (
                                                      <div className='search__dropdown-placeholder'>
                                                        Select
                                                      </div>
                                                    );
                                                  }
                                            }
                                            inputProps={{}}
                                            {...field}
                                            className='search__additional-search-select'
                                          >
                                            {searchMetrics?.map(
                                              (option, idx) => (
                                                <MenuItem
                                                  key={`lhs_option_${idx}`}
                                                  value={option.lhsCode}
                                                >
                                                  {option.lhsName}
                                                </MenuItem>
                                              )
                                            )}
                                          </Select>
                                        )}
                                      </Field>
                                      <Field
                                        name={`searchCriteria[${index}].operatorCode`}
                                        key={`operator_${index}`}
                                      >
                                        {({
                                          field,
                                          form: { touched, errors },
                                          meta,
                                        }: any) => (
                                          <Select
                                            displayEmpty
                                            renderValue={
                                              value?.operatorCode !== ''
                                                ? undefined
                                                : () => {
                                                    return (
                                                      <div className='search__dropdown-placeholder'>
                                                        Select
                                                      </div>
                                                    );
                                                  }
                                            }
                                            inputProps={{}}
                                            {...field}
                                            className='search__additional-search-select'
                                          >
                                            {searchMetrics
                                              ?.find(
                                                (o: SearchMetric) =>
                                                  o.lhsCode === value?.lhsCode
                                              )
                                              ?.operator?.map((option, idx) => (
                                                <MenuItem
                                                  key={`operator_option_${idx}`}
                                                  value={option?.code}
                                                >
                                                  {option?.value}
                                                </MenuItem>
                                              ))}
                                          </Select>
                                        )}
                                      </Field>
                                      <Field
                                        name={`searchCriteria[${index}].rhsCode`}
                                        key={`rhs_${index}`}
                                      >
                                        {({
                                          field,
                                          form: { touched, errors },
                                          meta,
                                        }: any) => (
                                          <Select
                                            displayEmpty
                                            renderValue={
                                              value?.rhsCode !== ''
                                                ? undefined
                                                : () => {
                                                    return (
                                                      <div className='search__dropdown-placeholder'>
                                                        Select
                                                      </div>
                                                    );
                                                  }
                                            }
                                            inputProps={{}}
                                            {...field}
                                            className='search__additional-search-select'
                                            onChange={(e) => {
                                              setFieldValue(
                                                `searchCriteria[${index}].rhsCode`,
                                                e.target.value
                                              );
                                              if (e.target.value === 'value') {
                                              } else {
                                                setFieldValue(
                                                  `searchCriteria[${index}].rhsCustomValue`,
                                                  null
                                                );
                                              }
                                            }}
                                          >
                                            {searchMetrics
                                              ?.find(
                                                (o: SearchMetric) =>
                                                  o.lhsCode === value?.lhsCode
                                              )
                                              ?.rhs?.map((option, idx) => (
                                                <MenuItem
                                                  key={`rhs_option_${idx}`}
                                                  value={
                                                    option?.code
                                                      ? option?.code
                                                      : 'value'
                                                  }
                                                >
                                                  {option?.value
                                                    ? option?.value
                                                    : 'value'}
                                                </MenuItem>
                                              ))}
                                          </Select>
                                        )}
                                      </Field>
                                    </div>

                                    <div className='search__additional-search-row-right'>
                                      {(value?.rhsCode === 'value' ||
                                        value?.rhsCustomValue !== null) && (
                                        <Field
                                          name={`searchCriteria[${index}].rhsCustomValue`}
                                        >
                                          {({
                                            field,
                                            form: { touched, errors },
                                            meta,
                                          }: any) => (
                                            <TextField
                                              name={`searchCriteria[${index}].rhsCustomValue`}
                                              className='search__additional-search-input'
                                              placeholder={'Value'}
                                              value={
                                                value?.rhsCustomValue ?? ''
                                              }
                                              onChange={(e) => {
                                                const regx = /^\d*\.?\d*$/;

                                                if (regx.test(e.target.value)) {
                                                  setFieldValue(
                                                    `searchCriteria[${index}].rhsCustomValue`,
                                                    e.target.value
                                                  );
                                                }
                                              }}
                                            />
                                          )}
                                        </Field>
                                      )}
                                      <div className='additional-search-action'>
                                        <IconButton
                                          disableFocusRipple
                                          disableRipple
                                          aria-label='remove-search-criteria'
                                          onClick={() => remove(index)}
                                        >
                                          <MdOutlineIndeterminateCheckBox
                                            size={22}
                                            className='header__icon'
                                            color={styles.colorWarning}
                                          />
                                        </IconButton>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            <div className='search__footer'>
                              <button
                                type='button'
                                className={
                                  searchData.isEditable
                                    ? 'search__button-add-search search__button--enable'
                                    : 'search__button-add-search search__button--disable'
                                }
                                onClick={() =>
                                  push({
                                    lhsCode: '',
                                    operatorCode: '',
                                    rhsCode: '',
                                    rhsCustomValue: null,
                                  })
                                }
                              >
                                {
                                  labels.rec_review.search
                                    .btn_add_search_criteria
                                }
                              </button>
                              <div className='search__other-actions'>
                                <Tooltip
                                  title={
                                    labels.rec_review.search.tooltip
                                      .create_search
                                  }
                                  arrow
                                  placement='bottom'
                                >
                                  <IconButton
                                    disableFocusRipple
                                    disableRipple
                                    aria-label='create-search'
                                    className='search__create'
                                    onClick={() => {
                                      onCreate();
                                    }}
                                  >
                                    <MdOutlineAdd size={22} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title={labels.rec_review.search.tooltip.reset}
                                  arrow
                                  placement='bottom'
                                >
                                  <IconButton
                                    disableFocusRipple
                                    disableRipple
                                    aria-label='reset'
                                    className='search__reset search__reset--enable'
                                    onClick={() => {
                                      onReset();
                                    }}
                                  >
                                    <MdRefresh size={22} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title={
                                    labels.rec_review.search.tooltip
                                      .delete_search
                                  }
                                  arrow
                                  placement='bottom'
                                >
                                  <IconButton
                                    disableFocusRipple
                                    disableRipple
                                    aria-label='search-delete'
                                    className={
                                      searchData.isEditable
                                        ? 'search__delete search__delete--enable'
                                        : 'search__delete  search__delete--disable'
                                    }
                                    onClick={() => {
                                      setConfMessageType('delete');
                                      setIsConfirmationModalOpen(true);
                                    }}
                                  >
                                    <MdDeleteOutline size={22} />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </LoadingBar>
        </div>
      </div>
    </>
  );
};
