import { RecReviewStatus, SearchMetricInfo } from 'graphql/gql-types';

export const DEFAULT_SEARCH_ID: number = 1;
export const SEARCH_ID_KEY = 'recReviewSearch';

export enum SelectedDays {
  SUNDAY = 'sunday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
}

export interface SearchInfo {
  duration?: number;
  searchCriteria?: SearchMetricInfo[];
  searchId?: number;
  searchName?: string;
  selectedDays?: string[];
  selloutInd?: boolean;
  startDate?: string;
  statuses?: RecReviewStatus[];
  userId?: string;
}

export interface Search extends SearchInfo {
  isEditable: boolean;
  searchType: string;
}

export enum SearchLookupType {
  USER_ID = 'USER_ID',
  SEARCH_ID = 'SEARCH_ID',
}

export interface SearchLookup {
  lookupColumn: SearchLookupType;
  lookupValue: string | number;
}
