import { RateOffsetLog } from 'graphql/gql-types';
import { twoDecimalRateFormatter } from 'pages/MyView/tableCustomCellRender';

export const transformData = (data: string[][]) => {
  const headers = data.map((row) => row[0]);
  const rows = data?.[0]
    ?.slice(1)
    .map((_, colIndex) => {
      const row: Record<string, string> = {};
      headers.forEach((header, rowIndex) => {
        let rowData = data[rowIndex][colIndex + 1];
        if (header === 'date') rowData = renamePersonHeaders(rowData);
        row[header] = rowData;
      });
      return row;
    })
    .filter((row) => !!row);

  return { rows, headers };
};

const renamePersonHeaders = (header: string) => {
  const perPersonHeader = header.toLowerCase().split('per_');
  if (perPersonHeader?.length > 1) {
    return `${perPersonHeader[1]}${
      perPersonHeader[1].split('_')[1] ? '+' : ''
    } Person`;
  }
  return header;
};

export const convertArrObjToArrString = (
  data: Array<RateOffsetLog>
): string[][] => {
  if (data?.length < 1) return [];
  const headers = Object.keys(data[0]).filter(
    (key) => key !== '__typename' && key !== 'rates' && key !== 'ratePlan'
  );

  const categories: Record<string, Set<string>> = {};

  data.forEach((item) => {
    item.rates.forEach((rate) => {
      if (!rate) return;
      if (!categories[rate.category]) categories[rate.category] = new Set();
      Object.keys(rate.offsets)
        .filter((key) => key !== '__typename' && key !== 'ratePlan')
        .forEach((offset) => {
          // @ts-ignore
          if (!!rate?.offsets[`${offset}`])
            categories[rate.category].add(offset);
        });
    });
  });

  Object.entries(categories).forEach(([category, offsets]) => {
    headers.push(category);
    offsets.forEach((offset) => headers.push(offset));
  });

  const transformData: string[][] = [headers];

  data.forEach((item) => {
    const row: Array<string> = [
      item.date,
      item.updatedAt || '',
      item.userId || '',
    ];
    Object.entries(categories).forEach(([category, offsets]) => {
      const rate = item.rates.find(
        (rate) => rate && rate.category === category
      );
      row.push(''); // empty space for the category column
      offsets.forEach((offset) => {
        const offsetData =
          // @ts-ignore
          rate?.offsets && twoDecimalRateFormatter(rate.offsets[offset]);
        row.push(offsetData || '');
      });
    });
    transformData.push(row);
  });
  return transformData;
};
