import './rate-shop-modal.scss';

import { CheckboxWithLabel, Select } from 'formik-mui';
import { Field, FieldArray, Form, Formik } from 'formik';
import { FormGroup, MenuItem } from '@mui/material';

import { GetRateShopQueryVariables } from 'features/my-view/gql/_gen_/rec-review.gql';
import { Modal } from 'components/Modal/Modal';
import { labels } from 'locales/en.label';
import { usePropertyContext } from 'context/propertyContext';

interface FormValues {
  bar: 'true' | 'false';
  los: number;
  ota: string;
  roomType: string;
  changeDays: number[];
}

interface RateShopModalProps {
  isShown: boolean;
  toggle: () => void;
  refetchRateShop: (variables: GetRateShopQueryVariables) => void;
}

const rateShopLabels = labels.rec_review.rate_shop_modal;

const paramOptions = {
  bar: [
    { label: 'Best Flex', value: 'true' },
    { label: 'Lowest', value: 'false' },
  ],
  los: [
    { label: '1', value: 1 },
    { label: '3', value: 3 },
    { label: '7', value: 7 },
  ],
  ota: [
    { label: 'Branded', value: 'branddotcom' },
    { label: 'Booking.com', value: 'bookingdotcom' },
    { label: 'Expedia', value: 'expedia' },
    { label: 'GDS', value: 'gds' },
    { label: 'HRS', value: 'hrs' },
  ],
  roomType: [
    { label: 'All Types', value: 'all' },
    { label: 'Standard', value: 'standard' },
    { label: 'Single', value: 'single' },
    { label: 'Budget', value: 'budget' },
    { label: 'Family', value: 'family' },
    { label: 'Suite', value: 'suite' },
    { label: 'Premium', value: 'premium' },
    { label: 'Dorm', value: 'dormitory' },
    { label: 'Shared Bath', value: 'sharedbathroom' },
    { label: 'Apartment', value: 'apartment' },
    { label: 'Park & Fly', value: 'parkandfly' },
    { label: 'Camper', value: 'camper' },
    { label: 'Villa', value: 'villa' },
    { label: 'Bungalow', value: 'bungalow' },
  ],
  changeDays: [
    { label: '1', value: 1 },
    { label: '3', value: 3 },
    { label: '7', value: 7 },
  ],
};

const initialValues: FormValues = {
  bar: 'true',
  los: 1,
  ota: 'branddotcom',
  roomType: 'all',
  changeDays: [1, 3, 7],
};

const mapMenuItems = ({
  label,
  value,
}: {
  label: string;
  value: string | number;
}) => (
  <MenuItem key={value} value={value}>
    {label}
  </MenuItem>
);

export const RateShopModal = ({
  isShown,
  toggle,
  refetchRateShop,
}: RateShopModalProps) => {
  const {
    property: { propertyId },
  } = usePropertyContext();
  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={({ bar, roomType, ...values }, { setSubmitting }) => {
        refetchRateShop({
          propertyId,
          ...values,
          bar: bar === 'true',
          roomType: roomType === 'all' ? undefined : roomType,
        });
        setSubmitting(false);
        toggle();
      }}
    >
      {({ submitForm, values }) => (
        <Form>
          <Modal
            className='rate-shop-modal'
            isShown={isShown}
            hide={toggle}
            headerText={rateShopLabels.header}
            messageBody={
              <>
                <div>
                  <label>{rateShopLabels.rate_type}</label>
                  <div className='common-dropdown-container'>
                    <Field name='bar' component={Select}>
                      {paramOptions.bar.map(({ label, value }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Field>
                  </div>
                </div>
                <div>
                  <label>{rateShopLabels.los}</label>
                  <div className='common-dropdown-container'>
                    <Field name='los' component={Select}>
                      {paramOptions.los.map(mapMenuItems)}
                    </Field>
                  </div>
                </div>
                <div>
                  <label>{rateShopLabels.channel}</label>
                  <div className='common-dropdown-container'>
                    <Field name='ota' component={Select}>
                      {paramOptions.ota.map(mapMenuItems)}
                    </Field>
                  </div>
                </div>
                <div>
                  <label>{rateShopLabels.room_type}</label>
                  <div className='common-dropdown-container'>
                    <Field name='roomType' component={Select}>
                      {paramOptions.roomType.map(mapMenuItems)}
                    </Field>
                  </div>
                </div>
                <div>
                  <label>{rateShopLabels.change_days}</label>
                  <FieldArray
                    name='changeDays'
                    render={(arrayHelpers) => (
                      <FormGroup>
                        {paramOptions.changeDays.map(({ label, value }) => (
                          <Field
                            key={label}
                            name='changeDays'
                            component={CheckboxWithLabel}
                            type='checkbox'
                            Label={{ label }}
                            checked={values.changeDays.includes(value)}
                            onChange={(_e: void, checked: boolean) => {
                              if (checked) {
                                arrayHelpers.push(value);
                              } else {
                                const idx = values.changeDays.indexOf(value);
                                arrayHelpers.remove(idx);
                              }
                            }}
                          />
                        ))}
                      </FormGroup>
                    )}
                  />
                </div>
              </>
            }
            isConfirmationModal
            confirmBtnText={labels.common.save}
            onConfirm={submitForm}
            onCancel={toggle}
          />
        </Form>
      )}
    </Formik>
  );
};
