import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import { MdInfoOutline } from 'react-icons/md';
import { Modal } from '../Modal/Modal';
import copy from 'copy-to-clipboard';
import { labels } from '../../locales/en.label';
import styles from '../../common/_variables.module.scss';
import { useModal } from '../Modal/useModal';
import { useState } from 'react';

export const AboutModal = () => {
  const { isShown, toggle } = useModal();
  const [copyMsg, setCopyMsg] = useState('click to copy');

  const handleCopy = () => {
    copy(`${labels.app.support_email}`);
    setCopyMsg('copied!');
    setTimeout(() => {
      setCopyMsg('click to copy');
    }, 2000);
  };

  const messageBody = (
    <ul>
      <li>
        <b>Name</b>: {labels.app.name}
      </li>
      <li>
        <b>Release</b>: {process.env.REACT_APP_VERSION}
      </li>
      <li>
        {' '}
        <b>Contact Support</b>
        <ul>
          <li>
            <a
              href={`mailto:${labels.app.support_email}?subject=BestREV2 Support`}
            >
              Email Support (Launch Mail Client)
            </a>
          </li>
          <li>
            <span onClick={handleCopy}>
              {labels.app.support_email} <b>{copyMsg}</b>
            </span>
          </li>
        </ul>
      </li>
      <li>
        <Link to='/changelog'>Code Changelog</Link>
      </li>
      <li>
        <Link to='/rate_changes'>Rate Change Logs</Link>
      </li>
      <li>
        <a
          href={`https://status.bestrev.bwhhotelgroup.com`}
          target='_blank'
          rel='noreferrer'
        >
          Status Page
        </a>
      </li>
    </ul>
  );

  return (
    <>
      <IconButton
        disableFocusRipple
        disableRipple
        aria-label='info'
        onClick={toggle}
      >
        <MdInfoOutline size={20} className='header__icon' />
      </IconButton>
      <Modal
        isShown={isShown}
        hide={toggle}
        headerText={labels.header.info_popup.header}
        onConfirm={toggle}
        onCancel={toggle}
        messageBody={messageBody}
        hasIcon={true}
        icon={<MdInfoOutline size={40} color={styles.colorGreen} />}
      ></Modal>
    </>
  );
};
