import { GET_PROPERTY_STAY_VIEW_CHART } from '../gql/gql-prop-stay-view-chart';
import { useQuery } from '@apollo/client';

type PropertyStayViewArgs = {
  selectedDays: string[];
  duration: number;
  propertyId?: string;
};

export const usePropStayViewChart = ({
  selectedDays,
  duration,
  propertyId,
}: PropertyStayViewArgs) => {
  return useQuery(GET_PROPERTY_STAY_VIEW_CHART, {
    skip: !propertyId,
    variables: {
      selectedDays,
      duration,
      propertyId,
    },
  });
};
