import { RecReviewStatus } from 'graphql/gql-types';

export type StatusOption = {
  label: string;
  value: string;
};
export const status: StatusOption[] = [
  { label: 'Review', value: RecReviewStatus.R },
  { label: 'Pending', value: RecReviewStatus.P },
  { label: 'Sent', value: RecReviewStatus.S },
  { label: 'No Change', value: RecReviewStatus.N },
  { label: 'Error', value: RecReviewStatus.E },
];
