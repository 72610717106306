import { Route, RouteProps } from 'react-router-dom';

import { Login } from 'components/Login/Login';
import { useUser } from 'features/users/context/userContext';

/**
 * Custom HOC that wraps the Route component from React Router.
 * If the user is authenticated, the child component is rendered.
 * If not authenticated, the Login component is displayed instead.
 */
export const PrivateRoute = ({
  children,
  ...rest
}: Omit<RouteProps, 'component'>) => {
  const { isAuthenticated } = useUser();

  return (
    <Route {...rest} render={() => (isAuthenticated ? children : <Login />)} />
  );
};
