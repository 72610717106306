import {
  useGetSearchMetricsQuery,
  useGetUserSearchesQuery,
  useSaveSearchMutation,
} from 'features/my-view/gql/_gen_/rec-review.gql';

import { GET_CANNED_SEARCHES } from 'queries/gqlRecReview';
import { lhsMetricOrder } from 'pages/MyView/config';
import { useQuery } from '@apollo/client';
import { useUser } from 'features/users/context/userContext';

export const useUserSearches = (userId?: string) => {
  const { data: userSearchesData, loading: loadingUserSearches } =
    useGetUserSearchesQuery({
      skip: !userId,
      variables: {
        userId: userId || '',
      },
    });

  return {
    userSearches: userSearchesData?.getUserSearches,
    loadingUserSearches,
  };
};

export const useSearchMetrics = () => {
  const { data, loading } = useGetSearchMetricsQuery();
  const result = data?.getSearchMetrics;
  const sortedMetrics =
    result &&
    [...result].sort((a, b) => {
      if (!a?.lhsCode || !b?.lhsCode) return 0;
      return (
        lhsMetricOrder.indexOf(a.lhsCode) - lhsMetricOrder.indexOf(b.lhsCode)
      );
    });
  return {
    searchMetrics: sortedMetrics,
    loading,
  };
};

export const useSaveSearch = (onSuccess: (data: any) => void) => {
  const [saveSearch, { loading: saveSearchLoading }] = useSaveSearchMutation({
    refetchQueries: ['GetUserSearches'],
    onCompleted: (data) => {
      onSuccess?.(data);
    },
  });
  return { saveSearch, saveSearchLoading };
};

export const useCannedSearches = () => {
  const { isAuthenticated } = useUser();
  return useQuery(GET_CANNED_SEARCHES, {
    skip: !isAuthenticated,
  });
};
