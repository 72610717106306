import { labels } from 'locales/en.label';

export const data = [
  {
    key: '1',
    query: { col1: 'sellout', operator: null, col2: null },
    value: labels.ask_best_rev.key1,
  },
  {
    key: '2',
    query: { col1: 'current_rate', operator: '<', col2: 'market_rate' },
    value: labels.ask_best_rev.key2,
  },
  {
    key: '3',
    query: { col1: 'adr_lyst', operator: '<', col2: 'value', value: '20%' },
    value: labels.ask_best_rev.key3,
  },
  {
    key: '4',
    query: {
      col1: 'transient_pkp_day_3',
      operator: '>',
      col2: 'total_capacity',
      value: '20%',
    },
    value: labels.ask_best_rev.key4,
  },
];
