/* eslint-disable */
import * as Types from '../../../../graphql/gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPropBestRevStatusQueryVariables = Types.Exact<{
  propertyId: Types.Scalars['String'];
}>;


export type GetPropBestRevStatusQuery = { __typename?: 'Query', getPropertyByPropertyId?: { __typename?: 'Property', bestrev_active?: boolean | undefined } | undefined };

export type SetPropBestRevStatusMutationVariables = Types.Exact<{
  propertyId: Types.Scalars['String'];
  status: Types.Scalars['Boolean'];
}>;


export type SetPropBestRevStatusMutation = { __typename?: 'Mutation', setPropBestRevStatus?: { __typename?: 'Property', bestrev_active?: boolean | undefined, property_id?: string | undefined } | undefined };

export type GetMigrationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMigrationsQuery = { __typename?: 'Query', getMigrations?: Array<{ __typename?: 'Migration', name?: string | undefined, executedAt?: string | undefined } | undefined> | undefined };

export type RunMigrationsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RunMigrationsMutation = { __typename?: 'Mutation', runMigrations?: { __typename?: 'MigrationResult', status?: string | undefined } | undefined };


export const GetPropBestRevStatusDocument = gql`
    query GetPropBestRevStatus($propertyId: String!) {
  getPropertyByPropertyId(propertyId: $propertyId) {
    bestrev_active
  }
}
    `;

/**
 * __useGetPropBestRevStatusQuery__
 *
 * To run a query within a React component, call `useGetPropBestRevStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropBestRevStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropBestRevStatusQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetPropBestRevStatusQuery(baseOptions: Apollo.QueryHookOptions<GetPropBestRevStatusQuery, GetPropBestRevStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropBestRevStatusQuery, GetPropBestRevStatusQueryVariables>(GetPropBestRevStatusDocument, options);
      }
export function useGetPropBestRevStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropBestRevStatusQuery, GetPropBestRevStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropBestRevStatusQuery, GetPropBestRevStatusQueryVariables>(GetPropBestRevStatusDocument, options);
        }
export type GetPropBestRevStatusQueryHookResult = ReturnType<typeof useGetPropBestRevStatusQuery>;
export type GetPropBestRevStatusLazyQueryHookResult = ReturnType<typeof useGetPropBestRevStatusLazyQuery>;
export type GetPropBestRevStatusQueryResult = Apollo.QueryResult<GetPropBestRevStatusQuery, GetPropBestRevStatusQueryVariables>;
export const SetPropBestRevStatusDocument = gql`
    mutation SetPropBestRevStatus($propertyId: String!, $status: Boolean!) {
  setPropBestRevStatus(propertyId: $propertyId, status: $status) {
    bestrev_active
    property_id
  }
}
    `;
export type SetPropBestRevStatusMutationFn = Apollo.MutationFunction<SetPropBestRevStatusMutation, SetPropBestRevStatusMutationVariables>;

/**
 * __useSetPropBestRevStatusMutation__
 *
 * To run a mutation, you first call `useSetPropBestRevStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPropBestRevStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPropBestRevStatusMutation, { data, loading, error }] = useSetPropBestRevStatusMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetPropBestRevStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetPropBestRevStatusMutation, SetPropBestRevStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPropBestRevStatusMutation, SetPropBestRevStatusMutationVariables>(SetPropBestRevStatusDocument, options);
      }
export type SetPropBestRevStatusMutationHookResult = ReturnType<typeof useSetPropBestRevStatusMutation>;
export type SetPropBestRevStatusMutationResult = Apollo.MutationResult<SetPropBestRevStatusMutation>;
export type SetPropBestRevStatusMutationOptions = Apollo.BaseMutationOptions<SetPropBestRevStatusMutation, SetPropBestRevStatusMutationVariables>;
export const GetMigrationsDocument = gql`
    query GetMigrations {
  getMigrations {
    name
    executedAt
  }
}
    `;

/**
 * __useGetMigrationsQuery__
 *
 * To run a query within a React component, call `useGetMigrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMigrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMigrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMigrationsQuery(baseOptions?: Apollo.QueryHookOptions<GetMigrationsQuery, GetMigrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMigrationsQuery, GetMigrationsQueryVariables>(GetMigrationsDocument, options);
      }
export function useGetMigrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMigrationsQuery, GetMigrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMigrationsQuery, GetMigrationsQueryVariables>(GetMigrationsDocument, options);
        }
export type GetMigrationsQueryHookResult = ReturnType<typeof useGetMigrationsQuery>;
export type GetMigrationsLazyQueryHookResult = ReturnType<typeof useGetMigrationsLazyQuery>;
export type GetMigrationsQueryResult = Apollo.QueryResult<GetMigrationsQuery, GetMigrationsQueryVariables>;
export const RunMigrationsDocument = gql`
    mutation RunMigrations {
  runMigrations {
    status
  }
}
    `;
export type RunMigrationsMutationFn = Apollo.MutationFunction<RunMigrationsMutation, RunMigrationsMutationVariables>;

/**
 * __useRunMigrationsMutation__
 *
 * To run a mutation, you first call `useRunMigrationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunMigrationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runMigrationsMutation, { data, loading, error }] = useRunMigrationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRunMigrationsMutation(baseOptions?: Apollo.MutationHookOptions<RunMigrationsMutation, RunMigrationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunMigrationsMutation, RunMigrationsMutationVariables>(RunMigrationsDocument, options);
      }
export type RunMigrationsMutationHookResult = ReturnType<typeof useRunMigrationsMutation>;
export type RunMigrationsMutationResult = Apollo.MutationResult<RunMigrationsMutation>;
export type RunMigrationsMutationOptions = Apollo.BaseMutationOptions<RunMigrationsMutation, RunMigrationsMutationVariables>;
export const namedOperations = {
  Query: {
    GetPropBestRevStatus: 'GetPropBestRevStatus',
    GetMigrations: 'GetMigrations'
  },
  Mutation: {
    SetPropBestRevStatus: 'SetPropBestRevStatus',
    RunMigrations: 'RunMigrations'
  }
}