import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface HeaderState {
  defaultSearchId?: number;
  selloutcount?: number;
  showAskRev?: boolean;
  enableSellOutFlag?: boolean;
  supportMessage?: string;
  showRateChangeLogs?: boolean;
  showRateOffsetLogs?: boolean;
}

const initialState: HeaderState = {
  defaultSearchId: undefined,
  selloutcount: 0,
  showAskRev: false,
  enableSellOutFlag: false,
  supportMessage: undefined,
  showRateChangeLogs: false,
  showRateOffsetLogs: false,
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setDefaultSearchId(state, action: PayloadAction<number | undefined>) {
      state.defaultSearchId = action.payload;
    },
    setSelloutCount(state, action: PayloadAction<number>) {
      state.selloutcount = action.payload;
    },
    setShowAskRev(state, action: PayloadAction<boolean>) {
      state.showAskRev = action.payload;
    },
    setEnableSellOutFlag(state, action: PayloadAction<boolean>) {
      state.enableSellOutFlag = action.payload;
    },
    setSupportMessage(state, action: PayloadAction<string | undefined>) {
      state.supportMessage = action.payload;
    },
    setShowRateChangeLogs(state, action: PayloadAction<boolean>) {
      state.showRateChangeLogs = action.payload;
    },
    setShowRateOffsetLogs(state, action: PayloadAction<boolean>) {
      state.showRateOffsetLogs = action.payload;
    },
  },
});

export const {
  setDefaultSearchId,
  setSelloutCount,
  setEnableSellOutFlag,
  setShowAskRev,
  setSupportMessage,
  setShowRateChangeLogs,
  setShowRateOffsetLogs,
} = headerSlice.actions;

export default headerSlice.reducer;
