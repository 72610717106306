import './number-input.scss';

import {
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material';

import { usePropertyContext } from 'context/propertyContext';

interface NumberInputProps extends OutlinedInputProps {
  isFloat?: boolean;
  showCurrencySymbol?: boolean;
  maxAmount?: number;
  styles?: React.CSSProperties;
  allowSigns?: boolean;
}

export const NumberInput = ({
  value,
  onChange,
  isFloat = false,
  showCurrencySymbol = true,
  maxAmount,
  allowSigns = false,
  styles,
  ...others
}: NumberInputProps) => {
  const { currencySymbol } = usePropertyContext();
  const startAdornment = showCurrencySymbol ? (
    <InputAdornment position='start'>{currencySymbol}</InputAdornment>
  ) : undefined;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const validNumber = new RegExp(
      allowSigns
        ? isFloat
          ? /^[+-]?\d*\.?\d*$/
          : /^[+-]?\d*$/
        : isFloat
        ? /^\d*\.?\d*$/
        : /^\d+$/
    );

    if (!validNumber.test(value)) return;

    const parsedValue = parseFloat(value);

    if (
      !Number.isNaN(parsedValue) &&
      maxAmount !== undefined &&
      parsedValue > maxAmount
    )
      return;
    onChange?.(event);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    let numberVal = parseFloat(event.target.value);
    if (Number.isNaN(numberVal)) numberVal = 0;
    event.target.value = numberVal.toFixed(isFloat ? 2 : 0);
    onChange?.(event);
  };

  return (
    <div className='common-dropdown-container' style={styles}>
      <OutlinedInput
        className='number-input'
        autoComplete='off'
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        startAdornment={startAdornment}
        {...others}
      />
    </div>
  );
};
