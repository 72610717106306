import {
  Competitor,
  LiveData,
  PropCompetitor,
  PropRecReview,
  RateChange,
  RateObj,
  RecReview,
  RecReviewStatus,
  SearchInfo,
  SearchMetricInfo,
} from 'graphql/gql-types';
import {
  addDays,
  dataDate,
  dateFormatData,
  dayRange,
  defaultPriorDays,
  getDayOfWeek,
  isDuringDataProcessing,
  today,
  todaySubtract,
} from 'features/dates/date-helpers';
import { getSeasonFormatData, useRecReview } from 'hooks/useRecReview';
import {
  selectEnableSellOutFlag,
  selectShowAskRev,
} from 'features/header/redux/selectors';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect, useMemo, useState } from 'react';
import {
  useGetIntradayForecastQuery,
  useGetPropertyRecRatesQuery,
} from 'features/rec-rates/gql/_gen_/property-rec-rates.gql';
import {
  useGetLiveDataQuery,
  useGetRateShopQuery,
  useGetUserFavNightsQuery,
} from 'features/my-view/gql/_gen_/rec-review.gql';

import { DOW_ALL } from 'features/search/search-helpers';
import { Maybe } from 'types/maybe-type';
import { REC_STATUS_ALL } from 'features/rec-rates/rec-rate-helpers';
import { SeasonType } from 'types/RecReviewTypes';
import _ from 'lodash';
import { calcTotalForecast } from './tableCustomCellRender';
import dayjs from 'dayjs';
import { labels } from 'locales/en.label';
import { selectQueryInput } from 'features/my-view/redux/selectors';
import { setShowDataProcessingMessage } from 'features/my-view/redux/my-view-slice';
import { toFixedNumber } from 'helpers/math-helpers';
import useApolloErrorAlerts from 'features/alerts/hooks/use-apollo-error-alerts';
import { usePropertyContext } from 'context/propertyContext';
import { useUser } from 'features/users/context/userContext';

type DashboardControllerProps = {
  propertyId?: string;
  askRevId?: string;
};

export type FavClickParams = {
  data: { property_id: string; stay_date: string };
};

export interface CompRateObj
  extends Partial<
    Omit<RateObj, 'hotelId' | 'arrivalDate' | 'extractDateTime' | 'changes'>
  > {
  hotelId: number;
  arrivalDate: string;
  extractDateTime: string;
  changes?: Pick<RateChange, 'day' | 'value'>[];
}

type RatesById = {
  [id: number]: CompRateObj;
};

export type RecReviewData = Omit<
  PropRecReview &
    RecReview &
    LiveData & { compRates: RatesById; season: SeasonType },
  '__typename' | 'last_updated_by'
> & { __typename?: string };

const ADR_VAR = 0.2;
const PKP_VAR = 0.2;

const getFormattedStayDate = (o: { stay_date?: string }) =>
  dataDate(o?.stay_date);

const filterByAskRevId = (rowData: RecReviewData[], askRevId: string) => {
  switch (askRevId) {
    case '1': {
      return rowData.filter((r) => r.sellout_ind === true);
    }
    case '2': {
      return rowData.filter(
        (r) => r.current_rate && r.market_rate && r.current_rate < r.market_rate
      );
    }
    case '3': {
      return rowData.filter(
        (r) => r.adr && r.adr_lyf && r.adr < r.adr_lyf * (1 - ADR_VAR)
      );
    }
    case '4': {
      return rowData.filter(
        (r) =>
          r.total_capacity &&
          r.transient_pkp_day_3 &&
          r.total_capacity * PKP_VAR < r.transient_pkp_day_3
      );
    }
    default:
      return rowData;
  }
};

const compareFunctions: { [operator: string]: (a: any, b: any) => boolean } = {
  '>': (a, b) => a > b,
  '<': (a, b) => a < b,
  '>=': (a, b) => a >= b,
  '<=': (a, b) => a <= b,
  '!=': (a, b) => a !== b,
  '=': (a, b) => a === b,
};

const compare = (a: any, operator: string, b: any) => {
  const fn = compareFunctions[operator];
  if (!fn) return false;
  return fn(a, b);
};

const compSetDTO = (compRates?: Maybe<PropCompetitor>[]) => {
  const currentComps = compRates?.find((cs) => cs?.stay_date === today());
  if (!currentComps) return [];
  const compSet: Pick<Competitor, 'hotelId' | 'name'>[] = [];

  for (let i = 1; i <= 10; i++) {
    const hotelId = currentComps[
      `comp_id_${i}` as keyof PropCompetitor
    ] as number;
    const name = currentComps[
      `comp_name_${i}` as keyof PropCompetitor
    ] as string;

    if (hotelId) compSet.push({ hotelId, name });
  }

  return compSet;
};

const compRatesDTO = (compRates?: Maybe<PropCompetitor>[]) => {
  if (!compRates?.length) return {};
  const transformedRates: { [stayDate: string]: RatesById } = {};

  compRates.forEach((dailyRates) => {
    if (!dailyRates) return;
    const ratesObj: RatesById = {};

    for (let i = 1; i <= 10; i++) {
      const hotelId = dailyRates[
        `comp_id_${i}` as keyof PropCompetitor
      ] as number;
      const value = dailyRates[
        `comp_rate_${i}` as keyof PropCompetitor
      ] as number;

      if (hotelId) {
        ratesObj[hotelId] = {
          hotelId,
          value,
          extractDateTime: dailyRates.last_updated_date!,
          arrivalDate: dailyRates.stay_date!,
        };
      }
    }
    transformedRates[dailyRates.stay_date!] = ratesObj;
  });

  return transformedRates;
};

const liveFields = new Set([
  'adr',
  'available_rooms',
  'current_otb',
  'current_rate',
  'current_revenue',
  'group_blocked',
  'group_booked',
  'occ_pct',
  'ooo',
  'revpar',
  'total_capacity',
  'transient_booked',
  'transient_capacity',
  'a_otb',
  'b_otb',
  'c_otb',
  'd_otb',
  'e_otb',
  'f_otb',
  'g_otb',
  'h_otb',
]);

export const useDashboardController = ({
  propertyId,
  askRevId,
}: DashboardControllerProps) => {
  const { user } = useUser();
  const { showPast } = usePropertyContext();

  const defaultStartDate = useMemo(
    () => (showPast ? todaySubtract(1, 'week') : today()),
    [showPast]
  );
  const daysPrior = showPast ? defaultPriorDays : 0;

  const [data, setData] = useState<RecReviewData[]>([]);

  const dispatch = useAppDispatch();
  const showAskRev = useAppSelector(selectShowAskRev);
  const enableSellOutFlag = useAppSelector(selectEnableSellOutFlag);

  // This is a search to power the AskBestREV feature, and is temporary.
  // TODO: We will build a new search for this feature in the next sprint.
  const defaultInput: SearchInfo = {
    userId: user?.id,
    duration: 365,
    selectedDays: DOW_ALL,
    startDate: defaultStartDate,
    statuses: REC_STATUS_ALL,
  };
  const queryInput = useAppSelector(selectQueryInput);

  const defaultEndDate = queryInput.duration
    ? addDays(queryInput?.duration + daysPrior, defaultStartDate).format(
        dateFormatData
      )
    : addDays(30 + daysPrior, defaultStartDate).format(dateFormatData);

  const {
    data: recReviewData,
    loading: loadingRecReview,
    error: recReviewError,
  } = useRecReview(defaultInput, propertyId);

  const {
    data: rateShopData,
    error: compSetError,
    loading: loadingRateShop,
    refetch: refetchRateShop,
  } = useGetRateShopQuery({
    skip: !propertyId,
    variables: { propertyId: propertyId! },
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: recRatesData,
    loading: loadingRecRates,
    error: recRatesError,
  } = useGetPropertyRecRatesQuery({
    skip: !propertyId,
    variables: {
      propertyId,
      dateRange: {
        startDate: defaultStartDate,
        endDate: defaultEndDate,
      },
    },
  });

  const { data: intradayForecastData } = useGetIntradayForecastQuery({
    skip: !propertyId,
    variables: { propertyId: propertyId! },
  });

  const { data: favData, error: favDataError } = useGetUserFavNightsQuery({
    skip: !propertyId || !user?.id,
    variables: {
      propertyId,
      userId: user?.id,
    },
  });

  const { data: liveRatesData } = useGetLiveDataQuery({
    skip: !propertyId,
    variables: {
      propertyId: propertyId!,
    },
  });

  useEffect(() => {
    if (
      recReviewData?.PropMetrics?.results.length === 0 &&
      isDuringDataProcessing()
    ) {
      dispatch(setShowDataProcessingMessage(true));
    } else {
      dispatch(setShowDataProcessingMessage(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recReviewData]);

  useApolloErrorAlerts([
    recReviewError,
    compSetError,
    recRatesError,
    favDataError,
  ]);

  // Prepare a list of dates to use as the foundation for the data.
  const dateRangeList = useMemo(
    () =>
      dayRange(defaultStartDate, defaultEndDate).filter((day: string) => {
        const dow = getDayOfWeek(day);
        return queryInput?.selectedDays?.includes(DOW_ALL[dow]);
      }),
    [defaultStartDate, defaultEndDate, queryInput?.selectedDays]
  );

  const metricsByDate = useMemo(
    () =>
      _.keyBy(recReviewData?.PropMetrics?.results, (m) =>
        dataDate(m.stay_date)
      ),
    [recReviewData?.PropMetrics?.results]
  );
  const compRatesByDate = useMemo(() => {
    const result: { [stayDate: string]: RatesById } = {};

    if (rateShopData?.getRateShop.dailyRates.length === 0) {
      return compRatesDTO(recReviewData?.PropCompetitorSet?.results);
    }

    rateShopData?.getRateShop.dailyRates.forEach(({ stayDate, rates }) => {
      const ratesById: RatesById = {};
      rates.forEach((r) => {
        ratesById[r.hotelId] = r;
      });
      result[stayDate] = ratesById;
    });

    return result;
  }, [
    rateShopData?.getRateShop.dailyRates,
    recReviewData?.PropCompetitorSet?.results,
  ]);
  const rateLevelsByDate = useMemo(
    () =>
      _.keyBy(recReviewData?.getPropRateLevels, (rl) =>
        dataDate(rl?.stay_date)
      ),
    [recReviewData?.getPropRateLevels]
  );
  const liveDataByDate = useMemo(
    () => _.keyBy(liveRatesData?.getLiveData, (lv) => dataDate(lv?.stay_date)),
    [liveRatesData?.getLiveData]
  );
  const recRatesByDate = useMemo(
    () =>
      _.keyBy(recRatesData?.getPropertyRecRates, (rr) =>
        dataDate(rr?.stay_date)
      ),
    [recRatesData?.getPropertyRecRates]
  );
  const seasonsByDate = useMemo(() => {
    const seasonData = recReviewData?.getPropSeasons?.filter((season) => {
      return season && dayjs(season.season_end).isSameOrAfter(defaultStartDate);
    });
    return getSeasonFormatData(seasonData);
  }, [recReviewData?.getPropSeasons, defaultStartDate]);
  const favNightsByDate: { [stayDate: string]: boolean } = useMemo(
    () =>
      _.mapValues(
        _.keyBy(favData?.listUserFavNights, getFormattedStayDate),
        'is_favorite'
      ),
    [favData?.listUserFavNights]
  );

  const compSet = useMemo(
    () =>
      rateShopData?.getRateShop.compSet.length
        ? rateShopData?.getRateShop.compSet
        : compSetDTO(recReviewData?.PropCompetitorSet?.results),
    [
      rateShopData?.getRateShop.compSet,
      recReviewData?.PropCompetitorSet?.results,
    ]
  );

  const missingStatusMap: Set<string> = new Set();

  useEffect(() => {
    const giveMeResult = (
      data: RecReviewData[],
      criteria: SearchMetricInfo
    ) => {
      const rhscond = criteria.rhsCode === 'value' ? false : true;
      const lhs = criteria.lhsCode as keyof RecReviewData;
      const rhs = criteria.rhsCode as keyof RecReviewData;

      const getValue = (row: RecReviewData, field: keyof RecReviewData) => {
        const originalValue = row[field];
        if (liveFields.has(field)) {
          const liveName = (lhs + '_live') as keyof RecReviewData;
          const liveValue = row[liveName];
          return liveValue ?? originalValue;
        }
        return originalValue;
      };

      return data.filter((row) => {
        let leftValue = getValue(row, lhs);

        if (lhs === 'occ_pct' && typeof leftValue === 'number')
          leftValue = toFixedNumber(leftValue, 1);
        if (lhs === 'fcst_occ_pct')
          leftValue = toFixedNumber(
            calcTotalForecast(row)?.fcst_occ_pct ?? 0,
            1
          );

        const rightValue = rhscond
          ? getValue(row, rhs)
          : criteria.rhsCustomValue;

        if (leftValue === undefined || rightValue === undefined) return false;
        return compare(leftValue, criteria.operatorCode, rightValue);
      });
    };

    // filterBySearchCriteria gets called recursively until queryIput.seachCriteria.length
    let filterBySearchCriteria: any = (
      data: RecReviewData[],
      start: number,
      length: number
    ) => {
      const result = giveMeResult(data, queryInput?.searchCriteria![start]!);
      if (length === 1) return result;
      if (length !== 0 && start !== length) {
        return filterBySearchCriteria(result, start + 1, length - 1);
      }
    };

    const formatDataForAgGridTable = () => {
      let rowData: RecReviewData[] =
        // Since we cannot guarantee that PropMetrics will not be length of zero, we should not use
        // it as the foundation for combining all the data. In RM Workspace we generate a list of dates and then
        // add the data from there. This allows us to show any data we do have.
        dateRangeList?.map((stay_date) => {
          const stayDate = dataDate(stay_date);

          const isPast = dayjs(stayDate).isBefore(dayjs(), 'day');

          const metrics = metricsByDate[stayDate];
          const rateLevels = rateLevelsByDate[stayDate];
          const liveData = liveDataByDate[stayDate];
          const recRates = isPast
            ? _.omit(recRatesByDate[stayDate], 'rec_status')
            : recRatesByDate[stayDate];
          const compRates = compRatesByDate[stayDate];
          const season = seasonsByDate[stayDate];
          const is_favorite = favNightsByDate[stayDate] ?? false;

          const result: RecReviewData = {
            ...metrics,
            ...rateLevels,
            ...liveData,
            ...recRates,
            compRates,
            season,
            is_favorite,
            stay_date,
          };
          return result;
        }) || [];

      // if AskRev isopen and any selection made.
      if (showAskRev && askRevId) {
        rowData = filterByAskRevId(rowData, askRevId);
      }

      if (
        enableSellOutFlag ||
        queryInput.searchName === labels.rec_review.search.search_names.alert
      ) {
        rowData = rowData.filter((r) => r.sellout_ind === true);
      }

      if (queryInput?.statuses?.length! > 0 && !loadingRecRates) {
        rowData = rowData.filter((r) => {
          const stayDate = new Date(r.stay_date);
          const today = new Date();
          if (!r.rec_status) {
            if (stayDate > today) {
              missingStatusMap.add(r.stay_date)
              console.log('Adding missing map for ', missingStatusMap.size);
            }
            return true;
          } else {
            if (stayDate > today) {
              missingStatusMap.delete(r.stay_date)
              console.log('Removing missing map for ', missingStatusMap.size);
            }
          }
          return queryInput.statuses?.includes(r.rec_status);
        });
      }

      if (queryInput?.searchCriteria?.length! > 0) {
        let filterDataBySearchCriteria: RecReviewData[] =
          rowData &&
          filterBySearchCriteria(
            rowData,
            0,
            queryInput.searchCriteria?.length!
          )!;
        rowData = filterDataBySearchCriteria;
      }

      const intradayRecRate =
        intradayForecastData?.getIntradayForecast.result?.rec_rate;
      const doaData = rowData[0];
      if (intradayRecRate && doaData?.stay_date === defaultStartDate) {
        if (doaData.rec_status === RecReviewStatus.R) {
          doaData.rec_rate = intradayRecRate;
        }
      }

      return rowData || [];
    };
    const data = formatDataForAgGridTable();
    setData(data);
  }, [
    askRevId,
    compRatesByDate,
    dateRangeList,
    enableSellOutFlag,
    favNightsByDate,
    intradayForecastData?.getIntradayForecast.result?.rec_rate,
    liveDataByDate,
    loadingRecRates,
    metricsByDate,
    queryInput.searchCriteria,
    queryInput.searchName,
    queryInput.statuses,
    rateLevelsByDate,
    recRatesByDate,
    seasonsByDate,
    showAskRev,
    defaultStartDate,
  ]);

  return {
    data,
    compSet,
    loading: loadingRecReview || loadingRecRates || loadingRateShop,
    missingStatusMap,
    refetchRateShop,
  };
};
