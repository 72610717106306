import './Login.scss';

import { Button } from '@mui/material';
import { labels } from 'locales/en.label';
import landingLogo from '../../images/landing_logo.png';
import masterBrandsLogo from '../../images/master_brands.png';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

export const Login = () => {
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    oktaAuth.setOriginalUri(history.location.pathname);
  }, [oktaAuth, history.location.pathname]);

  return (
    <div className='login'>
      <img
        style={{
          marginTop: '40px',
          marginBottom: '40px',
        }}
        src={landingLogo}
        alt='logo'
      />
      <p>
        <Button
          className='login-link'
          style={{
            fontFamily: 'Costa',
          }}
          variant='text'
          onClick={() => oktaAuth.signInWithRedirect()}
        >
          {labels.login.greeting}
        </Button>
      </p>
      <img
        style={{
          marginTop: '200px',
        }}
        src={masterBrandsLogo}
        alt='master brands logo'
      />
    </div>
  );
};
