/* eslint-disable */
import * as Types from '../../../../../graphql/gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePropSeasonMutationVariables = Types.Exact<{
  newPropSeason?: Types.InputMaybe<Types.PropSeasonInput>;
  updatedBy?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreatePropSeasonMutation = { __typename?: 'Mutation', createPropSeason?: { __typename?: 'PropSeason', property_id?: string | undefined, season_id?: string | undefined, season_name?: string | undefined, season_start?: string | undefined, season_end?: string | undefined, selected_days: Array<string>, season_priority?: number | undefined, highlight_myview?: boolean | undefined, min_rate?: number | undefined, max_rate?: number | undefined, derived_rate_type?: Types.OccupancyType | undefined, derived_rate_offset?: number | undefined, pricing_strategy?: Types.PricingStrategy | undefined, priority_threshold_low?: number | undefined, priority_threshold_med?: number | undefined, priority_threshold_high?: number | undefined, autopilot_low?: Types.AutopilotOption | undefined, autopilot_med?: Types.AutopilotOption | undefined, autopilot_high?: Types.AutopilotOption | undefined, last_updated_by?: string | undefined, last_updated_date?: string | undefined, use_proxy?: boolean | undefined, proxy_type?: Types.ProxyType | undefined, proxy_comp_id?: number | undefined, proxy_offset_amt?: number | undefined } | undefined };

export type GetPropSeasonsQueryVariables = Types.Exact<{
  propertyId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetPropSeasonsQuery = { __typename?: 'Query', getPropSeasons?: Array<{ __typename?: 'PropSeason', property_id?: string | undefined, season_id?: string | undefined, season_name?: string | undefined, season_start?: string | undefined, season_end?: string | undefined, selected_days: Array<string>, season_priority?: number | undefined, highlight_myview?: boolean | undefined, min_rate?: number | undefined, max_rate?: number | undefined, derived_rate_type?: Types.OccupancyType | undefined, derived_rate_offset?: number | undefined, pricing_strategy?: Types.PricingStrategy | undefined, priority_threshold_low?: number | undefined, priority_threshold_med?: number | undefined, priority_threshold_high?: number | undefined, autopilot_low?: Types.AutopilotOption | undefined, autopilot_med?: Types.AutopilotOption | undefined, autopilot_high?: Types.AutopilotOption | undefined, last_updated_by?: string | undefined, last_updated_date?: string | undefined, use_proxy?: boolean | undefined, proxy_type?: Types.ProxyType | undefined, proxy_comp_id?: number | undefined, proxy_offset_amt?: number | undefined }> | undefined };

export type UpdateSeasonMutationVariables = Types.Exact<{
  updatedSeason?: Types.InputMaybe<Types.UpdateSeasonInput>;
  updatedBy?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateSeasonMutation = { __typename?: 'Mutation', updateSeason?: { __typename?: 'UpdateSeasonsResult', success?: boolean | undefined } | undefined };

export type UpdateSeasonsPrioritiesMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.UpdateSeasonPrioritiesInput>;
}>;


export type UpdateSeasonsPrioritiesMutation = { __typename?: 'Mutation', updateSeasonsPriorities?: Array<{ __typename?: 'PropSeason', season_id?: string | undefined, season_priority?: number | undefined } | undefined> | undefined };

export type DeleteSeasonsMutationVariables = Types.Exact<{
  selectedIds: Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>;
  propertyId: Types.Scalars['String'];
}>;


export type DeleteSeasonsMutation = { __typename?: 'Mutation', deleteSeasons?: { __typename?: 'SeasonResponse', status_code?: number | undefined, result_rows?: number | undefined, success?: boolean | undefined } | undefined };


export const CreatePropSeasonDocument = gql`
    mutation CreatePropSeason($newPropSeason: PropSeasonInput, $updatedBy: String) {
  createPropSeason(season: $newPropSeason, updatedBy: $updatedBy) {
    property_id
    season_id
    season_name
    season_start
    season_end
    selected_days
    season_priority
    highlight_myview
    min_rate
    max_rate
    derived_rate_type
    derived_rate_offset
    pricing_strategy
    priority_threshold_low
    priority_threshold_med
    priority_threshold_high
    autopilot_low
    autopilot_med
    autopilot_high
    last_updated_by
    last_updated_date
    use_proxy
    proxy_type
    proxy_comp_id
    proxy_offset_amt
  }
}
    `;
export type CreatePropSeasonMutationFn = Apollo.MutationFunction<CreatePropSeasonMutation, CreatePropSeasonMutationVariables>;

/**
 * __useCreatePropSeasonMutation__
 *
 * To run a mutation, you first call `useCreatePropSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePropSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPropSeasonMutation, { data, loading, error }] = useCreatePropSeasonMutation({
 *   variables: {
 *      newPropSeason: // value for 'newPropSeason'
 *      updatedBy: // value for 'updatedBy'
 *   },
 * });
 */
export function useCreatePropSeasonMutation(baseOptions?: Apollo.MutationHookOptions<CreatePropSeasonMutation, CreatePropSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePropSeasonMutation, CreatePropSeasonMutationVariables>(CreatePropSeasonDocument, options);
      }
export type CreatePropSeasonMutationHookResult = ReturnType<typeof useCreatePropSeasonMutation>;
export type CreatePropSeasonMutationResult = Apollo.MutationResult<CreatePropSeasonMutation>;
export type CreatePropSeasonMutationOptions = Apollo.BaseMutationOptions<CreatePropSeasonMutation, CreatePropSeasonMutationVariables>;
export const GetPropSeasonsDocument = gql`
    query GetPropSeasons($propertyId: String) {
  getPropSeasons(propertyId: $propertyId) {
    property_id
    season_id
    season_name
    season_start
    season_end
    selected_days
    season_priority
    highlight_myview
    min_rate
    max_rate
    derived_rate_type
    derived_rate_offset
    pricing_strategy
    priority_threshold_low
    priority_threshold_med
    priority_threshold_high
    autopilot_low
    autopilot_med
    autopilot_high
    last_updated_by
    last_updated_date
    use_proxy
    proxy_type
    proxy_comp_id
    proxy_offset_amt
  }
}
    `;

/**
 * __useGetPropSeasonsQuery__
 *
 * To run a query within a React component, call `useGetPropSeasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropSeasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropSeasonsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetPropSeasonsQuery(baseOptions?: Apollo.QueryHookOptions<GetPropSeasonsQuery, GetPropSeasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropSeasonsQuery, GetPropSeasonsQueryVariables>(GetPropSeasonsDocument, options);
      }
export function useGetPropSeasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropSeasonsQuery, GetPropSeasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropSeasonsQuery, GetPropSeasonsQueryVariables>(GetPropSeasonsDocument, options);
        }
export type GetPropSeasonsQueryHookResult = ReturnType<typeof useGetPropSeasonsQuery>;
export type GetPropSeasonsLazyQueryHookResult = ReturnType<typeof useGetPropSeasonsLazyQuery>;
export type GetPropSeasonsQueryResult = Apollo.QueryResult<GetPropSeasonsQuery, GetPropSeasonsQueryVariables>;
export const UpdateSeasonDocument = gql`
    mutation UpdateSeason($updatedSeason: UpdateSeasonInput, $updatedBy: String) {
  updateSeason(updatedSeason: $updatedSeason, updatedBy: $updatedBy) {
    success
  }
}
    `;
export type UpdateSeasonMutationFn = Apollo.MutationFunction<UpdateSeasonMutation, UpdateSeasonMutationVariables>;

/**
 * __useUpdateSeasonMutation__
 *
 * To run a mutation, you first call `useUpdateSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeasonMutation, { data, loading, error }] = useUpdateSeasonMutation({
 *   variables: {
 *      updatedSeason: // value for 'updatedSeason'
 *      updatedBy: // value for 'updatedBy'
 *   },
 * });
 */
export function useUpdateSeasonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeasonMutation, UpdateSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSeasonMutation, UpdateSeasonMutationVariables>(UpdateSeasonDocument, options);
      }
export type UpdateSeasonMutationHookResult = ReturnType<typeof useUpdateSeasonMutation>;
export type UpdateSeasonMutationResult = Apollo.MutationResult<UpdateSeasonMutation>;
export type UpdateSeasonMutationOptions = Apollo.BaseMutationOptions<UpdateSeasonMutation, UpdateSeasonMutationVariables>;
export const UpdateSeasonsPrioritiesDocument = gql`
    mutation UpdateSeasonsPriorities($input: UpdateSeasonPrioritiesInput) {
  updateSeasonsPriorities(input: $input) {
    season_id
    season_priority
  }
}
    `;
export type UpdateSeasonsPrioritiesMutationFn = Apollo.MutationFunction<UpdateSeasonsPrioritiesMutation, UpdateSeasonsPrioritiesMutationVariables>;

/**
 * __useUpdateSeasonsPrioritiesMutation__
 *
 * To run a mutation, you first call `useUpdateSeasonsPrioritiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeasonsPrioritiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeasonsPrioritiesMutation, { data, loading, error }] = useUpdateSeasonsPrioritiesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSeasonsPrioritiesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeasonsPrioritiesMutation, UpdateSeasonsPrioritiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSeasonsPrioritiesMutation, UpdateSeasonsPrioritiesMutationVariables>(UpdateSeasonsPrioritiesDocument, options);
      }
export type UpdateSeasonsPrioritiesMutationHookResult = ReturnType<typeof useUpdateSeasonsPrioritiesMutation>;
export type UpdateSeasonsPrioritiesMutationResult = Apollo.MutationResult<UpdateSeasonsPrioritiesMutation>;
export type UpdateSeasonsPrioritiesMutationOptions = Apollo.BaseMutationOptions<UpdateSeasonsPrioritiesMutation, UpdateSeasonsPrioritiesMutationVariables>;
export const DeleteSeasonsDocument = gql`
    mutation DeleteSeasons($selectedIds: [String]!, $propertyId: String!) {
  deleteSeasons(selectedIds: $selectedIds, propertyId: $propertyId) {
    status_code
    result_rows
    success
  }
}
    `;
export type DeleteSeasonsMutationFn = Apollo.MutationFunction<DeleteSeasonsMutation, DeleteSeasonsMutationVariables>;

/**
 * __useDeleteSeasonsMutation__
 *
 * To run a mutation, you first call `useDeleteSeasonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeasonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeasonsMutation, { data, loading, error }] = useDeleteSeasonsMutation({
 *   variables: {
 *      selectedIds: // value for 'selectedIds'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useDeleteSeasonsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSeasonsMutation, DeleteSeasonsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSeasonsMutation, DeleteSeasonsMutationVariables>(DeleteSeasonsDocument, options);
      }
export type DeleteSeasonsMutationHookResult = ReturnType<typeof useDeleteSeasonsMutation>;
export type DeleteSeasonsMutationResult = Apollo.MutationResult<DeleteSeasonsMutation>;
export type DeleteSeasonsMutationOptions = Apollo.BaseMutationOptions<DeleteSeasonsMutation, DeleteSeasonsMutationVariables>;
export const namedOperations = {
  Query: {
    GetPropSeasons: 'GetPropSeasons'
  },
  Mutation: {
    CreatePropSeason: 'CreatePropSeason',
    UpdateSeason: 'UpdateSeason',
    UpdateSeasonsPriorities: 'UpdateSeasonsPriorities',
    DeleteSeasons: 'DeleteSeasons'
  }
}