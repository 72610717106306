import { BiLinkExternal } from 'react-icons/bi';
import { FaSpinner } from 'react-icons/fa';
import { HtmlTooltip } from 'components/html-tooltip';
import { ICellRendererParams } from 'ag-grid-community';
import { Link } from 'react-router-dom';
import { SeasonType } from 'types/RecReviewTypes';
import { formatDateWithGivenFormatter } from 'features/dates/date-helpers';
import styles from 'common/_variables.module.scss';

type StayDateWithSeasonTooltipProps = {
  params: ICellRendererParams<any, string>;
};
const MAX_CHAR = 25;

export const StayDateWithSeasonTooltip = ({
  params,
}: StayDateWithSeasonTooltipProps) => {
  const { value, data } = params;

  const stayDate = value ? (
    formatDateWithGivenFormatter(value, 'M/DD/YY')
  ) : (
    <FaSpinner color={styles.graphOrange} size={20} />
  );

  const season: SeasonType = data?.season;

  if (season) {
    return (
      <>
        <HtmlTooltip
          placement='right'
          title={
            <>
              <div
                style={{
                  color: '#484848',
                  fontSize: '14px',
                  verticalAlign: 'bottom',
                  lineHeight: '20px',
                }}
              >
                <span>
                  {season.season_name.length < MAX_CHAR
                    ? season.season_name
                    : season.season_name.substring(0, MAX_CHAR) + '...'}
                </span>
                <span
                  style={{
                    marginLeft: '10px',
                    verticalAlign: 'bottom',
                    color: '#484848',
                  }}
                >
                  <Link
                    to={{
                      pathname: '/rules/seasons',
                      state: { season: season },
                    }}
                    style={{ color: '#484848' }}
                  >
                    <BiLinkExternal />
                  </Link>
                </span>
              </div>
            </>
          }
        >
          <span style={{ textDecoration: 'underline' }}>{stayDate}</span>
        </HtmlTooltip>
      </>
    );
  } else {
    return <span>{stayDate}</span>;
  }
};
