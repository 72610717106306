import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { Security } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
};

export const Okta = ({ children }: Props) => {
  const history = useHistory();

  const oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_CLIENT_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['bestrev', 'email', 'openid', 'profile', 'offline_access'],
  });

  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};
