import './toggle-switch.scss';

interface ToggleProps {
  id: string;
  checked: boolean;
  options: [string, string];
  onToggle: (checked: boolean) => void;
}

export const ToggleSwitch = ({
  id,
  checked,
  options,
  onToggle,
}: ToggleProps) => {
  return (
    <div className='toggle-switch'>
      <input
        type='checkbox'
        id={id}
        checked={checked}
        onChange={(e) => {
          onToggle(e.target.checked);
        }}
      />
      <div className='content'>
        <label htmlFor={id}>
          <div className='toggle' />
          <div className='options'>
            <p className={!checked ? 'on' : 'off'}>{options[0]}</p>
            <p className={checked ? 'on' : 'off'}>{options[1]}</p>
          </div>
        </label>
      </div>
    </div>
  );
};
