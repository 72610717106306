import './season-form.scss';

import { Field, useFormikContext } from 'formik';
import { SeasonFormValues, defaultValues } from './seasons';
import { Select, TextField } from 'formik-mui';
import dayjs, { Dayjs } from 'dayjs';
import { formatUpdatedAt, today } from 'features/dates/date-helpers';
import {
  selectCurrentSeason,
  unsavedDataChanged,
} from 'features/rules/redux/rules-slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useCallback, useEffect, useState } from 'react';

import { DayPicker } from 'components/DayPicker/DayPicker';
import { InputDate } from 'components/InputDate/InputDate';
import { MenuItem } from '@mui/material';
import { SeasonPriority } from 'types/SeasonTypes';
import { SelectedDays } from 'types/SearchTypes';
import { labels } from 'locales/en.label';
import { useOnPropertyChange } from 'features/rules/seasons/seasons-hooks';
import { useUser } from 'features/users/context/userContext';

const getValidDays = (startDate: Dayjs, endDate: Dayjs) => {
  const dayNames: SelectedDays[] = [];
  let date: Dayjs = startDate;
  while (date.isSameOrBefore(endDate) && dayNames.length < 7) {
    const dayName = date.format('dddd').toLowerCase() as SelectedDays;
    if (!dayNames.includes(dayName)) {
      dayNames.push(dayName);
    }
    date = date.add(1, 'day');
  }
  return dayNames;
};

export const SeasonForm = () => {
  const { isReadOnly } = useUser();
  const dispatch = useAppDispatch();
  const selectedSeason = useAppSelector(selectCurrentSeason);
  const {
    values: { id, seasonName, startDate, endDate },
    dirty,
    setFieldValue,
    resetForm,
  } = useFormikContext<SeasonFormValues>();

  const [validDays, setValidDays] = useState<SelectedDays[]>([]);

  const defaultSelected = seasonName === labels.rules.default_season_name;

  useEffect(() => {
    dispatch(unsavedDataChanged(dirty));
  }, [dispatch, dirty]);

  const resetCallback = useCallback(() => {
    resetForm({ values: defaultValues });
  }, [resetForm]);
  useOnPropertyChange(resetCallback);

  useEffect(() => {
    if (selectedSeason) {
      const { season_start, season_end } = selectedSeason;
      setValidDays(getValidDays(dayjs(season_start), dayjs(season_end)));
    } else {
      setValidDays(getValidDays(dayjs(startDate), dayjs(endDate)));
    }
  }, [endDate, selectedSeason, startDate]);

  return (
    <div className='season-form'>
      <div className='row'>
        <Field
          className='name'
          name='seasonName'
          label={labels.add_form.season_name}
          disabled={id !== null || isReadOnly}
          component={TextField}
        />
      </div>
      <div className='row'>
        <InputDate
          name='startDate'
          label={labels.add_form.start_date}
          disablePast
          disabled={
            isReadOnly ||
            defaultSelected ||
            (id !== null && dayjs(startDate).isBefore(today()))
          }
          onValueChange={(val) => {
            const selectedDays = getValidDays(dayjs(val), dayjs(endDate));
            setFieldValue('selectedDays', selectedDays);
          }}
        />
      </div>
      <div className='row'>
        <InputDate
          name='endDate'
          label={labels.add_form.end_date}
          disablePast
          disabled={defaultSelected || isReadOnly}
          minDate={dayjs(startDate).add(1, 'day')}
          onValueChange={(val) => {
            const selectedDays = getValidDays(dayjs(startDate), dayjs(val));
            setFieldValue('selectedDays', selectedDays);
          }}
        />
        <div className='item'>
          <DayPicker
            name='selectedDays'
            validDays={validDays}
            isTooltipVisible
            isDisabled={defaultSelected || isReadOnly}
          />
        </div>
      </div>
      <div className='row'>
        <label className='highlight-myview-checkbox'>
          {labels.add_form.highlight_myview}
          <Field
            type='checkbox'
            name='highlightInMyView'
            disabled={defaultSelected || isReadOnly}
          />
        </label>
        <div className='item'>
          <div className='priority'>
            <label htmlFor='priority'>{labels.add_form.priority}</label>
            <Field
              name='priority'
              component={Select}
              disabled={defaultSelected || isReadOnly}
              inputProps={{
                id: 'priority',
              }}
            >
              {Object.entries(SeasonPriority).map(
                ([key, value]) =>
                  !Number.isNaN(Number(value)) && (
                    <MenuItem key={key} value={value}>
                      {key}
                    </MenuItem>
                  )
              )}
            </Field>
          </div>
        </div>
      </div>

      <span className='updated-by'>
        {selectedSeason && (
          <>
            <span className='footnote'>* </span>
            {labels.add_form.last_updated_by} {selectedSeason.last_updated_by}{' '}
            {labels.add_form.on}{' '}
            {formatUpdatedAt(
              selectedSeason.last_updated_date,
              'M/D/YYYY h:mm A Z'
            )}
          </>
        )}
      </span>
    </div>
  );
};
