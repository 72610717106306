import styles from 'common/_variables.module.scss';

export const occupancyChartConfig: Record<string, any> = {
  chart: {
    type: 'column',
    height: 150,
    spacing: [0, 16, 1, 16],
  },
  title: {
    text: 'Occupancy',
    align: 'left',
    margin: 0,
    style: {
      fontSize: '1.25rem',
      color: styles.colorGrey60,
    },
  },
  accessibility: {
    enabled: false,
  },
  credits: { enabled: false },
  legend: {
    align: 'right',
    layout: 'vertical',
    y: 5,
    margin: 0,
    itemMarginTop: 10,
    symbolRadius: 0,
    symbolHeight: 10,
    itemStyle: {
      fontWeight: '400',
      lineHeight: '16px',
      color: styles.colorGrey70,
    },
    useHTML: true,
    labelFormat:
      '<div style="display:flex;justify-content:space-between;width:80px"><span>{name}</span> <span>{yData}%</span></div>',
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.y}%</b>',
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  colors: [styles.graphLighterBlue, styles.graphDarkGrey, '#90ef8e'],
  xAxis: {
    categories: ['', ''],
    visible: false,
  },
  yAxis: [
    {
      min: -1,
      max: 100,
      startOnTick: false,
      visible: false,
    },
    {
      min: -1,
      max: 100,
      startOnTick: false,
      visible: false,
      opposite: true,
    },
  ],
  plotOptions: {
    column: {
      grouping: false,
      pointWidth: 10,
      borderRadius: 5,
      borderWidth: 0,
    },
  },
  series: [
    {
      name: 'LY Final',
      pointPlacement: -0.2,
    },
    {
      name: 'Forecast',
      pointPlacement: 0.2,
      yAxis: 1,
    },
    {
      name: 'My Hotel',
      pointPlacement: 0.2,
      yAxis: 1,
    },
  ],
};
