import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface UsersState {
  isSuperAdmin: boolean;
}

const initialState = {
  isSuperAdmin: false,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSuperAdmin(state, action: PayloadAction<boolean>) {
      state.isSuperAdmin = action.payload;
    },
  },
});

export const { setSuperAdmin } = usersSlice.actions;

export default usersSlice.reducer;
