import { CgSortAz, CgSortZa } from 'react-icons/cg';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';

import { labels } from 'locales/en.label';
import './SortByButton.scss';

interface SortButtonProps {
  value: string;
  onOrderChange: (order: string) => void;
}

export const SortButton = ({ value, onOrderChange }: SortButtonProps) => {
  return (
    <RadioGroup
      row
      aria-labelledby='segmented-controls-example'
      className='sort-btn'
      name='justify'
      value={value}
      onChange={(event) => {
        onOrderChange(event.target.value);
      }}
      sx={{
        bgcolor: 'neutral.softBg',
      }}
    >
      {[labels.season_list.ascending, labels.season_list.descending].map(
        (item) => (
          <Radio
            key={item}
            className='sort-btn__label'
            color='neutral'
            value={item}
            disableIcon
            label={
              {
                [labels.season_list.ascending]: (
                  <CgSortZa
                    className='sort-btn__icon'
                    style={{ height: '16px', width: '16px' }}
                    title={labels.season_list.ascending}
                  />
                ),
                [labels.season_list.descending]: (
                  <CgSortAz
                    className='sort-btn__icon'
                    style={{ height: '16px', width: '16px' }}
                    title={labels.season_list.descending}
                  />
                ),
              }[item]
            }
            variant='plain'
            sx={{
              px: 2,
              alignItems: 'center',
            }}
            componentsProps={{
              action: (actionObj) => {
                return {
                  sx: {
                    ...(actionObj.checked && {
                      bgcolor: 'green',
                      boxShadow: 'md',
                      '&:hover': {
                        bgcolor: 'light-grey',
                      },
                    }),
                  },
                };
              },
            }}
          />
        )
      )}
    </RadioGroup>
  );
};
