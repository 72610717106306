import { BiMinus, BiPlus } from 'react-icons/bi';
import { ICellEditorParams, ICellRendererParams } from 'ag-grid-community';
import { IconButton, Rating, Tooltip } from '@mui/material';
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { AiFillStar } from 'react-icons/ai';
import styles from 'common/_variables.module.scss';
import { useUser } from 'features/users/context/userContext';

export const renderRating = (params: ICellRendererParams) => {
  if (params.node.rowPinned) return <></>;
  return (
    <Rating
      name='read-only'
      value={params.value}
      readOnly
      size='small'
      icon={<AiFillStar fontSize='inherit' color={styles.graphOrange} />}
      emptyIcon={<AiFillStar fontSize='inherit' color={styles.lightGrey} />}
    />
  );
};

export const OverrideCell = ({ value, node }: ICellRendererParams) => {
  return node.isRowPinned() ? (
    <span
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
      tabIndex={-1}
    >
      {value ?? 0}
    </span>
  ) : (
    <span
      style={{
        backgroundColor: 'rgba(255, 212, 100, 0.3)',
        width: '100%',
        borderRadius: '10px',
        padding: '5px 0',
      }}
      className='rateToUploadCell'
      aria-label='Weighting'
    >
      {value ?? 0}
    </span>
  );
};

const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';

export const NumericCellEditor = memo(
  forwardRef((props: ICellEditorParams, ref) => {
    const createInitialState = () => {
      let startValue;
      if (props.eventKey === KEY_BACKSPACE || props.eventKey === KEY_DELETE) {
        startValue = '';
      } else if (props.charPress) {
        startValue = props.charPress;
      } else {
        startValue = props.value;
      }
      return {
        value: startValue,
      };
    };

    const initialState = createInitialState();
    const [value, setValue] = useState(initialState.value);

    const refInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
      const eInput = refInput.current!;
      if (!props.node.isRowPinned()) eInput.focus();
      else {
        props.stopEditing();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isLeftOrRight = (key: string) => {
      return ['ArrowLeft', 'ArrowRight'].includes(key);
    };

    const isCharNumeric = (charStr: string) => {
      const isValHasPeriod = charStr === '.' ? true : false;
      return (
        !isNaN(parseFloat(charStr)) ||
        !isNaN(parseInt(charStr)) ||
        isValHasPeriod
      );
    };

    const deleteOrBackspace = (key: string) => {
      return [KEY_DELETE, KEY_BACKSPACE].includes(key);
    };

    const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
      const { key } = event;
      if (isLeftOrRight(key) || deleteOrBackspace(key)) {
        event.stopPropagation();
        return;
      }
      if (!isCharNumeric(key)) {
        event.preventDefault();
      }
    };

    const onInputChange = useCallback((event) => {
      const { value } = event.target;
      const regex = /^0$|^[1-9]\d?$|^100$/;
      if (value === '' || regex.test(value)) {
        setValue(value);
      }
    }, []);

    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          return value;
        },
      };
    });

    return (
      <input
        ref={refInput}
        value={value}
        onChange={onInputChange}
        onKeyDown={onKeyDown}
        className='numeric-editor'
        aria-label='Numeric Editor'
      />
    );
  })
);

type ModifyCompSetCellRendererProps = {
  onClick: () => void;
  isInCompetitorSet: boolean;
};
export const ModifyCompSetCellRenderer = ({
  onClick,
  isInCompetitorSet,
}: ModifyCompSetCellRendererProps) => {
  const Icon = isInCompetitorSet ? BiMinus : BiPlus;
  const { isReadOnly } = useUser();

  return (
    <IconButton
      aria-label='Add to Competitor Set'
      onClick={onClick}
      disabled={isReadOnly}
      disableFocusRipple
      disableRipple
    >
      <Icon size={14} color={styles.graphBrown} />
    </IconButton>
  );
};

const truncateString = (cellValue: string, maxLength: number) => {
  if (cellValue.length > maxLength) {
    return cellValue.slice(0, maxLength) + '...';
  }
  return cellValue;
};

interface CellRendererParams {
  maxLength: number;
}

export const ellipsisCellRenderer = (params: any) => {
  const { value } = params;
  const { maxLength } = params.colDef.cellRendererParams as CellRendererParams;
  const truncatedText = truncateString(value, maxLength);
  const showTooltip = value.length > maxLength;

  return (
    <div className='ellipsis-cell'>
      {showTooltip ? (
        <Tooltip title={value} placement='top'>
          <span>{truncatedText}</span>
        </Tooltip>
      ) : (
        <span>{value}</span>
      )}
    </div>
  );
};
