import { IHeaderParams } from 'ag-grid-community';
import { IconType } from 'react-icons/lib';
import Tooltip from '@mui/material/Tooltip';
import { labels } from 'locales/en.label';
import { useRef } from 'react';
export interface ICustomHeaderParams extends IHeaderParams {
  headerIcon: string | IconType;
  addRemoveIcon: string;
  onClick: () => void; // on Plus/Minus click
  field: string;
}

export const CustomHeader = (props: ICustomHeaderParams) => {
  const { headerIcon } = props;
  const refButton = useRef(null);
  const refAddButton = useRef(null);

  const onSortRequested = (order: 'asc' | 'desc', event: any) => {
    props.setSort(order as any, event.shiftKey);
  };

  let columnIcon = null;
  if (headerIcon) {
    columnIcon = (
      <div ref={refButton} className='customHeader__column-icon'>
        {typeof headerIcon === 'string' ? (
          <i className={`fa ${props.headerIcon}`} />
        ) : (
          headerIcon
        )}
      </div>
    );
  }

  let sort = null;
  if (props.enableSorting) {
    sort = (
      <div className='customHeader__name'>
        {props.headerIcon === undefined && (
          <span className='customHeader__name-label'>{props.displayName}</span>
        )}
        {props.column.isSortAscending() && (
          <span className={`sortDownArrow`}>
            <i className='fa fa-sort-up'></i>
          </span>
        )}
        {props.column.isSortDescending() && (
          <span className={`sortUprrow`}>
            <i className='fa fa-sort-down'></i>
          </span>
        )}
      </div>
    );
  } else {
    sort = (
      <div className='customHeader__name'>
        {props.headerIcon === undefined && (
          <span className='customHeader__name-label'>{props.displayName}</span>
        )}
      </div>
    );
  }
  const handleClick = (event: any) => {
    if (props.column.isSortAscending()) {
      onSortRequested('desc', event);
    } else {
      onSortRequested('asc', event);
    }
  };
  let addEle = null;
  if (props.addRemoveIcon) {
    const iconType = props.addRemoveIcon;
    addEle = props.addRemoveIcon ? (
      <Tooltip
        title={
          iconType === 'fa-plus'
            ? labels.rec_review.dashboard.more_action.add_column_to_metric
            : labels.rec_review.dashboard.more_action.remove_column_from_metric
        }
        arrow
        placement='top'
      >
        <div
          ref={refAddButton}
          id={props.field}
          className={'customHeaderAddButton customHeader__name-label'}
          onClick={props.onClick}
          data-column={props.field}
        >
          <i className={`fa ${props.addRemoveIcon}`}></i>
        </div>
      </Tooltip>
    ) : (
      ''
    );
  }

  return (
    <>
      <div className='customHeader' onClick={(e) => handleClick(e)}>
        {addEle}
        <div className='customHeaderLabel'>
          {columnIcon}
          {sort}
        </div>
      </div>
    </>
  );
};
