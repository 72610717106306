import { RateOffsetTable, useRateOffsetLogs } from 'features/rate-offset-log';

import { RateOffsetLog } from 'graphql/gql-types';
import { useState } from 'react';

export const Main = () => {
  const [page, setPage] = useState(0);
  const { data, loading } = useRateOffsetLogs(page);
  const dataLength = data?.getHotelRateOffsetLogs?.length;

  const handlePaging = (pageChangeNumber: number) => {
    setPage((prevState) =>
      prevState + pageChangeNumber > -1
        ? prevState + pageChangeNumber
        : prevState
    );
  };

  return (
    <div>
      <h1>Rate Offset Log</h1>
      {loading && <div>Loading...</div>}

      {data && !loading && (
        <>
          <RateOffsetTable
            data={data?.getHotelRateOffsetLogs as RateOffsetLog[]}
          />
          <div className='form-buttons'>
            <div className='left'>
              <button
                type='button'
                onClick={() => handlePaging(-1)}
                disabled={page <= 0}
                className='back'
              >
                Previous
              </button>
            </div>
            <div className='right'>
              <button
                type='button'
                onClick={() => handlePaging(1)}
                disabled={!!dataLength && dataLength < 8}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
      {!data && !loading && <div>No data found</div>}
    </div>
  );
};
