import styles from 'common/_variables.module.scss';

export const revChartConfig: Record<string, any> = {
  chart: {
    type: 'column',
    height: 240,
    backgroundColor: 'transparent',
  },
  title: {
    text: undefined,
  },
  accessibility: {
    enabled: false,
  },
  legend: {
    verticalAlign: 'top',
    layout: 'vertical',
    itemMarginBottom: 5,
    symbolRadius: 0,
    symbolHeight: 10,
    useHTML: true,
    itemStyle: {
      fontWeight: '400',
      lineHeight: '17px',
      color: styles.colorGrey70,
    },
    x: 64,
  },
  credits: { enabled: false },
  colors: [styles.graphLighterBlue, styles.graphGreen],
  xAxis: {
    lineWidth: 0,
  },
  yAxis: {
    visible: false,
  },
  tooltip: {
    formatter: () => '',
  },
  plotOptions: {
    column: {
      pointPadding: 0.1,
      groupPadding: 0.25,
      borderWidth: 1,
      borderRadius: 5,
    },
  },
};

export const buildTooltip = (currencySymbol: string) => {
  return {
    headerFormat:
      '<span style="font-size:0.75rem;padding-left:3px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="padding:0 4px 0 0">{series.name}:</td>' +
      `<td style="padding:0"><b>${currencySymbol}{point.y}</b></td></tr>`,
    footerFormat: '</table>',
    shared: true,
    useHTML: true,
    borderColor: '#ccc',
    backgroundColor: '#fff',
    borderRadius: 10,
  };
};
