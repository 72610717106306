/* eslint-disable */
import * as Types from '../../../../graphql/gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRateTrendBySnapshotQueryVariables = Types.Exact<{
  propertyId?: Types.InputMaybe<Types.Scalars['String']>;
  stayDate?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetRateTrendBySnapshotQuery = { __typename?: 'Query', getRateTrendBySnapshot?: Array<{ __typename?: 'RateTrends', current_rate?: number | undefined, market_rate?: number | undefined, rec_rate?: number | undefined, current_otb?: number | undefined, comp_id_1?: number | undefined, comp_rate_1?: number | undefined, comp_id_2?: number | undefined, comp_rate_2?: number | undefined, comp_id_3?: number | undefined, comp_rate_3?: number | undefined, comp_id_4?: number | undefined, comp_rate_4?: number | undefined, comp_id_5?: number | undefined, comp_rate_5?: number | undefined, comp_id_6?: number | undefined, comp_rate_6?: number | undefined, comp_id_7?: number | undefined, comp_rate_7?: number | undefined, comp_id_8?: number | undefined, comp_rate_8?: number | undefined, comp_id_9?: number | undefined, comp_rate_9?: number | undefined, comp_id_10?: number | undefined, comp_rate_10?: number | undefined, last_updated_date?: string | undefined } | undefined> | undefined };


export const GetRateTrendBySnapshotDocument = gql`
    query GetRateTrendBySnapshot($propertyId: String, $stayDate: String) {
  getRateTrendBySnapshot(propertyId: $propertyId, stayDate: $stayDate) {
    current_rate
    market_rate
    rec_rate
    current_otb
    comp_id_1
    comp_rate_1
    comp_id_2
    comp_rate_2
    comp_id_3
    comp_rate_3
    comp_id_4
    comp_rate_4
    comp_id_5
    comp_rate_5
    comp_id_6
    comp_rate_6
    comp_id_7
    comp_rate_7
    comp_id_8
    comp_rate_8
    comp_id_9
    comp_rate_9
    comp_id_10
    comp_rate_10
    last_updated_date
  }
}
    `;

/**
 * __useGetRateTrendBySnapshotQuery__
 *
 * To run a query within a React component, call `useGetRateTrendBySnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRateTrendBySnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRateTrendBySnapshotQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      stayDate: // value for 'stayDate'
 *   },
 * });
 */
export function useGetRateTrendBySnapshotQuery(baseOptions?: Apollo.QueryHookOptions<GetRateTrendBySnapshotQuery, GetRateTrendBySnapshotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRateTrendBySnapshotQuery, GetRateTrendBySnapshotQueryVariables>(GetRateTrendBySnapshotDocument, options);
      }
export function useGetRateTrendBySnapshotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRateTrendBySnapshotQuery, GetRateTrendBySnapshotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRateTrendBySnapshotQuery, GetRateTrendBySnapshotQueryVariables>(GetRateTrendBySnapshotDocument, options);
        }
export type GetRateTrendBySnapshotQueryHookResult = ReturnType<typeof useGetRateTrendBySnapshotQuery>;
export type GetRateTrendBySnapshotLazyQueryHookResult = ReturnType<typeof useGetRateTrendBySnapshotLazyQuery>;
export type GetRateTrendBySnapshotQueryResult = Apollo.QueryResult<GetRateTrendBySnapshotQuery, GetRateTrendBySnapshotQueryVariables>;
export const namedOperations = {
  Query: {
    GetRateTrendBySnapshot: 'GetRateTrendBySnapshot'
  }
}