import {
  CREATE_USER_HOTEL_METRICS,
  GET_MY_METRICS,
  UPDATE_USER_HOTEL_METRICS,
} from '../../../queries/gqlMyView';
import {
  setMyMetrics,
  setTabs,
} from '../../../features/my-view/redux/my-view-slice';
import { useEffect, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { useAppDispatch } from 'redux/hooks';

type GetMetricArgs = {
  userId?: string;
  propertyId?: string;
};

export const useMyMetrics = ({ propertyId, userId }: GetMetricArgs) => {
  // Apollo Query for Column Data
  const { data: myMetricsData } = useQuery(GET_MY_METRICS, {
    skip: !userId || !propertyId,
    variables: {
      userId,
      propertyId,
    },
    fetchPolicy: 'network-only',
  });

  const myMetrics = useMemo(() => {
    if (myMetricsData?.getMyMetrics.columns) {
      const cols = JSON.parse(myMetricsData?.getMyMetrics.columns).myMetrics;
      return cols;
    }
  }, [myMetricsData]);

  const tabColumns = useMemo(() => {
    if (myMetricsData?.getMyMetrics.columns) {
      const tabs = JSON.parse(myMetricsData.getMyMetrics.columns).tabs;
      return tabs;
    }
  }, [myMetricsData]);

  const [createUserHotelMetrics] = useMutation(CREATE_USER_HOTEL_METRICS);

  const [updateUserHotelMetrics] = useMutation(UPDATE_USER_HOTEL_METRICS);

  // Dispatch to Redux Store
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setMyMetrics(myMetrics));
  }, [myMetrics, propertyId, dispatch]);

  useEffect(() => {
    dispatch(setTabs(tabColumns));
  }, [tabColumns, dispatch]);

  return {
    myMetricsData,
    createUserHotelMetrics,
    updateUserHotelMetrics,
  };
};
