import {
  CellFocusedEvent,
  ColDef,
  ColumnState,
  GridApi,
  GridReadyEvent,
  RowDataUpdatedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import {
  addDays,
  dataDate,
  dateFormatDisplay,
  today,
} from 'features/dates/date-helpers';
import {
  selectRecReviewTab,
  selectShowSearchPanel,
  selectTableSort,
  selectTabs,
} from 'features/my-view/redux/selectors';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { BiArrowToTop } from 'react-icons/bi';
import { BsCalendar2Check } from 'react-icons/bs';
import Button from '@mui/material/Button';
import { CustomHeader } from './DashboardCustomHeader';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import { DisplayedColumnsChangedEvent } from 'ag-grid-community';
import IconButton from '@mui/material/IconButton';
import { NumericCellEditor } from './NumericCellEditor';
import { RecReviewData } from './useDashboardController';
import { RecReviewStatus } from 'types/RecReviewTypes';
import dayjs from 'dayjs';
import { selectShowAskRev } from 'features/header/redux/selectors';
import { setTableSort } from 'features/my-view/redux/my-view-slice';
import styles from 'common/_variables.module.scss';
import { usePropertyContext } from 'context/propertyContext';
import { useRateUpload } from 'features/rate-upload/hooks/use-rate-upload';
import { useUser } from 'features/users/context/userContext';

const defaultColOptions: ColDef<RecReviewData> = {
  sortable: true,
  lockVisible: true, // by default, all columns cannot be removed
  suppressSizeToFit: true,
  comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
    // Nulls sort after anything else
    if (valueA === null) {
      return isDescending ? -1 : 1;
    }
    if (valueA === valueB) return 0;
    return valueA > valueB ? 1 : -1;
  },
};

const components: Record<string, any> = {
  agColumnHeader: CustomHeader,
  numericCellEditor: NumericCellEditor,
};

export const DashboardTable = (props: {
  data: any;
  columns: any;
  gridRef: React.RefObject<AgGridReact>;
  paginationMaxDuration: number;
  fixedColsLength: number;
  handleColumnMoved: (event: any) => void;
}) => {
  const showSearchPanel = useAppSelector(selectShowSearchPanel);
  const showAskRev = useAppSelector(selectShowAskRev);
  const { data, columns, gridRef, fixedColsLength, handleColumnMoved } = props;
  const colSort = useAppSelector(selectTableSort);
  const tabColumns = useAppSelector(selectTabs);
  const currentTab = useAppSelector(selectRecReviewTab);
  const dispatch = useAppDispatch();
  const { handleRowSelection, WarningModal } = useRateUpload();
  const [isForcePickerOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any | null>(null);
  const [selectedDate, handleDateChange] = useState(today);
  const { isReadOnly } = useUser();
  const { showPast } = usePropertyContext();

  const orderedColumns = (): ColumnState[] => {
    const currentTabColumns = tabColumns
      ? tabColumns[currentTab as keyof typeof tabColumns]
      : [];

    return currentTabColumns?.length
      ? [
          ...columns.slice(0, fixedColsLength).map((col: any) => {
            return { colId: col.field };
          }),
          ...currentTabColumns.map((col) => {
            return { colId: col };
          }),
        ]
      : columns.map((col: any) => {
          return { colId: col.field };
        });
  };

  useEffect(() => {
    if (colSort) {
      const columns = gridRef?.current?.columnApi?.getColumnState();
      const column = columns?.find((col) => col.colId === colSort.column);
      if (column) {
        const updatedCol = {
          ...column,
          sort: colSort.direction,
        };
        const updatedState = columns?.map((col) => {
          if (col.colId === colSort.column) {
            return updatedCol;
          }
          return {
            ...col,
            sort: null,
          };
        }) as ColumnState[];

        if (updatedState) {
          gridRef?.current?.columnApi.applyColumnState({
            state: updatedState,
          });
        }
      }
    }
  }, [colSort, gridRef, columns]);

  const gridApi: GridApi | undefined = gridRef?.current?.api;

  useEffect(() => {
    if (gridApi && showPast) {
      gridApi.ensureIndexVisible(7, 'top');
    } else if (gridApi && !showPast) {
      gridApi.ensureIndexVisible(0, 'top');
    }
  }, [showPast, gridApi]);

  const handleCellFocused = (event: CellFocusedEvent) => {
    const { rowIndex: prevRow, column } = event;
    const nextRow = event.api.getFocusedCell()?.rowIndex;

    if (!column || !nextRow || prevRow === nextRow) return;
    event.api.startEditingCell({
      rowIndex: nextRow,
      colKey: column,
    });
  };

  const handleGridReady = (evt: GridReadyEvent) => {
    const columns = orderedColumns();
    evt.api.sizeColumnsToFit();
    evt.columnApi.applyColumnState({ state: columns, applyOrder: true });
  };

  const handleRowDataUpdated = (evt: RowDataUpdatedEvent) => {
    evt.api.forEachNode((row) => {
      const isSelected = row.isSelected();
      if (
        row.data &&
        row.data.rec_status === RecReviewStatus.PENDING &&
        !isSelected
      ) {
        row.setSelected(true, false, true);
      }
    });
  };

  const handleFirstDataRendered = () => {
    if (gridApi && showPast) {
      gridApi.ensureIndexVisible(7, 'top');
    }
  };

  const jumpToRowDateSelection = (date: string) => {
    const formatDate = dataDate(date);
    const index = data.findIndex(
      (x: any) => dataDate(x.stay_date) === formatDate
    );
    gridApi?.ensureIndexVisible(index, 'top');
  };

  const handleSortChanged = (evt: SortChangedEvent) => {
    const colState = evt.columnApi.getColumnState();
    const sortedCol = colState.find((col) => col.sort);
    if (sortedCol) {
      const payload = {
        column: sortedCol.colId,
        direction: sortedCol.sort || null,
      };
      dispatch(setTableSort(payload));
    }
  };

  const handleTabChange = (evt: DisplayedColumnsChangedEvent) => {
    const columns = orderedColumns();
    evt.columnApi.applyColumnState({ state: columns, applyOrder: true });
  };

  const backTotop = () => {
    gridApi?.ensureIndexVisible(7);
  };

  const jumpForward = () => {
    gridApi?.ensureIndexVisible(gridApi?.getLastDisplayedRow(), 'top');
  };

  const jumpBack = () => {
    gridApi?.ensureIndexVisible(gridApi.getFirstDisplayedRow(), 'bottom');
  };

  const handleIsRowSelectable = (rowNode: any) => {
    return dayjs(rowNode.data.stay_date).isSameOrAfter(dayjs(), 'day');
  };

  return (
    <>
      <div className='dashboard__tables'>
        <div
          className={`ag-theme-alpine grid-wrapper ${
            showAskRev ? 'askform-vis' : ''
          } ${showSearchPanel ? 'sform-vis' : ''}`}
        >
          {data && (
            <>
              <AgGridReact
                columnDefs={columns}
                onColumnMoved={handleColumnMoved}
                components={components}
                rowData={data}
                defaultColDef={defaultColOptions}
                tooltipShowDelay={800}
                enterMovesDown={true}
                enterMovesDownAfterEdit={true}
                onCellFocused={handleCellFocused}
                onNewColumnsLoaded={handleTabChange}
                getRowId={(params) => params.data.stay_date}
                onGridReady={handleGridReady}
                onRowSelected={handleRowSelection}
                onSortChanged={handleSortChanged}
                onRowDataUpdated={handleRowDataUpdated}
                ref={gridRef}
                rowBuffer={0}
                rowMultiSelectWithClick={true}
                rowSelection='multiple'
                suppressContextMenu={true}
                suppressRowClickSelection={isReadOnly}
                rowModelType='clientSide'
                cacheBlockSize={365}
                cacheOverflowSize={10}
                maxConcurrentDatasourceRequests={2}
                infiniteInitialRowCount={365}
                maxBlocksInCache={2}
                rowHeight={46}
                headerHeight={70}
                maintainColumnOrder={true}
                stopEditingWhenCellsLoseFocus={true}
                onFirstDataRendered={handleFirstDataRendered}
                isRowSelectable={handleIsRowSelectable}
              />
              <WarningModal />
            </>
          )}
          <div className='dashboard__pagination-container'>
            <div className='dashboard__pagination'>
              <div className='dashboard__pagination-wrapper'>
                <IconButton
                  className='dashboard__pagination-row'
                  disableFocusRipple
                  disableRipple
                  aria-label='prev-rows'
                  onClick={jumpBack}
                >
                  <MdKeyboardArrowUp size={20} color={styles.colorPrimary} />
                </IconButton>
                <div className='dashboard__pagination-row'>
                  <DatePicker
                    open={isForcePickerOpen}
                    onClose={() => setIsOpen(false)}
                    value={selectedDate}
                    onChange={(newDate: Dayjs | null) => {
                      handleDateChange(newDate!.format(dateFormatDisplay));
                      jumpToRowDateSelection(
                        newDate!.format(dateFormatDisplay)
                      );
                    }}
                    inputFormat={'YYYY-MM-DD'}
                    maxDate={addDays(props.paginationMaxDuration - 1)}
                    disablePast
                    PopperProps={{
                      placement: 'bottom-end',
                      anchorEl: anchorEl,
                    }}
                    renderInput={(params) => (
                      <Button
                        className='dashboard__pagination-calender'
                        variant='contained'
                        onClick={(e) => {
                          setIsOpen((isOpen) => !isOpen);
                          setAnchorEl(e.currentTarget);
                        }}
                      >
                        <BsCalendar2Check
                          size={20}
                          color={styles.colorPrimary}
                        />
                      </Button>
                    )}
                  />
                </div>
                <IconButton
                  className='dashboard__pagination-row'
                  disableFocusRipple
                  disableRipple
                  aria-label='next-rows'
                  onClick={jumpForward}
                >
                  <MdKeyboardArrowDown size={20} color={styles.colorPrimary} />
                </IconButton>
              </div>
            </div>

            <IconButton
              className='dashboard__pagination-row'
              disableFocusRipple
              disableRipple
              aria-label='first-page'
              onClick={backTotop}
            >
              <div className='dashboard__back-to-top'>
                <BiArrowToTop size={20} color={styles.whiteColor} />
              </div>
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
};
