import { AlertColor } from '@mui/material/Alert';

export type Season = {
  propertyId: string;
  seasonId: string;
  seasonName: string;
  seasonStartDate: Date | null;
  seasonEndDate: Date | null;
  selectedDays: String[];
  seasonPriority: number;
  highlightInMyView: boolean;
  lastUpdatedBy?: string;
  lastUpdatedDate?: Date;
};

export type InfoInput = {
  message: string;
  messageType: AlertColor;
};

export enum SeasonPriority {
  Lowest = 1,
  Low,
  Medium,
  High,
  Highest,
}
