import {
  PayloadAction,
  Slice,
  SliceCaseReducers,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { FormikProps } from 'formik';
import {
  EventCategoryEnum,
  EventFormData,
  EventResponse,
} from 'graphql/gql-types';
import { RootState } from 'redux/store';
export interface eventsFormState {
  formValues: EventFormData;
  eventsFormRef: FormikProps<EventFormData> | null;
  isEventsFormDirty: boolean;
  isEventNameReadonly: boolean;
  currentEvent: EventResponse[];
}

export const initialFormState: eventsFormState = {
  formValues: {
    event_id: '',
    name: '',
    created_at: '',
    created_by_id: '',
    updated_at: '',
    updated_by_id: '',
    recurs: false,
    occ_impact: 0,
    category: EventCategoryEnum.general,
    property_id: '',
    records: [{ start_date: '', end_date: '', id: '', event_id: '' }],
  },
  eventsFormRef: null,
  isEventsFormDirty: false,
  isEventNameReadonly: false,
  currentEvent: [],
};

const reducers: SliceCaseReducers<eventsFormState> = {
  updateEventsFormValues: (
    state,
    action: PayloadAction<Partial<EventFormData>>
  ) => {
    state.formValues = { ...state.formValues, ...action.payload };
    if (state.eventsFormRef) {
      state.eventsFormRef.values = { ...state.formValues };
      state.isEventsFormDirty = state.eventsFormRef.dirty;
    }
  },
  setEventsFormRef: (
    state,
    action: PayloadAction<FormikProps<EventFormData>>
  ) => {
    state.eventsFormRef = action.payload;
  },
  setIsEventsFormDirty: (state, action: PayloadAction<boolean>) => {
    state.isEventsFormDirty = action.payload;
  },
  setIsEventNameReadonly: (state, action: PayloadAction<boolean>) => {
    state.isEventNameReadonly = action.payload;
  },
  setCurrentEvent(state, action: PayloadAction<EventResponse[]>) {
    state.currentEvent = action.payload;
  },
};

export const eventsFormSlice: Slice<eventsFormState, typeof reducers> =
  createSlice({
    name: 'eventsForm',
    initialState: initialFormState,
    reducers,
  });

export const selectEventsFormData = createSelector(
  (state: RootState) => state.eventsForm,
  (eventsForm) => eventsForm.formValues
);

export const selectCurrentEvent = createSelector(
  (state: RootState) => state.eventsForm,
  (eventsForm) => eventsForm.currentEvent
);

export const {
  updateEventsFormValues,
  setEventsFormRef,
  setIsEventsFormDirty,
  setIsEventNameReadonly,
  setCurrentEvent,
} = eventsFormSlice.actions;
export default eventsFormSlice.reducer;
