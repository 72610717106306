import {
  ColDef,
  ColumnMovedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import {
  ColumnSources,
  forecastColsData,
  inventoryColsData,
  kpiColsData,
  metricsDefaultCols,
  pickupColsData,
  ratesColsData,
} from './recReviewColumns';
import {
  addColumn,
  myViewTabsEnum,
  removeColumn,
  setTabs,
} from 'features/my-view/redux/my-view-slice';
import {
  renderCompRateCell,
  twoDecimalRateFormatter,
} from './tableCustomCellRender';
import {
  selectMyMetrics,
  selectRecReviewTab,
  selectTabs,
} from 'features/my-view/redux/selectors';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useCallback, useEffect, useState } from 'react';

import { Competitor } from 'graphql/gql-types';
import { RecReviewData } from './useDashboardController';
import { labels } from 'locales/en.label';
import { useApolloError } from 'hooks/useApolloError';
import { useMyMetrics } from './hooks/useMyMetrics';
import { usePropertyContext } from 'context/propertyContext';
import { useUser } from '../../features/users/context/userContext';

export interface ColumnObj extends Omit<ColDef<RecReviewData>, 'headerName'> {
  headerName: string | React.ReactElement;
  field: string;
  headerTooltip: string;
  cellRenderer?: (
    params: any
  ) => JSX.Element | string | number | null | undefined;
  compId?: number;
}

export interface SourceColumn extends ColumnObj {
  source: string;
  headerComponentParams?: {
    addRemoveIcon: string;
    onClick: (e: any) => void;
    field?: string;
  };
}

export const useDashboardColumns = (
  fixedColsLength: number,
  competitors?: Omit<Competitor, 'stars'>[]
) => {
  const {
    property: { propertyId },
    currencySymbol,
  } = usePropertyContext();
  const { user } = useUser();
  const myMetrics = useAppSelector(selectMyMetrics);
  const tabs = useAppSelector(selectTabs);
  const currentTab = useAppSelector(selectRecReviewTab);
  const dispatch = useAppDispatch();
  const { handleApolloError } = useApolloError();

  const [metricsCols, setMetricsCols] = useState(metricsDefaultCols);
  const userId = user?.id;

  const { createUserHotelMetrics, updateUserHotelMetrics } = useMyMetrics({
    propertyId: propertyId,
    userId: user?.id,
  });

  const getMyMetricCols = () => {
    if (!myMetrics) return [];
    const columns: SourceColumn[] = myMetrics
      .map((col) => {
        switch (col.source) {
          case ColumnSources.FORECAST:
            return forecastColsData.find((c) => c.field === col.field);
          case ColumnSources.INVENTORY:
            return inventoryColsData.find((c) => c.field === col.field);
          case ColumnSources.KPI:
            return kpiColsData.find((c) => c.field === col.field);
          case ColumnSources.PICKUP:
            return pickupColsData.find((c) => c.field === col.field);
          case ColumnSources.RATES:
            return ratesColsData.find((c) => c.field === col.field);
          case ColumnSources.COMPETITORS:
            if (!competitors) return null;
            const compCols = competitorCols(competitors);
            return compCols?.find((c) => c.field === col.field);
          default:
            return null;
        }
        // Filters out null/undefined values and fixes the typing
      })
      .flatMap((col) => col ?? []);
    return columns;
  };

  useEffect(() => {
    if (propertyId) {
      const metricCols = getMyMetricCols();
      if (metricCols?.length) {
        //Find column objects from column data
        const formattedCols: SourceColumn[] = metricCols.map((col) => {
          return {
            ...col,
            headerComponentParams: {
              addRemoveIcon: 'fa-minus',
              onClick: (e: React.SyntheticEvent) =>
                removeColumnFromMetric(e, col?.field),
            },
            maxWidth: 120,
          };
        });
        const updateMetricCols = [...formattedCols, ...metricsDefaultCols];
        setMetricsCols(updateMetricCols);
      } else {
        setMetricsCols(metricsDefaultCols);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs, propertyId, myMetrics, competitors]);

  const removeColumnFromMetric = (e: React.SyntheticEvent, field: string) => {
    e.stopPropagation();
    dispatch(removeColumn(field));

    const updatedCols = myMetrics?.filter((col) => col.field !== field);
    const updatedTabs = tabs?.metrics?.filter((col) => col !== field);

    dispatch(setTabs({ ...tabs, metrics: updatedTabs }));

    const colPayload = JSON.stringify({
      myMetrics: updatedCols,
      tabs: {
        ...tabs,
        metrics: updatedTabs,
      },
    });

    updateUserHotelMetrics({
      variables: {
        userId,
        propertyId,
        metricCols: colPayload,
      },
      onError: (error) => {
        handleApolloError(error);
      },
    });
  };

  const handleColumnMoved = useCallback(
    (e: ColumnMovedEvent) => {
      const { column, finished } = e;
      if (finished && column) {
        const columnOrder = e.columnApi
          ?.getColumnState()
          .map((col: any) => col.colId)
          .slice(fixedColsLength);

        const tabsCopy = { ...tabs };
        const updatedTabs = {
          ...tabsCopy,
          [currentTab]: columnOrder,
        };
        dispatch(setTabs(updatedTabs));

        if (!myMetrics) {
          const colPayload = JSON.stringify({
            tabs: updatedTabs,
          });
          if (tabs) {
            updateUserHotelMetrics({
              variables: {
                userId,
                propertyId,
                metricCols: colPayload,
              },
              onError: (error) => {
                handleApolloError(error);
              },
            });
          } else {
            createUserHotelMetrics({
              variables: {
                userId,
                propertyId,
                metricCols: colPayload,
              },
              onError: (error) => {
                handleApolloError(error);
              },
            });
          }
        } else {
          const colPayload = JSON.stringify({
            myMetrics: myMetrics,
            tabs: updatedTabs,
          });
          updateUserHotelMetrics({
            variables: {
              userId,
              propertyId,
              metricCols: colPayload,
            },
            onError: (error) => {
              handleApolloError(error);
            },
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTab, myMetrics, tabs, updateUserHotelMetrics, userId, propertyId]
  );

  const addColumnToMetric = (
    e: React.SyntheticEvent,
    field: string,
    source: string
  ) => {
    e.stopPropagation();
    dispatch(addColumn({ field, source }));

    const otherTabs = tabs ?? {};
    const metricCols = tabs?.metrics ?? metricsCols.map((col) => col.field);

    dispatch(setTabs({ ...otherTabs, metrics: [field, ...metricCols] }));

    const colPayload = JSON.stringify({
      myMetrics: [{ field, source }, ...(myMetrics || [])],
      tabs: {
        ...otherTabs,
        metrics: [field, ...metricCols],
      },
    });

    if (myMetrics || tabs) {
      updateUserHotelMetrics({
        variables: {
          userId,
          propertyId,
          metricCols: colPayload,
        },
        onError: (error) => {
          handleApolloError(error);
        },
      });
    } else {
      createUserHotelMetrics({
        variables: {
          userId,
          propertyId,
          metricCols: colPayload,
        },
        onError: (error) => {
          handleApolloError(error);
        },
      });
    }
  };

  const AddPlusIconToColumns = (columns: SourceColumn[]) => {
    const newArr = columns.map(function (column) {
      return {
        ...column,
        headerComponentParams: {
          addRemoveIcon: metricsCols.some((col) => col.field === column.field)
            ? ''
            : 'fa-plus',
          onClick: (e: React.SyntheticEvent) =>
            addColumnToMetric(e, column.field, column.source),
          field: column.field,
        },
        maxWidth: column.maxWidth || 120,
      };
    });
    return newArr;
  };

  const tabsKeys = tabs ? Object.keys(tabs) : [];

  const sortColumns = (cols: SourceColumn[], source: myViewTabsEnum) => {
    if (!tabs || !tabsKeys.includes(source)) return AddPlusIconToColumns(cols);
    const sortedCols = [...cols].sort((a, b) => {
      const aIdx = tabs[source]?.indexOf(a.field);
      const bIdx = tabs[source]?.indexOf(b.field);
      if (aIdx === undefined || bIdx === undefined) return 0;
      return aIdx - bIdx;
    });
    return AddPlusIconToColumns(sortedCols);
  };

  const forecastCols = sortColumns(forecastColsData, myViewTabsEnum.forecast);
  const inventoryCols = sortColumns(
    inventoryColsData,
    myViewTabsEnum.inventory
  );
  const pickupCols = sortColumns(pickupColsData, myViewTabsEnum.pickup);
  const ratesCols = sortColumns(ratesColsData, myViewTabsEnum.rates);
  const kpiCols = sortColumns(kpiColsData, myViewTabsEnum.kpi);

  const competitorCols = (competitors: Omit<Competitor, 'stars'>[]) => {
    if (!competitors) return;

    const competitorColsData: SourceColumn[] = [
      /* TODO:  We don’t have enough snapshots of data yet for this so we will hide it until there is data (B20V-2126).
      {
        headerName: 'Mkt Rate\n(LYST)',
        field: 'market_rate_lyst',
        source: ColumnSources.COMPETITORS,
        headerTooltip: labels.rec_review.dashboard.tooltip.market_rate_lyst,
        decimalFormatter: 2,
        maxWidth: 120,
        headerComponentParams: {
          addRemoveIcon: metricsCols.some(
            (col) => col.field === 'market_rate_lyst'
          )
            ? ''
            : 'fa-plus',
          onClick: () =>
            addColumnToMetric('market_rate_lyst', ColumnSources.COMPETITORS),
        },
        valueFormatter: (params: ValueFormatterParams) => {
          return twoDecimalRateFormatter(params?.value);
        },
      }, */
      {
        headerName: 'Mkt Rate\n(LYF)',
        field: 'market_rate_lyf',
        source: ColumnSources.COMPETITORS,
        headerTooltip: labels.rec_review.dashboard.tooltip.market_rate_lyf,
        maxWidth: 120,
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params?.value) return '-';
          return currencySymbol + twoDecimalRateFormatter(params?.value);
        },
        headerComponentParams: {
          addRemoveIcon: metricsCols.some(
            (col) => col.field === 'market_rate_lyf'
          )
            ? ''
            : 'fa-plus',
          onClick: (e: React.SyntheticEvent) =>
            addColumnToMetric(e, 'market_rate_lyf', ColumnSources.COMPETITORS),
        },
      },
    ];

    competitors.forEach(({ hotelId, name }) => {
      competitorColsData.push({
        headerName: name,
        field: `compRates.${hotelId}`,
        compId: hotelId,
        source: ColumnSources.COMPETITORS,
        headerTooltip: name,
        maxWidth: 120,
        cellRenderer: ({ value }) => renderCompRateCell(value, currencySymbol),
        headerComponentParams: {
          addRemoveIcon: metricsCols.some((col) => col.compId === hotelId)
            ? ''
            : 'fa-plus',
          onClick: (e: React.SyntheticEvent) =>
            addColumnToMetric(e, `${hotelId}`, ColumnSources.COMPETITORS),
        },
      });
    });

    return sortColumns(competitorColsData, myViewTabsEnum.competitors);
  };

  return {
    metricsCols,
    forecastCols,
    inventoryCols,
    pickupCols,
    ratesCols,
    competitorCols,
    kpiCols,
    handleColumnMoved,
  };
};
