import './price-decision-tooltip.scss';

import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { RecAction, RecRateExpectation } from 'graphql/gql-types';
import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

import { FaCheck } from 'react-icons/fa';
import { IoTriangle } from 'react-icons/io5';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { Maybe } from 'types/maybe-type';
import { labels as globalLabels } from 'locales/en.label';
import styles from 'common/_variables.module.scss';
import { thousandSeperatorRateFormatter } from '../../tableCustomCellRender';

const labels = globalLabels.rec_review.dashboard.price_decision;

interface RecRateExplainer {
  stay_date?: string | null;
  rec_action?: RecAction | null;
  pickup_ratio?: number | null;
  pickup_pace?: RecRateExpectation | null;
  pricing_ratio?: number | null;
  pricing_pace?: RecRateExpectation | null;
  incr_revenue?: number | null;
}

interface PriceDecisionTooltipProps {
  data: Maybe<RecRateExplainer>;
  loading: boolean;
  children: React.ReactElement<any, any> & React.ReactNode;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      color: styles.whiteColor,
      border: `1px solid #ccc`,
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: styles.whiteColor,
    border: `1px solid #ccc`,
    padding: 0,
    width: '19rem',
  },
}));

const Value = ({
  pace,
  ratio,
}: {
  pace: Maybe<RecRateExpectation>;
  ratio: Maybe<number>;
}) => {
  if (!pace || !ratio) return <div className='value' />;
  return (
    <div className='value'>
      {ratio.toFixed(2)}
      {pace === RecRateExpectation.INLINE ? (
        <FaCheck className='icon check' />
      ) : (
        <IoTriangle
          className={`icon triangle-${
            pace === RecRateExpectation.HIGH ? 'up' : 'down'
          }`}
        />
      )}
    </div>
  );
};

export const PriceDecisionTooltip = ({
  data,
  loading,
  children,
}: PriceDecisionTooltipProps) => {
  return (
    <StyledTooltip
      title={
        <LoadingBar className='price-decision-tooltip' loading={loading}>
          <div className='content'>
            <h1 className='header'>{labels.eval_demand_pattern}</h1>
            <div className='body'>
              <div className='left'>
                <div className='label'>{labels.recommended_action}</div>
                {data?.rec_action === RecAction.NC && (
                  <>
                    <FaCheck className='icon check' />
                    <div className='value'>{labels.nc}</div>
                  </>
                )}
                {data?.rec_action === RecAction.INC && (
                  <>
                    <ArrowUpward className='icon arrow-up' />
                    <div className='value'>{labels.inc}</div>
                  </>
                )}
                {data?.rec_action === RecAction.DEC && (
                  <>
                    <ArrowDownward className='icon arrow-down' />
                    <div className='value'>{labels.dec}</div>
                  </>
                )}
              </div>
              <hr className='divider' />
              <div className='right'>
                <div className='list-item'>
                  <div className='label'>{labels.price}</div>
                  <Value
                    pace={data?.pricing_pace}
                    ratio={data?.pricing_ratio}
                  />
                </div>
                <div className='list-item'>
                  <div className='label'>{labels.pickup}</div>
                  <Value pace={data?.pickup_pace} ratio={data?.pickup_ratio} />
                </div>
                <div className='list-item'>
                  <div className='label'>Incremental Revenue</div>
                  <div className='value'>
                    {thousandSeperatorRateFormatter(data?.incr_revenue)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LoadingBar>
      }
    >
      {children}
    </StyledTooltip>
  );
};

export default PriceDecisionTooltip;
