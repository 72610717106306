import { Tooltip, TooltipProps } from '@mui/material';

import React from 'react';

interface ComingSoonTooltipProps extends Omit<TooltipProps, 'title'> {
  children: React.ReactElement;
}

const ComingSoonTooltip: React.FC<ComingSoonTooltipProps> = ({
  children,
  ...tooltipProps
}) => (
  <Tooltip title='Coming Soon' arrow placement='right' {...tooltipProps}>
    {children}
  </Tooltip>
);

export default ComingSoonTooltip;
