import './group-price-tool.scss';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import {
  addDays,
  dateFormatData,
  dayRange,
  today,
} from 'features/dates/date-helpers';
import { groupData, roomData } from './groupData';

import { AvailabilityTable } from './availability-table';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DropdownList } from 'components/DropdownList/DropdownList';
import { FieldType } from './slider-input';
import { MdMoreVert } from 'react-icons/md';
import { NetBenefitTable } from './net-benefit-table';
import SliderInput from './slider-input';
import TextField from '@mui/material/TextField';
import { Tooltip } from '@mui/material';
import { useState } from 'react';

export interface GroupPriceToolInput {
  checkInDate: string;
  checkOutDate: string;
  roomsRequested: number;
  rateRequested: number;
  commission: number;
  direct_var_opex: number;
  wash_factor: number;
  rebate: number;
  profit_margin: number;
  f_and_b_profit: number;
  meeting_space_benefit: number;
  comp_rooms: number;
  occ_fcst_override: number;
  adr_fcst_override: number;
  planners_adv_points: number;
}

export function GroupPriceTool() {
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const { handleSubmit, control, getValues, setValue, resetField, reset } =
    useForm<GroupPriceToolInput>({
      defaultValues: {
        checkInDate: today(),
        checkOutDate: addDays(1).format(dateFormatData),
        roomsRequested: 20,
        rateRequested: 100,
        commission: 10,
        direct_var_opex: 35,
        wash_factor: 10,
        rebate: 0,
        profit_margin: 10,
        f_and_b_profit: 0,
        meeting_space_benefit: 0,
        comp_rooms: 0,
        occ_fcst_override: 0,
        adr_fcst_override: 0,
        planners_adv_points: 0,
      },
    });

  const onSubmit: SubmitHandler<GroupPriceToolInput> = (data) => {
    console.log(data);
  };

  const formValues = getValues();

  const dates = dayRange('2024-08-01', '2024-08-04');
  // const dates = dayRange(formValues.checkInDate, dayjs(formValues.checkOutDate).subtract(1, 'day').format(dateFormatData));

  return (
    <div className='group-price-tool'>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='group-price-tool__form'
      >
        <div className='basic-fields form-row'>
          <Controller
            name='checkInDate'
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                renderInput={(params) => <TextField {...params} />}
                value={field.value}
                label='Check In Date'
                minDate={today()}
              />
            )}
          />
          <Controller
            name='checkOutDate'
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                renderInput={(params) => <TextField {...params} />}
                value={field.value}
                label='Check Out Date'
              />
            )}
          />
          <Controller
            name='roomsRequested'
            control={control}
            render={({ field }) => (
              <TextField {...field} label='Rooms Requested' />
            )}
          />
          <Controller
            name='rateRequested'
            control={control}
            render={({ field }) => (
              <TextField {...field} label='Rate Requested' />
            )}
          />
          <Controller
            name='commission'
            control={control}
            render={({ field }) => (
              <SliderInput
                label='Commission'
                field={field as FieldType}
                min={0}
                max={20}
                metric='%'
                setValue={setValue}
                resetField={resetField}
              />
            )}
          />
          <button className='submit' type='submit'>
            Submit
          </button>
          <DropdownList
            content={<MdMoreVert color='gray' style={{ fontSize: '24px' }} />}
            menu={[
              {
                id: 1,
                title: 'Reset All Fields',
                onClick: reset,
              },
            ]}
          />
          <Tooltip
            title={showAdvanced ? 'Hide Advanced' : 'Show Advanced'}
            arrow
            placement='top'
          >
            <button
              className='advanced-toggle'
              type='button'
              onClick={() => setShowAdvanced(!showAdvanced)}
            >
              {showAdvanced ? (
                <IoIosArrowUp
                  className='advanced-toggle__icon'
                  style={{ fontSize: '20px' }}
                />
              ) : (
                <IoIosArrowDown
                  className='advanced-toggle__icon'
                  style={{ fontSize: '20px' }}
                />
              )}
            </button>
          </Tooltip>
        </div>
        {showAdvanced && (
          <div className='advanced-fields'>
            <Controller
              name='direct_var_opex'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='Direct Variable OPEX per Room Night'
                  field={field as FieldType}
                  min={0}
                  max={50}
                  metric='%'
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
            <Controller
              name='wash_factor'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='Wash Factor'
                  field={field as FieldType}
                  min={0}
                  max={50}
                  metric='%'
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
            <Controller
              name='rebate'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='Rebate per Room Night'
                  field={field as FieldType}
                  min={0}
                  max={50}
                  metric='$'
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
            <Controller
              name='profit_margin'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='Profit Margin Required by Hotel'
                  field={field as FieldType}
                  min={0}
                  max={30}
                  metric='%'
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
            <Controller
              name='f_and_b_profit'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='F & B Profit per Group Room Night'
                  field={field as FieldType}
                  min={0}
                  max={50}
                  metric='$'
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
            <Controller
              name='meeting_space_benefit'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='Meeting Space Benefit (total for stay)'
                  field={field as FieldType}
                  min={0}
                  max={5000}
                  metric='$'
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
            <Controller
              name='comp_rooms'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='Comp Rooms per Night'
                  field={field as FieldType}
                  min={0}
                  max={100}
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
            <Controller
              name='occ_fcst_override'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='Occ Forecast Override'
                  field={field as FieldType}
                  min={-20}
                  max={20}
                  metric='%'
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
            <Controller
              name='adr_fcst_override'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='ADR Forecast Override'
                  field={field as FieldType}
                  min={-20}
                  max={20}
                  metric='%'
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
            <Controller
              name='planners_adv_points'
              control={control}
              render={({ field }) => (
                <SliderInput
                  label='Planners Advantage Points per Dollar'
                  field={field as FieldType}
                  min={0}
                  max={3}
                  setValue={setValue}
                  resetField={resetField}
                />
              )}
            />
          </div>
        )}
      </form>
      <NetBenefitTable data={groupData} dates={dates} formValues={formValues} />
      <AvailabilityTable data={roomData} dates={dates} />
    </div>
  );
}
