import { AlertPopup } from './AlertPopup';
import { MdFlag } from 'react-icons/md';
import { useState } from 'react';

interface Props {
  value?: number | string | null;
}

export const RecAlert = (props: Props) => {
  const [showModal, setShowModal] = useState(false);
  if (props.value) {
    return (
      <>
        <span
          ref={(ref: HTMLSpanElement) => {
            if (!ref) return;
            ref.onclick = (event: MouseEvent) => {
              // We have to intercept the click and stop the propagation
              // from the span ref in order to prevent the AgGrid from
              // selecting the row.
              event.stopPropagation();
              setShowModal(true);
            };
          }}
          style={{
            display: 'inline-block',
            verticalAlign: 'sub',
            cursor: 'pointer',
          }}
        >
          <MdFlag color='#FF4E4E' size={22} />
        </span>
        {showModal && <AlertPopup {...props} />}
      </>
    );
  }
  return null;
};
