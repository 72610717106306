import { gql } from '@apollo/client';

export const GET_MY_METRICS = gql`
  query GetMyMetrics($userId: String!, $propertyId: String!) {
    getMyMetrics(userId: $userId, propertyId: $propertyId) {
      user_id
      property_id
      columns
    }
  }
`;

export const CREATE_USER_HOTEL_METRICS = gql`
  mutation CreateUserHotelMetrics(
    $userId: String!
    $propertyId: String!
    $metricCols: String!
  ) {
    createUserHotelMetrics(
      userId: $userId
      propertyId: $propertyId
      metricCols: $metricCols
    ) {
      user_id
      property_id
      columns
    }
  }
`;

export const UPDATE_USER_HOTEL_METRICS = gql`
  mutation UpdateUserHotelMetrics(
    $userId: String!
    $propertyId: String!
    $metricCols: String!
  ) {
    updateUserHotelMetrics(
      userId: $userId
      propertyId: $propertyId
      metricCols: $metricCols
    ) {
      user_id
      property_id
      columns
    }
  }
`;
