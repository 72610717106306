import { Button, Tooltip } from '@mui/material';

import { labels } from 'locales/en.label';
import { useOktaAuth } from '@okta/okta-react';
import { useUser } from 'features/users/context/userContext';

function UserDetails() {
  const userLabels = labels.header.user_details;
  const { oktaAuth } = useOktaAuth();
  const { isAuthenticated, user } = useUser();

  const login = () => {
    oktaAuth.signInWithRedirect();
  };

  const logout = async () => {
    await oktaAuth.signOut();
  };

  return (
    <div className={`user-details ${isAuthenticated ? 'user' : 'no-user'}`}>
      <Tooltip
        title={user ? `${userLabels.logout} (${user.name})` : userLabels.login}
        arrow
        placement='bottom'
      >
        <Button className='login-btn' onClick={user ? logout : login}>
          <h5 className='user-details__name'>
            {user?.initials || userLabels.default_initials}
          </h5>
        </Button>
      </Tooltip>
    </div>
  );
}

export default UserDetails;
