import './autopilot-info.scss';

import { labels } from 'locales/en.label';

const infoText = labels.rules.autopilot_tab.info;

export const AutopilotInfo = () => (
  <div className='autopilot-info'>
    <h3 className='header'>{infoText.header_1}</h3>
    <p className='text'>{infoText.paragraph_1}</p>

    <h3 className='header'>{infoText.header_2}</h3>
    <p className='text'>{infoText.paragraph_2}</p>
  </div>
);
