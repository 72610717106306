import './comp-rate-tooltip.scss';

import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';

import { CompRateObj } from 'pages/MyView/useDashboardController';
import { formatDateWithGivenFormatter } from 'features/dates/date-helpers';
import { labels } from 'locales/en.label';
import styled from 'styled-components';
import styles from 'common/_variables.module.scss';
import { twoDecimalRateFormatter } from 'pages/MyView/tableCustomCellRender';
import { usePropertyContext } from 'context/propertyContext';

const tooltipLabels = labels.comp_rates.tooltip;

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      color: styles.whiteColor,
      border: `1px solid #ccc`,
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: styles.whiteColor,
    border: `1px solid #ccc`,
    padding: 0,
    maxWidth: 'none',
  },
}));

interface CompRateTooltipProps {
  data: CompRateObj;
  children: React.ReactElement<any, any> & React.ReactNode;
}

const format = (value: string) =>
  formatDateWithGivenFormatter(value, 'M/DD/YY');

export const CompRateTooltip = ({ data, children }: CompRateTooltipProps) => {
  const { currencySymbol } = usePropertyContext();

  return (
    <StyledTooltip
      style={{ display: 'inline' }}
      title={
        <div className='comp-rate-tooltip'>
          <div className='row'>
            <span className='label'>{tooltipLabels.stay_date}</span>
            <span>{format(data.arrivalDate)}</span>
          </div>
          <div className='row'>
            <span className='label'>{tooltipLabels.shop_date}</span>
            <span>{format(data.extractDateTime)}</span>
          </div>
          <div className='row'>
            <span className='label'>{tooltipLabels.rate}</span>
            <span>
              {currencySymbol}
              {twoDecimalRateFormatter(data.value ?? 0)}
            </span>
          </div>
          <div className='row'>
            <span className='label'>{tooltipLabels.room_name}</span>
            <span>{data.roomName}</span>
          </div>
          <div className='row'>
            <span className='label'>{tooltipLabels.los}</span>
            <span>{data.los}</span>
          </div>
          <div className='row'>
            <span className='label'>{tooltipLabels.best_flex}</span>
            <span>{data.best_flex ? 'True' : 'False'}</span>
          </div>
          <div className='row'>
            <span className='label'>{tooltipLabels.rate_changes}</span>
          </div>
          {data.changes?.map(({ day, value }) => (
            <div className='row' key={day}>
              <span className='label sub'>{tooltipLabels.days_ago(day)}</span>
              <span>
                {currencySymbol}
                {twoDecimalRateFormatter(value)}
              </span>
            </div>
          ))}
        </div>
      }
    >
      {children}
    </StyledTooltip>
  );
};
