import './alerts.scss';

import { useEffect, useRef } from 'react';

import { Alert } from '@mui/material';
import { AlertObj } from 'features/alerts/redux/alerts-slice';
import { Link } from 'react-router-dom';

interface AlertsProps {
  alerts: AlertObj[];
  onAlertClose: (id: string) => void;
  /** Duration in MS before alerts close automatically */
  duration?: number;
}

/** Primary component for displaying alerts in the app */
export const Alerts = ({ alerts, onAlertClose, duration }: AlertsProps) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  // Automatically close top notification after duration ends
  useEffect(() => {
    clearTimeout(timeoutRef.current);
    if (duration && alerts.length) {
      timeoutRef.current = setTimeout(() => {
        onAlertClose(alerts[0].id);
      }, duration * 1000);
    }
  }, [alerts, duration, onAlertClose]);

  return (
    <div className='alerts-container'>
      {alerts.map(({ id, severity, message, linkTo }) => {
        return (
          <Alert key={id} severity={severity} onClose={() => onAlertClose(id)}>
            {linkTo ? <Link to={linkTo}>{message}</Link> : message}
          </Alert>
        );
      })}
    </div>
  );
};

export default Alerts;
