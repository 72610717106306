import 'index.css';
import './fonts/CostaPtf-Regular.otf';

import { App } from 'App';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'components/error-boundary';
import { GenericFallback } from 'components/generic-fallback/generic-fallback';
import { Okta } from 'okta';
import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ErrorBoundary fallback={<GenericFallback />}>
        <Okta>
          <App />
        </Okta>
      </ErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
