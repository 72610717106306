import './Header.scss';

import { AboutModal } from './about-modal';
import IconButton from '@mui/material/IconButton';
import { MdRecordVoiceOver } from 'react-icons/md';
import { PropertySearch } from '../PropertySearch/PropertySearch';
import { SelloutCount } from './sellout';
import { TbHotelService, TbZoomMoney } from 'react-icons/tb';
import logo from './logo.png';
import logoMobile from './logo-mobile.png';
import {
  setShowAskRev,
  setShowRateChangeLogs,
  setShowRateOffsetLogs,
} from 'features/header/redux/header-slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import useDeviceType from 'hooks/useDeviceType';
import { Link } from 'react-router-dom';
import { useUser } from 'features/users/context/userContext';
import { selectShowRateChangeLogs, selectShowRateOffsetLogs } from 'features/header/redux/selectors';

function PropertyDetails() {
  const { isAuthenticated } = useUser();
  const dispatch = useAppDispatch();
  const { isMobile } = useDeviceType();
  const showRateChangeLogs = useAppSelector(selectShowRateChangeLogs);
  const showRateOffsetLogs = useAppSelector(selectShowRateOffsetLogs);

  return (
    <>
      <div className='header__left-wrapper'>
        <div className='header__company-info'>
          <img
            alt='logo'
            src={isMobile ? logoMobile : logo}
            className='header__company-logo'
          />
        </div>
        <div className='header__divider'></div>
        <div className='header__property'>
          <PropertySearch />
        </div>
      </div>

      <div className='header__icons'>
        {isAuthenticated && (
          <>
            <IconButton
              disableFocusRipple
              disableRipple
              aria-label='ask-rev'
              onClick={() => {
                dispatch(setShowAskRev(true));
              }}
            >
              <MdRecordVoiceOver className='header__icon' />
            </IconButton>
            <IconButton
              disableFocusRipple
              disableRipple
              title='Rate Change Logs'
              aria-label='rate-change-logs'
              onClick={(e) => {
                e.preventDefault();
                dispatch(setShowRateChangeLogs(!showRateChangeLogs));
              }}
            >
              <Link
                to={{
                  pathname: !showRateChangeLogs
                    ? '/rate_changes'
                    : '/my-view/metrics',
                }}
              >
                <TbZoomMoney className='header__icon' />
              </Link>
            </IconButton>
            <IconButton
              disableFocusRipple
              disableRipple
              title='Rate Offset Logs'
              aria-label='rate-offset-logs'
              onClick={(e) => {
                e.preventDefault();
                dispatch(setShowRateOffsetLogs(!showRateOffsetLogs));
              }}
            >
              <Link
                to={{
                  pathname: !showRateOffsetLogs
                    ? '/rate_offsets'
                    : '/my-view/metrics',
                }}
              >
                <TbHotelService className='header__icon' />
              </Link>
            </IconButton>
            <AboutModal />
            {/* Hidden from display until fully implemented per B20V-2035:
            <IconButton
              disableFocusRipple
              disableRipple
              aria-label='notification'
            >
              <MdNotifications
                size={20}
                className={'header__icon header__icon-disable'}
              />
            </IconButton> */}
            <SelloutCount />
          </>
        )}
      </div>
    </>
  );
}

export default PropertyDetails;
