export const groupData = [
  {
    capacity: 117,
    current_otb: 110,
    expected_transient_adr: 145.17,
    transient_fcst: 17,
    transient_and_group_fcst: 23,

    guests_walked: 0,
    shoulder_rooms_displaced: 0,
    incremental_room_revenue: 2700,
    cost_of_guests_walked: 0,
    incremental_direct_variable_opex: -560,
    displacement_cost: 0,
    shoulder_displacement: 0,
    combined_f_and_b_meeting_space_benefit: 0,
    planners_advantage_points_cost: 0,
    net_benefit: 2140,
    required_profit: 300,
    take_group: 1840,
    // 'Another Group In House Day Prior?': 6,
    stay_date: '2024-08-01',
  },
  {
    capacity: 117,
    current_otb: 13,
    expected_transient_adr: 143.7,
    transient_fcst: 16,
    transient_and_group_fcst: 22,

    guests_walked: 0,
    shoulder_rooms_displaced: 0,
    incremental_room_revenue: 2700,
    cost_of_guests_walked: 0,
    incremental_direct_variable_opex: -560,
    displacement_cost: 0,
    shoulder_displacement: 0,
    combined_f_and_b_meeting_space_benefit: 0,
    planners_advantage_points_cost: 0,
    net_benefit: 2140,
    required_profit: 300,
    take_group: 1840,
    // 'Another Group In House Day Prior?': 0,
    stay_date: '2024-08-02',
  },
  {
    capacity: 117,
    current_otb: 12,
    expected_transient_adr: 146.36,
    transient_fcst: 84,
    transient_and_group_fcst: 90,

    guests_walked: 0,
    shoulder_rooms_displaced: 0,
    incremental_room_revenue: 2700,
    cost_of_guests_walked: 0,
    incremental_direct_variable_opex: -560,
    displacement_cost: 0,
    shoulder_displacement: 0,
    combined_f_and_b_meeting_space_benefit: 0,
    planners_advantage_points_cost: 0,
    net_benefit: 2140,
    required_profit: 300,
    take_group: 1840,
    // 'Another Group In House Day Prior?': 0,
    stay_date: '2024-08-03',
  },
  {
    capacity: 117,
    current_otb: 14,
    expected_transient_adr: 154.24,
    transient_fcst: 76,
    transient_and_group_fcst: 82,

    guests_walked: 0,
    shoulder_rooms_displaced: 0,
    incremental_room_revenue: 2700,
    cost_of_guests_walked: 0,
    incremental_direct_variable_opex: -560,
    displacement_cost: 0,
    shoulder_displacement: 0,
    combined_f_and_b_meeting_space_benefit: 0,
    planners_advantage_points_cost: 0,
    net_benefit: 2140,
    required_profit: 300,
    take_group: 1840,
    // 'Another Group In House Day Prior?': 0,
    stay_date: '2024-08-04',
  },
];

export const roomData = [
  {
    bed_type: 'DD',
    room_type_description: 'DD,MOBACC,BATHTB,NSMK',
    stay_date: '2024-08-01',
    remaining: 2,
  },
  {
    bed_type: 'DD',
    room_type_description: 'DD,MOBACC,BATHTB,NSMK',
    stay_date: '2024-08-02',
    remaining: 2,
  },
  {
    bed_type: 'DD',
    room_type_description: 'DD,MOBACC,BATHTB,NSMK',
    stay_date: '2024-08-03',
    remaining: 2,
  },
  {
    bed_type: 'DD',
    room_type_description: 'DD,MOBACC,BATHTB,NSMK',
    stay_date: '2024-08-04',
    remaining: 2,
  },
  {
    bed_type: 'DD',
    room_type_description: 'DD,NSMK,WHISPD,MICRFG,COFMKR,HAIR,IRON',
    stay_date: '2024-08-01',
    remaining: 24,
  },
  {
    bed_type: 'DD',
    room_type_description: 'DD,NSMK,WHISPD,MICRFG,COFMKR,HAIR,IRON',
    stay_date: '2024-08-02',
    remaining: 24,
  },
  {
    bed_type: 'DD',
    room_type_description: 'DD,NSMK,WHISPD,MICRFG,COFMKR,HAIR,IRON',
    stay_date: '2024-08-03',
    remaining: 24,
  },
  {
    bed_type: 'DD',
    room_type_description: 'DD,NSMK,WHISPD,MICRFG,COFMKR,HAIR,IRON',
    stay_date: '2024-08-04',
    remaining: 24,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,MOBACC,BATHTB,NSMK',
    stay_date: '2024-08-01',
    remaining: 0,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,MOBACC,BATHTB,NSMK',
    stay_date: '2024-08-02',
    remaining: 0,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,MOBACC,BATHTB,NSMK',
    stay_date: '2024-08-03',
    remaining: 0,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,MOBACC,BATHTB,NSMK',
    stay_date: '2024-08-04',
    remaining: 0,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,MOBACC,ROLLSH,NSMK',
    stay_date: '2024-08-01',
    remaining: 0,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,MOBACC,ROLLSH,NSMK',
    stay_date: '2024-08-02',
    remaining: 0,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,MOBACC,ROLLSH,NSMK',
    stay_date: '2024-08-03',
    remaining: 0,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,MOBACC,ROLLSH,NSMK',
    stay_date: '2024-08-04',
    remaining: 0,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,NSMK,BALCON,MICRFG,WIFINT,COFMKR',
    stay_date: '2024-08-01',
    remaining: 31,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,NSMK,BALCON,MICRFG,WIFINT,COFMKR',
    stay_date: '2024-08-02',
    remaining: 31,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,NSMK,BALCON,MICRFG,WIFINT,COFMKR',
    stay_date: '2024-08-03',
    remaining: 31,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,NSMK,BALCON,MICRFG,WIFINT,COFMKR',
    stay_date: '2024-08-04',
    remaining: 31,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,NSMK,BAY,BALCON,MICRFG,WIFI',
    stay_date: '2024-08-01',
    remaining: 6,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,NSMK,BAY,BALCON,MICRFG,WIFI',
    stay_date: '2024-08-02',
    remaining: 6,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,NSMK,BAY,BALCON,MICRFG,WIFI',
    stay_date: '2024-08-03',
    remaining: 6,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,NSMK,BAY,BALCON,MICRFG,WIFI',
    stay_date: '2024-08-04',
    remaining: 6,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,NSMK,WHISPD,MICRFG,COFMKR,HAIR,IRON',
    stay_date: '2024-08-01',
    remaining: 8,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,NSMK,WHISPD,MICRFG,COFMKR,HAIR,IRON',
    stay_date: '2024-08-02',
    remaining: 8,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,NSMK,WHISPD,MICRFG,COFMKR,HAIR,IRON',
    stay_date: '2024-08-03',
    remaining: 10,
  },
  {
    bed_type: 'K',
    room_type_description: 'K,NSMK,WHISPD,MICRFG,COFMKR,HAIR,IRON',
    stay_date: '2024-08-04',
    remaining: 10,
  },
  {
    bed_type: 'QQ',
    room_type_description: 'QQ,NSMK,BALCON,MICRFG,WIFINT,COFMKR',
    stay_date: '2024-08-01',
    remaining: 12,
  },
  {
    bed_type: 'QQ',
    room_type_description: 'QQ,NSMK,BALCON,MICRFG,WIFINT,COFMKR',
    stay_date: '2024-08-02',
    remaining: 12,
  },
  {
    bed_type: 'QQ',
    room_type_description: 'QQ,NSMK,BALCON,MICRFG,WIFINT,COFMKR',
    stay_date: '2024-08-03',
    remaining: 12,
  },
  {
    bed_type: 'QQ',
    room_type_description: 'QQ,NSMK,BALCON,MICRFG,WIFINT,COFMKR',
    stay_date: '2024-08-04',
    remaining: 12,
  },
  {
    bed_type: 'QQ',
    room_type_description: 'QQ,NSMK,BAY,BALCON,MICRFG,WIFI',
    stay_date: '2024-08-01',
    remaining: 6,
  },
  {
    bed_type: 'QQ',
    room_type_description: 'QQ,NSMK,BAY,BALCON,MICRFG,WIFI',
    stay_date: '2024-08-02',
    remaining: 6,
  },
  {
    bed_type: 'QQ',
    room_type_description: 'QQ,NSMK,BAY,BALCON,MICRFG,WIFI',
    stay_date: '2024-08-03',
    remaining: 6,
  },
  {
    bed_type: 'QQ',
    room_type_description: 'QQ,NSMK,BAY,BALCON,MICRFG,WIFI',
    stay_date: '2024-08-04',
    remaining: 5,
  },
  {
    bed_type: 'QQ',
    room_type_description: 'QQ,NSMK,STUDIO,MICRFG,WIFINT,COFMKR',
    stay_date: '2024-08-01',
    remaining: 11,
  },
  {
    bed_type: 'QQ',
    room_type_description: 'QQ,NSMK,STUDIO,MICRFG,WIFINT,COFMKR',
    stay_date: '2024-08-02',
    remaining: 11,
  },
  {
    bed_type: 'QQ',
    room_type_description: 'QQ,NSMK,STUDIO,MICRFG,WIFINT,COFMKR',
    stay_date: '2024-08-03',
    remaining: 11,
  },
  {
    bed_type: 'QQ',
    room_type_description: 'QQ,NSMK,STUDIO,MICRFG,WIFINT,COFMKR',
    stay_date: '2024-08-04',
    remaining: 10,
  },
  {
    bed_type: 'QQ',
    room_type_description: 'QQ,NSMK,STUDIO,SOFBD,MICRFG,WIFINT',
    stay_date: '2024-08-01',
    remaining: 10,
  },
  {
    bed_type: 'QQ',
    room_type_description: 'QQ,NSMK,STUDIO,SOFBD,MICRFG,WIFINT',
    stay_date: '2024-08-02',
    remaining: 10,
  },
  {
    bed_type: 'QQ',
    room_type_description: 'QQ,NSMK,STUDIO,SOFBD,MICRFG,WIFINT',
    stay_date: '2024-08-03',
    remaining: 10,
  },
  {
    bed_type: 'QQ',
    room_type_description: 'QQ,NSMK,STUDIO,SOFBD,MICRFG,WIFINT',
    stay_date: '2024-08-04',
    remaining: 10,
  },
  {
    bed_type: 'SQQ',
    room_type_description: 'SQQ,NSMK,2BALC,JACZZ,SOFABD,MICRFG',
    stay_date: '2024-08-01',
    remaining: 2,
  },
  {
    bed_type: 'SQQ',
    room_type_description: 'SQQ,NSMK,2BALC,JACZZ,SOFABD,MICRFG',
    stay_date: '2024-08-02',
    remaining: 2,
  },
  {
    bed_type: 'SQQ',
    room_type_description: 'SQQ,NSMK,2BALC,JACZZ,SOFABD,MICRFG',
    stay_date: '2024-08-03',
    remaining: 2,
  },
  {
    bed_type: 'SQQ',
    room_type_description: 'SQQ,NSMK,2BALC,JACZZ,SOFABD,MICRFG',
    stay_date: '2024-08-04',
    remaining: 2,
  },
];
