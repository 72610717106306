import { alertAdded } from 'features/alerts/redux/alerts-slice';
import { useDispatch } from 'react-redux';

export const useApolloError = () => {
  const dispatch = useDispatch();

  const handleApolloError = (error: Error) => {
    dispatch(alertAdded('error', error.message));
  };

  return { handleApolloError };
};
