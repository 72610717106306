import { dayDiff, monthsOut, today } from 'features/dates/date-helpers';
export const priorityColors = ['#21E01D', '#FFBF42', '#FF4E4E'];

const endDate = monthsOut({
  months: 3,
  firstOrLast: 'last',
});

export const METRICS_DURATION = dayDiff(today(), endDate) + 1;

export const toDollarAmount = (value: number | undefined) => {
  if (value === undefined || value === null) return;
  let formattedVal = `${value}`;
  if (value % 1 !== 0) formattedVal = value.toFixed(2);
  return `${formattedVal}`;
};

export const toDollarAmountDecimals = (value: number | undefined) => {
  if (value === undefined || value === null) return;
  const formattedVal = value.toFixed(2);
  return `${formattedVal}`;
};
