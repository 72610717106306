import { TableColumn, TableStyles } from 'react-data-table-component';

import DataTable from 'components/data-table/data-table-base';
import { convertArrObjToArrString, transformData } from '../helpers/helpers';
import { RateOffsetLog } from 'graphql/gql-types';
import './style.css';

const customStyles: TableStyles = {
  table: {
    style: {
      border: 'none',
      maxHeight: '650px',
    },
  },
  headCells: {
    style: {
      fontSize: '15px',
      fontWeight: 'bold',
      color: 'white',
      backgroundColor: '#6e6e6e',
    },
  },
  cells: {
    style: {
      fontSize: '12px',
      color: '#4a4a4a',
    },
  },
};

const MyDataTable = (dataRaw: Array<RateOffsetLog>) => {
  const data = convertArrObjToArrString(dataRaw);

  const { rows, headers } = transformData(data);

  const columns: TableColumn<any>[] = headers.map((header) => ({
    name: header.split('')[0].toUpperCase() + header.substring(1),
    selector: (row: any) => row[header],
    center: header === 'date',
    sortable: false,
  }));

  const conditionalRowStyles = [
    {
      when: (row: Record<string, string>) => {
        return row['date'].includes(',');
      },
      style: {
        backgroundColor: '#dedede',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];
  return (
    <DataTable
      customStyles={customStyles}
      columns={columns}
      data={rows}
      pagination={false}
      striped={false}
      highlightOnHover={false}
      conditionalRowStyles={conditionalRowStyles}
    />
  );
};

export const RateOffsetTable = ({ data = [] }: { data: RateOffsetLog[] }) => {
  return (
    <div className='ag-theme-alpine' style={{ maxWidth: '99%' }}>
      {MyDataTable(data)}
    </div>
  );
};
