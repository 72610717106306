/* eslint-disable */
import * as Types from '../../../../graphql/gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEventsQueryVariables = Types.Exact<{
  propertyId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetEventsQuery = { __typename?: 'Query', getEvents?: Array<{ __typename?: 'EventResponse', id?: string | undefined, event_id?: string | undefined, start_date?: string | undefined, end_date?: string | undefined, event?: { __typename?: 'EventType', name?: string | undefined, recurs?: boolean | undefined, category?: Types.EventCategoryEnum | undefined, occ_impact?: number | undefined, property_id?: string | undefined, created_by_id?: string | undefined, created_at?: string | undefined, updated_by_id?: string | undefined, updated_at?: string | undefined } | undefined }> | undefined };

export type CreateEventMutationVariables = Types.Exact<{
  event?: Types.InputMaybe<Types.NewEventInput>;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent?: { __typename?: 'CreateEventResponse', message?: string | undefined, success?: boolean | undefined } | undefined };

export type DeleteEventsMutationVariables = Types.Exact<{
  selectedIds: Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>;
  propertyId: Types.Scalars['String'];
}>;


export type DeleteEventsMutation = { __typename?: 'Mutation', deleteEvents?: { __typename?: 'DeleteEventResponse', message?: string | undefined, success?: boolean | undefined } | undefined };

export type UpdateEventMutationVariables = Types.Exact<{
  event?: Types.InputMaybe<Types.UpdateEventInput>;
  propertyId: Types.Scalars['String'];
}>;


export type UpdateEventMutation = { __typename?: 'Mutation', updateEvent?: { __typename?: 'UpdateEventResponse', message?: string | undefined, success?: boolean | undefined } | undefined };

export type DeleteEventRecordMutationVariables = Types.Exact<{
  record?: Types.InputMaybe<Types.EventRecordInput>;
}>;


export type DeleteEventRecordMutation = { __typename?: 'Mutation', deleteEventRecord?: { __typename?: 'DeleteEventRecordResponse', message?: string | undefined, success?: boolean | undefined } | undefined };


export const GetEventsDocument = gql`
    query GetEvents($propertyId: String) {
  getEvents(propertyId: $propertyId) {
    id
    event_id
    start_date
    end_date
    event {
      name
      recurs
      category
      occ_impact
      property_id
      created_by_id
      created_at
      updated_by_id
      updated_at
    }
  }
}
    `;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
      }
export function useGetEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
        }
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsQueryResult = Apollo.QueryResult<GetEventsQuery, GetEventsQueryVariables>;
export const CreateEventDocument = gql`
    mutation CreateEvent($event: NewEventInput) {
  createEvent(event: $event) {
    message
    success
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const DeleteEventsDocument = gql`
    mutation DeleteEvents($selectedIds: [String]!, $propertyId: String!) {
  deleteEvents(selectedIds: $selectedIds, propertyId: $propertyId) {
    message
    success
  }
}
    `;
export type DeleteEventsMutationFn = Apollo.MutationFunction<DeleteEventsMutation, DeleteEventsMutationVariables>;

/**
 * __useDeleteEventsMutation__
 *
 * To run a mutation, you first call `useDeleteEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventsMutation, { data, loading, error }] = useDeleteEventsMutation({
 *   variables: {
 *      selectedIds: // value for 'selectedIds'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useDeleteEventsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventsMutation, DeleteEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventsMutation, DeleteEventsMutationVariables>(DeleteEventsDocument, options);
      }
export type DeleteEventsMutationHookResult = ReturnType<typeof useDeleteEventsMutation>;
export type DeleteEventsMutationResult = Apollo.MutationResult<DeleteEventsMutation>;
export type DeleteEventsMutationOptions = Apollo.BaseMutationOptions<DeleteEventsMutation, DeleteEventsMutationVariables>;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($event: UpdateEventInput, $propertyId: String!) {
  updateEvent(event: $event, propertyId: $propertyId) {
    message
    success
  }
}
    `;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const DeleteEventRecordDocument = gql`
    mutation DeleteEventRecord($record: EventRecordInput) {
  deleteEventRecord(record: $record) {
    message
    success
  }
}
    `;
export type DeleteEventRecordMutationFn = Apollo.MutationFunction<DeleteEventRecordMutation, DeleteEventRecordMutationVariables>;

/**
 * __useDeleteEventRecordMutation__
 *
 * To run a mutation, you first call `useDeleteEventRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventRecordMutation, { data, loading, error }] = useDeleteEventRecordMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useDeleteEventRecordMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventRecordMutation, DeleteEventRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventRecordMutation, DeleteEventRecordMutationVariables>(DeleteEventRecordDocument, options);
      }
export type DeleteEventRecordMutationHookResult = ReturnType<typeof useDeleteEventRecordMutation>;
export type DeleteEventRecordMutationResult = Apollo.MutationResult<DeleteEventRecordMutation>;
export type DeleteEventRecordMutationOptions = Apollo.BaseMutationOptions<DeleteEventRecordMutation, DeleteEventRecordMutationVariables>;
export const namedOperations = {
  Query: {
    GetEvents: 'GetEvents'
  },
  Mutation: {
    CreateEvent: 'CreateEvent',
    DeleteEvents: 'DeleteEvents',
    UpdateEvent: 'UpdateEvent',
    DeleteEventRecord: 'DeleteEventRecord'
  }
}