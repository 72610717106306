import { ExportDataType, ExportHeaderColumns } from 'types/ExportColumns';

import { CSVLink } from 'react-csv';

type ExportHeader = ExportHeaderColumns[];

type ExportProps = {
  content?: React.ReactElement;
  csvHeaders: ExportHeader;
  csvData: ExportDataType;
  fileName: string;
};

export const ExportCSV = ({
  csvHeaders,
  csvData,
  fileName,
  content,
}: ExportProps) => (
  <CSVLink headers={csvHeaders} data={csvData} filename={fileName}>
    {content}
  </CSVLink>
);
