import './autopilot-form.scss';

import { ErrorMessage, Field, useFormikContext } from 'formik';
import { MenuItem, OutlinedInput, SelectChangeEvent } from '@mui/material';
import {
  selectCurrentSeason,
  unsavedDataChanged,
} from 'features/rules/redux/rules-slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { AutopilotFormValues } from './autopilot';
import { AutopilotOption } from 'graphql/gql-types';
import { NumberInput } from 'components/number-input/number-input';
import { SeasonSelect } from '../season-select/season-select';
import { Select } from 'formik-mui';
import { labels } from 'locales/en.label';
import { useAppTracking } from 'hooks/useTracking';
import { useEffect } from 'react';
import { useUser } from 'features/users/context/userContext';

const commonLabels = labels.common;
const autopilotLabels = labels.rules.autopilot_tab;

const AutopilotSelect = ({ name }: { name: string }) => {
  const { isReadOnly } = useUser();
  const selectedSeason = useAppSelector(selectCurrentSeason);
  const { track } = useAppTracking();

  const priority = name?.split('autopilot')[1];

  const handleChange = (e: SelectChangeEvent<string>) => {
    // options Y and P (positive only) are considered TRUE for enabled.
    const enabled = e.target.value === AutopilotOption.N ? false : true;

    track({
      action: 'Autopilot Select',
      page: 'Rules',
      autopilotPriority: priority,
      autopilotEnabled: enabled,
      autopilotOption: e.target.value,
      seasonName: selectedSeason?.season_name,
      seasonId: selectedSeason?.season_id,
    });
  };

  return (
    <div className='common-dropdown-container autopilot-select-container'>
      <Field
        name={name}
        component={Select}
        onChange={handleChange}
        disabled={isReadOnly}
      >
        <MenuItem value={AutopilotOption.Y}>{commonLabels.yes}</MenuItem>
        <MenuItem value={AutopilotOption.N}>{commonLabels.no}</MenuItem>
        <MenuItem value={AutopilotOption.P}>
          {autopilotLabels.positive_only}
        </MenuItem>
      </Field>
    </div>
  );
};

export const AutopilotForm = () => {
  const { isReadOnly } = useUser();
  const dispatch = useAppDispatch();
  const { values, dirty, handleChange } =
    useFormikContext<AutopilotFormValues>();

  useEffect(() => {
    dispatch(unsavedDataChanged(dirty));
  }, [dirty, dispatch]);

  return (
    <div className='autopilot-form'>
      <div className='row'>
        <div className='common-dropdown-container season-name'>
          <SeasonSelect />
        </div>
      </div>

      <div className='row ap-error'>
        <ErrorMessage name='priorityThresholdHigh' />
      </div>

      <div className='header'>
        <span>{commonLabels.priority}</span>
        <span>{labels.rules.autopilot}</span>
      </div>

      <div className='top-labels'>
        <span className='label large' />
        <div className='priority-fields'>
          <span className='label'>{autopilotLabels.min}</span>
          <span className='label'>{autopilotLabels.max}</span>
        </div>
      </div>

      <div className='main-section'>
        <div className='priority-section'>
          <div className='row'>
            <span className='label large'>{autopilotLabels.high}</span>
            <div className='priority-fields'>
              <NumberInput
                name='priorityThresholdHigh'
                value={values.priorityThresholdHigh}
                onChange={handleChange}
                disabled={isReadOnly}
                styles={{ width: '120px' }}
              />
              <div className='common-dropdown-container'>
                <OutlinedInput
                  className='number-input'
                  disabled
                  style={{ width: '120px' }}
                />
              </div>
            </div>
          </div>

          <div className='row'>
            <span className='label large'>{autopilotLabels.med}</span>
            <div className='priority-fields'>
              <NumberInput
                name='priorityThresholdMed'
                value={values.priorityThresholdMed}
                onChange={handleChange}
                disabled={isReadOnly}
                styles={{ width: '120px' }}
              />
              <NumberInput
                name='priorityThresholdHigh'
                value={values.priorityThresholdHigh}
                onChange={handleChange}
                disabled={isReadOnly}
                styles={{ width: '120px' }}
              />
            </div>
          </div>

          <div className='row'>
            <span className='label large'>{autopilotLabels.low}</span>
            <div className='priority-fields'>
              <NumberInput
                value={values.priorityThresholdLow}
                disabled
                styles={{ width: '120px' }}
              />
              <NumberInput
                name='priorityThresholdMed'
                value={values.priorityThresholdMed}
                onChange={handleChange}
                disabled={isReadOnly}
                styles={{ width: '120px' }}
              />
            </div>
          </div>
        </div>

        <div className='divider'>
          <hr />
        </div>

        <div className='autopilot-section'>
          <div className='row'>
            <AutopilotSelect name='autopilotHigh' />
          </div>
          <div className='row'>
            <AutopilotSelect name='autopilotMed' />
          </div>
          <div className='row'>
            <AutopilotSelect name='autopilotLow' />
          </div>
        </div>
      </div>
    </div>
  );
};
