import './loading-bar.scss';

import { LinearProgress } from '@mui/material';

type LoadingBarProps = {
  loading?: boolean;
  className?: string;
  overlayStyle?: React.CSSProperties | undefined;
  children?: React.ReactElement | null;
};

export const LoadingBar = ({
  loading = true,
  className = '',
  overlayStyle,
  children,
}: LoadingBarProps) => {
  return (
    <div className={`loading-bar-container ${className}`}>
      {loading ? (
        <>
          {children}
          <div className='overlay' style={overlayStyle} />
          <LinearProgress className='progress-bar' />
        </>
      ) : (
        children
      )}
    </div>
  );
};
