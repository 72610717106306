import {
  ColDef,
  GridOptions,
  RowClassParams,
  RowStyle,
} from 'ag-grid-community';

import { CustomHeader } from 'pages/MyView/DashboardCustomHeader';
import { labels } from 'locales/en.label';
import styles from 'common/_variables.module.scss';

const competitorLabels = labels.rules.competitor_table;

export const tableComponents = {
  agColumnHeader: CustomHeader,
};

export const allCompetitorColumnDefs: ColDef[] = [
  {
    headerName: competitorLabels.name,
    field: 'comp_name',
    minWidth: 200,
    cellClass: 'competitor__name',
    sortable: true,
    // cellRenderer: ellipsisCellRenderer,
    // cellRendererParams: {
    //   maxLength: 25, // Adjust the maximum length before truncating
    // },
  },
  // {
  //   headerName: competitorLabels.address,
  //   field: 'comp_address',
  //   minWidth: 200,
  // },
  // { headerName: competitorLabels.city, field: 'comp_city' },
  // { headerName: competitorLabels.state, field: 'comp_state' },
  // { headerName: competitorLabels.zip, field: 'comp_zip' },
  // { headerName: competitorLabels.country, field: 'comp_country' },
];

export const competitorSetColumnDefs: ColDef[] = [
  {
    headerName: competitorLabels.name,
    field: 'comp_name',
    flex: 0.7,
    cellClass: 'competitor__name',
    sortable: true,
    sort: 'asc',
    // cellRenderer: ellipsisCellRenderer,
    // cellRendererParams: {
    //   maxLength: 32, // Adjust the maximum length before truncating
    // },
  },
  // Hidden from display until fully implemented per B20V-2305:
  // {
  //   headerName: competitorLabels.rating,
  //   field: 'rating',
  //   cellRenderer: renderRating,
  //   sortable: true,
  //   sortIndex: 1,
  //   flex: 0.1,
  // },
  // {
  //   headerName: competitorLabels.review,
  //   field: 'user_count',
  //   cellClass: 'competitor__review',
  //   sortable: true,
  //   sortIndex: 2,
  //   width: 100,
  //   flex: 0.1,
  //   minWidth: 100,
  // },
];

export const getRowStyle = (params: RowClassParams): RowStyle | undefined => {
  if (params.node.rowPinned) {
    return {
      backgroundColor: 'rgba(196, 196, 196, 0.34)',
    };
  }
};

export const gridOptions: GridOptions = {
  rowSelection: 'multiple',
  headerHeight: 70,
  rowHeight: 40,
  suppressRowClickSelection: true,
  singleClickEdit: true,
  stopEditingWhenCellsLoseFocus: true,
  suppressMovableColumns: true,
  suppressContextMenu: true,
  onCellEditingStarted: (params) => {
    params.api.startEditingCell({
      rowIndex: params.rowIndex ?? 1,
      colKey: params.column,
    });
  },
};

export const competitorChartSeries = {
  bw_rates: {
    name: 'BWH Rate',
    color: styles.graphBrown,
  },
  market_rates: {
    name: 'Market Rate',
    color: styles.graphLightBlue,
  },
  competitor_rate: {
    color: styles.graphCornflowerBlue,
  },
};
export const competitorTypes: [string, string] = [
  'Competitor Set',
  'All Competitors',
];
