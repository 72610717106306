import { OktaProfileType, useUser } from 'features/users/context/userContext';
import { createContext, useContext, useEffect, useState } from 'react';
import {
  setEnableSellOutFlag,
  setSelloutCount,
} from 'features/header/redux/header-slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  useGetPropertyById,
  useGetpropertySelloutCount,
} from 'hooks/useProperty';
import {
  useGetShowPastLazyQuery,
  useToggleShowPastMutation,
} from 'features/my-view/gql/_gen_/my-metrics.gql';

import { DOW_ALL } from 'features/search/search-helpers';
import { PropertyType } from '../types/RecReviewTypes';
import { apolloErrorAdded } from 'features/alerts/redux/alerts-slice';
import { defaultProperty } from 'components/Header/testInfo';
import { selectProperty } from 'features/property/redux/selectors';
import { setAllRowsSelected } from 'features/rate-upload/redux/rate-upload-slice';
import { setProperty } from 'features/property/redux/property-slice';
import { useAppTracking } from 'hooks/useTracking';

interface PropertyContextProps {
  property: PropertyType;
  setProperty: (property: PropertyType) => void;
  currencySymbol: string;
  setCurrencySymbol: (currencySymbol: string) => void;
  showPast: boolean;
  toggleShowPast: () => void;
}

interface PropertyProviderProps {
  children: React.ReactNode;
}

const PropertyContext = createContext<PropertyContextProps | undefined>(
  undefined
);

const PropertyProvider = ({ children }: PropertyProviderProps) => {
  const { oktaUser, user, locale } = useUser();
  const userId = user?.id;
  const property = useAppSelector(selectProperty);
  const dispatch = useAppDispatch();
  const { track } = useAppTracking();

  const [currencySymbol, setCurrencySymbol] = useState('$');
  const [showPast, setShowPast] = useState(true);

  const [getShowPast] = useGetShowPastLazyQuery({
    variables: {
      propertyId: property.propertyId,
      userId: userId || '',
    },
    onError: (error) => {
      dispatch(apolloErrorAdded(error));
    },
    onCompleted: (data) => {
      const showPastData = data?.getMyMetrics?.show_past;
      if (typeof showPastData === 'boolean' && showPastData !== showPast) {
        setShowPast(showPastData);
      }
    },
  });

  const [toggleShowPast] = useToggleShowPastMutation({
    variables: {
      propertyId: property.propertyId,
      userId: userId || '',
    },
    onError: (error) => {
      dispatch(apolloErrorAdded(error));
    },
  });

  const handleToggleShowPast = () => {
    setShowPast(!showPast);
    toggleShowPast();
  };

  useEffect(() => {
    if (userId && property.propertyId) {
      getShowPast();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, property.propertyId]);

  const input = {
    userId,
    duration: 365,
    selectedDays: DOW_ALL,
  };
  const [getPropertyById] = useGetPropertyById();

  const emptyProperty: PropertyType = {
    propertyId: '',
    propertyName: '',
    currencyCode: 'USD',
  };

  useEffect(() => {
    if (!oktaUser) return;

    if (oktaUser.profileType === OktaProfileType.CORPORATE) {
      if (!property.propertyId) {
        handleSetProperty(defaultProperty);
      }
    } else if (oktaUser.profileType === OktaProfileType.HOTEL) {
      if (!property.propertyId && user?.prop_id && user.prop_id.length > 0) {
        handleSetProperty({
          propertyId: user.prop_id[0],
          propertyName: user.prop_id[0],
        } as PropertyType);
      } else if (user?.prop_id && user.prop_id.length === 0) {
        // they are no authorized hotels
        handleSetProperty(emptyProperty);
      }
    } else {
      handleSetProperty(emptyProperty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaUser, user]);

  useEffect(() => {
    if (user && property) {
      track({
        action: 'Property Viewed',
        propertyId: property.propertyId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, property]);

  useEffect(() => {
    if (!property) return;
    if (property.propertyId === property.propertyName) {
      // Find the property search_name if the propertyId is the same as the propertyName
      getProperty(property.propertyId);
    }
    if (
      property.currencyCode === null ||
      property.currencyCode === '' ||
      property.currencyCode === undefined
    ) {
      console.log('property.currencyCode', property.currencyCode);
      getProperty(property.propertyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property]);

  const { propertySelloutCount } = useGetpropertySelloutCount({
    propertyId: property?.propertyId,
    input,
    userId,
  });

  useEffect(() => {
    if (property?.propertyId && propertySelloutCount >= 0) {
      dispatch(setSelloutCount(propertySelloutCount));
    }
  }, [property?.propertyId, propertySelloutCount, dispatch]);

  useEffect(() => {
    if (property?.propertyId) {
      dispatch(setAllRowsSelected(false));
      const symbol = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: property?.currencyCode || 'USD',
      }).formatToParts(1)[0].value;
      setCurrencySymbol(symbol);
    }
  }, [dispatch, locale, property?.currencyCode, property?.propertyId]);

  const getProperty = async (propertyId: string) => {
    if (!propertyId) return;
    if (propertyId === '') return;

    await getPropertyById({
      variables: { propertyId },
      onCompleted: (data) => {
        if (data && data.getPropertyByPropertyId) {
          const foundProperty = data.getPropertyByPropertyId;
          handleSetProperty({
            propertyId: foundProperty.property_id,
            propertyName: foundProperty.search_name,
            currencyCode: foundProperty.currency_code || 'USD',
          });
        }
      },
      onError(error) {
        dispatch(apolloErrorAdded(error));
      },
    });
  };

  const handleSetProperty = (property: PropertyType) => {
    dispatch(setProperty(property));
    dispatch(setEnableSellOutFlag(false));
  };

  const value = {
    property,
    setProperty: handleSetProperty,
    currencySymbol,
    setCurrencySymbol,
    showPast,
    toggleShowPast: handleToggleShowPast,
  };
  return (
    <PropertyContext.Provider value={value}>
      {children}
    </PropertyContext.Provider>
  );
};

const usePropertyContext = () => {
  const context = useContext(PropertyContext);
  if (context === undefined) {
    throw new Error(`useProperty must be used within a PropertyProvider`);
  }
  return context;
};

export { PropertyProvider, usePropertyContext };
