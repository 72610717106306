import { ApolloError } from '@apollo/client';
import { usePropertyContext } from 'context/propertyContext';
import {
  useCreateEventMutation,
  useDeleteEventRecordMutation,
  useDeleteEventsMutation,
  useGetEventsQuery,
  useUpdateEventMutation,
} from '../gql/_gen_/events.gql';
import {
  CreateEventResponse,
  EventResponse,
  EventCategoryEnum,
  UpdateEventInput,
  NewEventInput,
  EventRecordInput,
} from 'graphql/gql-types';
import { useAppDispatch } from 'redux/hooks';
import { useApolloError } from 'hooks/useApolloError';
import { alertAdded } from 'features/alerts/redux/alerts-slice';
import { labels } from 'locales/en.label';

export interface EventsFormValues {
  id: string | null;
  event_id: string;
  name: string;
  recurs: boolean;
  category: EventCategoryEnum;
  start_date: string;
  end_date: string;
  occ_impact: number;
  property_id: string;
  created_by_id: string;
  created_at: string;
  updated_by_id: string;
  updated_at: string;
}

export const useEvents = (): {
  eventsList: EventResponse[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const {
    property: { propertyId },
  } = usePropertyContext();
  const { data, loading, error } = useGetEventsQuery({
    skip: !propertyId,
    variables: { propertyId },
  });

  return { eventsList: data?.getEvents, loading, error };
};

export const useCreateEvent = () => {
  const dispatch = useAppDispatch();
  const { handleApolloError } = useApolloError();
  const [createNewEvent, { loading: createEventLoading }] =
    useCreateEventMutation({
      refetchQueries: ['GetEvents'],
      awaitRefetchQueries: true,
    });
  const checkEndDateBeforeStartDate = (records: any[]) => {
    return records.some((obj) => obj.start_date === '' || obj.end_date === '');
  };
  const createEvent = async (
    values: NewEventInput
  ): Promise<CreateEventResponse | undefined> => {
    const checkDates = values && checkEndDateBeforeStartDate(values.records!);
    if (checkDates) throw new Error('Start and end dates must be defined');
    const { data } = await createNewEvent({
      variables: {
        event: {
          property_id: values.property_id,
          name: values.name,
          records: values.records,
          recurs: values.recurs,
          occ_impact: values.occ_impact,
          category: values.category,
          updated_by_id: values.updated_by_id,
          created_by_id: values.created_by_id,
          created_at: values.created_at,
          updated_at: values.updated_at,
        },
      },

      onCompleted: () => {
        const message = labels.events.create_confirmation_msg;
        dispatch(alertAdded('success', `${message}`));
      },
      onError: (error) => {
        if (error.message === labels.alerts.insufficientRole) {
          handleApolloError(error);
        } else {
          dispatch(alertAdded('error', labels.events.create_error_msg));
        }
      },
    });

    return data?.createEvent;
  };

  return { createEvent, createEventLoading };
};

export const useDeleteEvents = () => {
  const {
    property: { propertyId },
  } = usePropertyContext();

  const [deleteEventMutation, { loading: deleteEventsLoading }] =
    useDeleteEventsMutation({
      refetchQueries: ['GetEvents'],
      awaitRefetchQueries: true,
    });
  const { handleApolloError } = useApolloError();

  const deleteEvents = async (
    selectedEvents: EventResponse,
    onCompleted?: () => void
  ) => {
    const selectedIds = selectedEvents.event_id;
    await deleteEventMutation({
      variables: {
        selectedIds,
        propertyId,
      },

      onCompleted,
      onError: (error) => {
        handleApolloError(error);
      },
    });
  };

  return { deleteEvents, deleteEventsLoading };
};

export const useUpdateEvent = () => {
  const dispatch = useAppDispatch();

  const [updateExistingEvent, { loading: updateEventLoading }] =
    useUpdateEventMutation({
      refetchQueries: ['GetEvents'],
      awaitRefetchQueries: true,
    });
  const { handleApolloError } = useApolloError();

  const updateEvent = async (
    event: UpdateEventInput,
    propertyId: string,
    onCompleted?: () => void
  ) => {
    const { data } = await updateExistingEvent({
      variables: {
        event,
        propertyId,
      },

      onCompleted: () => {
        const message = labels.events.modify_confirmation_msg;
        dispatch(alertAdded('success', `${message}`));
      },
      onError: (error) => {
        if (error.message === labels.alerts.insufficientRole) {
          handleApolloError(error);
        } else {
          dispatch(alertAdded('error', labels.events.modify_error_msg));
        }
      },
    });
    return data?.updateEvent;
  };

  return { updateEvent, updateEventLoading };
};

export const useDeleteEventRecord = () => {
  const [deleteEventRecordMutation, { loading: deleteEventRecordLoading }] =
    useDeleteEventRecordMutation({
      refetchQueries: ['GetEvents'],
      awaitRefetchQueries: true,
    });
  const { handleApolloError } = useApolloError();

  const deleteEventRecord = async (
    record: EventRecordInput,
    onCompleted?: () => void
  ) => {
    await deleteEventRecordMutation({
      variables: {
        record,
      },

      onCompleted,
      onError: (error) => {
        handleApolloError(error);
      },
    });
  };

  return { deleteEventRecord, deleteEventRecordLoading };
};
