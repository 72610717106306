import { gql } from '@apollo/client';

const CORE_PROP_METRICS_FIELDS = gql`
  fragment CorePropMetricsFields on PropRecReview {
    __typename
    adr
    adr_lyst
    available_rooms
    current_otb
    current_otb_lyf
    current_rate
    current_revenue
    event_id
    fcst_occ_pct
    fcst_rev
    fcst_rev_rec
    group_blocked
    group_booked
    market_rate
    occ_pct
    occ_pct_lyf
    out_of_order
    overriden_rate
    pkp_day_1
    prev_rec_rate
    property_id
    rec_rate
    stay_date
    total_capacity
    total_fcst
    yoy_rate_lyst
  }
`;

const CORE_RATE_LEVEL_FIELDS = gql`
  fragment CoreRateLevelFields on PropRateLevels {
    __typename
    a_current_otb
    a_fcst_sell
    a_rate_level
    a_status
    b_current_otb
    b_fcst_sell
    b_rate_level
    b_status
    c_current_otb
    c_fcst_sell
    c_rate_level
    c_status
    d_current_otb
    d_fcst_sell
    d_rate_level
    d_status
    e_current_otb
    e_fcst_sell
    e_rate_level
    e_status
    f_current_otb
    f_fcst_sell
    f_rate_level
    f_status
    g_fcst_sell
    g_current_otb
    g_rate_level
    g_status
    h_current_otb
    h_fcst_sell
    h_rate_level
    h_status
  }
`;

export const GET_PROPERTY_BY_PROPERTY_ID = gql`
  query GetPropertyByPropertyId($propertyId: String!) {
    getPropertyByPropertyId(propertyId: $propertyId) {
      property_id
      search_name
      timezone
      currency_code
    }
  }
`;

export const GET_PROPERTIES = gql`
  query QueryProperties($searchTerm: String!) {
    getProperties(searchTerm: $searchTerm) {
      search_name
      property_id
      timezone
      currency_code
    }
  }
`;

export const GET_SELLOUT_INDICATOR = gql`
  query QuerySelloutIndicators($propertyId: String!, $stayDate: String!) {
    getSelloutReasons(propertyId: $propertyId, stayDate: $stayDate)
  }
`;

export const SAVE_SEARCH = gql`
  mutation QuerySaveSearch($searchToSave: SearchInfo) {
    SaveSearch(searchToSave: $searchToSave) {
      results
      result_rows
      status_code
      success
    }
  }
`;

export const DELETE_SEARCH = gql`
  mutation QueryDeleteSearch($searchId: Int) {
    DeleteSearch(searchId: $searchId) {
      results
      result_rows
      status_code
      success
    }
  }
`;

export const MAKE_STAY_DATE_FAV_UNFAV = gql`
  mutation ToggleFavoriteStayNight(
    $propertyId: String!
    $stayDate: String!
    $userId: String!
  ) {
    favoriteStayNight(
      propertyId: $propertyId
      stayDate: $stayDate
      userId: $userId
    ) {
      stay_date
      is_favorite
      property_id
      user_id
    }
  }
`;

export const GET_REC_REVIEW_SEARCH_METRICS = gql`
  query QuerySearchMetrics($propertyId: String!) {
    SearchMetrics(propertyId: $propertyId) {
      results {
        lhsCode
        lhsName
        operator {
          code
          value
        }
        rhs {
          code
          value
        }
      }
    }
  }
`;

export const GET_RECREVIEW_DATA = gql`
  ${CORE_PROP_METRICS_FIELDS}
  ${CORE_RATE_LEVEL_FIELDS}
  query QueryRecReview($propertyId: String, $input: SearchInfo) {
    PropMetrics(propertyId: $propertyId, input: $input) {
      result_rows
      results {
        ...CorePropMetricsFields
        adr_lyf
        available_cap
        con_dmd_curr_rate
        con_dmd_rec_rate
        current_otb_lyst
        dow
        overbooking
        derived_rate
        final_rec_rate
        last_current_rate
        market_rate_lyf
        market_rate_lyst
        occ_pct_lyst
        pkp_day_1_ly
        pkp_day_3
        pkp_day_3_ly
        pkp_day_7
        pkp_day_7_ly
        pkp_doa
        pkp_doa_ly
        transient_pkp_day_1
        transient_pkp_day_3
        transient_pkp_day_7
        group_pkp_day_1
        group_pkp_day_3
        group_pkp_day_7
        rate_variance
        revpar_lyf
        revpar
        sellout_ind
        revpar_lyst
        transient_cap
        unc_dmd_curr_rate
        yoy_rate_lyf
        last_updated_date
      }
    }

    getPropRateLevels(propertyId: $propertyId, input: $input) {
      ...CoreRateLevelFields
      property_id
      stay_date
    }

    getPropSeasons(propertyId: $propertyId) {
      property_id
      season_id
      season_name
      season_start
      season_end
      selected_days
      season_priority
      highlight_myview
    }
  }
`;

export const GET_PROP_METRICS = gql`
  ${CORE_PROP_METRICS_FIELDS}
  query QueryPropMetrics($propertyId: String, $input: SearchInfo) {
    PropMetrics(propertyId: $propertyId, input: $input) {
      result_rows
      results {
        ...CorePropMetricsFields
        fcst_rev_rec
        transient_booked
      }
    }
  }
`;

export const GET_FAV_NIGHTS = gql`
  query QueryUserFavNights($propertyId: String, $userId: String) {
    listUserFavNights(propertyId: $propertyId, userId: $userId) {
      stay_date
      is_favorite
      property_id
      user_id
    }
  }
`;

export const GET_RATE_LEVELS = gql`
  ${CORE_RATE_LEVEL_FIELDS}
  query QueryPropRateLevels($propertyId: String, $input: SearchInfo) {
    getPropRateLevels(propertyId: $propertyId, input: $input) {
      ...CoreRateLevelFields
    }
  }
`;

export const GET_CANNED_SEARCHES = gql`
  query GetCannedSearchIds {
    getCannedSearchIds {
      search_id
      search_name
    }
  }
`;
